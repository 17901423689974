/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MasterTok3Dto
 */
export interface MasterTok3Dto {
    /**
     * 小特徴タイプ
     * @type {string}
     * @memberof MasterTok3Dto
     */
    tok3Type?: string;
    /**
     * 小特徴名
     * @type {string}
     * @memberof MasterTok3Dto
     */
    name?: string;
    /**
     * 小特徴種別
     * @type {string}
     * @memberof MasterTok3Dto
     */
    tok3DtlitemBaitType?: string;
}

/**
 * Check if a given object implements the MasterTok3Dto interface.
 */
export function instanceOfMasterTok3Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterTok3DtoFromJSON(json: any): MasterTok3Dto {
    return MasterTok3DtoFromJSONTyped(json, false);
}

export function MasterTok3DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterTok3Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tok3Type': !exists(json, 'tok3Type') ? undefined : json['tok3Type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tok3DtlitemBaitType': !exists(json, 'tok3DtlitemBaitType') ? undefined : json['tok3DtlitemBaitType'],
    };
}

export function MasterTok3DtoToJSON(value?: MasterTok3Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tok3Type': value.tok3Type,
        'name': value.name,
        'tok3DtlitemBaitType': value.tok3DtlitemBaitType,
    };
}

