export * from './occupation';

import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { getRequiredMessage } from '../../../messages';

type Rules = RegisterOptions<FormData, 'jobIntroduction.occupations'>;

/**
 * 職種のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useOccupationsValidationRules = (): Rules => {
  const propertyName = '職種';
  const minLength = 1;
  const maxLength = 6;

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredMessage(propertyName, 'select')
      },
      validate: {
        minItems: (value) => {
          if (!value || value.length < minLength)
            return `${propertyName}は${minLength}つ以上選択してください。`;

          return undefined;
        },
        maxItems: (value) => {
          if (!value || value.length > maxLength)
            return `${propertyName}は${maxLength}つ以下で選択してください。`;

          return undefined;
        }
      }
    }),
    []
  );
};
