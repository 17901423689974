import { FC, MouseEventHandler } from 'react';
import { ArrowRightIcon } from 'assets/icons';
import { CustomButton } from 'components/parts/CustomButton';

type Props = {
  isHoverDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * 前ステップへ戻るボタンを返す
 * @param {boolean | undefined} isHoverDisabled ホバーが無効であるか
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 前ステップへ戻るボタン
 */
const PrevButton: FC<Props> = ({ isHoverDisabled = false, onClick }) => {
  return (
    <CustomButton
      disabled={false}
      width={'104px'}
      height={'48px'}
      textcolor={'var(--kanri-secondary-text)'}
      border="1px solid"
      bordercolor="#CCC"
      backgroundcolor="white"
      hoveropacity={isHoverDisabled ? '1' : '0.85'}
      hoverborder="1px solid"
      hoverbordercolor="#CCC"
      onClick={onClick}
    >
      <ArrowRightIcon
        style={{
          transform: 'rotate(180deg)',
          marginRight: '8px'
        }}
      />
      <span
        style={{
          display: 'inline-block',
          textAlign: 'center',
          width: '100%',
          marginRight: '6px',
          fontSize: 14
        }}
      >
        戻る
      </span>
    </CustomButton>
  );
};

export default PrevButton;
