/**
 * 文字列をDateに変換
 * 文字列をDateに変換する際ブラウザによって対応している日付フォーマットが異なるので、
 * どのモダンブラウザでも対応しているフォーマットを経由して変換する
 * @param str
 * @returns
 */
export const strToDate = (str: string) => {
  const splitted = str.replace(/-/g, '/').split('.');
  const date = new Date(splitted[0]);
  if (splitted[1]) date.setMilliseconds(Number(splitted[1]));
  return date;
};

/**
 * 数値を指定された桁数で0埋めする
 * @param {number} value 数値
 * @param {number} length 桁数
 * @returns {string} 0埋めされた文字列
 */
export const zeroPadding = (value: number, length: number) =>
  value.toString().padStart(length, '0');
