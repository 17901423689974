import { ChangeEventHandler, FC, FocusEventHandler } from 'react';
import { TextField as MuiTextField } from '@mui/material';
import Control from './TextFieldControl';
import CountHelperText from './TextFieldCountHelperText';
import Label from './TextFieldLabel';

type Props = {
  label?: string;
  value?: string;
  placeholder?: string;
  width?: number;
  maxLength?: number;
  rows?: number;
  hasHelperText?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  isError?: boolean;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};

/**
 * テキストフィールドを返す
 * @param {string | undefined} label ラベル文言
 * @param {string | undefined} value 入力値
 * @param {string | undefined} placeholder プレースホルダ
 * @param {number | undefined} width フィールド幅
 * @param {number | undefined} maxLength 最大入力文字数
 * @param {number | undefined} rows 入力行数
 * @param {boolean | undefined} hasHelperText ヘルパーテキストを表示するか
 * @param {boolean | undefined} isRequired 入力が必須であるか
 * @param {boolean | undefined} isDisabled 入力が無効であるか
 * @param {string} errorMessage エラーメッセージ
 * @param {boolean | undefined} isError エラーがあるか
 * @param {FocusEventHandler<HTMLTextAreaElement> | undefined} onBlur フォーカスが外れた際に実行される関数
 * @param {ChangeEventHandler<HTMLTextAreaElement> | undefined} onChange 値が変更された際に実行される関数
 * @returns テキストフィールド
 */
const TextField: FC<Props> = ({
  label = '',
  value = '',
  placeholder = '',
  width = 0,
  maxLength = 999,
  rows = 0,
  hasHelperText = false,
  isRequired = false,
  isDisabled = false,
  errorMessage,
  isError = false,
  onBlur,
  onChange
}) => {
  const displayHelperText = () => {
    if (errorMessage && isError) {
      return errorMessage;
    }
    if (hasHelperText) {
      return (
        <CountHelperText
          maxLength={maxLength}
          remainingLength={maxLength - value.length}
          isError={value.length > maxLength}
        />
      );
    }
    return null;
  };
  return (
    <Control disabled={isDisabled}>
      <MuiTextField
        label={label && <Label label={label} isRequired={isRequired} />}
        value={value}
        placeholder={placeholder}
        helperText={displayHelperText()}
        fullWidth={width <= 0}
        multiline={rows > 0}
        rows={rows}
        disabled={isDisabled}
        error={isError}
        style={{ ...(width && { width }) }}
        onBlur={onBlur}
        onChange={onChange}
      />
    </Control>
  );
};

export default TextField;
