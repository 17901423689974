import React from 'react';

/**
 * @typedef {object} TabContentFormWrapProps
 * @property {React.ReactNode} children - 子要素として描画するReactノード
 */
type TabContentFormWrapProps = {
  children: React.ReactNode;
};

/**
 * タブコンテンツの内部レイアウトを管理するコンポーネント。
 * @param {TabContentFormWrapProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} 内部コンテンツのレンダリング
 */
const TabContentFormWrap: React.FC<TabContentFormWrapProps> = ({
  children
}) => {
  return (
    <div
      style={{
        padding: '10px 0 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}
    >
      {children}
    </div>
  );
};

export default TabContentFormWrap;
