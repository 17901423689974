import {
  Alert,
  AlertColor,
  AlertProps,
  Snackbar,
  SnackbarCloseReason,
  Stack,
  keyframes
} from '@mui/material';
import { MdiCloseBoxIcon } from 'assets/icons';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import { styled } from '@mui/system';

const PopupAlertBarAnimation = keyframes`
    0% { transform: translateY(100%); }
    60% { transform: translateY(-10%); }
    70% { transform: translateY(10%); }
    80% { transform: translateY(-10%); }
    90% { transform: translateY(5%); }
    100% { transform: translateY(0%); }
`;

const AnimatedDiv = styled('div')`
  animation: ${PopupAlertBarAnimation} 0.7s forwards;
`;

const CustomTransition = forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode }
>((props, ref) => {
  return <AnimatedDiv ref={ref}>{props.children}</AnimatedDiv>;
});

const CustomSnackbar = styled(Snackbar)({
  '.MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center'
  }
});
interface CustomAlertProps extends AlertProps {
  bgcolor: string;
}
const CustomAlert = styled(Alert)<CustomAlertProps>(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  '.MuiAlert-message': {
    display: 'flex',
    alignItems: 'center'
  },
  borderRadius: '8px'
}));

const StyledCloseIcon = styled(MdiCloseBoxIcon)({
  cursor: 'pointer',
  fill: '#FFFFFF',
  '&:hover': {
    opacity: 0.8
  }
});

type AlertMessageType =
  | 'success'
  | 'saveSuccess'
  | 'serverError'
  | 'exclusiveCheckError';

type Props = {
  type?: AlertMessageType;
  isOpened?: boolean;
  onClose?: () => void;
  setOpened: Dispatch<SetStateAction<boolean>>;
  autoHideDurationMs?: number | null;
  additionalMessage?: string;
};

const alertMessages: {
  [key in AlertMessageType]: {
    severity: AlertColor;
    message: React.ReactElement | string;
    backgroundColor: string;
  };
} = {
  success: {
    severity: 'success',
    message: '処理が成功しました。',
    backgroundColor: 'rgba(41, 135, 45, 0.90)'
  },
  saveSuccess: {
    severity: 'success',
    message: '採用サイトを一時保存しました。',
    backgroundColor: 'rgba(41, 135, 45, 0.90)'
  },
  serverError: {
    severity: 'error',
    message: (
      <>
        サーバーエラーが
        <wbr />
        発生しました。
        <wbr />
        時間を置いて
        <wbr />
        ページを再表示してください。
      </>
    ),
    backgroundColor: 'rgba(220, 19, 87, 0.90)'
  },
  exclusiveCheckError: {
    severity: 'error',
    message: (
      <>
        既に他のユーザーによって
        <wbr />
        更新されています。
        <wbr />
        ページを再表示して
        <wbr />
        やり直してください
      </>
    ),
    backgroundColor: 'rgba(220, 19, 87, 0.90)'
  }
};

function CommonSnackbar({
  type = 'success',
  isOpened = false,
  setOpened,
  autoHideDurationMs = null,
  onClose,
  additionalMessage = ''
}: Props) {
  const handleClose = useCallback(
    (reason: SnackbarCloseReason) => {
      if (reason !== 'clickaway') {
        onClose?.();
      }
    },
    [onClose]
  );

  return (
    <Stack>
      <CustomSnackbar
        open={isOpened}
        {...(autoHideDurationMs !== null
          ? { autoHideDuration: autoHideDurationMs }
          : {})}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        style={{ minWidth: '90%' }}
        onClose={(_, reason) => handleClose(reason)}
        TransitionComponent={CustomTransition}
      >
        <CustomAlert
          elevation={6}
          variant="filled"
          severity={alertMessages[type].severity}
          bgcolor={alertMessages[type].backgroundColor}
        >
          <div
            style={{
              display: 'inline-block',
              fontSize: '16px',
              wordBreak: 'keep-all',
              textAlign: 'center',
              width: '100%'
            }}
          >
            {alertMessages[type].message}
            {additionalMessage}
          </div>
          {autoHideDurationMs === null ? (
            <StyledCloseIcon onClick={() => setOpened(false)} />
          ) : (
            <></>
          )}
        </CustomAlert>
      </CustomSnackbar>
    </Stack>
  );
}

export type SnackbarHandler = {
  open: (type: AlertMessageType, additionalMessage?: string) => void;
};

export const CommonSnackbarWithRef = forwardRef<SnackbarHandler>((_, ref) => {
  const [messageType, setMessageType] = useState<AlertMessageType>('success');
  const [additionalMessage, setAdditionalMessage] = useState<string>('');
  const [isOpened, setOpened] = useState(false);

  const handleClose = useCallback(() => setOpened(false), []);

  useImperativeHandle(ref, () => ({
    open: (type, am = '') => {
      setMessageType(type);
      setAdditionalMessage(am);
      setOpened(true);
    }
  }));

  return (
    <CommonSnackbar
      isOpened={isOpened}
      setOpened={setOpened}
      type={messageType}
      additionalMessage={additionalMessage}
      onClose={handleClose}
    />
  );
});
