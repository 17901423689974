import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useImageDownloader from 'hooks/saiyouPageEditing/useImageDownloader';
import { PresetImageData } from 'types/saiyouPageEditing/PresetImageData';
import ImageRadioGroup from './ImageRadioGroup';

type Props = {
  selectedImageUrl?: string;
  onSelectImage?: (imageUrl?: string) => void;
  onServerError?: () => void;
};

/**
 * 「フリー素材から選ぶ」タブを返す
 * @param {string | undefined} selectedImageUrl 選択されている画像のURL
 * @param {Function | undefined} onSelectImage 画像が選択された際に実行される関数
 * @param {Function | undefined} onServerError サーバーエラーが発生した際に実行される関数
 * @returns 「フリー素材から選ぶ」タブ
 */
const FreeImageTab: FC<Props> = ({
  selectedImageUrl = '',
  onSelectImage,
  onServerError
}) => {
  const { compId } = useParams();

  const { downloadImagesAsync } = useImageDownloader('free');

  const [imageOptions, setImageOptions] = useState<PresetImageData[]>([]);
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [hasLoadingProgress, setLoadingProgress] = useState(false);

  const selectedImage = useMemo(
    () => imageOptions.find(({ url }) => url === selectedImageUrl),
    [selectedImageUrl, imageOptions]
  );

  const finishLoading = useCallback(() => {
    setLoadingProgress(false);
    setLoadingComplete(true);
  }, []);

  useEffect(() => {
    const downloadAsync = async (localCompId: string) => {
      setLoadingProgress(true);

      const result = await downloadImagesAsync(localCompId);

      if (!result.isSuccess) {
        finishLoading();
        onServerError?.();

        return;
      }

      setImageOptions(result.images);
      finishLoading();
    };

    if (compId && !isLoadingComplete) void downloadAsync(compId);
  }, [
    compId,
    isLoadingComplete,
    onServerError,
    downloadImagesAsync,
    finishLoading
  ]);

  return (
    <div className="free">
      <ImageRadioGroup
        selectedImage={selectedImage}
        imageOptions={imageOptions}
        hasLoadingProgress={hasLoadingProgress}
        onSelect={onSelectImage}
      />
    </div>
  );
};

export default FreeImageTab;
