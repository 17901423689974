import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface CustomTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  children?: React.ReactNode;
}

const CustomTabs = styled((props: CustomTabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent'
    },
    backgroundColor: '#AEB6D1',
    borderRadius: '6px',
    minHeight: 'unset',
    padding: '1px',
    marginRight: '40px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)'
  })
);

const CustomTab = styled((props: React.ComponentProps<typeof Tab>) => (
  <Tab {...props} />
))(({ theme }) => ({
  '&.MuiButtonBase-root': {
    width: '70px',
    height: '40px',
    minWidth: 'unset',
    minHeight: 'unset',
    boxShadow: 'none',
    ':first-of-type': {
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px'
    },
    ':last-of-type': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px'
    },
    '&.Mui-selected': {
      backgroundColor: '#9FB2BF',
      boxShadow: '1px 1px 1px 0 rgba(0, 0, 0, 0.15) inset'
    }
  },
  backgroundColor: 'white',
  border: '1px solid #AEB6D1',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)'
}));

export { CustomTabs, CustomTab };
