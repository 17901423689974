import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

/**
 * フォームのオートコンプリートHooks
 * @returns フォームのオートコンプリート関数群
 */
const useFormAutocomplete = (): {
  autocomplete: (formData: FormData) => void;
} => {
  const { setValue } = useFormContext<FormData>();

  const autocomplete = useCallback(
    (formData: FormData) => {
      setValue('style', formData.style);
      setValue('topImage', formData.topImage);
      setValue('corporationIntroduction', formData.corporationIntroduction);
      setValue('jobIntroduction', formData.jobIntroduction);
      setValue('staffInterview', formData.staffInterview);
      const newJobMgrNos = Array(3)
        .fill('')
        .map((v, i) => formData.jobMgrNos[i] || v);
      setValue('jobMgrNos', newJobMgrNos);
      setValue('jobPoint', formData.jobPoint);
      setValue('afterProcess', formData.afterProcess);
      setValue('message', formData.message);
      setValue('faq', formData.faq);
      setValue('corporationName', formData.corporationName);
      setValue('logoImagePath', formData.logoImagePath);
      setValue('homepageUrl', formData.homepageUrl);
      setValue('sns', formData.sns);
      setValue('corporationAbout', formData.corporationAbout);
      setValue('isVisibleTopPage', formData.isVisibleTopPage);
      setValue('siteTitle', formData.siteTitle);
      const newBrandNames = Array(5)
        .fill('')
        .map((v, i) => formData.brandNames[i] || v);
      setValue('brandNames', newBrandNames);
      setValue('employmentStatus', formData.employmentStatus);
      setValue('siteDescription', formData.siteDescription);
    },
    [setValue]
  );

  return { autocomplete };
};

export default useFormAutocomplete;
