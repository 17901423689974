/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrentCorporation } from './CurrentCorporation';
import {
    CurrentCorporationFromJSON,
    CurrentCorporationFromJSONTyped,
    CurrentCorporationToJSON,
} from './CurrentCorporation';

/**
 * 
 * @export
 * @interface MeResDto
 */
export interface MeResDto {
    /**
     * アカウント名
     * @type {string}
     * @memberof MeResDto
     */
    name?: string;
    /**
     * アカウントタイプ
     * @type {number}
     * @memberof MeResDto
     */
    accountType?: MeResDtoAccountTypeEnum;
    /**
     * ロールコード
     * @type {Array<number>}
     * @memberof MeResDto
     */
    roleCodes?: Array<MeResDtoRoleCodesEnum>;
    /**
     * 
     * @type {CurrentCorporation}
     * @memberof MeResDto
     */
    currentCorporation?: CurrentCorporation;
    /**
     * 本番採用ページ設定更新権限があるかどうか
     * @type {string}
     * @memberof MeResDto
     */
    isAvailablePublish?: MeResDtoIsAvailablePublishEnum;
}


/**
 * @export
 */
export const MeResDtoAccountTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type MeResDtoAccountTypeEnum = typeof MeResDtoAccountTypeEnum[keyof typeof MeResDtoAccountTypeEnum];

/**
 * @export
 */
export const MeResDtoRoleCodesEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28
} as const;
export type MeResDtoRoleCodesEnum = typeof MeResDtoRoleCodesEnum[keyof typeof MeResDtoRoleCodesEnum];

/**
 * @export
 */
export const MeResDtoIsAvailablePublishEnum = {
    _1: '1',
    _0: '0'
} as const;
export type MeResDtoIsAvailablePublishEnum = typeof MeResDtoIsAvailablePublishEnum[keyof typeof MeResDtoIsAvailablePublishEnum];


/**
 * Check if a given object implements the MeResDto interface.
 */
export function instanceOfMeResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MeResDtoFromJSON(json: any): MeResDto {
    return MeResDtoFromJSONTyped(json, false);
}

export function MeResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'accountType': !exists(json, 'accountType') ? undefined : json['accountType'],
        'roleCodes': !exists(json, 'roleCodes') ? undefined : json['roleCodes'],
        'currentCorporation': !exists(json, 'currentCorporation') ? undefined : CurrentCorporationFromJSON(json['currentCorporation']),
        'isAvailablePublish': !exists(json, 'isAvailablePublish') ? undefined : json['isAvailablePublish'],
    };
}

export function MeResDtoToJSON(value?: MeResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'accountType': value.accountType,
        'roleCodes': value.roleCodes,
        'currentCorporation': CurrentCorporationToJSON(value.currentCorporation),
        'isAvailablePublish': value.isAvailablePublish,
    };
}

