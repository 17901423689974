import { FC, useMemo } from 'react';
import { CheckCircleIcon } from 'assets/icons';

type Props = {
  index?: number;
  supplementText?: string;
  hasStepText?: boolean;
  isSelected?: boolean;
  isCompleted?: boolean;
};

/**
 * プログレスバーのステップを返す
 * @param {number | undefined} index アイコン内の番号
 * @param {string | undefined} supplementText アイコン下の説明文
 * @param {boolean | undefined} hasStepText アイコン上の「STEP」を表示するか
 * @param {boolean | undefined} isSelected このステップが選択されているか
 * @param {boolean | undefined} isCompleted このステップが完了しているか
 * @returns プログレスバーのステップ
 */
const ProgressStep: FC<Props> = ({
  index = undefined,
  supplementText = '',
  hasStepText = false,
  isSelected = false,
  isCompleted = false
}) => {
  const rootClassName = useMemo(() => {
    const shared = 'step-wrap';
    if (isSelected) return `${shared} active`;
    if (isCompleted) return `${shared} checked`;

    return shared;
  }, [isSelected, isCompleted]);

  const displayIndex = useMemo(() => (index ? index.toString() : ''), [index]);

  return (
    <div className={rootClassName}>
      <span className="step-text">
        {hasStepText && !isCompleted ? 'STEP' : ''}
      </span>
      <div className="step-number">
        {isCompleted ? <CheckCircleIcon /> : displayIndex}
      </div>
      <span className="step-supplement">{supplementText}</span>
    </div>
  );
};

export default ProgressStep;
