import { generateTextRule } from '../shared';

/**
 * 有料職業紹介事業許可番号のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useLicenseNo2ValidationRules = () => {
  const propertyName = '有料職業紹介事業許可番号';
  const maxLength = 100;
  return generateTextRule<'corporationAbout.licenseNo2'>(
    propertyName,
    maxLength,
    false
  );
};
