/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageUploadReqDto
 */
export interface ImageUploadReqDto {
    /**
     * アップロードするファイルの種類
     * @type {string}
     * @memberof ImageUploadReqDto
     */
    type?: ImageUploadReqDtoTypeEnum;
    /**
     * どの用途用にリサイズをかけるか。（icon=縦360px横240px、default=縦1920px横1440px）
     * @type {string}
     * @memberof ImageUploadReqDto
     */
    resizeMode?: ImageUploadReqDtoResizeModeEnum;
    /**
     * URL文字列。外部URL形式でアップロードする場合は必須
     * @type {string}
     * @memberof ImageUploadReqDto
     */
    outerUrl?: string;
    /**
     * Base64文字列(DataURI)。Base64形式でアップロードする場合は必須
     * @type {string}
     * @memberof ImageUploadReqDto
     */
    base64?: string;
}


/**
 * @export
 */
export const ImageUploadReqDtoTypeEnum = {
    Base64: 'base64',
    OuterUrl: 'outerUrl'
} as const;
export type ImageUploadReqDtoTypeEnum = typeof ImageUploadReqDtoTypeEnum[keyof typeof ImageUploadReqDtoTypeEnum];

/**
 * @export
 */
export const ImageUploadReqDtoResizeModeEnum = {
    Default: 'default',
    Icon: 'icon'
} as const;
export type ImageUploadReqDtoResizeModeEnum = typeof ImageUploadReqDtoResizeModeEnum[keyof typeof ImageUploadReqDtoResizeModeEnum];


/**
 * Check if a given object implements the ImageUploadReqDto interface.
 */
export function instanceOfImageUploadReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImageUploadReqDtoFromJSON(json: any): ImageUploadReqDto {
    return ImageUploadReqDtoFromJSONTyped(json, false);
}

export function ImageUploadReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageUploadReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'resizeMode': !exists(json, 'resizeMode') ? undefined : json['resizeMode'],
        'outerUrl': !exists(json, 'outerUrl') ? undefined : json['outerUrl'],
        'base64': !exists(json, 'base64') ? undefined : json['base64'],
    };
}

export function ImageUploadReqDtoToJSON(value?: ImageUploadReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'resizeMode': value.resizeMode,
        'outerUrl': value.outerUrl,
        'base64': value.base64,
    };
}

