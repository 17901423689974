type InputType = 'input' | 'select' | 'upload';

const actionNames: { [key in InputType]: string } = {
  input: '入力',
  select: '選択',
  upload: 'アップロード'
};

/**
 * 必須バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {InputType} type 入力方式
 * @returns {string} エラーメッセージ
 */
export const getRequiredMessage = (
  propertyName: string,
  type: InputType
): string => `${propertyName}を${actionNames[type]}してください。`;
