import { generateTextRule } from '../shared';

/**
 * 採用担当からのメッセージのプロフィール情報のバリデーションルール返す
 * @returns  バリデーションルール
 */
export const useMessageProfileValidationRules = () => {
  const propertyName = 'プロフィール情報';
  const maxLength = 20;

  return generateTextRule<`message.profile`>(propertyName, maxLength, false);
};
