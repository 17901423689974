import { FC, MouseEventHandler } from 'react';
import { Button, styled } from '@mui/material';
import { EditIcon } from 'assets/icons';

type Props = {
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * 画像やアイコンの変更ボタンを返す
 * @param {string | undefined} label ラベル (ボタンに表示される文言)
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 変更ボタン
 */
const EditImageOrIconButton: FC<Props> = ({ label = '', onClick }) => {
  return (
    <CustomButton onClick={onClick}>
      <EditIcon />
      {label}
    </CustomButton>
  );
};

export default EditImageOrIconButton;

const CustomButton = styled(Button)(() => ({
  display: 'flex',
  height: '38px',
  padding: '10px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
  borderRadius: '8px',
  border: ' 1px solid #CCC',
  background: '#FFF',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
  color: 'var(--kanri-secondary-text, #595959)',
  textAlign: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '100%',
  width: '160px'
}));
