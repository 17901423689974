import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { LeftMenuTabType } from 'types/saiyouPageEditing/LeftMenuTabType';
import styles from './index.module.scss';
import TabContentHead from './shared/TabContentHead';
import TabContentInner from './shared/TabContentInner';
import TabContentText from './shared/TabContentText';
import TabContentWrapper from './shared/TabContentWrapper';
import DesignSettingSection from './DesignSettingSection';
import ContentsSettingSection from './ContentsSettingSection';
import ScrollToTopButton from './ScrollToTopButton';
import AccordionContext from 'context/saiyouPageEditing/AccordionContext';
import SiteSettingSection from './SiteSettingSection';
import SEOSettingSection from './SEOSettingSection';
import InitGuide from '../InitGuide';

type Props = {
  scrollTargetTab?: LeftMenuTabType;
  isVisible?: boolean;
};

/**
 * 左ペインのタブコンテンツを返す
 * @param {LeftMenuTabType | undefined} scrollTargetTab スクロール対象のタブ
 * @param {boolean | undefined} isVisible 表示するか
 * @returns 左ペインのタブコンテンツ
 */
const DrawerTabContent: FC<Props> = ({
  scrollTargetTab,
  isVisible = false
}) => {
  const { rootRef } = useContext(AccordionContext);

  const scrollRef = useRef<HTMLDivElement>(null);

  const designSection = useRef<HTMLDivElement>(null);
  const contentSection = useRef<HTMLDivElement>(null);
  const siteSection = useRef<HTMLDivElement>(null);
  const seoSection = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [scrollRef]);

  const scrollTargetSection = useMemo(() => {
    if (!scrollTargetTab) return null;

    return {
      design: designSection,
      content: contentSection,
      site: siteSection,
      seo: seoSection
    }[scrollTargetTab];
  }, [scrollTargetTab, designSection, contentSection, siteSection, seoSection]);

  useEffect(() => {
    if (!scrollTargetSection) return;

    scrollTargetSection.current?.scrollIntoView();
  }, [scrollTargetSection]);

  return (
    <div
      ref={rootRef}
      style={{ height: '100%', overflowY: 'auto', position: 'relative' }}
    >
      <div
        ref={scrollRef}
        className={`${styles['fade-in']} ${isVisible ? styles.active : ''}`}
        style={{ padding: '20px 10px 100px' }}
      >
        <TabContentWrapper>
          <InitGuide />
          <TabContentInner>
            <TabContentHead title="採用サイトの設定" />
            <TabContentText text="求職者に貴社の魅力を伝え、応募を後押しするTOPページを作りましょう。設定した内容は画面右側のプレビューで確認できます。" />
          </TabContentInner>
        </TabContentWrapper>
        <DesignSettingSection sectionRef={designSection} />
        <ContentsSettingSection sectionRef={contentSection} />
        <SiteSettingSection sectionRef={siteSection} />
        <SEOSettingSection sectionRef={seoSection} />
        <ScrollToTopButton onClick={scrollToTop} />
      </div>
    </div>
  );
};

export default DrawerTabContent;
