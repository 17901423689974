import { FC } from 'react';
import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { TopHeaderMenu } from 'components/parts/topHeaderMenu';

type Props = {
  children: React.ReactNode;
  mainContentWidth: number;
  changeSlider: (value: number) => void;
  sliderValue: number;
  changeDevice: (value: number) => void;
  deviceValue: number;
  canPublish?: boolean;
  onClickPreview?: () => void;
  pageSetting?: PageSettingAdminReferenceResDto;
};

/**
 * 編集ページ専用のヘッダーを表示するため、共通レイアウトと分離
 * @param {React.ReactNode} children 内部のhtml
 * @param {number} mainContentWidth mainタグの幅
 * @param {Function} changeSlider 拡大縮小のバーに変更があった時に動く関数
 * @param {number} sliderValue 拡大縮小のバーの現在値
 * @param {Function} changeDevice PC・iPhoneの表示切り替えがあった時に動く関数
 * @param {number} deviceValue PC・iPhoneの表示切り替えの現在値
 * @param {Function | undefined} onClickPreview プレビューボタンがクリックされた際に実行される関数
 * @param {PageSettingAdminReferenceResDto} pageSetting ページ設定
 * @returns {JSX.Element} 編集画面レイアウトコンポーネント
 */
export const EditPageLayout: FC<Props> = ({
  children,
  mainContentWidth,
  changeSlider,
  sliderValue,
  changeDevice,
  deviceValue,
  onClickPreview,
  pageSetting
}) => {
  return (
    <div className="flex flex-col">
      <TopHeaderMenu
        headerMode="edit"
        mainContentWidth={mainContentWidth}
        changeSlider={changeSlider}
        sliderValue={sliderValue}
        changeDevice={changeDevice}
        deviceValue={deviceValue}
        onClickPreview={onClickPreview}
        pageSetting={pageSetting}
      >
        <div>{children}</div>
      </TopHeaderMenu>
    </div>
  );
};
