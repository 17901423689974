import { generateTextRule } from '../shared';

/**
 * 代表者名のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const usePresidentNameValidationRules = () => {
  const propertyName = '代表者名';
  const maxLength = 50;
  return generateTextRule<'corporationAbout.presidentName'>(
    propertyName,
    maxLength,
    false
  );
};
