import { FC } from 'react';
import { TopHeaderMenu } from '../parts/topHeaderMenu';

type Props = {
  children: React.ReactNode;
};

export const PrivatePageLayout: FC<Props> = ({ children }) => {
  return (
    <div>
      <TopHeaderMenu>
        <div>{children}</div>
      </TopHeaderMenu>
    </div>
  );
};
