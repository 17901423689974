import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { generateTextRule } from '../../shared';

type Rules = RegisterOptions<
  FormData,
  `jobIntroduction.occupations.${number}.description`
>;

/**
 * 職種紹介文のバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useOccupationDescriptionValidationRules = (): Rules => {
  const propertyName = '職種紹介文';
  const maxLength = 100;
  return generateTextRule<`jobIntroduction.occupations.${number}.description`>(
    propertyName,
    maxLength,
    true
  );
};
