import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from './pages/Loading';
import { NotFoundPage } from './pages/ErrorPage';
import {
  CommonSnackbarWithRef,
  SnackbarHandler
} from './parts/SnackBarWrapper';
import { MeResDto } from 'api-client/management';
import { getMe } from 'logic/clientWrapper/management';

interface LoginWrapperProps {
  children: ReactNode;
}
interface LoginContextContent {
  meInfo?: MeResDto;
}
const LoginContext = createContext<LoginContextContent>({});

export const LoginWrapper: FC<LoginWrapperProps> = ({ children }) => {
  const auth0 = useAuth0();
  const { isAuthenticated, user, isLoading, loginWithRedirect } = auth0;
  const { compId } = useParams();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [meInfo, setMeInfo] = useState<MeResDto>();
  const snackbar = useRef<SnackbarHandler>(null);

  //compIdが数字ではない場合はログイン前にエラーページに遷移させる
  if (!/^\d+$/.test(compId ?? '')) {
    return <NotFoundPage />;
  }

  useEffect(() => {
    (async () => {
      if (!user) return;

      try {
        const u = await getMe(compId, auth0); //ログインユーザー情報の取得
        if (u) {
          setMeInfo(u);
        }
      } catch (_) {
        snackbar.current?.open('serverError');
      }
    })();
  }, [compId, isAuthenticated, user]);

  //ローカルストレージに認証情報がある場合はログインせずにトップページへ遷移する
  useEffect(() => {
    if (isAuthenticated && user && compId && location === `/${compId}`) {
      navigate(`top`);
    }
  }, [isAuthenticated, user, compId, navigate, location]);

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated && !meInfo) {
    return (
      <>
        <Loading />
        <CommonSnackbarWithRef ref={snackbar} />
      </>
    );
  }

  //ログイン後、/に遷移してしまうので、appStateプロパティでログイン前のパス（/企業番号）を保存し、onRedirectCallbackに渡してリダイレクトを実施
  if (!isAuthenticated && !user) {
    loginWithRedirect({
      appState: { returnTo: `${compId}/top`, compId: compId }
    });
    return <Loading />;
  }

  return (
    <LoginContext.Provider value={{ meInfo: meInfo }}>
      {children}
    </LoginContext.Provider>
  );
};
export const useLoginContext = (): LoginContextContent =>
  useContext(LoginContext);
