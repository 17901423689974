import * as React from 'react';
import { styled } from '@mui/material/styles';
import ListItemButton, {
  ListItemButtonProps
} from '@mui/material/ListItemButton';

interface CustomListItemButtonProps extends ListItemButtonProps {
  open: boolean;
  transitionduration: string;
  path: string;
  itempath: string;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(ListItemButton)<CustomListItemButtonProps>(
  ({ open, transitionduration, path, itempath }) => {
    const isActive = itempath.includes(path);
    const commonTransition = `${transitionduration} cubic-bezier(0, 0, 0.2, 1) 0ms`;

    const generateCommonStyles = (isActive: boolean, open: boolean) => {
      return {
        backgroundColor: isActive ? 'var(--kanri-primary-hover)' : 'inherit',
        borderRight: isActive
          ? '4px solid var(--kanri-primary)'
          : '4px solid #ffffff',
        '& span.MuiTypography-root': {
          fontWeight: isActive ? 700 : 'inherit',
          color: isActive ? 'var(--kanri-primary)' : '#595959'
        },
        '& .MuiListItemIcon-root': {
          '& svg path': {
            fill: isActive ? 'var(--kanri-primary)' : ''
          },
          transform: open ? 'unset' : 'translateX(2px)'
        }
      };
    };

    return {
      transitionDuration: transitionduration,
      minHeight: open ? 46 : 48,
      justifyContent: open ? 'initial' : 'center',
      padding: open ? '5px 16px' : '5px',
      transition: `border-color ${commonTransition}`,
      ...generateCommonStyles(isActive, open),
      '&:hover': {
        ...generateCommonStyles(true, open)
      }
    };
  }
);

/**
 * メニューの項目ボタン
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @param path 表示されているURL
 * @param itempath 対象のメニューアイテムが持つURL
 * @returns
 */
interface MenuListItemButtonProps {
  children: React.ReactNode;
  open: boolean;
  transitionduration: string;
  path: string;
  itempath: string;
}
export const MenuListItemButton = React.memo(
  ({
    children,
    open,
    transitionduration,
    path,
    itempath
  }: MenuListItemButtonProps) => {
    return (
      <CustomListItemButton
        open={open}
        transitionduration={transitionduration}
        path={path}
        itempath={itempath}
      >
        {children}
      </CustomListItemButton>
    );
  }
);
