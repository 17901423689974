import * as React from 'react';
import { styled } from '@mui/material/styles';

/**
 * カスタムコンポーネントの作成
 */
interface CustomDivProps {
  children?: React.ReactNode;
  open?: boolean;
  transitionduration?: string;
}
const DrawerHeader = styled('div')<CustomDivProps>(
  ({ theme, open, transitionduration }) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-end' : 'center',
    padding: '5px 0',
    minHeight: '60px',
    transitionDuration: transitionduration,
    [theme.breakpoints.up('sm')]: {
      minHeight: '60px'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  })
);
/**
 * ヘッダー部分に使用するスペーサー
 */
interface CustomDrawerSpacerProps {
  children?: React.ReactNode;
  open?: boolean;
  onClick?: () => void;
  transitionduration?: string;
}
export const CustomDrawerSpacer = React.memo(
  ({
    children,
    open,
    onClick,
    transitionduration
  }: CustomDrawerSpacerProps) => {
    return (
      <DrawerHeader
        onClick={onClick}
        open={open}
        transitionduration={transitionduration}
      >
        {children}
      </DrawerHeader>
    );
  }
);
