/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MasterShok2Dto } from './MasterShok2Dto';
import {
    MasterShok2DtoFromJSON,
    MasterShok2DtoFromJSONTyped,
    MasterShok2DtoToJSON,
} from './MasterShok2Dto';

/**
 * 
 * @export
 * @interface MasterShok1Dto
 */
export interface MasterShok1Dto {
    /**
     * 大職種コード
     * @type {string}
     * @memberof MasterShok1Dto
     */
    shok1Code?: string;
    /**
     * 大職種名
     * @type {string}
     * @memberof MasterShok1Dto
     */
    name?: string;
    /**
     * 中職種マスタ
     * @type {Array<MasterShok2Dto>}
     * @memberof MasterShok1Dto
     */
    shok2?: Array<MasterShok2Dto>;
}

/**
 * Check if a given object implements the MasterShok1Dto interface.
 */
export function instanceOfMasterShok1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterShok1DtoFromJSON(json: any): MasterShok1Dto {
    return MasterShok1DtoFromJSONTyped(json, false);
}

export function MasterShok1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterShok1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shok1Code': !exists(json, 'shok1Code') ? undefined : json['shok1Code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shok2': !exists(json, 'shok2') ? undefined : ((json['shok2'] as Array<any>).map(MasterShok2DtoFromJSON)),
    };
}

export function MasterShok1DtoToJSON(value?: MasterShok1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shok1Code': value.shok1Code,
        'name': value.name,
        'shok2': value.shok2 === undefined ? undefined : ((value.shok2 as Array<any>).map(MasterShok2DtoToJSON)),
    };
}

