import { RefObject, useContext, useMemo } from 'react';
import AccordionContext from 'context/saiyouPageEditing/AccordionContext';
import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';

/**
 * 左ペイン用アコーディオンのコンテキストHooks
 * @param {AccordionCode} accordionCode アコーディオンのユニークコード
 * @returns コンテキスト
 */
const useAccordionContext = (
  accordionCode: AccordionCode
): {
  accordionRef: RefObject<HTMLDivElement> | null;
  isExpanded: boolean;
  onChange?: (newExpandedAccordion: AccordionCode, isExpanded: boolean) => void;
  onEntered?: () => void;
  onExited?: () => void;
} => {
  const { accordionRefs, expandedAccordion, onChange, onEntered, onExited } =
    useContext(AccordionContext);

  const isExpanded = useMemo(
    () => accordionCode === expandedAccordion,
    [accordionCode, expandedAccordion]
  );

  const accordionRef = useMemo(
    () => accordionRefs?.find(({ key }) => key === accordionCode)?.ref ?? null,
    [accordionCode, accordionRefs]
  );

  return {
    accordionRef,
    isExpanded,
    onChange,
    onEntered,
    onExited
  };
};

export default useAccordionContext;
