import './style.scss';
import React from 'react';

/**
 * @interface CommonFrameProps
 * @description フレームのプロパティを定義するインターフェイス
 * @property {string} title - h2のタイトルテキスト
 * @property {string} subtitle - サブタイトル
 */
interface CommonFrameProps {
  title: string;
  subtitle?: string;
  parentStyle?: string;
  children: React.ReactNode;
}

/**
 * フレーム（タイトルと白背景のセット）
 * @param {CommonFrameProps} props - コンポーネントのプロパティ
 * @param param.title h2のタイトルテキスト
 * @param param.subtitle サブタイトル
 * @param param.parentStyle 親コンポーネントでスタイルのパターン分けに使用（デフォルト：defaultStlye）
 * @param param.children 子要素
 * @returns {React.ReactElement} React コンポーネント
 */
export const CommonFrame = ({
  title,
  subtitle,
  parentStyle,
  children
}: CommonFrameProps) => {
  return (
    <>
      <h2
        className={'_commonFrame_title'.concat(
          parentStyle ? `_${parentStyle}` : ''
        )}
        data-subtitle={subtitle}
      >
        {title}
      </h2>
      {children &&
        React.Children.map(children, (child) => (
          <div
            className={'_commonFrame_contents'.concat(
              parentStyle ? `_${parentStyle}` : ''
            )}
          >
            {child}
          </div>
        ))}
    </>
  );
};
