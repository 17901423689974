/**
 * 必須バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {'input' | 'select'} type 入力方式
 * @returns {string} エラーメッセージ
 */
export const getRequiredErrorMessage = (
  propertyName: string,
  type: 'input' | 'select'
): string =>
  `${propertyName}を${type === 'input' ? '入力' : '選択'}してください。`;

/**
 * 最大文字数バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {number} maxLength 最大文字数
 * @returns {string} エラーメッセージ
 */
export const getMaxLengthErrorMessage = (
  propertyName: string,
  maxLength: number
): string => `${propertyName}は${maxLength}文字以内で入力してください。`;

const toStringFromDate = (date: Date): string =>
  `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;

/**
 * 最小日時バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {Date} minDate 最小日時
 * @returns {string} エラーメッセージ
 */
export const getMinDateErrorMessage = (
  propertyName: string,
  minDate: Date
): string =>
  `${propertyName}は${toStringFromDate(minDate)}以降で入力してください。`;

/**
 * 最大日時バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {Date} maxDate 最大日時
 * @returns {string} エラーメッセージ
 */
export const getMaxDateErrorMessage = (
  propertyName: string,
  maxDate: Date
): string =>
  `${propertyName}は${toStringFromDate(maxDate)}以前で入力してください。`;
