import { ButtonProps } from '@mui/material';
import { OpenInNewIcon } from 'assets/icons';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { CustomButton } from 'components/parts/CustomButton';

interface CustomOpenInNewIconProps extends ButtonProps {
  fillcolor: string;
}
const CustomOpenInNewIcon = styled(OpenInNewIcon)<CustomOpenInNewIconProps>(
  ({ fillcolor }) => ({
    marginLeft: '4px',
    '& .fill': {
      fill: fillcolor
    }
  })
);

/**
 * @interface PdfButtonProps
 * @description pdfボタンプロパティを定義するインターフェイス
 * @property {string} link - 遷移先のリンク
 * @property {string} file - ダウンロードするpdfファイル名
 */
interface PdfButtonProps {
  link: string;
}

export const PdfButton = ({ link }: PdfButtonProps) => {
  /* start ボタン非活性テスト用にクエリパラメータ取得 */
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const disabled = queryString.get('disabled'); //TODO: 非活性化の条件を適切に変える

  /* pdfボタンをクリックすると、ダウンロードしながら別タブを開く*/
  const handleClickPdf = (link: string) => {
    const element = document.createElement('a');
    element.href = link;
    //element.download = file;
    element.target = '_blank';
    element.click();
  };

  return (
    <CustomButton
      width="145px"
      height="44px"
      textcolor="#fff"
      border="none"
      bordercolor="none"
      backgroundcolor="var(--kanri-primary, #3e91d5)"
      margin="0 0 0 auto"
      flexshrink="0"
      hoveropacity="0.85"
      hoverborder="none"
      hoverbordercolor="var(--kanri-primary, #3e91d5)"
      disabled={disabled === '1'}
      onClick={() => handleClickPdf(link)}
    >
      PDFを開く
      <CustomOpenInNewIcon
        fillcolor={
          disabled === '1'
            ? 'var(--kanri-sub-text, #777)'
            : 'var(--white, #fff)'
        }
      />
    </CustomButton>
  );
};
