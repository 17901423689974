import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import TextField from '../parts/TextField';
import { useFreeTextValidationRules } from '../validations/freeText';

const placeholder = `サービス利用規約個人情報のお取扱いにつきまして｛商業施設株式会社｝は、本ウェブサイトにおいて取得した個人情報を以下のとおり取扱います。  

a）個人情報を取得する事業者 ｛          ｝  

b）個人情報保護管理者の職名、所属及び連絡先 ｛          ｝ 

c）個人情報の利用目的  取得した個人情報は、以下の各号に掲げる目的の範囲内でのみ利用します。（１）当社が管理運営する｛XXXXモール｝入居テナントの採用、募集情報等の提供・連絡のため。（２）当社が管理運営する｛XXXXモール｝入居テナントの採用選考のため。 

d）第三者への提供 第三者への提供はいたしません。 

e）個人情報の取扱いの委託 個人情報の取扱いの全部または一部を委託する場合があります。この際は、個人情報の取扱に関する契約を締結し、委託先に対する必要かつ適切な監督を行います。 

f) 共同利用 当社は以下の内容におきまして、 取得した個人情報を共同利用させていただいております。 

(1)共同利用の範囲 当社及び当社が管理運営する｛XXXXモール｝入居テナント（https://www.dip-net.co.jp/company/group※テナント名をすべて記載するか、テナントのURLを記載してください） 

(2) 共同利用するデータの項目 本ウェブサイトにおいて取得した個人情報

(3) 利用目的:c)項に同じ (4) 個人情報の管理について責任を有する者: ｛商業施設株式会社｝ 

g)本人からの開示要求など 本人から(1)利用目的の通知、(2)開示、(3)訂正、追加又は削除、(4)利用の停止、消去又は第三者への提供の停止などの必要がある場合、以下の問合せ窓口よりお問合せください。 ｛          ｝ 

h）任意性 本応募フォームにおいて、必須項目と任意項目があります。必須項目については、ご入力いただかなかった場合、 ｛商業施設株式会社｝からのサービスを提供できません。任意項目については、ご入力いただかなかった場合、｛商業施設株式会社｝のサービスを十分に提供できない場合があります。 

i）容易に認識できない個人情報の取得方法 本サービスを利用いただく際にCookie情報を取得します。サービス利用規約個人情報のお取扱いにつきまして｛商業施設株式会社｝は、本ウェブサイトにおいて取得した個人情報を以下のとおり取扱います。  
サービス利用規約個人情報のお取扱いにつきまして｛商業施設株式会社｝は、本ウェブサイトにおいて取得した個人情報を以下のとおり取扱います。`;

/**
 * 自由記述セクションをレンダリングする
 * @returns {React.JSX.Element} 自由記述セクション
 */
export default function FreeTextSection() {
  const {
    control,
    formState: { errors }
  } = useFormContext<EnhancedPolicyFormData>();

  const rules = useFreeTextValidationRules();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          自由記述
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <Controller
            name="freeText"
            control={control}
            rules={rules}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                value={value}
                placeholder={placeholder}
                rows={36}
                maxLength={5000}
                hasHelperText={true}
                errorMessage={
                  errors.freeText?.type !== 'maxLength'
                    ? errors.freeText?.message
                    : ''
                }
                isError={!!errors.freeText}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </div>
      </label>
    </div>
  );
}
