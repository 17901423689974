export const sampleText = Object.freeze({
  policy: [
    {
      title: '採用ページコボット・バイトル 利用規約',
      body: ['下記よりご確認ください。'],
      className: {
        body: 'secondaryText' //文字色：#595959
      },
      link: {
        href: 'https://www.dip-net.co.jp/service/terms',
        text: 'https://www.dip-net.co.jp/service/terms',
        target: '_blank',
        rel: 'noreferrer'
      }
    },
    {
      title: 'dip API利用規約',
      body: [
        `最終改訂 2022年05月31日`,
        `本規約は、ディップ株式会社（以下「当社」という）が提供するAPI機能（以下「dip API」（第１条に定義される）という）を利用する場合に適用されます。なお、dip APIは、バイトル管理画面に付随する機能の一部のため、本規約以外の内容については、オンライン情報サービス利用規約（https://agent.baitoru.com/static/policy）が適用されます。`,
        `第１条（定義）\n本規約において、以下の用語は、以下の定義する意味を有します。\n（１）「dip API」とは、ディップサービス（以下で定義される）を利用しているお客様から個人情報の管理等の委託を受けたATS事業者に対し、お客様が定めた情報の取得方法および取得範囲に基づき、当該情報を取得することができるサービスを提供するアプリケーション・プログラミング・インターフェースをいいます。\n（２）「ディップサービス」とは、当社または当社の子会社（以下、総称して「ディップグループ」という）が単独または共同で運営する、インターネット上の求人求職関連サービスサイトおよびそれに関連するサービスの総称をいいます。\n（３）「お客様」とは、本規約に承諾の上、dip APIの利用を承認した法人、個人およびその他の団体をいいます。\n（４）「ATS」とは、お客様が個人情報の管理等を委託しているATS事業者がお客様に対し提供する採用管理サービスをいいます。`,
        `第２条（利用目的）\n当社は、ディップサービスとATS間の応募者情報連携をより正確かつ迅速に行うことを目的として、お客様にdip APIを提供いたします。\nお客様は、当該目的以外でdip APIを利用することはできません。`,
        `第３条（サービス内容）\nお客様は、dip APIを通じ、以下のサービスを利用することができます。\n（１）当社からATSへお客様認証済みアカウント情報が閲覧できる応募者情報の提供`,
        `第４条（利用前の準備）\n１．お客様は、dip APIを利用するにあたり、バイトル管理画面にアクセスする必要があります。\n２．お客様は、自らの責任と費用負担にてインターネットインフラ等をご用意いただき、適切に設置、操作等をしていただく必要があります。なお、お客様がアクセスするためのインターネットインフラ等、その他機器・ソフトウェアの準備、設置、操作の方法等については、当社は一切関与いたしません。`,
        `第５条（利用方法）\n１．お客様は、dip APIを利用するにあたり、バイトル管理画面に登録しているID・パスワード（以下「ID・パスワード」という）をdip APIのアカウント認証画面上でお客様自身により入力し、お客様の責任においてアカウント情報の認証を行う必要があります。`,
        `第６条（ID・パスワード）\n１．お客様は、自己のID・パスワードの使用および管理について、一切の責任を負うものとします。\n２．お客様は、理由の如何を問わず、ID・パスワードを第三者に使用させ、あるいは譲渡、貸与、名義変更、売買、担保設定等をすることはできません。\n３．お客様はID・パスワードを失念した場合、または漏洩したおそれのある場合には速やかに当社にその旨を連絡する義務を負います。\n４．ID・パスワードのお客様による不適切な使用または第三者の使用によりお客様が損害を被った場合、当社の責に帰する事由による場合を除き、当社は一切責任を負いません。`,
        `第７条（利用料金）お客様は、当社が有料と指定したサービスを除き、dip APIを無償で利用できます。`,
        `第８条（サービス内容の変更・中断・終了）\n１．当社はお客様への事前の通知なくして、dip APIの諸条件の変更や、部分的な改廃をすることがあり、お客様は、これを承諾するものとします。\n２．以下の各号に該当する場合、お客様に事前に連絡することなく、一時的にdip APIの提供を中断する場合があります。\n（１）dip APIのシステム保守を定期的、または緊急に行う場合\n（２）停電・通信インフラの故障などにより、dip APIが提供できなくなった場合\n（３）天災により、dip APIが提供できなくなった場合\n（４）公共サービスの停止、地震、嵐その他の重大な自然現象、暴動等の不可抗力により、dip APIの提供ができなくなった場合\n（５）予想外の技術的問題が発生した場合\n（６）その他、当社がdip APIの一時的な中断を必要と判断した場合\n３．当社は、やむを得ない事案が発生した場合、当社の判断により、dip APIの提供を終了することがあります。dip APIを終了する場合には、あらかじめ、ウェブサイトにおける告知その他適宜の方法によりお客様に通知いたします。ただし、緊急その他やむをえない事情がある場合はこの限りでありません。`,
        `第９条（免責事項）\n前条に定めるdip APIの変更、中断、終了により、お客様がdip APIを利用できなかったことに関する損害、作業が中断したことに関する損害、データが失われたことに関する損害、dip APIを利用することによって得られたであろう利益を得られなかった損害など、dip APIの利用に際して発生した損害については、直接損害・間接損害、現実に発生した損害か否かを問わず、当社は一切の責任を負わないものとし、お客様はこれを承諾するものとします。`,
        `第１０条（秘密保持）\nお客様は、dip APIに関連して得た情報は、第三者への開示をしないものとします。`,
        `第１１条（規約の変更）\n当社は、以下の場合に、当社の裁量により本規約を変更することができます。\n（１）本規約の変更が、お客様の利益に適合するとき\n（２）本規約の変更が、本規約の利用目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき\n２ 当社は前項による本規約の変更にあたり、変更後の本規約の効力発生日の１ヶ月前までに、本規約を変更する旨及び変更後の本規約の内容とその効力発生日をバイトル管理画面上に掲示することにより、お客様に通知します。\n３ 変更後の本規約の効力発生日以降にお客様がdip APIを利用したときは、お客様は、本規約の変更に同意したものとみなします。`
      ],
      className: {
        body: 'primaryText' // 文字色：#333
      }
    }
  ]
});
