import { FormControl, styled } from '@mui/material';

type Props = { disabled?: boolean };

const StyledSelectBoxControl = styled(FormControl)<Props>(({ disabled }) => ({
  width: '100%',
  '&.MuiFormControl-root': {
    border: 'none',
    '& .MuiInputBase-colorPrimary': {
      color: 'var(--kanri-sub-text, #777)'
    },
    '& .MuiFormLabel-colorPrimary': {
      color: 'var(--kanri-sub-text, #777)',
      fontSize: 14,
      fontWeight: 700
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused , &.MuiFormLabel-filled': {
        transform: 'translate(14px, -8px) scale(1)',
        color: 'var(--kanri-secondary-text, #595959)'
      }
    },
    '& .MuiInputBase-root': {
      padding: 16,
      '& .MuiChip-root': {
        paddingRight: 8,
        '& svg': {
          width: 8,
          height: 8,
          '&:hover': {
            opacity: 0.6
          }
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-gray-03, #899194)'
      },
      '& .MuiInputBase-input': {
        fontSize: 14,
        fontWeight: 400,
        color: 'var(--kanri-secondary-text, #595959)',
        padding: 0,
        lineHeight: 1
      },
      ...(disabled && {
        background: 'var(--kanri-disabled-button-color)',
        border: '1px solid var(--kanri-gray)',
        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none'
        },
        '& input::placeholder': {
          '-webkit-text-fill-color': 'var(--kanri-sub-text) !important'
        }
      })
    },
    '& .Mui-focused': {
      fontSize: 14,
      fontWeight: 700,
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-gray-03, #899194)'
      }
    },
    '& .Mui-error': {
      fontWeight: 400,
      color: 'var(--kanri-erroralert, #DC1357)',
      '&.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-erroralert, #DC1357)'
      },
      '& .MuiFormHelperText-right': {
        color: 'var(--kanri-erroralert, #DC1357)'
      },
      '&.MuiFormLabel-root': {
        color: 'var(--kanri-erroralert, #DC1357)'
      }
    },
    '& legend': {
      color: 'var(--kanri-sub-text, #777)',
      fontSize: 14,
      fontWeight: 400,
      height: 14
    },
    '& .MuiAutocomplete-groupUl .MuiAutocomplete-option': {
      color: 'var(--kanri-secondary-text, #595959)',
      whiteSpace: 'nowrap',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      '&:hover': {
        background: 'var(--kanri-bg-01, #F2F3F7)'
      }
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 0.25em)',
      right: 8
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'unset'
    }
  }
}));

export default StyledSelectBoxControl;
