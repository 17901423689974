/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentCorporation
 */
export interface CurrentCorporation {
    /**
     * 企業名
     * @type {string}
     * @memberof CurrentCorporation
     */
    name?: string;
    /**
     * 今現在契約があるかどうか　0：契約なし、1：契約あり）
     * @type {number}
     * @memberof CurrentCorporation
     */
    isContracting?: number;
    /**
     * 最後に契約していた段階でTOPページが利用できるプランだったか(0：TOPなしプランor最後のプラン情報なし　1：TOPありプラン)
     * @type {string}
     * @memberof CurrentCorporation
     */
    isAvailableTopPagePlanInLastContract?: string;
}

/**
 * Check if a given object implements the CurrentCorporation interface.
 */
export function instanceOfCurrentCorporation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CurrentCorporationFromJSON(json: any): CurrentCorporation {
    return CurrentCorporationFromJSONTyped(json, false);
}

export function CurrentCorporationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentCorporation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isContracting': !exists(json, 'isContracting') ? undefined : json['isContracting'],
        'isAvailableTopPagePlanInLastContract': !exists(json, 'isAvailableTopPagePlanInLastContract') ? undefined : json['isAvailableTopPagePlanInLastContract'],
    };
}

export function CurrentCorporationToJSON(value?: CurrentCorporation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'isContracting': value.isContracting,
        'isAvailableTopPagePlanInLastContract': value.isAvailableTopPagePlanInLastContract,
    };
}

