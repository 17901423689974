import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled
} from '@mui/material';
import { OptionsProps } from '../type';
import { ArrowBottomIcon } from 'assets/icons';

/**
 * @interface SelectBox
 * @description セレクトボックスのプロパティを定義するインターフェイス
 * @property {string} value - 呼び出し元で利用する値
 * @property {string} label - セレクトボックスのラベル
 * @property {boolean} disabled - 入力可否
 * @property {OptionsProps[]} category - リストの中身
 * @property {number} width - セレクトボックスの幅
 * @property {boolean} onChange - 値が変わったときに呼び出される関数
 */
interface SelectBoxProps {
  value: string;
  disabled?: boolean;
  category: OptionsProps[];
  width?: number;
  isError?: boolean;
  errorMessage?: string;
  onChange: (value: string) => void;
}

/**
 * @function SelectBox
 * @description セレクトボックスコンポーネントを生成する関数
 * @param {GroupingSelectBoxProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} - グルーピングされたセレクトボックスコンポーネント
 */
const SelectBox = React.memo(
  ({
    value,
    disabled = false,
    category,
    width,
    isError = false,
    errorMessage = '',
    onChange
  }: SelectBoxProps) => {
    /**
     * @description 選択されたジョブカテゴリセットに基づいて適切なジョブカテゴリデータを取得し、
     * グループ化されたオプションのリストを生成する
     */

    const handleChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      onChange(newValue);
    };
    return (
      <FormSelectBoxStyled
        fullWidth={width === undefined}
        style={{ ...(width && { width: width }) }}
        disabled={disabled}
        error={isError}
      >
        <Select
          value={value}
          onChange={handleChange}
          IconComponent={ArrowBottomIcon}
        >
          {category.map((elm, key) => {
            return elm ? (
              <MenuItem key={`${elm.label}_${key}`} value={elm.value}>
                {elm.label}
              </MenuItem>
            ) : (
              <></>
            );
          })}
        </Select>
        <FormHelperText>{errorMessage ?? ''}</FormHelperText>
      </FormSelectBoxStyled>
    );
  }
);

export { SelectBox };

/**
 * `FormSelectBoxStyledProps` のプロパティの型定義。
 * @typedef {object} FormSelectBoxStyledProps
 * @property {boolean} disabled - 入力可否の切り替え
 */
interface FormSelectBoxStyledProps {
  disabled?: boolean;
}

/**
 * スタイリングされたアコーディオンコンポーネント。
 * @type {StyledComponent}
 */
const FormSelectBoxStyled = styled(FormControl)<FormSelectBoxStyledProps>(
  ({ disabled }) => ({
    width: '100%',
    '&.MuiFormControl-root': {
      border: 'none',
      '& .MuiInputBase-colorPrimary': {
        color: 'var(--kanri-sub-text, #777)'
      },
      '& .MuiFormLabel-colorPrimary': {
        color: 'var(--kanri-sub-text, #777)',
        fontSize: 14,
        fontWeight: 700
      },
      '& .MuiInputLabel-root': {
        '&.Mui-focused , &.MuiFormLabel-filled': {
          transform: 'translate(14px, -8px) scale(1)',
          color: 'var(--kanri-secondary-text, #595959)'
        }
      },
      '& .MuiInputBase-root': {
        padding: 16,
        '& .MuiChip-root': {
          paddingRight: 8,
          '& svg': {
            width: 8,
            height: 8,
            '&:hover': {
              opacity: 0.6
            }
          }
        },
        '& .MuiSelect-select': {
          display: 'inline-flex',
          alignItems: 'center'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--kanri-gray-03, #899194)'
        },
        '& .MuiInputBase-input': {
          fontSize: 14,
          fontWeight: 400,
          color: 'var(--kanri-secondary-text, #595959)',
          padding: 0,
          lineHeight: 1
        },
        ...(disabled && {
          background: 'var(--kanri-disabled-button-color)',
          border: '1px solid var(--kanri-gray)',
          '& .MuiOutlinedInput-notchedOutline': {
            display: 'none'
          },
          '& input::placeholder': {
            '-webkit-text-fill-color': 'var(--kanri-sub-text) !important'
          }
        })
      },
      '& .Mui-focused': {
        fontSize: 14,
        fontWeight: 700,
        '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--kanri-gray-03, #899194)'
        }
      },
      '& .Mui-error': {
        '&.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--kanri-erroralert, #DC1357)'
        },
        '& .MuiFormHelperText-right': {
          color: 'var(--kanri-erroralert, #DC1357)'
        }
      },
      '& legend': {
        color: 'var(--kanri-sub-text, #777)',
        fontSize: 14,
        fontWeight: 400,
        height: 14
      },
      '& .MuiAutocomplete-groupUl .MuiAutocomplete-option': {
        color: 'var(--kanri-secondary-text, #595959)',
        whiteSpace: 'nowrap',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
        '&:hover': {
          background: 'var(--kanri-bg-01, #F2F3F7)'
        }
      },
      '& .MuiSelect-icon': {
        right: 8,
        top: 'unset'
      }
    }
  })
);
