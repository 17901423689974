import {
  PolicySettingReferenceResDtoIsJointUseEnum,
  PolicySettingReferenceResDtoIsSameContactEnum,
  PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum
} from 'api-client/public';
import { ContactInformationType, InformationType, OptionsProps } from '../type';

/**
 * 電話番号・メールアドレス・URLのリストで利用する項目
 */
export const InformationTypeOptions: OptionsProps[] = [
  {
    label: '選択してください',
    value: InformationType.NotSelected
  },
  {
    label: '電話番号',
    value: InformationType.Tel
  },
  {
    label: 'メールアドレス',
    value: InformationType.Email
  },
  {
    label: 'URL',
    value: InformationType.Url
  }
];

/**
 * 電話番号・メールアドレス・URLのリストで利用する項目(「本人からの開示要求など」の連絡先)
 */
export const ContactInformationTypeOptions: OptionsProps[] = [
  {
    label: '選択してください',
    value: ContactInformationType.NotSelected
  },
  {
    label: '電話番号',
    value: ContactInformationType.Tel
  },
  {
    label: 'メールアドレス',
    value: ContactInformationType.Email
  },
  {
    label: 'URL',
    value: ContactInformationType.Url
  }
];

/**
 * 共同利用の範囲で利用する項目
 */
export const PersonalInformationProtectorTypeOptions: OptionsProps[] = [
  {
    label: '本契約企業',
    value:
      PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.ContractedCorp
  },
  {
    label: '共同利用企業',
    value:
      PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.JoitUseCorp
  },
  {
    label: 'その他',
    value:
      PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.Other
  }
];

/**
 * 共同利用で利用する項目
 */
export const IsJointUseOptions: OptionsProps[] = [
  {
    label: '共同利用する',
    value: PolicySettingReferenceResDtoIsJointUseEnum.NUMBER_1.toString()
  },
  {
    label: '共同利用しない',
    value: PolicySettingReferenceResDtoIsJointUseEnum.NUMBER_0.toString()
  }
];

/**
 * 「本人からの開示要求など」の連絡先で利用する項目
 */
export const IsSameContactOptions: OptionsProps[] = [
  {
    label: '上記と同じ',
    value: PolicySettingReferenceResDtoIsSameContactEnum.NUMBER_1.toString()
  },
  {
    label: '新たに選択',
    value: PolicySettingReferenceResDtoIsSameContactEnum.NUMBER_0.toString()
  }
];
