/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JobCheckResDto,
  JobDetailResDto,
  JobEntryReqDto,
  JobListResDto,
  JobSummaryResDto,
  JobTelEntryReqDto,
  JobTelEntryResDto,
} from '../models';
import {
    JobCheckResDtoFromJSON,
    JobCheckResDtoToJSON,
    JobDetailResDtoFromJSON,
    JobDetailResDtoToJSON,
    JobEntryReqDtoFromJSON,
    JobEntryReqDtoToJSON,
    JobListResDtoFromJSON,
    JobListResDtoToJSON,
    JobSummaryResDtoFromJSON,
    JobSummaryResDtoToJSON,
    JobTelEntryReqDtoFromJSON,
    JobTelEntryReqDtoToJSON,
    JobTelEntryResDtoFromJSON,
    JobTelEntryResDtoToJSON,
} from '../models';

export interface CheckJobExistanceRequest {
    compMgrNo: number;
    jobId: string;
}

export interface EntryToJobRequest {
    compMgrNo: number;
    jobId: string;
    jobEntryReqDto: JobEntryReqDto;
}

export interface EntryToJobByTelRequest {
    compMgrNo: number;
    jobId: string;
    jobTelEntryReqDto: JobTelEntryReqDto;
}

export interface GetCorporationJobRequest {
    compMgrNo: number;
    hits?: number;
    page?: number;
    sort?: GetCorporationJobSortEnum;
    midAreaCdList?: Array<string>;
    tdfkCdList?: Array<string>;
    area1CdList?: Array<string>;
    area2CdList?: Array<string>;
    sareaCdList?: Array<string>;
    ekiCdList?: Array<string>;
    salaryKbn?: GetCorporationJobSalaryKbnEnum;
    minSalary?: number;
    baitFlgList?: Array<GetCorporationJobBaitFlgListEnum>;
    shok1CdList?: Array<string>;
    shok2CdList?: Array<string>;
    shok3CdList?: Array<string>;
    knmTermKbnList?: Array<GetCorporationJobKnmTermKbnListEnum>;
    knmTimeFlgList?: Array<GetCorporationJobKnmTimeFlgListEnum>;
    tokuchoShiftCdList?: Array<string>;
    tokuchoDaysCdList?: Array<string>;
    tokuchoTimeCdList?: Array<string>;
    tokuchoStartEndCdList?: Array<string>;
    tokuchoShiftTokuCdList?: Array<string>;
    tokuchoZangyoCdList?: Array<string>;
    tokuchoCdList?: Array<string>;
    freewords?: Array<string>;
}

export interface GetCorporationJobSummaryRequest {
    compMgrNo: number;
}

export interface GetJobDetailRequest {
    compMgrNo: number;
    jobId: string;
}

/**
 * 
 */
export class JobApi extends runtime.BaseAPI {

    /**
     *      バイトルCore応募先案件取得APIへリクエストを行い、その結果を返す。     （このAPIはレプリケーションDBではなくマスターとなるDBを見ているため、最新のデータをもとにJOBの存在チェックをかけることができる）     CloudFrontでキャッシュされないようPOSTで定義     
     * 仕事存在チェックAPI
     */
    async checkJobExistanceRaw(requestParameters: CheckJobExistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobCheckResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling checkJobExistance.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling checkJobExistance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job/{jobId}/check`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobCheckResDtoFromJSON(jsonValue));
    }

    /**
     *      バイトルCore応募先案件取得APIへリクエストを行い、その結果を返す。     （このAPIはレプリケーションDBではなくマスターとなるDBを見ているため、最新のデータをもとにJOBの存在チェックをかけることができる）     CloudFrontでキャッシュされないようPOSTで定義     
     * 仕事存在チェックAPI
     */
    async checkJobExistance(requestParameters: CheckJobExistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobCheckResDto> {
        const response = await this.checkJobExistanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      バイトルCore Web応募APIへリクエストを行う     
     * Web応募API
     */
    async entryToJobRaw(requestParameters: EntryToJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling entryToJob.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling entryToJob.');
        }

        if (requestParameters.jobEntryReqDto === null || requestParameters.jobEntryReqDto === undefined) {
            throw new runtime.RequiredError('jobEntryReqDto','Required parameter requestParameters.jobEntryReqDto was null or undefined when calling entryToJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job/{jobId}`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobEntryReqDtoToJSON(requestParameters.jobEntryReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *      バイトルCore Web応募APIへリクエストを行う     
     * Web応募API
     */
    async entryToJob(requestParameters: EntryToJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.entryToJobRaw(requestParameters, initOverrides);
    }

    /**
     *      電話応募履歴の登録と、電話応募用の電話番号を返す。処理の流れとしては下記の通り。     ・バイトルCore 仕事検索APIをリクエスト     ・自動応答する案件(free_dial_kbn=1)だった場合、バイトルCore CallクレヨンAPIをリクエスト     ・バイトルCore TEL応募APIをリクエスト     ・自動応答案件の場合は CallクレヨンAPIで帰ってきた電話番号を、そうではない場合もしくは Callクレヨンからの電話番号が取得できなかった場合は      　仕事検索APIから取得した応募用の電話番号を返す（電話応募に対応していない案件の場合は電話番号を返さない）     
     * 応募TEL取得&応募履歴連携API
     */
    async entryToJobByTelRaw(requestParameters: EntryToJobByTelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobTelEntryResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling entryToJobByTel.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling entryToJobByTel.');
        }

        if (requestParameters.jobTelEntryReqDto === null || requestParameters.jobTelEntryReqDto === undefined) {
            throw new runtime.RequiredError('jobTelEntryReqDto','Required parameter requestParameters.jobTelEntryReqDto was null or undefined when calling entryToJobByTel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job/{jobId}/tel`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobTelEntryReqDtoToJSON(requestParameters.jobTelEntryReqDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobTelEntryResDtoFromJSON(jsonValue));
    }

    /**
     *      電話応募履歴の登録と、電話応募用の電話番号を返す。処理の流れとしては下記の通り。     ・バイトルCore 仕事検索APIをリクエスト     ・自動応答する案件(free_dial_kbn=1)だった場合、バイトルCore CallクレヨンAPIをリクエスト     ・バイトルCore TEL応募APIをリクエスト     ・自動応答案件の場合は CallクレヨンAPIで帰ってきた電話番号を、そうではない場合もしくは Callクレヨンからの電話番号が取得できなかった場合は      　仕事検索APIから取得した応募用の電話番号を返す（電話応募に対応していない案件の場合は電話番号を返さない）     
     * 応募TEL取得&応募履歴連携API
     */
    async entryToJobByTel(requestParameters: EntryToJobByTelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobTelEntryResDto> {
        const response = await this.entryToJobByTelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      バイトルCore案件検索APIに対してリクエスト実行     フリーワードが入っている場合は、上記リクエスト前にバイトルCoreAPIフリーワード事前処理APIでフリーワードの事前処理を行い、     その結果をもとに案件検索APIをリクエストする      クエリパラメータにバリデーションはかけない。     バイトルCore案件検索APIのレスポンスのパラメータ数が多いため、フロントエンドでのパラメータの取捨選択が行いやすいように、本APIのレスポンスの一部でany型を採用。     
     * 企業内仕事情報一覧取得API
     */
    async getCorporationJobRaw(requestParameters: GetCorporationJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobListResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getCorporationJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.hits !== undefined) {
            queryParameters['hits'] = requestParameters.hits;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.midAreaCdList) {
            queryParameters['midAreaCdList'] = requestParameters.midAreaCdList;
        }

        if (requestParameters.tdfkCdList) {
            queryParameters['tdfkCdList'] = requestParameters.tdfkCdList;
        }

        if (requestParameters.area1CdList) {
            queryParameters['area1CdList'] = requestParameters.area1CdList;
        }

        if (requestParameters.area2CdList) {
            queryParameters['area2CdList'] = requestParameters.area2CdList;
        }

        if (requestParameters.sareaCdList) {
            queryParameters['sareaCdList'] = requestParameters.sareaCdList;
        }

        if (requestParameters.ekiCdList) {
            queryParameters['ekiCdList'] = requestParameters.ekiCdList;
        }

        if (requestParameters.salaryKbn !== undefined) {
            queryParameters['salaryKbn'] = requestParameters.salaryKbn;
        }

        if (requestParameters.minSalary !== undefined) {
            queryParameters['minSalary'] = requestParameters.minSalary;
        }

        if (requestParameters.baitFlgList) {
            queryParameters['baitFlgList'] = requestParameters.baitFlgList;
        }

        if (requestParameters.shok1CdList) {
            queryParameters['shok1CdList'] = requestParameters.shok1CdList;
        }

        if (requestParameters.shok2CdList) {
            queryParameters['shok2CdList'] = requestParameters.shok2CdList;
        }

        if (requestParameters.shok3CdList) {
            queryParameters['shok3CdList'] = requestParameters.shok3CdList;
        }

        if (requestParameters.knmTermKbnList) {
            queryParameters['knmTermKbnList'] = requestParameters.knmTermKbnList;
        }

        if (requestParameters.knmTimeFlgList) {
            queryParameters['knmTimeFlgList'] = requestParameters.knmTimeFlgList;
        }

        if (requestParameters.tokuchoShiftCdList) {
            queryParameters['tokuchoShiftCdList'] = requestParameters.tokuchoShiftCdList;
        }

        if (requestParameters.tokuchoDaysCdList) {
            queryParameters['tokuchoDaysCdList'] = requestParameters.tokuchoDaysCdList;
        }

        if (requestParameters.tokuchoTimeCdList) {
            queryParameters['tokuchoTimeCdList'] = requestParameters.tokuchoTimeCdList;
        }

        if (requestParameters.tokuchoStartEndCdList) {
            queryParameters['tokuchoStartEndCdList'] = requestParameters.tokuchoStartEndCdList;
        }

        if (requestParameters.tokuchoShiftTokuCdList) {
            queryParameters['tokuchoShiftTokuCdList'] = requestParameters.tokuchoShiftTokuCdList;
        }

        if (requestParameters.tokuchoZangyoCdList) {
            queryParameters['tokuchoZangyoCdList'] = requestParameters.tokuchoZangyoCdList;
        }

        if (requestParameters.tokuchoCdList) {
            queryParameters['tokuchoCdList'] = requestParameters.tokuchoCdList;
        }

        if (requestParameters.freewords) {
            queryParameters['freewords'] = requestParameters.freewords;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobListResDtoFromJSON(jsonValue));
    }

    /**
     *      バイトルCore案件検索APIに対してリクエスト実行     フリーワードが入っている場合は、上記リクエスト前にバイトルCoreAPIフリーワード事前処理APIでフリーワードの事前処理を行い、     その結果をもとに案件検索APIをリクエストする      クエリパラメータにバリデーションはかけない。     バイトルCore案件検索APIのレスポンスのパラメータ数が多いため、フロントエンドでのパラメータの取捨選択が行いやすいように、本APIのレスポンスの一部でany型を採用。     
     * 企業内仕事情報一覧取得API
     */
    async getCorporationJob(requestParameters: GetCorporationJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobListResDto> {
        const response = await this.getCorporationJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      バイトルCore掲載件数取得(企業)APIから該当CompMgrNoの案件の集計結果を返す     集計結果の区分値に関しては、バイトルCoreのマスタ取得系APIから必要なレコードを取得し、     区分値の示す文字列もレスポンスとして返す。     ・特徴に関しては、特徴マスタで連携される特徴+Happyボーナス/社員登用あり/即日勤務OK/年齢撤廃でサマリした件数を返す     ・時給に関しては最低賃金を下回るものは削除した件数を返す     
     * 企業内仕事情報サマリ取得API
     */
    async getCorporationJobSummaryRaw(requestParameters: GetCorporationJobSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobSummaryResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getCorporationJobSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job/summary`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobSummaryResDtoFromJSON(jsonValue));
    }

    /**
     *      バイトルCore掲載件数取得(企業)APIから該当CompMgrNoの案件の集計結果を返す     集計結果の区分値に関しては、バイトルCoreのマスタ取得系APIから必要なレコードを取得し、     区分値の示す文字列もレスポンスとして返す。     ・特徴に関しては、特徴マスタで連携される特徴+Happyボーナス/社員登用あり/即日勤務OK/年齢撤廃でサマリした件数を返す     ・時給に関しては最低賃金を下回るものは削除した件数を返す     
     * 企業内仕事情報サマリ取得API
     */
    async getCorporationJobSummary(requestParameters: GetCorporationJobSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobSummaryResDto> {
        const response = await this.getCorporationJobSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      バイトルCore案件検索API・および企業案件前後案件取得APIから該当のレコードを取得して返す。     レコードが存在しなければ404エラーを返す。      バイトルCore案件検索APIのレスポンスのパラメータ数が多いため、フロントエンドでのパラメータの取捨選択が行いやすいように、本APIのレスポンスの一部でany型を採用。     
     * 仕事詳細取得API
     */
    async getJobDetailRaw(requestParameters: GetJobDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobDetailResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getJobDetail.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling getJobDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/{compMgrNo}/job/{jobId}`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobDetailResDtoFromJSON(jsonValue));
    }

    /**
     *      バイトルCore案件検索API・および企業案件前後案件取得APIから該当のレコードを取得して返す。     レコードが存在しなければ404エラーを返す。      バイトルCore案件検索APIのレスポンスのパラメータ数が多いため、フロントエンドでのパラメータの取捨選択が行いやすいように、本APIのレスポンスの一部でany型を採用。     
     * 仕事詳細取得API
     */
    async getJobDetail(requestParameters: GetJobDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobDetailResDto> {
        const response = await this.getJobDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetCorporationJobSortEnum = {
    Osusume: 'osusume',
    New: 'new',
    Salary0: '-salary0',
    Salary1: '-salary1',
    Salary2: '-salary2',
    Salary3: '-salary3'
} as const;
export type GetCorporationJobSortEnum = typeof GetCorporationJobSortEnum[keyof typeof GetCorporationJobSortEnum];
/**
 * @export
 */
export const GetCorporationJobSalaryKbnEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_6: 6
} as const;
export type GetCorporationJobSalaryKbnEnum = typeof GetCorporationJobSalaryKbnEnum[keyof typeof GetCorporationJobSalaryKbnEnum];
/**
 * @export
 */
export const GetCorporationJobBaitFlgListEnum = {
    Normal: 'normal',
    Regular: 'regular',
    Contract: 'contract',
    Haken: 'haken',
    Outsg: 'outsg',
    NlimEhaken: 'nlim_ehaken',
    SyokaiRhaken: 'syokai_rhaken'
} as const;
export type GetCorporationJobBaitFlgListEnum = typeof GetCorporationJobBaitFlgListEnum[keyof typeof GetCorporationJobBaitFlgListEnum];
/**
 * @export
 */
export const GetCorporationJobKnmTermKbnListEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type GetCorporationJobKnmTermKbnListEnum = typeof GetCorporationJobKnmTermKbnListEnum[keyof typeof GetCorporationJobKnmTermKbnListEnum];
/**
 * @export
 */
export const GetCorporationJobKnmTimeFlgListEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type GetCorporationJobKnmTimeFlgListEnum = typeof GetCorporationJobKnmTimeFlgListEnum[keyof typeof GetCorporationJobKnmTimeFlgListEnum];
