import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AccordionContext from 'context/saiyouPageEditing/AccordionContext';
import {
  AccordionCode,
  allAccordionCodes
} from 'types/saiyouPageEditing/AccordionCode';

type Props = {
  expandedAccordion?: AccordionCode;
  onChangeAccordion?: (accordion: AccordionCode, isExpanded: boolean) => void;
} & PropsWithChildren;

/**
 * 左ペイン用アコーディオンのコンテキストプロバイダを返す
 * @param {ReactNode | ReactNode[] | undefined} children 子コンポーネント
 * @param {AccordionCode | undefined} expandedAccordion 開いているアコーディオン
 * @param {Function | undefined} onChangeAccordion アコーディオンの開閉状態が変更された際に実行される関数
 * @returns コンテキストプロバイダ
 */
const AccordionProvider: FC<Props> = ({
  children,
  expandedAccordion,
  onChangeAccordion
}) => {
  const [isNewOpened, setNewOpened] = useState(false);
  const [isPrevClosed, setPrevClosed] = useState(true);

  const rootRef = useRef<HTMLDivElement>(null);

  const accordionRefs = allAccordionCodes.map((code) => ({
    key: code,
    ref: useRef<HTMLDivElement>(null)
  }));

  const expandedAccordionRef = useMemo(
    () =>
      accordionRefs.find(({ key }) => key === expandedAccordion)?.ref ?? null,
    [accordionRefs, expandedAccordion]
  );

  const handleEnter = useCallback(() => setNewOpened(true), []);

  const handleExit = useCallback(() => setPrevClosed(true), []);

  useEffect(() => {
    if (
      isNewOpened &&
      isPrevClosed &&
      expandedAccordionRef?.current?.offsetTop
    ) {
      rootRef?.current?.scrollTo({
        top: expandedAccordionRef?.current?.offsetTop,
        behavior: 'smooth'
      });
      setNewOpened(false);
      setPrevClosed(false);
    }
  }, [isNewOpened, isPrevClosed, rootRef, expandedAccordionRef]);

  return (
    <AccordionContext.Provider
      value={{
        rootRef,
        accordionRefs,
        expandedAccordion,
        onChange: onChangeAccordion,
        onEntered: handleEnter,
        onExited: handleExit
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

export default AccordionProvider;
