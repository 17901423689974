import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../../common/ContentAcc/ContentAcc';
import TabContentInner from '../../shared/TabContentInner';
import TabContentText from '../../shared/TabContentText';
import TextField from 'components/parts/saiyouPageEditing/common/TextField';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import { useJobMgrNoFieldRules } from 'validation/rules/saiyouPageEditing';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300
  }
});

/**
 * 「募集中のお仕事一覧」アコーディオンを返す
 * @returns 「募集中のお仕事一覧」アコーディオン
 */
const AvailableJobsAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('jobMgrNos');

  const rules = useJobMgrNoFieldRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="募集中のお仕事一覧"
      dispCrown
      isExpanded={isExpanded}
      isError={errors.jobMgrNos && Object.keys(errors.jobMgrNos).length > 0}
      onChange={(value) => onChange?.('jobMgrNos', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText
          text={
            <>
              募集中のお仕事一覧で固定で表示したい案件がある場合に案件の
              <CustomWidthTooltip
                title={
                  <>
                    案件URL(https://saiyo.page/111111/job/1234567)の末尾「1234567」が仕事管理番号です。
                    <br />
                    未記入の場合、または仕事管理番号が無効の場合はおすすめ順で案件を自動補充します。最大3件表示します。
                  </>
                }
                arrow
              >
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  仕事管理番号
                </span>
              </CustomWidthTooltip>
              を入力。
            </>
          }
        />
        <TabContentFormWrap>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <Controller
                key={`job_mgr_no_${i}`}
                name={`jobMgrNos.${i}`}
                control={control}
                rules={{ ...rules, onChange: onChangeValue }}
                render={({
                  field: { value, onBlur, onChange },
                  formState: { errors }
                }) => (
                  <TextField
                    label={`案件表示（${i + 1}番目）`}
                    value={value}
                    placeholder="仕事管理番号"
                    maxLength={10}
                    helperText={
                      errors.jobMgrNos?.[i]?.type !== 'maxLength'
                        ? errors.jobMgrNos?.[i]?.message
                        : ''
                    }
                    hasLengthHelperText
                    isError={!!errors.jobMgrNos?.[i]}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            ))}
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default AvailableJobsAccordion;
