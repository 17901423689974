import { useEffect } from 'react';
import { useConfig } from 'context/ConfigContext';
import { PageColor } from 'types/saiyouPageEditing/PageColor';
import { PageDesign } from 'types/saiyouPageEditing/PageDesign';

/**
 * デザイン・カラー設定用Hooks
 */
const usePageStyleEffect = (
  pageDesign: PageDesign,
  pageColor: PageColor
): void => {
  const config = useConfig();

  useEffect(() => {
    if (pageDesign == null || pageColor == null) return;

    type ColorKeys = keyof typeof config.colors;
    type ThemeKeys = keyof (typeof config.colors)[ColorKeys]['theme'];

    const color: ColorKeys = pageColor;
    const theme: ThemeKeys = pageDesign;

    document.documentElement.style.setProperty(
      '--kanri-selected-key-color',
      config.colors[color]['theme'][theme].keyColor
    );
    document.documentElement.style.setProperty(
      '--kanri-selected-sub-color',
      config.colors[color]['theme'][theme].subColor
    );
    document.documentElement.style.setProperty(
      '--kanri-selected-background-color',
      config.colors[color]['theme'][theme].backgroundColor
    );
    document.documentElement.style.setProperty(
      '--kanri-selected-text-color',
      config.colors[color]['theme'][theme].textColor
    );
    document.documentElement.style.setProperty(
      '--kanri-selected-subbackground-color',
      config.colors[color]['theme'][theme].subBackgroundColor
    );

    const colors = Object.keys(config.colors);
    colors.forEach((color: string) => {
      document.documentElement.style.setProperty(
        `--kanri-${color}-key-color`,
        config.colors[color].theme[theme].keyColor
      );
    });
  }, [pageDesign, pageColor]);
};

export default usePageStyleEffect;
