import { RefObject, createContext } from 'react';
import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';

type AccordionProps = {
  rootRef?: RefObject<HTMLDivElement>;
  accordionRefs?: { key: AccordionCode; ref: RefObject<HTMLDivElement> }[];
  expandedAccordion?: AccordionCode;
  onChange?: (newExpandedAccordion: AccordionCode, isExpanded: boolean) => void;
  onEntered?: () => void;
  onExited?: () => void;
};

/**
 * 左ペイン用アコーディオンのコンテキスト
 */
const AccordionContext = createContext<AccordionProps>({
  expandedAccordion: undefined
});

export default AccordionContext;
