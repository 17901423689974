import { FC } from 'react';
import ProgressStep from './ProgressStep';
import './style.scss';

type Props = {
  step?: number;
};

/**
 * プログレスバーを返す
 * @param {number | undefined} step 現在のステップ
 * @returns プログレスバー
 */
const ProgressBar: FC<Props> = ({ step = 1 }) => {
  return (
    <div className="progresbar">
      <ProgressStep
        index={1}
        supplementText="基本設定"
        hasStepText
        isSelected={step === 1}
        isCompleted={step > 1}
      />
      <ProgressStep
        index={2}
        supplementText="デザイン設定"
        hasStepText
        isSelected={step === 2}
      />
      <ProgressStep supplementText="完了" isSelected={step === 2} />
    </div>
  );
};

export default ProgressBar;
