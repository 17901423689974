import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { MAX_URL_LENGTH, urlFormatErrorMsg } from '../shared/consts';

type Rules = RegisterOptions<FormData, 'homepageUrl'>;

/**
 * 会社ホームページのバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useHomepageUrlValidationRules = (): Rules => {
  const propertyName = '会社ホームページのURL';

  return useMemo(() => {
    return {
      validate: {
        validUrl: (value) => {
          if (!value) return undefined;

          return /^https?:\/\/[\w/:%#$&?()~.=+-]+$/.test(value) &&
            value.length <= MAX_URL_LENGTH
            ? undefined
            : urlFormatErrorMsg(propertyName);
        }
      }
    };
  }, [propertyName]);
};
