import { PolicySettingReferenceResDto } from 'api-client/public';

/**
 * 選択オプションの型
 */
export type OptionsProps = {
  label: string;
  value: string;
};

/**
 * 電話番号・メールアドレス・URLの選択タイプ
 */
export enum InformationType {
  NotSelected = 'notSelected',
  Tel = 'tel',
  Email = 'email',
  Url = 'url'
}

/**
 * 「本人開示要求など」の電話番号・メールアドレス・URLの選択タイプ
 */
export enum ContactInformationType {
  NotSelected = 'notSelected',
  Tel = 'contactTel',
  Email = 'contactEmail',
  Url = 'contactUrl'
}

/**
 * 入力用のフォームデータ
 */
export type EnhancedPolicyFormData = PolicySettingReferenceResDto & {
  isChangedPolicyType?: boolean;
  informationType: InformationType;
  contactInformationType: ContactInformationType;
};
