import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../../common/ContentAcc/ContentAcc';
import FormDivider from '../../shared/FormDivider';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import TabContentInner from '../../shared/TabContentInner';
import TabContentText from '../../shared/TabContentText';
import VisibilityToggleButton from '../../shared/VisibilityToggleButton';
import FAQReorderBlocks from './FAQReorderBlocks';

/**
 * 「よくある質問」アコーディオンを返す
 * @returns 「よくある質問」アコーディオン
 */
const FAQAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext<FormData>();

  const isVisible = watch('faq.isVisible');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('faq');

  return (
    <ContentAcc
      ref={accordionRef}
      title="よくある質問"
      dispCrown
      isExpanded={isExpanded}
      isError={errors.faq && Object.keys(errors.faq).length > 0}
      onChange={(value) => onChange?.('faq', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="面接などでよく聞かれる質問を設定すると応募者が働く姿をよりイメージしやすくなります。この内容はGoogleの検索結果にも表示されることがあります。" />
        <TabContentFormWrap>
          <Controller
            name="faq.isVisible"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <VisibilityToggleButton isVisible={value} onChange={onChange} />
            )}
          />
          <FormDivider />
        </TabContentFormWrap>
        <FAQReorderBlocks minLength={isVisible ? 1 : 0} />
      </TabContentInner>
    </ContentAcc>
  );
};

export default FAQAccordion;
