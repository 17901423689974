import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { getRequiredMessage } from 'validation/messages';

type Rules = RegisterOptions<
  FormData,
  `jobIntroduction.occupations.${number}.code`
>;

/**
 * 職種(コード)のバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useOccupationCodeValidationRules = (): Rules => {
  const propertyName = '職種';

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredMessage(propertyName, 'select')
      }
    }),
    []
  );
};
