import { generateTextRule } from '../shared';

/**
 * 売上高のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useEarningsValidationRules = () => {
  const propertyName = '売上高';
  const maxLength = 20;
  return generateTextRule<'corporationAbout.earnings'>(
    propertyName,
    maxLength,
    false
  );
};
