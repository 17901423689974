import { generateTextRule } from '../shared';

/**
 * 所在地のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useAddressValidationRules = () => {
  const propertyName = '所在地';
  const maxLength = 300;
  return generateTextRule<'corporationAbout.address'>(
    propertyName,
    maxLength,
    true
  );
};
