/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageSettingAdminReferenceResDtoPreview } from './PageSettingAdminReferenceResDtoPreview';
import {
    PageSettingAdminReferenceResDtoPreviewFromJSON,
    PageSettingAdminReferenceResDtoPreviewFromJSONTyped,
    PageSettingAdminReferenceResDtoPreviewToJSON,
} from './PageSettingAdminReferenceResDtoPreview';
import type { PageSettingAdminReferenceResDtoPublished } from './PageSettingAdminReferenceResDtoPublished';
import {
    PageSettingAdminReferenceResDtoPublishedFromJSON,
    PageSettingAdminReferenceResDtoPublishedFromJSONTyped,
    PageSettingAdminReferenceResDtoPublishedToJSON,
} from './PageSettingAdminReferenceResDtoPublished';

/**
 * 
 * @export
 * @interface PageSettingAdminReferenceResDto
 */
export interface PageSettingAdminReferenceResDto {
    /**
     * 
     * @type {PageSettingAdminReferenceResDtoPublished}
     * @memberof PageSettingAdminReferenceResDto
     */
    published?: PageSettingAdminReferenceResDtoPublished;
    /**
     * 
     * @type {PageSettingAdminReferenceResDtoPreview}
     * @memberof PageSettingAdminReferenceResDto
     */
    preview?: PageSettingAdminReferenceResDtoPreview;
    /**
     * 外部公開用プレビューのUUID
     * @type {string}
     * @memberof PageSettingAdminReferenceResDto
     */
    previewPageUuid?: string;
}

/**
 * Check if a given object implements the PageSettingAdminReferenceResDto interface.
 */
export function instanceOfPageSettingAdminReferenceResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSettingAdminReferenceResDtoFromJSON(json: any): PageSettingAdminReferenceResDto {
    return PageSettingAdminReferenceResDtoFromJSONTyped(json, false);
}

export function PageSettingAdminReferenceResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingAdminReferenceResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'published': !exists(json, 'published') ? undefined : PageSettingAdminReferenceResDtoPublishedFromJSON(json['published']),
        'preview': !exists(json, 'preview') ? undefined : PageSettingAdminReferenceResDtoPreviewFromJSON(json['preview']),
        'previewPageUuid': !exists(json, 'previewPageUuid') ? undefined : json['previewPageUuid'],
    };
}

export function PageSettingAdminReferenceResDtoToJSON(value?: PageSettingAdminReferenceResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'published': PageSettingAdminReferenceResDtoPublishedToJSON(value.published),
        'preview': PageSettingAdminReferenceResDtoPreviewToJSON(value.preview),
        'previewPageUuid': value.previewPageUuid,
    };
}

