import { FC, useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useCatchPhraseValidationRules,
  useLayoutImagePathValidationRules
} from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import ContentAccHead from '../../../../common/ContentAcc/ContentAccHead';
import RadioButtonsGroup from '../../../../common/RadioButtonsGroup';
import TextField from '../../../../common/TextField';
import ImageUploadEntry from '../../../ImageUploader/ImageUploadEntry';
import FormDivider from '../shared/FormDivider';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

const layoutOptions = [
  { label: 'ミニマル', value: 'minimal', defaultSelected: true },
  { label: 'スタンダード', value: 'standard' },
  { label: 'リッチ', value: 'rich' }
];

/**
 * 「TOP画像」アコーディオンを返す
 * @returns 「TOP画像」アコーディオン
 */
const TopImageAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue
  } = useFormContext<FormData>();

  const layout = watch('topImage.layout');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('topImage');

  const layoutImagePathRules = useLayoutImagePathValidationRules(
    layout === 'standard' || layout === 'rich'
  );
  const catchPhraseRules = useCatchPhraseValidationRules();

  useEffect(() => {
    setValue(
      'topImage.layoutImagePath',
      getValues('topImage.layoutImagePath'),
      { shouldValidate: true }
    );
  }, [layout, getValues, setValue]);

  return (
    <ContentAcc
      ref={accordionRef}
      dispCrown
      title="TOP画像"
      isExpanded={isExpanded}
      isRequired
      isError={errors.topImage && Object.keys(errors.topImage).length > 0}
      onChange={(value) => onChange?.('topImage', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="TOPページにメインビジュアルを設定すると貴社の雰囲気が応募者に伝わり、応募者が働く姿をよりイメージしやすくなります。" />
        <TabContentFormWrap>
          <ContentAccHead title="レイアウト" />
          <Controller
            name="topImage.layout"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <RadioButtonsGroup
                options={layoutOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <FormDivider />
          <Controller
            name="topImage.layoutImagePath"
            control={control}
            rules={{ ...layoutImagePathRules, onChange: onChangeValue }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <ImageUploadEntry
                uploadedImagePath={value}
                errorMessage={errors.topImage?.layoutImagePath?.message}
                onUploadImage={onChange}
                onClearImage={() => onChange()}
              />
            )}
          />
          <Controller
            name="topImage.catchPhrase"
            control={control}
            rules={{ ...catchPhraseRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="キャッチフレーズ"
                value={value}
                placeholder="私の仕事が、社会を変える。"
                maxLength={40}
                rows={2}
                maxNewLines={2}
                helperText={
                  errors.topImage?.catchPhrase?.type !== 'maxLength'
                    ? errors.topImage?.catchPhrase?.message
                    : ''
                }
                hasLengthHelperText
                isRequired
                isError={!!errors.topImage?.catchPhrase}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default TopImageAccordion;
