import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { ContactInformationType, EnhancedPolicyFormData } from '../type';
import { SelectBox } from '../parts/SelectBox';
import { IsSameContactOptions } from '../parts/groupOptions';

/**
 * 「本人開示要求など」の連絡先が同じかどうかセクションをレンダリングする
 * @returns {React.JSX.Element} 「本人開示要求など」の連絡先が同じかどうかセクション
 */
export default function IsSameContactSection() {
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext<EnhancedPolicyFormData>();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          「本人からの開示要求など」の連絡先
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <>
            <Controller
              name={'isSameContact'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectBox
                  value={value !== undefined ? value.toString() : ''}
                  width={400}
                  category={IsSameContactOptions}
                  isError={!!errors.isSameContact}
                  onChange={(selectedValue) => {
                    onChange(selectedValue);
                    setValue(
                      'contactInformationType',
                      ContactInformationType.NotSelected
                    );
                    setValue(ContactInformationType.Tel, '');
                    setValue(ContactInformationType.Email, '');
                    setValue(ContactInformationType.Url, '');
                    setValue('contactMemo', '');
                    clearErrors('contactInformationType');
                    clearErrors(ContactInformationType.Tel);
                    clearErrors(ContactInformationType.Email);
                    clearErrors(ContactInformationType.Url);
                    clearErrors('contactMemo');
                  }}
                />
              )}
            />
          </>
        </div>
      </label>
    </div>
  );
}
