import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { getMaxLengthErrorMessage, getRequiredErrorMessage } from './shared';

/**
 * 企業名のバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useCompanyNameValidationRules = (): RegisterOptions<
  EnhancedPolicyFormData,
  'corporationName'
> => {
  const PropertyName = '企業名';
  const MaxLength = 100;

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredErrorMessage(PropertyName, 'input')
      },
      maxLength: {
        value: MaxLength,
        message: getMaxLengthErrorMessage(PropertyName, MaxLength)
      }
    }),
    []
  );
};
