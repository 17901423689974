import { generateTextRule } from '../shared';

/**
 * 資本金のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useCapitalValidationRules = () => {
  const propertyName = '資本金';
  const maxLength = 20;
  return generateTextRule<'corporationAbout.capital'>(
    propertyName,
    maxLength,
    false
  );
};
