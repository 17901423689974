import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import CorporationNameTextField from '../../../SharedFields/CorporationNameTextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「表示用企業名」アコーディオンを返す
 * @returns 「表示用企業名」アコーディオン
 */
const CorporationNameAccordion: FC = () => {
  const {
    formState: { errors }
  } = useFormContext<FormData>();

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('corpName');

  return (
    <ContentAcc
      ref={accordionRef}
      title="表示用企業名の設定"
      isExpanded={isExpanded}
      isRequired
      isError={!!errors.corporationName}
      onChange={(value) => onChange?.('corpName', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="採用サイトのタイトルに使用する、貴社名を設定してください。" />
        <TabContentFormWrap>
          <CorporationNameTextField placeholder="株式会社○○○○" />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default CorporationNameAccordion;
