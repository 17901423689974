import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import './style.scss';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: '8px',
  border: '2px solid var(--kanri-bg-02, #E1E4ED)',
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'var(--kanri-bg-02, #E1E4ED)',
  color: 'var(--kanri-primary-text, #333)',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '1.3',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--kanri-primary-text, #333)',
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  color: 'var(--kanri-secondary-text, #595959)',
  lineHeight: '1.6',
  padding: '20px',
  borderTop: '1px solid var(--kanri-gray-01, #DDD)'
}));

/**
 * @interface CustomizedAccordionsProps
 * @description アコーディオンプロパティを定義するインターフェイス
 * @property {Array} contentArray - アコーディオンのカテゴリー、タイトル、本文
 */
interface CustomizedAccordionsProps {
  contentArray: Array<{
    category: string;
    title: string;
    body: React.ReactNode[];
  }>;
  expanded: string;
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * アコーディオン
 * @param {CustomizedAccordionsProps} props - コンポーネントのプロパティ
 * @param param.category FAQのカテゴリー
 * @param param.title アコーディオンのタイトル
 * @param param.text アコーディオンの中身
 */

export const CustomizedAccordions = ({
  contentArray,
  setExpanded,
  expanded
}: CustomizedAccordionsProps) => {
  // アコーディオンの開閉
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '');
    };

  // 質問のカテゴリ別にグループ化する
  const groupedData = contentArray.reduce((result, item) => {
    if (!result[item.category]) {
      result[item.category] = [];
    }
    result[item.category].push(item);
    return result;
  }, {} as { [key: string]: { category: string; title: string; body: React.ReactNode[] }[] });

  return (
    <>
      {Object.keys(groupedData).map((category, index) => (
        <div key={index} className="customizedAccordion">
          <h3 className="customizedAccordion_category">{category}</h3>
          {groupedData[category].map((item, index2) => (
            <div key={item.title}>
              <Accordion
                expanded={expanded === `q${index}-${index2}`}
                onChange={handleChange(`q${index}-${index2}`)}
              >
                <AccordionSummary
                  aria-controls={`q${index}-${index2}-content`}
                  id={`q${index}-${index2}`}
                >
                  Q{index2 + 1}.{item.title}
                </AccordionSummary>
                <AccordionDetails>
                  {item.body.map((paragraph, index3) => (
                    <p key={index3} className="customizedAccordion_detail">
                      {paragraph}
                    </p>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
