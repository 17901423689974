import { generateTextRule } from '../shared';

/**
 * 会社紹介のバリデーションルールを返す
 * @param {boolean} isRequired 必須であるか
 * @returns バリデーションルール
 */
export const useCorporationIntroductionTextValidationRules = (
  isRequired: boolean
) => {
  const propertyName = '会社紹介';
  const maxLength = 300;

  return generateTextRule<'corporationIntroduction.text'>(
    propertyName,
    maxLength,
    isRequired
  );
};
