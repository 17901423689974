import { useMemo } from 'react';
import { RegisterOptions, FieldValues } from 'react-hook-form';

/**
 * 募集中のお仕事一覧のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useJobMgrNoFieldRules = () => {
  const propertyName = '仕事管理番号';
  const maxLength = 10;

  //数字のみ、最大10桁
  return useMemo(() => {
    const rule: RegisterOptions<FieldValues> = {
      validate: {
        maxLength: (value) => {
          if (value && value.length > maxLength) {
            return `${propertyName}は${maxLength}文字以内で入力してください。`;
          }
          return true;
        },
        number: (value: unknown) => {
          const stringValue = value as string;
          if (stringValue && !/^\d+$/.test(stringValue)) {
            return `${propertyName}は数字で入力してください。`;
          }
          return true;
        }
      }
    };
    return rule;
  }, [propertyName, maxLength]);
};
