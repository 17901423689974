import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useQuestionContentValidationRules,
  useQuestionTitleValidationRules
} from 'validation/rules/saiyouPageEditing';
import TextField from '../../../../../common/TextField';
import FormDivider from '../../shared/FormDivider';
import ReorderBlockHeader from '../../shared/ReorderBlockHeader';
import TabContentFormWrap from '../../shared/TabContentFormWrap';

type Props = {
  index: number;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 「よくある質問」の並び替え可能ブロックを返す
 * @param {number} index 表示順
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 「よくある質問」の並び替え可能ブロックを返す
 */
const FAQReorderBlock: FC<Props> = ({
  index,
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  const { control, watch } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const titleRules = useQuestionTitleValidationRules();
  const contentRules = useQuestionContentValidationRules();

  const title = watch(`faq.questions.${index}.title`);
  const content = watch(`faq.questions.${index}.content`);

  return (
    <TabContentFormWrap>
      <ReorderBlockHeader
        title={`質問${index + 1}`}
        canSwapUp={canSwapUp}
        canSwapDown={canSwapDown}
        canRemove={canRemove}
        onSwapUp={onSwapUp}
        onSwapDown={onSwapDown}
        onRemove={onRemove}
      />
      <Controller
        name={`faq.questions.${index}.title`}
        control={control}
        rules={{ ...titleRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="タイトル"
            value={title}
            placeholder="未経験でも応募可能ですか？"
            maxLength={30}
            helperText={
              errors.faq?.questions?.[index]?.title?.type !== 'maxLength'
                ? errors.faq?.questions?.[index]?.title?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.faq?.questions?.[index]?.title}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`faq.questions.${index}.content`}
        control={control}
        rules={{ ...contentRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="内容"
            value={content}
            placeholder="可能です。お仕事が初めての方でも安心してスタートできるように、内定者研修・導入研修・実務研修・OJT研修など段階的に様々な研修プログラムを準備しています。"
            maxLength={300}
            rows={8}
            helperText={
              errors.faq?.questions?.[index]?.content?.type !== 'maxLength'
                ? errors.faq?.questions?.[index]?.content?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.faq?.questions?.[index]?.content}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <FormDivider />
    </TabContentFormWrap>
  );
};

export default FAQReorderBlock;
