import { FC, useCallback, useState } from 'react';
import { accordionParents } from 'constants/saiyouPageEditing/AccordionParents';
import AccordionProvider from 'providers/saiyouPageEditing/AccordionProvider';
import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';
import { LeftMenuTabType } from 'types/saiyouPageEditing/LeftMenuTabType';
import DrawerTabMenu from './DrawerTabMenu';
import DrawerTabContent from './DrawerTabContent';

type Props = {
  expandedAccordion?: AccordionCode;
  onStartTabSwitch?: () => void;
  onEndTabSwitch?: () => void;
  onChangeAccordion?: (accordion: AccordionCode, isExpanded: boolean) => void;
};

/**
 * 左ペインのタブ切り替えコンポーネントを返す
 * @param {AccordionCode | undefined} expandedAccordion 開いているアコーディオン
 * @param {Function | undefined} onChangeTabStart タブが切り替わり始めた際に実行される関数
 * @param {Function | undefined} onChangeTabStart タブが切り替わり終えた際に実行される関数
 * @param {Function | undefined} onChangeAccordion アコーディオンの開閉状態が変更された際に実行される関数
 * @returns 左ペインのタブ切り替えコンポーネント
 */
const DrawerTabSwitcher: FC<Props> = ({
  expandedAccordion,
  onStartTabSwitch,
  onEndTabSwitch,
  onChangeAccordion
}) => {
  const tabSwitchingDelayMs = 500;

  const [currentTab, setCurrentTab] = useState<LeftMenuTabType>('design');
  const [isVisibleContent, setVisibleContent] = useState(true);
  const [scrollTargetTab, setScrollTargetTab] = useState<LeftMenuTabType>();

  /**
   * タブを切り替える
   * @param {LeftMenuTabType} value 切り替えるタブ
   * @param {boolean} fade フェードイン・アウトするかどうか
   * @param {boolean} isScroll スクロールするかどうか
   */
  const changeTab = useCallback(
    (value: LeftMenuTabType, fade = false, isScroll = false) => {
      setCurrentTab(value);

      onStartTabSwitch?.();

      setVisibleContent(!fade);

      setTimeout(() => {
        if (isScroll) setScrollTargetTab(value);

        setVisibleContent(true);

        onEndTabSwitch?.();
      }, tabSwitchingDelayMs);
    },
    [tabSwitchingDelayMs, onStartTabSwitch, onEndTabSwitch]
  );

  const handleChangeAccordion = useCallback(
    (accordion: AccordionCode, isExpanded: boolean) => {
      if (isExpanded) changeTab(accordionParents[accordion]);

      onChangeAccordion?.(accordion, isExpanded);
    },
    [accordionParents, changeTab, onChangeAccordion]
  );

  return (
    <>
      <DrawerTabMenu
        currentTab={currentTab}
        onChangeTab={(tab) => changeTab(tab, true, true)}
      />
      <AccordionProvider
        expandedAccordion={expandedAccordion}
        onChangeAccordion={handleChangeAccordion}
      >
        <DrawerTabContent
          scrollTargetTab={scrollTargetTab}
          isVisible={isVisibleContent}
        />
      </AccordionProvider>
    </>
  );
};

export default DrawerTabSwitcher;
