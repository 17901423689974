import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import './LazyIcon.scss';

type Props = {
  filePath?: string;
};

/**
 * 指定されたファイル名のアイコン、または、プログレスサークルを返す
 * @param {string | undefined} filePath アイコンのファイルパス
 * @returns アイコン、または、プログレスサークル
 */
const LazyIcon: FC<Props> = ({ filePath = '' }) => {
  const [svgContent, setSvgContent] = useState<string | undefined>(undefined);

  const iconUrl = useMemo(
    () => `${process.env.REACT_APP_PUBLIC_IMAGE_BASEPATH}/${filePath}`,
    [filePath]
  );

  const loadSvgAsync = useCallback(
    async (url: string) =>
      new Promise<string>((resolve) => {
        fetch(url)
          .then((response) => response.text())
          .then((data) => {
            resolve(data);
          });
      }),
    []
  );

  useEffect(() => {
    const load = async () => {
      const svg = await loadSvgAsync(iconUrl);
      setSvgContent(svg);
    };
    void load();
  }, [iconUrl, loadSvgAsync]);

  return (
    <div className="LazyIcon">
      {svgContent ? (
        <div
          className="svg-icon"
          dangerouslySetInnerHTML={{ __html: svgContent }}
        />
      ) : (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default LazyIcon;
