import { generateTextRule } from '../../shared';

/**
 * 表示用職種名のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useOccupationNameValidationRules = () => {
  const propertyName = '表示用職種名';
  const maxLength = 100;
  return generateTextRule<`jobIntroduction.occupations.${number}.name`>(
    propertyName,
    maxLength,
    true
  );
};
