/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PolicySettingReferenceResDto,
} from '../models';
import {
    PolicySettingReferenceResDtoFromJSON,
    PolicySettingReferenceResDtoToJSON,
} from '../models';

export interface GetPolicySettingRequest {
    compMgrNo: number;
}

/**
 * 
 */
export class PolicySettingPublicApi extends runtime.BaseAPI {

    /**
     *      設定データがない場合は空データを返す     
     * サービス利用規約設定取得
     */
    async getPolicySettingRaw(requestParameters: GetPolicySettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolicySettingReferenceResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getPolicySetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/{compMgrNo}/policy-setting`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicySettingReferenceResDtoFromJSON(jsonValue));
    }

    /**
     *      設定データがない場合は空データを返す     
     * サービス利用規約設定取得
     */
    async getPolicySetting(requestParameters: GetPolicySettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolicySettingReferenceResDto> {
        const response = await this.getPolicySettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
