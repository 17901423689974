import { FC } from 'react';
import Divider from '@mui/material/Divider';

/**
 * フォーム間の分割線を返す
 * @returns 分割線
 */
const FormDivider: FC = () => (
  <Divider sx={{ borderColor: 'var(--kanri-gray, #9FB2BF)' }} />
);

export default FormDivider;
