import { Box, Stepper } from '@mui/material';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FC, useContext, useEffect, useState } from 'react';
import InitGuideImage from 'assets/img/initGuidImage.png';
import {
  Card,
  CardMedia,
  StepContent,
  Description,
  ActionButton,
  Label,
  CardContent,
  Step,
  LastStepContent
} from './parts';

/**
 * 初期設定ガイド
 **/
const InitGuide: FC = () => {
  const {
    hasDisplayedInitModal,
    isLoading,
    pageSetting,
    hasSavedBySaveButton,
    hasPolicy,
    onClickPublish,
    onClickSave,
    compId,
    canPublish,
    navigateToPolicyPage
  } = useContext(EditSaiyouPageContext);

  const steps = [
    {
      label: '採用サイトを確認・編集しましょう',
      description:
        '右のプレビューを確認して、各セクションの編集フォームで修正してください。編集したら一時保存をしましょう。',
      actionItem: (
        <ActionButton
          onClick={onClickSave}
          label="一時保存して次へ"
          gaTag="InitGuideSaveBtn"
        />
      )
    },
    {
      label: '個人情報利用規約を設定しましょう',
      description: '個人情報保護管理者について設定してください。',
      //
      actionItem: (
        <ActionButton
          onClick={navigateToPolicyPage}
          label="個人情報利用規約を設定する"
          gaTag="InitGuidePolicySettingBtn"
        />
      )
    },
    {
      label: '採用サイトを公開しましょう',
      description: '内容に問題がなければ、サイトを公開してください。',
      actionItem: (
        <ActionButton
          onClick={onClickPublish}
          label="公開"
          width="160px"
          gaTag="InitGuidePublishBtn"
          disabled={!canPublish}
        />
      )
    },
    {
      label: 'URLを確認しましょう',
      description: (
        <p>採用サイトが公開されました！ 求人を掲載して、応募を待ちましょう。</p>
      ),
      actionItem: (
        <LastStepContent
          hasPublished={!!pageSetting?.published?.updatedAt}
          compId={compId ?? ''}
        />
      )
    }
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [hideThisGuide, setHideThisGuide] = useState(true);

  useEffect(() => {
    const publishedUpdated = pageSetting?.published?.updatedAt;

    if (isLoading) return; //まだロード中なら判定ロジックに入らない

    const setStep = (stp: number) => {
      setActiveStep(stp);
      setHideThisGuide(false);
    };

    //STEP表示判定
    if (hasDisplayedInitModal && !hasSavedBySaveButton) {
      setStep(1);
      return;
    }
    if (!hasPolicy) {
      setStep(2);
      return;
    }
    if (!publishedUpdated) {
      setStep(3);
      return;
    }
    if (!hideThisGuide) {
      setStep(4);
      return;
    }
    //ここまで辿り着いたらガイド非表示
    setHideThisGuide(true);
  }, [
    pageSetting,
    hasPolicy,
    hasDisplayedInitModal,
    isLoading,
    hasSavedBySaveButton
  ]);

  return (
    <>
      {!hideThisGuide && (
        <Card>
          <CardMedia component="img" image={InitGuideImage} />
          <CardContent>
            {/* activeStepは0始まりなので1引いてあげる */}
            <Stepper activeStep={activeStep - 1} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <Label>{step.label}</Label>
                  <StepContent>
                    <Description>{step.description}</Description>
                    <Box>{step.actionItem}</Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      )}
    </>
  );
};
export default InitGuide;
