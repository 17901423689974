import { FC } from 'react';
import { Link } from '@mui/material';
import { WarningAmberIcon } from 'assets/icons';
import CloseButton from './CloseButton';

type Props = {
  onClickClose?: () => void;
};

/**
 * 初期設定モーダルのエラーコンテンツを返す
 * @param {Function | undefined} onClickClose 閉じるボタンがクリックされた際に実行される関数
 * @returns 初期設定モーダルのエラーコンテンツ
 */
const ErrorContent: FC<Props> = ({ onClickClose }) => (
  <>
    <div className="dialog-content-wrap error">
      <div className="error-content-wrap">
        <WarningAmberIcon className="error-icon" />
        <div className="error-head">求人原稿が見つかりません</div>
        <div className="error-detail">
          採用ページコボットの初期設定には、バイトル管理画面上に1件以上のバイトル・バイトルNEXT用原稿が必要です。
          <br />
          バイトル管理画面で原稿を作成してください（{' '}
          <Link
            target="_blank"
            href={process.env.REACT_APP_BAITORU_MANAGEMENT_URL}
          >
            詳細はこちら
          </Link>{' '}
          ）。
        </div>
      </div>
    </div>
    <div className="button-wrap justify-center">
      <CloseButton onClick={onClickClose} />
    </div>
  </>
);

export default ErrorContent;
