/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ImageListResItemDto,
  ImageUploadReqDto,
  ImageUploadResDto,
} from '../models';
import {
    ImageListResItemDtoFromJSON,
    ImageListResItemDtoToJSON,
    ImageUploadReqDtoFromJSON,
    ImageUploadReqDtoToJSON,
    ImageUploadResDtoFromJSON,
    ImageUploadResDtoToJSON,
} from '../models';

export interface ListImageRequest {
    compMgrNo: number;
    page?: number;
    searchWord?: string;
    imageType?: ListImageImageTypeEnum;
}

export interface UploadImageRequest {
    compMgrNo: number;
    imageUploadReqDto: ImageUploadReqDto;
}

/**
 * 
 */
export class ImageApi extends runtime.BaseAPI {

    /**
     *      フリー画像(S3バケットに存在しているもの)　もしくはバイトル管理にアップロードされている画像を返す。     1ページ最大100件でページングしてデータを返却。     
     * 画像リスト取得API
     */
    async listImageRaw(requestParameters: ListImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImageListResItemDto>>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling listImage.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.searchWord !== undefined) {
            queryParameters['searchWord'] = requestParameters.searchWord;
        }

        if (requestParameters.imageType !== undefined) {
            queryParameters['imageType'] = requestParameters.imageType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/image`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImageListResItemDtoFromJSON));
    }

    /**
     *      フリー画像(S3バケットに存在しているもの)　もしくはバイトル管理にアップロードされている画像を返す。     1ページ最大100件でページングしてデータを返却。     
     * 画像リスト取得API
     */
    async listImage(requestParameters: ListImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImageListResItemDto>> {
        const response = await this.listImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      画像データをリサイズした上でS3に保存し、保存先のURLを返す     base64化した画像データと、別の場所にホスティングされている画像URL　どちらかを選択してアップロード     対応ファイルはjpeg(jpg)/png/gifの画像（gifアニメもアップロードできるが静止画としてリサイズされる）     
     * 画像アップロードAPI
     */
    async uploadImageRaw(requestParameters: UploadImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImageUploadResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling uploadImage.');
        }

        if (requestParameters.imageUploadReqDto === null || requestParameters.imageUploadReqDto === undefined) {
            throw new runtime.RequiredError('imageUploadReqDto','Required parameter requestParameters.imageUploadReqDto was null or undefined when calling uploadImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/image`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImageUploadReqDtoToJSON(requestParameters.imageUploadReqDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageUploadResDtoFromJSON(jsonValue));
    }

    /**
     *      画像データをリサイズした上でS3に保存し、保存先のURLを返す     base64化した画像データと、別の場所にホスティングされている画像URL　どちらかを選択してアップロード     対応ファイルはjpeg(jpg)/png/gifの画像（gifアニメもアップロードできるが静止画としてリサイズされる）     
     * 画像アップロードAPI
     */
    async uploadImage(requestParameters: UploadImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImageUploadResDto> {
        const response = await this.uploadImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListImageImageTypeEnum = {
    Baitoru: 'baitoru',
    Free: 'free'
} as const;
export type ListImageImageTypeEnum = typeof ListImageImageTypeEnum[keyof typeof ListImageImageTypeEnum];
