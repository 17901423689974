import { FC } from 'react';
import { CircularProgress } from '@mui/material';

/**
 * ローディング時のマスクを返す
 * @returns ローディング時のマスク
 */
const LoadingMask: FC = () => (
  <div
    style={{
      backgroundColor: 'rgba(1, 1, 1, 0.5)',
      width: '100vw',
      height: '100vh',
      position: 'relative',
      zIndex: 10000
    }}
  >
    <div
      style={{ paddingTop: 100, textAlign: 'center', padding: '50vh 0 0 0' }}
    >
      <CircularProgress />
      <div
        style={{
          color: '#fff'
        }}
      >
        読み込んでいます。
      </div>
    </div>
  </div>
);

export default LoadingMask;
