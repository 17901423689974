import { Button } from '@mui/material';
import { ArrowIcon } from 'assets/icons';
import { FC } from 'react';

type Props = {
  onClick?: () => void;
};

/**
 * 「この画面のトップへ戻る」ボタンを返す
 * @param {Function | undefined} onClick クリックされた際に実行される関数
 * @returns 「この画面のトップへ戻る」ボタン
 */
const ScrollToTopButton: FC<Props> = ({ onClick }) => (
  <div
    style={{
      display: 'flex',
      padding: '20px 0px 40px 0px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch'
    }}
  >
    <Button onClick={onClick}>
      <div
        style={{
          display: 'flex',
          padding: '0px 4px 5px 8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          borderBottom: '1px solid var(--kanri-gray-01, #DDD)'
        }}
      >
        <span
          style={{
            color: 'var(--kanri-primary-text, #333)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '100%'
          }}
        >
          この画面のトップへ戻る
        </span>
        <ArrowIcon
          style={{
            transform: 'rotate(180deg)',
            width: '8px',
            height: '6px'
          }}
        />
      </div>
    </Button>
  </div>
);

export default ScrollToTopButton;
