import { PropsWithChildren, forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { ArrowRightIcon, CrownIcon } from 'assets/icons';
import RequiredLabel from '../RequiredLabel';
import ErrorTooltip from './ErrorTooltip';
import StyledAccordion from './StyledAccordion';
import StyledAccordionDetails from './StyledAccordionDetails';
import StyledAccordionSummary from './StyledAccordionSummary';
import { styled } from '@mui/material';

const BootstrapTitle = styled(Typography)({
  lineHeight: '100%'
});

const BootstrapCrown = styled(CrownIcon)({
  width: '14px',
  height: '12.16px'
});

type Props = {
  title?: string;
  dispCrown?: boolean;
  disabled?: boolean;
  isExpanded?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  onChange?: (isExpanded: boolean) => void;
  onEntered?: () => void;
  onExited?: () => void;
} & PropsWithChildren;

/**
 * カスタムアコーディオンコンポーネントを返す
 * @param {string | undefined} title タイトル
 * @param {boolean | undefined} dispCrown 王冠アイコンを表示するかどうか
 * @param {boolean | undefined} isExpanded 展開されているか
 * @param {boolean | undefined} disabled アコーディオンを無効化するか
 * @param {boolean | undefined} isRequired 必須項目であるか
 * @param {boolean | undefined} isError エラーがあるか
 * @param {Function | undefined} onChange 展開状態が変更された際に実行される関数
 * @param {Function | undefined} onEntered 展開された際に実行される関数
 * @param {Function | undefined} onExited 閉じられた際に実行される関数
 * @returns カスタムアコーディオンコンポーネント
 */
const ContentAcc = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      title = '',
      disabled = false,
      dispCrown = false,
      isExpanded = false,
      isRequired = false,
      isError = false,
      onChange,
      onEntered,
      onExited
    },
    ref
  ) => {
    return (
      <StyledAccordion
        ref={ref}
        expanded={isExpanded}
        onChange={(_, expanded) => onChange?.(expanded)}
        disabled={disabled}
        TransitionProps={{
          onEntered: onEntered,
          onExited: onExited
        }}
      >
        <StyledAccordionSummary
          expandIcon={
            <ArrowRightIcon style={{ width: '14px', height: '14px' }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <BootstrapTitle>{title}</BootstrapTitle>
          {dispCrown && (
            <BootstrapCrown
              fill={
                isExpanded ? '#FFF' : 'var(--kanri-secondary-text, #595959)'
              }
            />
          )}
          {isRequired && <RequiredLabel />}
          {isError && <ErrorTooltip />}
        </StyledAccordionSummary>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    );
  }
);

export default ContentAcc;
