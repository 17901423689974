import { CSSProperties, FC } from 'react';
import { CheckCircleIcon } from 'assets/icons';

type Props = {
  label: string;
  style?: CSSProperties;
};

/**
 * 未選択時のラジオボタンラベルを返す
 * @param {string} label ラベル文言
 * @param {CSSProperties | undefined} style 追加のCSSプロパティ
 * @returns 未選択時のラジオボタンラベル
 */
const NormalRadioLabel: FC<Props> = ({ label, style }) => (
  <p
    className={'radioChoiceButton'}
    style={{
      color: '#777',
      background: '#DDD',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.20)',
      ...style
    }}
  >
    <CheckCircleIcon className="checkCircleIcon" style={{ fill: '#F3F3F3' }} />
    {label}
  </p>
);

export default NormalRadioLabel;
