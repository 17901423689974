import { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { DrawerStyles } from 'constants/saiyouPageEditing/DrawerStyles';
import { useConfig } from 'context/ConfigContext';

type PreviewTargetDevice = 'pc' | 'sp';

type Props = {
  iframeRef: RefObject<HTMLIFrameElement>;
  offsetX: number;
  heightPercentage: number;
  scalePercentage?: number;
  targetDevice?: PreviewTargetDevice;
  isVisible?: boolean;
  mainContentWidth: number;
};

/**
 * プレビューエリアを返す
 * @param {RefObject<HTMLIFrameElement>} iframeRef iframeのref
 * @param {boolean} hasPolicy プライバシーポリシーがあるか
 * @param {number} offsetX 画面のX座標値
 * @param {number} heightPercentage 画面の高さ(%)
 * @param {number | undefined} scale 画面のスケール(%)
 * @param {PreviewTargetDevice | undefined} targetDevice ターゲットデバイス
 * @param {boolean | undefined} isVisible 表示するか
 * @param {number} mainContentWidth メインコンテンツの幅
 * @returns プレビューエリア
 */
const PreviewContent: FC<Props> = ({
  iframeRef,
  offsetX,
  heightPercentage,
  scalePercentage = 75,
  targetDevice = 'pc',
  isVisible = false,
  mainContentWidth
}) => {
  const config = useConfig();

  const [fixedScalePercentage, setFixedScalePercentage] =
    useState<number>(scalePercentage);
  const [fixedHeight, setFixedHeight] = useState<string>(
    heightPercentage + '%'
  );

  const nowMinWidth = targetDevice === 'pc' ? config.pcWidth : config.spWidth;
  useEffect(() => {
    if (mainContentWidth < nowMinWidth) {
      setFixedScalePercentage(
        scalePercentage * 0.95 * (mainContentWidth / nowMinWidth)
      );
      setFixedHeight(
        'calc(' + heightPercentage * (nowMinWidth / mainContentWidth) + '%)'
      );
    } else {
      setFixedScalePercentage(scalePercentage * 0.95);
      setFixedHeight('calc(' + heightPercentage + '%)');
    }
  }, [scalePercentage, mainContentWidth, targetDevice, heightPercentage]);

  const previewUrl = useMemo(
    () => `${process.env.REACT_APP_PUBLIC_FRONT_HOST}/embedded/preview`,
    []
  );

  return (
    <div
      style={
        isVisible
          ? {
              left: offsetX,
              transition: DrawerStyles.transition
            }
          : {
              left: offsetX,
              transition: DrawerStyles.transition,
              display: 'none'
            }
      }
      className="main-contents-wrap"
    >
      <div className="main-contents-scroll-wrap">
        <div
          style={{
            width: Math.min(
              targetDevice === 'pc' ? config.pcWidth : config.spWidth,
              mainContentWidth
            )
          }}
          className="main-contents"
        >
          <iframe
            ref={iframeRef}
            title="preview"
            src={previewUrl}
            style={{
              transform: `scale(${fixedScalePercentage / 100})`,
              minWidth: targetDevice === 'pc' ? config.pcWidth : config.spWidth,
              height: fixedHeight,
              transformOrigin: 'top center'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewContent;
