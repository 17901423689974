import { InfoIcon } from 'assets/icons';

type Props = {
  children: React.ReactNode;
};

export const WarningMessageArea = ({ children }: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#ffeef4',
        color: '#e96492',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <InfoIcon fill="#e96492" style={{ margin: '0 10px 0 0' }} />

      {children}
    </div>
  );
};
