import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { ContactInformationType, EnhancedPolicyFormData } from '../type';
import TextField from '../parts/TextField';
import { SelectBox } from '../parts/SelectBox';
import { ContactInformationTypeOptions } from '../parts/groupOptions';
import { useEmailValidationRules } from '../validations/email';
import { useTelValidationRules } from '../validations/tel';
import { useUrlValidationRules } from '../validations/url';

/**
 * 「本人開示要求など」の電話番号・メールアドレス・URLセクションをレンダリングする
 * @returns {React.JSX.Element} 「本人開示要求など」の電話番号・メールアドレス・URLセクション
 */
export default function ContactTelOrMailOrUrlSection() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors
  } = useFormContext<EnhancedPolicyFormData>();

  const contactInformationTypeValue = watch('contactInformationType');

  const contactTelRules = useTelValidationRules<'contactTel'>();
  const contactEmailRules = useEmailValidationRules<'contactEmail'>();
  const contactUrlRules = useUrlValidationRules<'contactUrl'>();

  const configMap = {
    [ContactInformationType.NotSelected]: {
      placeholder: '選択してください',
      rules: {},
      isDisabled: true
    },
    [ContactInformationType.Tel]: {
      placeholder: '電話番号を入力',
      rules: contactTelRules
    },
    [ContactInformationType.Email]: {
      placeholder: 'メールアドレスを入力',
      rules: contactEmailRules
    },
    [ContactInformationType.Url]: {
      placeholder: 'URLを入力',
      rules: contactUrlRules
    }
  };

  const currentConfig = configMap[contactInformationTypeValue];

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          電話番号・メールアドレス・URL
          <span
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              fontWeight: 'normal'
            }}
          >
            ※いずれかを選択してください
          </span>
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <>
            <Controller
              name={'contactInformationType'}
              control={control}
              rules={{
                validate: (value) =>
                  value !== ContactInformationType.NotSelected
                    ? undefined
                    : '選択してください。'
              }}
              render={({ field: { value, onChange } }) => (
                <SelectBox
                  value={value}
                  width={180}
                  category={ContactInformationTypeOptions}
                  isError={!!errors.contactInformationType}
                  errorMessage={errors.contactInformationType?.message}
                  onChange={(selectedValue) => {
                    onChange(selectedValue);
                    setValue(ContactInformationType.Tel, '');
                    setValue(ContactInformationType.Email, '');
                    setValue(ContactInformationType.Url, '');
                    clearErrors(ContactInformationType.Tel);
                    clearErrors(ContactInformationType.Email);
                    clearErrors(ContactInformationType.Url);
                  }}
                />
              )}
            />
            {contactInformationTypeValue ===
              ContactInformationType.NotSelected && (
              <TextField
                placeholder={currentConfig.placeholder}
                width={400}
                maxLength={100}
                hasHelperText={false}
                isDisabled={true}
              />
            )}
            {contactInformationTypeValue !==
              ContactInformationType.NotSelected && (
              <Controller
                name={contactInformationTypeValue}
                control={control}
                rules={currentConfig.rules}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    value={value}
                    placeholder={currentConfig.placeholder}
                    width={400}
                    maxLength={100}
                    errorMessage={errors[contactInformationTypeValue]?.message}
                    isError={!!errors[contactInformationTypeValue]}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </>
        </div>
      </label>
    </div>
  );
}
