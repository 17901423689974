import { CustomButton } from './CustomButton';
import { styled } from '@mui/material';

export const CloseAndBackButtons = () => {
  const ButtonWrap = styled('div')(() => ({
    display: 'flex',
    paddingTop: 20,
    alignItems: 'flex-start',
    gap: 20
  }));
  return (
    <ButtonWrap>
      <CustomButton
        width="250px"
        height="44px"
        textcolor="var(--kanri-primary)"
        border="1px solid"
        bordercolor="var(--kanri-primary)"
        backgroundcolor="white"
        hoveropacity="1"
        hoverborder="2px solid"
        hoverbordercolor="var(--kanri-primary)"
        onClick={() => window.close()}
      >
        閉じる
      </CustomButton>

      <CustomButton
        width="250px"
        height="44px"
        textcolor="#ffffff"
        border="1px solid"
        bordercolor="#3E91D5"
        backgroundcolor="#3E91D5"
        hoveropacity="0.85"
        hoverborder="2px solid"
        hoverbordercolor="#3E91D5"
        onClick={() => {
          window.location.href =
            process.env.REACT_APP_BAITORU_MANAGEMENT_URL ?? '';
        }}
      >
        バイトル管理画面に戻る
      </CustomButton>
    </ButtonWrap>
  );
};
