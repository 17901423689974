import { generateTextRule } from '../shared';

/**
 * 従業員数のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useEmployeesLengthValidationRules = () => {
  const propertyName = '従業員数';
  const maxLength = 10;
  return generateTextRule<'corporationAbout.employeesLength'>(
    propertyName,
    maxLength,
    false
  );
};
