import React from 'react';

/**
 * @typedef {object} TabContentTextProps
 * @property {string} text - 表示するテキスト
 */
type TabContentTextProps = {
  text: React.ReactNode;
};

/**
 * タブコンテンツ内でのテキストを管理するコンポーネント。
 * @param {TabContentTextProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} テキストのレンダリング
 */
const TabContentText: React.FC<TabContentTextProps> = ({ text }) => {
  return (
    <div>
      <p
        style={{
          color: 'var(--kanri-secondary-text, #595959)',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%'
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default TabContentText;
