import { FC } from 'react';
import { styled } from '@mui/material';
import { DrawerStyles } from 'constants/saiyouPageEditing/DrawerStyles';
import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';
import TabSwitcher from './DrawerTabSwitcher';

type Props = {
  isOpen?: boolean;
  expandedAccordion?: AccordionCode;
  onStartTabSwitch?: () => void;
  onEndTabSwitch?: () => void;
  onChangeAccordion?: (accordion: AccordionCode, isExpanded: boolean) => void;
};

/**
 * 左ペインの内部コンポーネントを返す
 * @param {boolean | undefined} isOpen 開いているか
 * @param {AccordionCode | undefined} expandedAccordion 開いているアコーディオン
 * @param {Function | undefined} onChangeTabStart タブが切り替わり始めた際に実行される関数
 * @param {Function | undefined} onChangeTabStart タブが切り替わり終えた際に実行される関数
 * @param {Function | undefined} onChangeAccordion アコーディオンの開閉状態が変更された際に実行される関数
 * @returns 左ペインの内部コンポーネント
 */
const DrawerInner: FC<Props> = ({
  isOpen = false,
  expandedAccordion,
  onStartTabSwitch,
  onEndTabSwitch,
  onChangeAccordion
}) => (
  <StyledDrawerInner isOpen={isOpen}>
    {isOpen && (
      <TabSwitcher
        expandedAccordion={expandedAccordion}
        onStartTabSwitch={onStartTabSwitch}
        onEndTabSwitch={onEndTabSwitch}
        onChangeAccordion={onChangeAccordion}
      />
    )}
  </StyledDrawerInner>
);

export default DrawerInner;

const StyledDrawerInner = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  width: isOpen ? 350 : 5,
  position: 'fixed',
  left: 0,
  top: 60,
  boxShadow: '4px 0 8px rgba(0, 0, 0, 0.08)',
  background: '#FFFFFF',
  height: '100%',
  zIndex: 2,
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  paddingTop: '40px',
  ...DrawerStyles
}));
