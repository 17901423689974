import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../../common/ContentAcc/ContentAcc';
import FormDivider from '../../shared/FormDivider';
import TabContentInner from '../../shared/TabContentInner';
import TabContentText from '../../shared/TabContentText';
import TabContentFormWrap from '../../shared/TabContentFormWrap';
import VisibilityToggleButton from '../../shared/VisibilityToggleButton';
import JobPointReorderBlocks from './JobPointReorderBlocks';

/**
 * 「お仕事のポイント」アコーディオンを返す
 * @returns 「お仕事のポイント」アコーディオン
 */
const JobPointAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext<FormData>();

  const isVisible = watch('jobPoint.isVisible');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('jobPoint');

  return (
    <ContentAcc
      ref={accordionRef}
      title="お仕事のポイント"
      dispCrown
      isExpanded={isExpanded}
      isError={errors.jobPoint && Object.keys(errors.jobPoint).length > 0}
      onChange={(value) => onChange?.('jobPoint', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="魅力的な福利厚生を設定し、応募者に安心感を与えましょう。" />
        <TabContentFormWrap>
          <Controller
            name="jobPoint.isVisible"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <VisibilityToggleButton isVisible={value} onChange={onChange} />
            )}
          />
          <FormDivider />
        </TabContentFormWrap>
        <JobPointReorderBlocks minLength={isVisible ? 1 : 0} />
      </TabContentInner>
    </ContentAcc>
  );
};

export default JobPointAccordion;
