import * as React from 'react';
import { styled } from '@mui/material/styles';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';

interface CustomListItemIconProps extends ListItemIconProps {
  open: boolean;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(ListItemIcon)<CustomListItemIconProps>(
  ({ open }) => ({
    width: '50px',
    height: '50px',
    marginRight: open ? 3 : 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  })
);
/**
 * メニュー内のユーザー情報項目のボタンベース
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @returns
 */
interface ListItemMoreVertIconProps {
  children: React.ReactNode;
  open: boolean;
}
export const ListItemMoreVertIcon = React.memo(
  ({ children, open }: ListItemMoreVertIconProps) => {
    return <CustomListItemButton open={open}>{children}</CustomListItemButton>;
  }
);
