import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { getRequiredErrorMessage } from './shared';
import { EnhancedPolicyFormData } from '../type';

type TelProperty = 'tel' | 'contactTel';
/**
 * 電話番号のバリデーションHooks
 * @param {boolean | undefined} isRequired 必須項目であるか
 * @param {string | undefined} propertyName プロパティ名
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useTelValidationRules = <T extends TelProperty>(): RegisterOptions<
  EnhancedPolicyFormData,
  T
> => {
  const PropertyName = '電話番号';
  const MinLength = 10;
  const MaxLength = 11;

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredErrorMessage(PropertyName, 'input')
      },
      minLength: {
        value: MinLength,
        message: `${PropertyName}は半角数字の10~11文字で入力してください。`
      },
      maxLength: {
        value: MaxLength,
        message: `${PropertyName}は半角数字の10~11文字で入力してください。`
      },
      validate: {
        startsWithZero: (value?: string) =>
          !value || value.startsWith('0')
            ? undefined
            : '0から始まる番号を入力してください。',
        numberOrHyphen: (value?: string) =>
          !value || value.match(/^[0-9]{10,11}$/)
            ? undefined
            : `${PropertyName}は半角数字で入力してください。`
      }
    }),
    []
  );
};
