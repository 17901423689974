import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useBase64 from 'hooks/useBase64';
import { uploadImage } from 'logic/clientWrapper/management';
import { ImageResizeType } from 'types/saiyouPageEditing/ImageResizeType';

/**
 * 画像のアップロードHooks
 * @param {string | undefined} compId 企業管理番号
 * @returns 画像アップロード関数群
 */
const useImageUploader = (
  compId?: string
): {
  uploadImageAsyncFromFile: (
    image: File,
    resizeType: ImageResizeType
  ) => Promise<string | undefined>;
  uploadImageAsyncFromUrl: (
    imageUrl: string,
    resizeType: ImageResizeType
  ) => Promise<string | undefined>;
} => {
  const auth0 = useAuth0();

  const { toBase64FromFile } = useBase64();

  const uploadImageAsyncFromFile = useCallback(
    async (image: File, resizeType: ImageResizeType) => {
      if (!compId) return undefined;

      const base64 = await toBase64FromFile(image);
      const response = await uploadImage(
        compId,
        {
          type: 'base64',
          resizeMode: resizeType,
          base64
        },
        auth0
      );

      if (!response || typeof response === 'number') return undefined;

      return response.uploadedPath;
    },
    [compId, auth0, toBase64FromFile]
  );

  const uploadImageAsyncFromUrl = useCallback(
    async (imageUrl: string, resizeType: ImageResizeType) => {
      if (!compId) return undefined;

      const response = await uploadImage(
        compId,
        {
          type: 'outerUrl',
          resizeMode: resizeType,
          outerUrl: imageUrl
        },
        auth0
      );

      if (!response || typeof response === 'number') return undefined;

      return response.uploadedPath;
    },
    [compId, auth0]
  );

  return { uploadImageAsyncFromFile, uploadImageAsyncFromUrl };
};

export default useImageUploader;
