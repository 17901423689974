import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

type Props = {
  disabled: boolean;
};

/**
 * テキストフィールドのフォームコントロールを返す
 * @param {boolean} disabled 入力が無効であるか
 * @returns テキストフィールドのフォームコントロール
 */
const TextFieldControl = styled(FormControl)<Props>(({ disabled }) => ({
  display: 'block',
  width: '100%',
  '&.MuiFormControl-root': {
    border: 'none',
    '& .MuiInputBase-colorPrimary': {
      color: 'var(--kanri-sub-text, #777)'
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 400,
      color: 'var(--kanri-secondary-text, #595959)',
      padding: 0
    },
    '& .MuiFormLabel-colorPrimary': {
      color: 'var(--kanri-sub-text, #777)',
      fontSize: 14,
      fontWeight: 700
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused , &.MuiFormLabel-filled': {
        transform: 'translate(14px, -8px) scale(1)',
        color: 'var(--kanri-secondary-text, #595959)'
      }
    },
    '& .MuiInputBase-root': {
      padding: 16,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-gray-03, #899194)'
      },
      ...(disabled && {
        background: 'var(--kanri-disabled-button-color)',
        border: '1px solid var(--kanri-gray)',
        '& .MuiOutlinedInput-notchedOutline': {
          display: 'none'
        },
        '& input::placeholder': {
          '-webkit-text-fill-color': 'var(--kanri-sub-text) !important'
        }
      })
    },
    '& .Mui-focused': {
      fontSize: 14,
      fontWeight: 700,
      '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-gray-03, #899194)'
      }
    },
    '& .Mui-error': {
      fontWeight: 400,
      color: 'var(--kanri-erroralert, #DC1357)',
      '&.MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--kanri-erroralert, #DC1357)'
      },
      '& .MuiFormHelperText-right': {
        color: 'var(--kanri-erroralert, #DC1357)'
      },
      '&.MuiFormLabel-root': {
        color: 'var(--kanri-erroralert, #DC1357)'
      }
    },
    '& legend': {
      color: 'var(--kanri-sub-text, #777)',
      fontSize: 14,
      fontWeight: 400,
      height: 14
    },
    '& .MuiFormHelperText-root': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .MuiFormHelperText-left': {
      color: 'var(--kanri-secondary-text, #595959)',
      fontSize: 12,
      fontWeight: 400
    },
    '& .MuiFormHelperText-bold': {
      fontWeight: 700,
      paddingRight: 2
    },
    '& .MuiFormHelperText-right': {
      color: 'var(--kanri-secondary-text, #595959)',
      fontSize: 12,
      fontWeight: 400,
      '& .MuiFormHelperText-bold': {
        padding: '0 2px'
      }
    },
    '& .MuiInputBase-inputMultiline': {
      padding: 0,
      lineHeight: '130%',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
}));

export default TextFieldControl;
