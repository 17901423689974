import { generateTextRule } from '../shared';

/**
 * 福利厚生・待遇のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useWelfareValidationRules = () => {
  const propertyName = '福利厚生・待遇';
  const maxLength = 300;
  return generateTextRule<'corporationAbout.welfare'>(
    propertyName,
    maxLength,
    false
  );
};
