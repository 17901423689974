import React, { createContext, useContext, ReactNode } from 'react';

type ColorDefinition = {
  label: string;
  value: string;
  theme: {
    basic: {
      keyColor: string;
      subColor: string;
      backgroundColor: string;
      textColor: string;
      subBackgroundColor: string;
    };
    stylish: {
      keyColor: string;
      subColor: string;
      backgroundColor: string;
      textColor: string;
      subBackgroundColor: string;
    };
    pop: {
      keyColor: string;
      subColor: string;
      backgroundColor: string;
      textColor: string;
      subBackgroundColor: string;
    };
    natural: {
      keyColor: string;
      subColor: string;
      backgroundColor: string;
      textColor: string;
      subBackgroundColor: string;
    };
  };
};

/**
 * @property {Record<string, ColorDefinition>} colors 採用コボットで採用している色の種類
 * @property {string} defaultColor デザインタブにてデフォルトで選択される色
 * @property {Record<string, Record<string, string>>} theme 採用コボットで採用しているテーマの種類
 * @property {number} editPageMinWidth 編集画面の最小幅
 * @property {number} leftWindowOpenWidth 編集画面の左ウィンドウオープン時の幅
 * @property {number} leftWindowCloseWidth 編集画面の左ウィンドウクローズ時の幅
 * @property {number} pcWidth 編集画面のiframeに表示するPCサイズの幅
 * @property {number} spWidth 編集画面のiframeに表示するSPサイズの幅
 */
interface Config {
  colors: Record<string, ColorDefinition>;
  defaultColor: string;
  theme: Record<string, Record<string, string>>;
  editPageMinWidth: number;
  leftWindowOpenWidth: number;
  leftWindowCloseWidth: number;
  pcWidth: number;
  spWidth: number;
}

// 実際の設定値
const configData: Config = {
  colors: {
    red: {
      label: 'レッド',
      value: 'red',
      theme: {
        basic: {
          keyColor: '#D62C2C',
          subColor: '#F8DCDC',
          backgroundColor: '#FFFFFF',
          textColor: '#1B0302',
          subBackgroundColor: '#FCEFEF'
        },
        stylish: {
          keyColor: '#561D1D',
          subColor: '#CBB9B9',
          backgroundColor: '#FFFFFF',
          textColor: '#1E0101',
          subBackgroundColor: '#EFEAEA'
        },
        pop: {
          keyColor: '#E83B2B',
          subColor: '#FFC2BC',
          backgroundColor: '#FFFFFF',
          textColor: '#210300',
          subBackgroundColor: '#FFEDEB'
        },
        natural: {
          keyColor: '#E46448',
          subColor: '#F1D8D3',
          backgroundColor: '#FFFFFF',
          textColor: '#463743',
          subBackgroundColor: '#FBF3F2'
        }
      }
    },
    orange: {
      label: 'オレンジ',
      value: 'orange',
      theme: {
        basic: {
          keyColor: '#CE4B01',
          subColor: '#FFEDE2',
          backgroundColor: '#FFFFFF',
          textColor: '#170E03',
          subBackgroundColor: '#FFF7F2'
        },
        stylish: {
          keyColor: '#695A51',
          subColor: '#DCD7D4',
          backgroundColor: '#FFFFFF',
          textColor: '#261912',
          subBackgroundColor: '#F5F3F2'
        },
        pop: {
          keyColor: '#ED7B12',
          subColor: '#FFD7B2',
          backgroundColor: '#FFFFFF',
          textColor: '#301700',
          subBackgroundColor: '#FFF3E8'
        },
        natural: {
          keyColor: '#DD8433',
          subColor: '#F4EADC',
          backgroundColor: '#FFFFFF',
          textColor: '#463E37',
          subBackgroundColor: '#FCF9F5'
        }
      }
    },
    green: {
      label: 'グリーン',
      value: 'green',
      theme: {
        basic: {
          keyColor: '#327650',
          subColor: '#DEF2DA',
          backgroundColor: '#FFFFFF',
          textColor: '#081F04',
          subBackgroundColor: '#F0F9EE'
        },
        stylish: {
          keyColor: '#003311',
          subColor: '#AEC0B4',
          backgroundColor: '#FFFFFF',
          textColor: '#011202',
          subBackgroundColor: '#E7ECE9'
        },
        pop: {
          keyColor: '#1AAF98',
          subColor: '#B6F1E6',
          backgroundColor: '#FFFFFF',
          textColor: '#D2382F',
          subBackgroundColor: '#E9FBF8'
        },
        natural: {
          keyColor: '#4C826B',
          subColor: '#CEE1D2',
          backgroundColor: '#FFFFFF',
          textColor: '#374640',
          subBackgroundColor: '#F0F6F2'
        }
      }
    },
    blue: {
      label: 'ブルー',
      value: 'blue',
      theme: {
        basic: {
          keyColor: '#005497',
          subColor: '#E2EFFA',
          backgroundColor: '#FFFFFF',
          textColor: '#011AC2',
          subBackgroundColor: '#F2F8FD'
        },
        stylish: {
          keyColor: '#020054',
          subColor: '#B5B5CD',
          backgroundColor: '#FFFFFF',
          textColor: '#030223',
          subBackgroundColor: '#E9E9F0'
        },
        pop: {
          keyColor: '#1C6FEC',
          subColor: '#B8DDFF',
          backgroundColor: '#FFFFFF',
          textColor: '#013242',
          subBackgroundColor: '#EAF5FF'
        },
        natural: {
          keyColor: '#4778A5',
          subColor: '#E0E6EF',
          backgroundColor: '#FFFFFF',
          textColor: '#373D46',
          subBackgroundColor: '#F6F8FA'
        }
      }
    },
    gray: {
      label: 'グレー',
      value: 'gray',
      theme: {
        basic: {
          keyColor: '#757575',
          subColor: '#EAEAEA',
          backgroundColor: '#FFFFFF',
          textColor: '#171717',
          subBackgroundColor: '#F6F6F6'
        },
        stylish: {
          keyColor: '#595959',
          subColor: '#D6D6D6',
          backgroundColor: '#FFFFFF',
          textColor: '#1C1C1C',
          subBackgroundColor: '#F3F3F3'
        },
        pop: {
          keyColor: '#6D7582',
          subColor: '#C6CBD2',
          backgroundColor: '#FFFFFF',
          textColor: '#404855',
          subBackgroundColor: '#EEEFF2'
        },
        natural: {
          keyColor: '#7A7A7A',
          subColor: '#DEDEDE',
          backgroundColor: '#FFFFFF',
          textColor: '#303030',
          subBackgroundColor: '#F5F5F5'
        }
      }
    },
    purple: {
      label: 'パープル',
      value: 'purple',
      theme: {
        basic: {
          keyColor: '#5755B0',
          subColor: '#D5D4F8',
          backgroundColor: '#FFFFFF',
          textColor: '#121C42',
          subBackgroundColor: '#ECECFC'
        },
        stylish: {
          keyColor: '#301256',
          subColor: '#C7B7DB',
          backgroundColor: '#FFFFFF',
          textColor: '#0D001E',
          subBackgroundColor: '#EEE9F4'
        },
        pop: {
          keyColor: '#9C54D6',
          subColor: '#E8CBFF',
          backgroundColor: '#FFFFFF',
          textColor: '#1D0A2D',
          subBackgroundColor: '#F8EFFF'
        },
        natural: {
          keyColor: '#7D61AE',
          subColor: '#D9D1E5',
          backgroundColor: '#FFFFFF',
          textColor: '#3F3746',
          subBackgroundColor: '#F4F1F7'
        }
      }
    }
  },
  defaultColor: 'gray',
  theme: {
    basic: { label: 'ベーシック', value: 'basic' },
    stylish: { label: 'スタイリッシュ', value: 'stylish' },
    pop: { label: 'ポップ', value: 'pop' },
    natural: { label: 'ナチュラル', value: 'natural' }
  },
  editPageMinWidth: 1280,
  leftWindowOpenWidth: 350,
  leftWindowCloseWidth: 5,
  pcWidth: 1275,
  spWidth: 375
};

const ConfigContext = createContext<Config | undefined>(undefined);

/**
 * アプリの設定を利用するためのカスタムフック。
 * @returns {Config} - 設定オブジェクト。
 * @throws {Error} ConfigProviderの外部で使用された場合にエラーをスローします。
 */
export const useConfig = (): Config => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

/**
 * ConfigProviderのプロパティの型定義です。
 * @typedef ConfigProviderProps
 * @property {ReactNode} children - 子コンポーネント。
 * @property {Partial<Config>} [overrideConfig] - デフォルトの設定を部分的に上書きするオブジェクト。
 */
interface ConfigProviderProps {
  children: ReactNode;
  overrideConfig?: Partial<Config>;
}

/**
 * 子コンポーネントに設定値を提供するコンポーネント。
 * @param {ConfigProviderProps} props - プロパティ。
 * @returns {JSX.Element} プロバイダーコンポーネント。
 */
export const ConfigProvider: React.FC<ConfigProviderProps> = ({
  children,
  overrideConfig = {}
}) => {
  const mergedConfig = { ...configData, ...overrideConfig };
  return (
    <ConfigContext.Provider value={mergedConfig}>
      {children}
    </ConfigContext.Provider>
  );
};
