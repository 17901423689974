import * as React from 'react';
import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { useLoginContext } from 'components/LoginWrapper';
import { Visibility } from 'enums/Visibility';
import './style.scss';

type Status = 'status-green' | 'status-red' | 'status-limited';

const statusTexts: { [status in Status]: string } = {
  'status-green': '公開中',
  'status-limited': 'TOPページのみ非公開',
  'status-red': '非公開'
};

/**
 * ステータスのテキスト部分
 * @param pageSetting ページ設定
 * @returns
 */
type Props = {
  pageSetting?: PageSettingAdminReferenceResDto;
};

export const StatusText: React.FC<Props> = ({ pageSetting }) => {
  const { meInfo } = useLoginContext();

  const isAvailableTopPagePlanInLastContract = React.useMemo(
    () =>
      meInfo?.currentCorporation?.isAvailableTopPagePlanInLastContract ===
      Visibility.Visible.toString(),
    [meInfo]
  );

  const status = React.useMemo((): Status => {
    if (!!pageSetting?.published && pageSetting.published.isOpen) {
      return isAvailableTopPagePlanInLastContract &&
        pageSetting.published.topPageVisibility
        ? 'status-green'
        : 'status-limited';
    }

    return 'status-red';
  }, [pageSetting, isAvailableTopPagePlanInLastContract]);

  return (
    <span className="StatusText">
      <span className={status}>{statusTexts[status]}</span>
    </span>
  );
};
