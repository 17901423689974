import { Accordion, styled } from '@mui/material';
import { DrawerTabSharedProps } from 'constants/saiyouPageEditing/DrawerTabSharedProps';

/**
 * スタイリングされたアコーディオンコンポーネント。
 * @type {StyledComponent}
 */
const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  borderRadius: 6,
  border: 'none',
  minHeight: DrawerTabSharedProps.minHeight,
  margin: 0,

  '&.Mui-expanded': {
    borderRadius: 6,
    border: '2px solid var(--kanri-primary, #3E91D5)',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.20)',
    height: 'auto',
    minHeight: 'auto',
    margin: 0
  },
  '&.MuiAccordion-root': {
    '&:before': {
      top: 0
    }
  }
});

export default StyledAccordion;
