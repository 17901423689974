import { FC } from 'react';
import RadioLabel from './RadioLabel';
import StyledRadio from './StyledRadio';
import StyledRadioLabel from './StyledRadioLabel';

type Props = {
  label: string;
  value: string;
  imageSrc?: string;
  isSelected?: boolean;
};

/**
 * デザイン設定用ラジオボタンを返す
 * @param {string} label ラベル文言
 * @param {string} value 選択値
 * @param {string | undefined} imageSrc 画像ソース
 * @param {boolean | undefined} isSelected 選択されているか
 * @returns デザイン設定用ラジオボタン
 */
const DesignRadio: FC<Props> = ({
  label,
  value,
  imageSrc = '',
  isSelected = false
}) => {
  return (
    <StyledRadioLabel
      value={value}
      control={<StyledRadio />}
      label={
        <>
          <img
            src={imageSrc}
            alt={label}
            style={{ width: '100%', height: 80 }}
          />
          <RadioLabel label={label} isSelected={isSelected} />
        </>
      }
    />
  );
};

export default DesignRadio;
