import React from 'react';
import { CommonHeader } from 'components/parts/commonHeader';
import { CommonFooter } from 'components/parts/footer';
import HelpButton from 'components/parts/HelpButton';
import { styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { CloseAndBackButtons } from 'components/parts/CloseAndBackButtons';

/**
 * ログアウトページのコンポーネント
 * @param {CustomSelectDialogProps} CustomSelectDialogProps - プロパティ
 * @returns {React.FC} レンダリングする要素
 */
export const LogoutPage: React.FC = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  //リダイレクト先が指定されていたらリダイレクトする（主にセッション切れなどによる再ログイン用）
  const redirect = queryString.get('redirectTo');
  if (redirect) {
    window.location.href = decodeURI(redirect);
    return <></>;
  }
  const LogoutWrap = styled('div')(() => ({
    display: 'flex',
    padding: '160px 20px 11px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    gap: 40,
    flexShrink: 0
  }));

  const CatchStyled = styled('div')(() => ({
    '& h2': {
      color: 'var(--kanri-primary-text, #333)',
      textAlign: 'center',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: -1.2,
      marginBottom: 20
    },
    '& p': {
      color: 'var(--kanri-secondary-text, #595959)',
      textAlign: 'center',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '160%'
    }
  }));

  const HelpButtonWrap = styled('div')(() => ({
    position: 'fixed',
    right: 20,
    bottom: 20,
    zIndex: 1
  }));

  return (
    <>
      <CommonHeader />
      <div className="flex flex-col justify-center items-center w-full h-screen bg-gray-300">
        <LogoutWrap>
          <CatchStyled>
            <h2>ログアウトしました</h2>
            <p>
              ご利用ありがとうございました。またのご利用をお待ちしております。
              <br />
              再度ログインする場合はバイトル管理画面からアクセスしなおしてください。
            </p>
          </CatchStyled>
          <CloseAndBackButtons />
        </LogoutWrap>
      </div>
      <CommonFooter pt="logoutPage" />
      <HelpButtonWrap>
        <HelpButton />
      </HelpButtonWrap>
    </>
  );
};
