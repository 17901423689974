import * as React from 'react';
import { styled } from '@mui/material/styles';
import Divider, {
  DividerProps as MuiDividerProps
} from '@mui/material/Divider';

interface DividerProps extends MuiDividerProps {
  open: boolean;
  isEditPage: boolean;
}
/**
 * muiのDividerを上書き
 */
const CustomDiv = styled(Divider, {
  shouldForwardProp: (prop) => !['open', 'isEditPage'].includes(String(prop))
})<DividerProps>(({ open, isEditPage }) => ({
  margin: open ? 'auto 20px' : 'auto 8px',
  transition: 'opacity 0.5s',
  opacity: !open && isEditPage ? '0' : '1'
}));
/**
 * 区切り線
 * @param open ドロワーの開閉判定
 * @param isEditPage 編集ページかどうか
 * @returns
 */
interface CustomDividerProps {
  open: boolean;
  isEditPage?: boolean;
}
export const CustomDivider = React.memo(
  ({ open, isEditPage }: CustomDividerProps) => {
    return <CustomDiv open={open} isEditPage={isEditPage ? true : false} />;
  }
);
