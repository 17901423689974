import { AccordionSummary, styled } from '@mui/material';
import { DrawerTabSharedProps } from 'constants/saiyouPageEditing/DrawerTabSharedProps';

const AccCommonStyles = {
  height: DrawerTabSharedProps.minHeight,
  minHeight: DrawerTabSharedProps.minHeight,
  padding: '5px 5px 5px 15px',
  transition: '0.5s',
  '& .MuiAccordionSummary-content': {
    margin: 0,
    gap: '8px',
    alignItems: 'center'
  }
};

/**
 * スタイリングされたアコーディオンサマリーコンポーネント。
 * @type {StyledComponent}
 */
const StyledAccordionSummary = styled(AccordionSummary)({
  ...AccCommonStyles,
  color: 'var(--kanri-primary-text, #333)',
  fontSize: 24,
  fontStyle: 'normal',
  fontWeight: 400,
  letterSpacing: -1.2,
  borderTop: 'solid 1px #EEE',
  height: DrawerTabSharedProps.minHeight,
  '&.Mui-expanded': {
    ...AccCommonStyles,
    display: 'flex',
    background: 'var(--kanri-primary, #3E91D5)',
    borderTop: 'solid 1px var(--kanri-primary, #3E91D5)',
    '& .MuiTypography-root': { color: '#ffffff', fontWeight: 700 }
  },
  '& .MuiTypography-root': {
    color: 'var(--kanri-primary-text, #333)',
    fontSize: 14,
    fontStyle: 'normal'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: 10,
    transform: 'rotate(90deg)',
    transition: 'none',
    '& svg': {
      fill: 'var(--kanri-secondary-text, #595959)'
    },
    '&.Mui-expanded': {
      opacity: 1,
      transform: 'rotate(-90deg)',
      '& svg': {
        fill: '#FFFFFF'
      }
    }
  }
});

export default StyledAccordionSummary;
