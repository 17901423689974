import { FC } from 'react';
import { CommonFrame } from 'components/parts/commonFrame';
import { CommonHeader } from 'components/parts/commonHeader';
import { CommonFooter } from 'components/parts/footer';
import { CloseButton } from 'components/parts/closeButton';
import { sampleText } from './sample';
import { OpenInNewIcon } from 'assets/icons';
import './style.scss';

export const PolicyPage: FC = () => {
  /**
   * TODO:sample.tsにJSONで値が格納されています
   */
  const { policy } = sampleText;

  return (
    <>
      <CommonHeader />
      <div className="policy_content">
        {policy.map((item, index) => (
          <CommonFrame key={index} title={item.title}>
            <div>
              {item?.body?.map((text, index) => (
                <p
                  key={index}
                  className={`policy_content_${item?.className?.body}`}
                >
                  {text}
                </p>
              ))}
              {item?.link && (
                <a
                  href={item.link.href}
                  className="policy_content_link"
                  target={item.link.target}
                  rel={item.link.rel}
                >
                  {item.link.text}
                  <OpenInNewIcon width="14" height="14" />
                </a>
              )}
            </div>
          </CommonFrame>
        ))}
        <CloseButton />
        <CommonFooter paddingTop={30} paddingBottom={30} pt="commonPage" />
      </div>
    </>
  );
};
