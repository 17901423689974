import { FC, MouseEventHandler } from 'react';
import { CustomButton } from 'components/parts/CustomButton';

type Props = {
  label: string;
  isDisabled?: boolean;
  isHoverDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * 次ステップへ進むボタンを返す
 * @param {string} label ラベル文言
 * @param {boolean | undefined} isDisabled 操作が無効であるか
 * @param {boolean | undefined} isHoverDisabled ホバーが無効であるか
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 次ステップへ進むボタン
 */
const NextButton: FC<Props> = ({
  label,
  isDisabled = false,
  isHoverDisabled = false,
  onClick
}) => {
  return (
    <CustomButton
      disabled={isDisabled}
      width={'300px'}
      height={'50px'}
      textcolor={isDisabled ? 'var(--kanri-sub-text)' : 'white'}
      border="1px solid"
      bordercolor={
        isDisabled
          ? 'var(--kanri-disabled-button-color)'
          : 'var(--kanri-primary)'
      }
      backgroundcolor={
        isDisabled
          ? 'var(--kanri-disabled-button-color)'
          : 'var(--kanri-primary)'
      }
      hoveropacity={isHoverDisabled ? '1' : '0.85'}
      hoverborder="1px solid"
      hoverbordercolor={
        isDisabled
          ? 'var(--kanri-disabled-button-color)'
          : 'var(--kanri-primary)'
      }
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export default NextButton;
