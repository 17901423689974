import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { getMaxLengthErrorMessage } from './shared';

type MemoProperty = 'memo' | 'contactMemo';
/**
 * 備考のバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useMemoValidationRules = <
  T extends MemoProperty
>(): RegisterOptions<EnhancedPolicyFormData, T> => {
  const PropertyName = '備考';
  const MaxLength = 300;

  return useMemo(
    () => ({
      maxLength: {
        value: MaxLength,
        message: getMaxLengthErrorMessage(PropertyName, MaxLength)
      }
    }),
    []
  );
};
