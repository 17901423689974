import * as React from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { StatusText } from 'components/parts/statusText';
import './style.scss';
import { strToDate, zeroPadding } from 'logic/util';

const CustomSlider = styled(Slider)({
  width: 148,
  height: '32px',
  padding: '0 !important',
  '.MuiSlider-track': {
    backgroundColor: '#1A86D1'
  },
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
    border: '1px solid #DDDDDD',
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.1)'
    }
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#BFBFBF',
    opacity: 1
  }
});

/**
 * 編集ページヘッダー部分
 * @param compId 企業ID
 * @returns
 */
interface StatusBarProps {
  changeSlider?: (value: number) => void;
  sliderValue?: number;
  pageSetting?: PageSettingAdminReferenceResDto;
}

/**
 * 編集ページにおけるヘッダー下ステータスバー
 * @param {Function} changeSlider 拡大縮小のバーに変更があった時に動く関数
 * @param {number} sliderValue 拡大縮小のバーの現在値
 * @param {PageSettingAdminReferenceResDto} pageSetting ページ設定
 * @returns {JSX.Element} 編集ページにおけるヘッダー下ステータスバーコンポーネント
 */
export const StatusBar = React.memo(
  ({ changeSlider, sliderValue, pageSetting }: StatusBarProps) => {
    const updatedDateTime = React.useMemo(() => {
      const formatDate = (date: Date) =>
        `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}` +
        ` ${zeroPadding(date.getHours(), 2)}` +
        `:${zeroPadding(date.getMinutes(), 2)}`;

      if (pageSetting?.published?.updatedAt)
        return formatDate(strToDate(pageSetting.published.updatedAt));

      if (pageSetting?.preview?.updatedAt)
        return formatDate(strToDate(pageSetting.preview.updatedAt));

      return '';
    }, [pageSetting]);

    const handleChange = (event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number' && changeSlider) {
        changeSlider(newValue);
      }
    };

    return (
      <div id="editHeaderMenu_StatusBar" style={{ width: '100%' }}>
        <div className="editHeaderMenu_StatusBar-wrap">
          <div className="editHeaderMenu_StatusBar-contents-wrap">
            <div className="slider-wrap">
              <CustomSlider
                value={sliderValue}
                min={25}
                max={100}
                step={5}
                onChange={handleChange}
              />
              <Typography>
                <span className="slider-value">{sliderValue}%</span>
              </Typography>
            </div>
            <div className="information-wrap">
              <div className="status-text-wrap">
                ステータス：
                <StatusText pageSetting={pageSetting} />
              </div>
              <span className="timestamp-text">
                {updatedDateTime ? `更新日時：${updatedDateTime}` : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
