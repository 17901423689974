/**
 * URLの最大文字数
 */
export const MAX_URL_LENGTH = 500;

/**
 * URLフォーマットバリデーションに引っ掛かった時のエラーメッセージ
 */
export const urlFormatErrorMsg = (propertyName: string) =>
  `${propertyName}を${MAX_URL_LENGTH}文字以内で正しく入力してください`;
