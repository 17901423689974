import { generateTextRule } from '../../shared';

/**
 * 応募の流れのタイトルのバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useAfterProcessTitleValidationRules = () => {
  const propertyName = 'タイトル';
  const maxLength = 20;
  return generateTextRule<`afterProcess.processList.${number}.title`>(
    propertyName,
    maxLength,
    true
  );
};
