import { FC, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import useFormAutocomplete from 'hooks/saiyouPageEditing/useFormAutocomplete';
import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';
import { FormData } from 'types/saiyouPageEditing/FormData';
import DrawerInner from './DrawerInner';
import OpenAndCloseButton from './OpenAndCloseButton';

type Props = {
  defaultFormData?: FormData;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onStartTabSwitch?: () => void;
  onEndTabSwitch?: () => void;
};

/**
 * 左ペインを返す
 * @param {FormData | undefined} defaultFormData フォームデータのデフォルト値
 * @param {boolean | undefined} isOpen 開いているか
 * @param {Function | undefined} onOpen 開いた際に実行される関数
 * @param {Function | undefined} onClose 閉じた際に実行される関数
 * @param {Function | undefined} onChangeTabStart タブが切り替わり始めた際に実行される関数
 * @param {Function | undefined} onChangeTabStart タブが切り替わり終えた際に実行される関数
 * @returns 左ペイン
 */
const LeftMenu: FC<Props> = ({
  defaultFormData,
  isOpen = false,
  onOpen,
  onClose,
  onStartTabSwitch,
  onEndTabSwitch
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState<
    AccordionCode | undefined
  >('design');

  const { autocomplete } = useFormAutocomplete();

  const handleClick = useCallback(
    () => (isOpen ? onClose?.() : onOpen?.()),
    [isOpen]
  );

  const handleChangeAccordion = useCallback(
    (accordion: AccordionCode, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? accordion : undefined);
    },
    []
  );

  useEffect(() => {
    if (defaultFormData) autocomplete(defaultFormData);
  }, [defaultFormData, autocomplete]);

  return (
    <Drawer isOpen={isOpen}>
      <OpenAndCloseButton isOpen={isOpen} onClick={handleClick} />
      <DrawerInner
        isOpen={isOpen}
        expandedAccordion={expandedAccordion}
        onStartTabSwitch={onStartTabSwitch}
        onEndTabSwitch={onEndTabSwitch}
        onChangeAccordion={handleChangeAccordion}
      />
    </Drawer>
  );
};

export default LeftMenu;

const Drawer = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  width: isOpen ? 380 : 60,
  position: 'relative'
}));
