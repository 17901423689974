import { FC, MouseEventHandler } from 'react';
import { styled } from '@mui/material';
import {
  MajestIconsMenuExpandLeftLineIcon,
  MajestIconsMenuExpandRightLineIcon
} from 'assets/icons';
import { DrawerStyles } from 'constants/saiyouPageEditing/DrawerStyles';

type Props = {
  isOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

/**
 * 左ペインの開閉ボタンを返す
 * @param {boolean | undefined} isOpen 開いているか
 * @param {MouseEventHandler<HTMLDivElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 左ペインの開閉ボタン
 */
const OpenAndCloseButton: FC<Props> = ({ isOpen = false, onClick }) => (
  <DrawerIconWrap isOpen={isOpen} onClick={onClick}>
    {isOpen ? (
      <MajestIconsMenuExpandLeftLineIcon />
    ) : (
      <MajestIconsMenuExpandRightLineIcon />
    )}
  </DrawerIconWrap>
);

export default OpenAndCloseButton;

const DrawerIconWrap = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
  width: 30,
  height: 80,
  cursor: 'pointer',
  zIndex: 1202,
  position: 'fixed',
  left: isOpen ? 349 : 5,
  top: 'calc(50% + 40px)',
  transform: 'translateY(-50%)',
  background: '#ffffff',
  borderRadius: '0 6px 6px 0',
  boxShadow: '4px 0 8px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...DrawerStyles
}));
