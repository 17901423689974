import { ChangeEventHandler, FC } from 'react';
import { RadioGroup, styled } from '@mui/material';
import designBasic from 'assets/img/designBasic.jpg';
import designNatural from 'assets/img/designNatural.jpg';
import designPop from 'assets/img/designPop.jpg';
import designStylish from 'assets/img/designStylish.jpg';
import { useConfig } from 'context/ConfigContext';
import DesignRadio from './DesignRadio';

const StyledRadioGroup = styled(RadioGroup)({
  '&.MuiFormGroup-root': {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '20px 10px'
  }
});

type Props = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

/**
 * デザイン設定用ラジオグループを返す
 * @param {string | undefined} value デザイン
 * @param {ChangeEventHander<HTMLInputElement> | undefined} onChange デザインが変更された際に実行される関数
 * @returns デザイン設定用ラジオグループを返す
 */
const DesignRadioGroup: FC<Props> = ({ value = '', onChange }) => {
  const config = useConfig();

  return (
    <StyledRadioGroup
      value={value}
      className="DesignSelectImage"
      onChange={onChange}
    >
      <DesignRadio
        label={config.theme.basic.label}
        value={config.theme.basic.value}
        imageSrc={designBasic}
        isSelected={value === config.theme.basic.value}
      />
      <DesignRadio
        label={config.theme.stylish.label}
        value={config.theme.stylish.value}
        imageSrc={designStylish}
        isSelected={value === config.theme.stylish.value}
      />
      <DesignRadio
        label={config.theme.pop.label}
        value={config.theme.pop.value}
        imageSrc={designPop}
        isSelected={value === config.theme.pop.value}
      />
      <DesignRadio
        label={config.theme.natural.label}
        value={config.theme.natural.value}
        imageSrc={designNatural}
        isSelected={value === config.theme.natural.value}
      />
    </StyledRadioGroup>
  );
};

export default DesignRadioGroup;
