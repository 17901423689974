import { FC, useCallback, useRef } from 'react';
import { MuiFileInput } from 'mui-file-input';
import { Button, styled } from '@mui/material';

type Props = {
  image?: File;
  isError?: boolean;
  onChange?: (image?: File) => void;
};

/**
 * 画像選択フィールドを返す
 * @param {File | undefined} image 選択されている画像
 * @param {boolean | undefined} isError エラーが発生しているか
 * @param {Function | undefined} onChange 値が変更された際に実行される関数
 * @returns 画像選択フィールド
 */
const ImageBrowseField: FC<Props> = ({
  image = null,
  isError = false,
  onChange
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (value: File | File[] | null) => {
      if (!value) {
        onChange?.();
        return;
      }

      if (Array.isArray(value)) onChange?.(value[0]);
      else onChange?.(value);
    },
    [onChange]
  );

  const handleClick = useCallback(() => {
    fileInput.current?.click();
  }, [fileInput]);

  return (
    <ImageBrowseContainer
      className={`FileInputWrap FileInput${
        isError ? ' FileInputWrapError' : ''
      }`}
    >
      <StyledMuiFileInput
        inputRef={fileInput}
        value={image}
        inputProps={{ accept: '.jpg,.jpeg,.png,.gif' }}
        placeholder="アップロードする画像を選択してください"
        onChange={handleChange}
      />
      <StyledButton variant="outlined" onClick={handleClick}>
        ファイル選択
      </StyledButton>
    </ImageBrowseContainer>
  );
};

export default ImageBrowseField;

const ImageBrowseContainer = styled('div')(() => ({
  border: '1px solid var(--kanri-gray-03, #899194)',
  borderRadius: 4,
  display: 'flex',
  position: 'relative',
  '& label': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '&:hover': {
    border: '1px solid #ffffff',
    '&:before': {
      position: 'absolute',
      content: '""',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      display: 'block',
      left: 0,
      top: 0,
      zIndex: 0,
      borderRadius: 4,
      border: '2px solid var(--kanri-gray-03, #899194)'
    },
    '& .MuiFormControl-root': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '""',
        right: -1,
        top: 0,
        width: 2,
        height: '100%',
        backgroundColor: 'var(--kanri-gray-03, #899194)',
        zIndex: 1
      }
    },
    '& .MuiButton-outlinedPrimary,.MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiButton-outlinedPrimary,.MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&.FileInputWrapError': {
    border: '1px solid #ffffff',
    '&:before': {
      position: 'absolute',
      content: '""',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      display: 'block',
      left: 0,
      top: 0,
      zIndex: 0,
      borderRadius: 4,
      border: '2px solid var(--kanri-erroralert, #DC1357)'
    },
    '& .MuiFormControl-root': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '""',
        right: -1,
        top: 0,
        width: 2,
        height: '100%',
        backgroundColor: 'var(--kanri-erroralert, #DC1357)',
        zIndex: 1
      }
    },
    '& .MuiButton-outlinedPrimary': {
      border: 'none',
      color: '#ffffff',
      backgroundColor: 'var(--kanri-erroralert, #DC1357)',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'var(--kanri-erroralert, #DC1357)'
      }
    },
    '& .MuiFileInput-Typography-size-text': {
      color: 'var(--kanri-erroralert, #DC1357)'
    }
  }
}));

const StyledMuiFileInput = styled(MuiFileInput)(() => ({
  width: 409,
  borderRight: '1px solid var(--kanri-gray-03, #899194)',
  '& .MuiInputBase-input': {
    padding: '10px 0'
  },
  '& .MuiInputBase-root': {
    borderRadius: '4px 0 0 4px',
    padding: 0,
    color: 'var(--kanri-sub-text, #777)',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSvgIcon-root': {
    display: 'none'
  },
  '& span.MuiFileInput-placeholder': {
    color: 'var(--kanri-sub-text, #777)',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%'
  }
}));

const StyledButton = styled(Button)(() => ({
  '&.MuiButton-outlinedPrimary': {
    color: 'var(--kanri-secondary-text, #595959)',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    borderRadius: '0 4px 4px 0',
    border: 'none',
    padding: '12px 14px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  '&:hover': {
    cursor: 'pointer'
  }
}));
