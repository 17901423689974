import { useCallback } from 'react';

/**
 * Base64のHooks
 * @returns Base64の変換関数群
 */
const useBase64 = (): {
  toBase64FromFile: (file: File) => Promise<string>;
} => {
  const toBase64FromFile = useCallback(
    (file: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;

        reader.readAsDataURL(file);
      }),
    []
  );

  return { toBase64FromFile };
};

export default useBase64;
