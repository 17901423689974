import { generateTextRule } from '../../shared';

/**
 * スタッフインタビューのプロフィール情報のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useStaffInterviewProfileValidationRules = () => {
  const propertyName = 'プロフィール情報';
  const maxLength = 20;
  return generateTextRule<`staffInterview.interviews.${number}.profile`>(
    propertyName,
    maxLength,
    true
  );
};
