import React from 'react';

/**
 * @typedef {object} TabContentWrapperProps
 * @property {React.ReactNode} children - 子コンポーネントまたはテキスト
 */
type TabContentWrapperProps = {
  children: React.ReactNode;
};

/**
 * タブコンテンツをラッピングするためのコンポーネント。
 * このコンポーネントはタブ内の各セクションを囲む役割があります。
 * @param {TabContentWrapperProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} ラッピングされたコンテンツ
 */
const TabContentWrapper: React.FC<TabContentWrapperProps> = ({ children }) => {
  return (
    <div
      style={{
        padding: '20px 0',
        margin: '-20px 0'
      }}
    >
      {children}
    </div>
  );
};

export default TabContentWrapper;
