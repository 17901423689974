import { FC, useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useMessageProfileValidationRules,
  useMessageTextValidationRules
} from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import ImageUploadEntry from '../../../ImageUploader/ImageUploadEntry';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';
import VisibilityToggleButton from '../shared/VisibilityToggleButton';

/**
 * 「採用担当からのメッセージ」アコーディオンを返す
 * @returns 「採用担当からのメッセージ」アコーディオン
 */
const MessageAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext<FormData>();

  const isVisible = watch('message.isVisible');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('message');

  const profileRules = useMessageProfileValidationRules();
  const textRules = useMessageTextValidationRules(!!isVisible);

  useEffect(() => {
    setValue('message.text', getValues('message.text'), {
      shouldValidate: true
    });
  }, [isVisible, getValues, setValue]);

  return (
    <ContentAcc
      ref={accordionRef}
      title="採用担当からのメッセージ"
      dispCrown
      isExpanded={isExpanded}
      isError={errors.message && Object.keys(errors.message).length > 0}
      onChange={(value) => onChange?.('message', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="どんな働き方やキャリアパスがあるか、採用担当の想いを伝えて応募を後押しすることで、応募に繋がりやすくなります。" />
        <TabContentFormWrap>
          <Controller
            name="message.isVisible"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <VisibilityToggleButton isVisible={value} onChange={onChange} />
            )}
          />
          <Controller
            name="message.profile"
            control={control}
            rules={{ ...profileRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="プロフィール情報"
                value={value}
                placeholder="人事総務本部 山田太郎"
                maxLength={20}
                helperText={
                  errors.message?.profile?.type !== 'maxLength'
                    ? errors.message?.profile?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.message?.profile}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="message.text"
            control={control}
            rules={{ ...textRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="メッセージ本文"
                value={value}
                placeholder="弊社に興味を持っていただき、ありがとうございます。弊社は人材サービスとDXサービスの提供を通して、誰もが働く喜びと幸せを感じられる社会の実現を目指します。夢とアイデアと情熱に溢れるみなさんとの挑戦を、心から楽しみにしています。疑問や不安があればお気軽にお問い合わせください。"
                maxLength={300}
                rows={8}
                helperText={
                  errors.message?.text?.type !== 'maxLength'
                    ? errors.message?.text?.message
                    : ''
                }
                hasLengthHelperText
                isRequired={!!isVisible}
                isError={!!errors.message?.text}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="message.imagePath"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <ImageUploadEntry
                uploadedImagePath={value}
                onUploadImage={onChange}
                onClearImage={() => onChange()}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default MessageAccordion;
