import { generateTextRule } from './shared';

/**
 * ブランドのバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useBrandNameValidationRules = () => {
  const propertyName = 'ブランド';
  const maxLength = 20;

  return generateTextRule<`brandNames.${number}`>(
    propertyName,
    maxLength,
    false
  );
};
