import {
  PolicySettingReferenceResDtoIsJointUseEnum,
  PolicySettingReferenceResDtoIsSameContactEnum,
  PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum
} from 'api-client/public';
import {
  ContactInformationType,
  EnhancedPolicyFormData,
  InformationType
} from './type';
import { PolicySettingRegisterReqDto } from 'api-client/management';

/**
 * 個人情報利用規約の設定項目の初期値
 */
export const defaultValues: EnhancedPolicyFormData = {
  policyType: 0,
  isChangedPolicyType: false,
  corporationName: '',
  affiliationName: '',
  personalInformationProtectionManagerName: '',

  informationType: InformationType.NotSelected,
  contactInformationType: ContactInformationType.NotSelected,
  tel: '',
  email: '',
  url: '',
  isJointUse: PolicySettingReferenceResDtoIsJointUseEnum.NUMBER_0,
  isSameContact: PolicySettingReferenceResDtoIsSameContactEnum.NUMBER_1,

  memo: '',
  rangeOfJointUse: '',
  personalInformationProtectorType:
    PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.ContractedCorp,
  personalInformationProtectorName: '',
  contactTel: '',
  contactEmail: '',
  contactUrl: '',
  contactMemo: ''
};

export const formatOnlyValuesKeys: (keyof PolicySettingRegisterReqDto)[] = [
  'affiliationName',
  'corporationName',
  'memo',
  'rangeOfJointUse',
  'personalInformationProtectionManagerName',
  'personalInformationProtectorName',
  'contactMemo',
  'contactTel',
  'contactEmail',
  'contactUrl',
  'isJointUse',
  'isSameContact',
  'personalInformationProtectorType',
  'tel',
  'email',
  'url'
];

export const freeOnlyValuesKeys: (keyof PolicySettingRegisterReqDto)[] = [
  'freeText'
];
