/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolicySettingRegisterReqDto
 */
export interface PolicySettingRegisterReqDto {
    /**
     * テンプレート(0) or 自由(1) 記述
     * @type {number}
     * @memberof PolicySettingRegisterReqDto
     */
    policyType?: PolicySettingRegisterReqDtoPolicyTypeEnum;
    /**
     * 所属名・役職名
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    affiliationName?: string;
    /**
     * (利用規約用)企業名
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    corporationName?: string;
    /**
     * 個人情報保護管理者名
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    personalInformationProtectionManagerName?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    tel?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    email?: string;
    /**
     * URL
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    url?: string;
    /**
     * 備考
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    memo?: string;
    /**
     * 共同利用するかどうか
     * @type {number}
     * @memberof PolicySettingRegisterReqDto
     */
    isJointUse?: PolicySettingRegisterReqDtoIsJointUseEnum;
    /**
     * 共同利用の範囲
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    rangeOfJointUse?: string;
    /**
     * 個人情報の管理責任を有するもの
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    personalInformationProtectorType?: PolicySettingRegisterReqDtoPersonalInformationProtectorTypeEnum;
    /**
     * 個人情報の管理について責任を有するもの(個人名)
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    personalInformationProtectorName?: string;
    /**
     * 「本人開示要求など」の連絡先が同じかどうか
     * @type {number}
     * @memberof PolicySettingRegisterReqDto
     */
    isSameContact?: PolicySettingRegisterReqDtoIsSameContactEnum;
    /**
     * 「本人開示要求など」の連絡先＞電話番号
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    contactTel?: string;
    /**
     * 「本人開示要求など」の連絡先＞メールアドレス
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    contactEmail?: string;
    /**
     * 「本人開示要求など」の連絡先＞URL
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    contactUrl?: string;
    /**
     * 「本人開示要求など」の連絡先＞備考
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    contactMemo?: string;
    /**
     * 自由記述
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    freeText?: string;
    /**
     * 最終更新日。画面が表示された時点でのデータの最終更新日をリクエストする想定。DBの最新データの更新日がこれより後になっていたら排他エラーを返す。      新規登録の場合はこのパラメータは不要だが、登録されたデータがあれば排他エラーに引っかかる。
     * @type {string}
     * @memberof PolicySettingRegisterReqDto
     */
    finalUpdatedAt?: string;
}


/**
 * @export
 */
export const PolicySettingRegisterReqDtoPolicyTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type PolicySettingRegisterReqDtoPolicyTypeEnum = typeof PolicySettingRegisterReqDtoPolicyTypeEnum[keyof typeof PolicySettingRegisterReqDtoPolicyTypeEnum];

/**
 * @export
 */
export const PolicySettingRegisterReqDtoIsJointUseEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type PolicySettingRegisterReqDtoIsJointUseEnum = typeof PolicySettingRegisterReqDtoIsJointUseEnum[keyof typeof PolicySettingRegisterReqDtoIsJointUseEnum];

/**
 * @export
 */
export const PolicySettingRegisterReqDtoPersonalInformationProtectorTypeEnum = {
    ContractedCorp: 'contractedCorp',
    JoitUseCorp: 'joitUseCorp',
    Other: 'other'
} as const;
export type PolicySettingRegisterReqDtoPersonalInformationProtectorTypeEnum = typeof PolicySettingRegisterReqDtoPersonalInformationProtectorTypeEnum[keyof typeof PolicySettingRegisterReqDtoPersonalInformationProtectorTypeEnum];

/**
 * @export
 */
export const PolicySettingRegisterReqDtoIsSameContactEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type PolicySettingRegisterReqDtoIsSameContactEnum = typeof PolicySettingRegisterReqDtoIsSameContactEnum[keyof typeof PolicySettingRegisterReqDtoIsSameContactEnum];


/**
 * Check if a given object implements the PolicySettingRegisterReqDto interface.
 */
export function instanceOfPolicySettingRegisterReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicySettingRegisterReqDtoFromJSON(json: any): PolicySettingRegisterReqDto {
    return PolicySettingRegisterReqDtoFromJSONTyped(json, false);
}

export function PolicySettingRegisterReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicySettingRegisterReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policyType': !exists(json, 'policyType') ? undefined : json['policyType'],
        'affiliationName': !exists(json, 'affiliationName') ? undefined : json['affiliationName'],
        'corporationName': !exists(json, 'corporationName') ? undefined : json['corporationName'],
        'personalInformationProtectionManagerName': !exists(json, 'personalInformationProtectionManagerName') ? undefined : json['personalInformationProtectionManagerName'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'isJointUse': !exists(json, 'isJointUse') ? undefined : json['isJointUse'],
        'rangeOfJointUse': !exists(json, 'rangeOfJointUse') ? undefined : json['rangeOfJointUse'],
        'personalInformationProtectorType': !exists(json, 'personalInformationProtectorType') ? undefined : json['personalInformationProtectorType'],
        'personalInformationProtectorName': !exists(json, 'personalInformationProtectorName') ? undefined : json['personalInformationProtectorName'],
        'isSameContact': !exists(json, 'isSameContact') ? undefined : json['isSameContact'],
        'contactTel': !exists(json, 'contactTel') ? undefined : json['contactTel'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactUrl': !exists(json, 'contactUrl') ? undefined : json['contactUrl'],
        'contactMemo': !exists(json, 'contactMemo') ? undefined : json['contactMemo'],
        'freeText': !exists(json, 'freeText') ? undefined : json['freeText'],
        'finalUpdatedAt': !exists(json, 'finalUpdatedAt') ? undefined : json['finalUpdatedAt'],
    };
}

export function PolicySettingRegisterReqDtoToJSON(value?: PolicySettingRegisterReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policyType': value.policyType,
        'affiliationName': value.affiliationName,
        'corporationName': value.corporationName,
        'personalInformationProtectionManagerName': value.personalInformationProtectionManagerName,
        'tel': value.tel,
        'email': value.email,
        'url': value.url,
        'memo': value.memo,
        'isJointUse': value.isJointUse,
        'rangeOfJointUse': value.rangeOfJointUse,
        'personalInformationProtectorType': value.personalInformationProtectorType,
        'personalInformationProtectorName': value.personalInformationProtectorName,
        'isSameContact': value.isSameContact,
        'contactTel': value.contactTel,
        'contactEmail': value.contactEmail,
        'contactUrl': value.contactUrl,
        'contactMemo': value.contactMemo,
        'freeText': value.freeText,
        'finalUpdatedAt': value.finalUpdatedAt,
    };
}

