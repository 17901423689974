import {
  PolicySettingReferenceResDto,
  PolicySettingReferenceResDtoIsSameContactEnum,
  PolicySettingReferenceResDtoPolicyTypeEnum
} from 'api-client/public';
import {
  PolicySettingRegisterReqDto,
  PolicySettingRegisterReqDtoIsJointUseEnum
} from 'api-client/management';
import {
  ContactInformationType,
  EnhancedPolicyFormData,
  InformationType
} from './type';
import { strToDate } from 'logic/util';
import {
  defaultValues,
  formatOnlyValuesKeys,
  freeOnlyValuesKeys
} from './defaults';

// APIからのレスポンスをEnhancedPolicyFormDataに変換する関数
export const convertToEnhancedPolicyFormData = (
  data: PolicySettingReferenceResDto
): EnhancedPolicyFormData => {
  let informationType = InformationType.NotSelected;
  if (data.tel) {
    informationType = InformationType.Tel;
  } else if (data.email) {
    informationType = InformationType.Email;
  } else if (data.url) {
    informationType = InformationType.Url;
  }

  let contactInformationType = ContactInformationType.NotSelected;
  if (data.contactTel) {
    contactInformationType = ContactInformationType.Tel;
  } else if (data.contactEmail) {
    contactInformationType = ContactInformationType.Email;
  } else if (data.contactUrl) {
    contactInformationType = ContactInformationType.Url;
  }

  if (data.policyType === undefined) {
    data.policyType = defaultValues.policyType;
  }
  if (data.isJointUse === undefined) {
    data.isJointUse = defaultValues.isJointUse;
  }
  if (data.isSameContact === undefined) {
    data.isSameContact = defaultValues.isSameContact;
  }

  return {
    ...data,
    informationType,
    contactInformationType
  };
};

export const checkPolicyTypeIsTemplate = (
  policyType: PolicySettingReferenceResDtoPolicyTypeEnum | undefined
): boolean => {
  return policyType !== PolicySettingReferenceResDtoPolicyTypeEnum.NUMBER_1;
};

// EnhancedPolicyFormDataからPolicyFormDataに変換する関数
export const convertToPolicyFormData = (
  data: EnhancedPolicyFormData
): PolicySettingRegisterReqDto => {
  const {
    informationType,
    contactInformationType,
    updatedAt,
    isJointUse,
    isSameContact,
    ...rest
  } = data;

  const finalUpdatedAt = updatedAt
    ? strToDate(updatedAt).toISOString()
    : undefined;

  const numericIsJointUse = Number(
    isJointUse
  ) as PolicySettingRegisterReqDtoIsJointUseEnum;
  const numericIsSameContact = Number(
    isSameContact
  ) as PolicySettingReferenceResDtoIsSameContactEnum;

  let result: PolicySettingRegisterReqDto = {
    ...rest,
    finalUpdatedAt,
    isJointUse: numericIsJointUse,
    isSameContact: numericIsSameContact
  };

  // 削除するキーを決定
  const exceptKeys: (keyof PolicySettingRegisterReqDto)[] =
    checkPolicyTypeIsTemplate(rest.policyType)
      ? freeOnlyValuesKeys
      : formatOnlyValuesKeys;

  // exceptKeysに基づいてフィルタリング
  result = Object.fromEntries(
    Object.entries(result).filter(
      ([key]) => !exceptKeys.includes(key as keyof PolicySettingRegisterReqDto)
    )
  ) as PolicySettingRegisterReqDto;

  return result;
};
