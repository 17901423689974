import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useSiteTitleValidationRules } from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「サイトタイトル」アコーディオンを返す
 * @returns 「サイトタイトル」アコーディオン
 */
const SiteTitleAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('siteTitle');

  const rules = useSiteTitleValidationRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="サイトタイトル"
      isExpanded={isExpanded}
      isError={!!errors.siteTitle}
      onChange={(value) => onChange?.('siteTitle', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="Googleなどの検索エンジンに表示するサイトタイトルを設定できます。未入力の場合はおすすめのタイトルが表示されます。" />
        <TabContentFormWrap>
          <Controller
            name="siteTitle"
            control={control}
            rules={{ ...rules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                value={value}
                placeholder="株式会社○○○○公式 正社員求人サイト"
                maxLength={150}
                helperText={
                  errors.siteTitle?.type !== 'maxLength'
                    ? errors.siteTitle?.message
                    : ''
                }
                hasLengthHelperText
                isRequired
                isError={!!errors.siteTitle}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default SiteTitleAccordion;
