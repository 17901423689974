import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getPreInitialPageSetting } from 'logic/clientWrapper/management';
import { PreFormData } from 'types/saiyouPageEditing/PreFormData';

/**
 * 初期モーダルデータ取得Hooks
 * @param {string | undefined} compId 企業管理番号
 * @returns 初期モーダルデータ
 */
const usePreFormData = (
  compId?: string
): {
  isInitialized: boolean;
  preFormData?: PreFormData | null;
  isServerError: boolean;
  isNoManuscriptError: boolean;
} => {
  const [isInitialized, setInitialized] = useState(false);
  const [preFormData, setPreFormData] = useState<PreFormData | null>();
  const [isServerError, setServerError] = useState(false);
  const [isNoManuscriptError, setIsNoManuscriptError] = useState(false);

  const auth0 = useAuth0();

  useEffect(() => {
    const getPreInitialPageSettingAsync = async (compMgrNo: string) => {
      const { status, data } = await getPreInitialPageSetting(compMgrNo, auth0);

      let preFormData = null;

      if (data) {
        preFormData = {
          corporationName: data.dispCorpName,
          employmentStatus: data.siteTargetKoyoKeitai,
          occupationCodes: data.shok3CdList,
          pageDesign: data.designType,
          pageColor: data.color
        };
      }

      if (status === 'success' || status === '404error') {
        setPreFormData(preFormData);
        setInitialized(true);
        if (status === '404error') setIsNoManuscriptError(true);
      } else {
        setServerError(true);
      }
    };

    if (compId && auth0) void getPreInitialPageSettingAsync(compId);
  }, [compId, auth0]);

  return {
    isInitialized,
    preFormData,
    isServerError,
    isNoManuscriptError
  };
};

export default usePreFormData;
