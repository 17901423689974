import { FC } from 'react';
import RequiredLabel from './RequiredLabel';

type Props = {
  label?: string;
  isRequired?: boolean;
};

/**
 * フィールドのラベルを返す
 * @param {string} label ラベル文言
 * @param {boolean | undefined} isRequired 必須項目であるか
 * @returns フィールドのラベル
 */
const FieldLabel: FC<Props> = ({ label = '', isRequired = false }) => {
  return (
    <div style={{ display: 'inline-flex', gap: '10px' }}>
      {label}
      {label && isRequired && <RequiredLabel />}
    </div>
  );
};

export default FieldLabel;
