import { generateTextRule } from '../../shared';

/**
 * よくある質問のタイトルのバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useQuestionTitleValidationRules = () => {
  const propertyName = 'タイトル';
  const maxLength = 30;

  return generateTextRule<`faq.questions.${number}.title`>(
    propertyName,
    maxLength,
    true
  );
};
