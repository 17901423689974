import { FC } from 'react';
import { Link } from '@mui/material';
import StyledQuestionIcon from './StyledQuestionIcon';
import StyledTooltip from './StyledTooltip';

/**
 * ヘルプツールチップを返す
 * @returns ヘルプツールチップ
 */
const HelpTooltip: FC = () => (
  <div className="main-contents-help-button">
    <StyledTooltip
      title={
        <div style={{ textAlign: 'center' }}>
          ヘルプページが
          <br />
          別ウィンドウで開きます。
        </div>
      }
    >
      <Link target="_blank" href="/help">
        <StyledQuestionIcon />
      </Link>
    </StyledTooltip>
  </div>
);

export default HelpTooltip;
