import { Button, ButtonProps, styled } from '@mui/material';

interface CustomButtonProps extends ButtonProps {
  width: string;
  textcolor: string;
  bordercolor: string;
  border: string;
  backgroundcolor: string;
  hoveropacity: string;
  hoverborder: string;
  hoverbordercolor: string;
  disabled?: boolean;
  height?: string;
  margin?: string;
  flexshrink?: string;
}

export const CustomButton = styled(Button)<CustomButtonProps>(
  ({
    width,
    border,
    bordercolor,
    textcolor,
    backgroundcolor,
    hoveropacity,
    hoverborder,
    hoverbordercolor,
    disabled,
    height,
    margin,
    flexshrink
  }) => ({
    width: width,
    ...(height && { height: height }),
    borderRadius: '8px',
    border: border,
    borderColor: disabled ? 'var(--kanri-disabled-button-color)' : bordercolor,
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%',
    color: disabled ? 'var(--kanri-disabled-button-text-color)' : textcolor,
    backgroundColor: disabled
      ? 'var(--kanri-disabled-button-color)'
      : backgroundcolor,
    ':hover': {
      opacity: hoveropacity,
      backgroundColor: backgroundcolor,
      border: hoverborder,
      borderColor: disabled
        ? 'var(--kanri-disabled-button-color)'
        : hoverbordercolor
    },
    padding: '10px 8px',
    margin: margin,
    flexShrink: flexshrink
  })
);
