import { FC, useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useCorporationIntroductionTextValidationRules } from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';
import VisibilityToggleButton from '../shared/VisibilityToggleButton';

/**
 * 「会社紹介」アコーディオンを返す
 * @returns 「会社紹介」アコーディオン
 */
const CorporationIntroAccordion: FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useFormContext<FormData>();

  const isVisible = watch('corporationIntroduction.isVisible');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('corpIntro');

  const textRules = useCorporationIntroductionTextValidationRules(!!isVisible);

  useEffect(() => {
    setValue(
      'corporationIntroduction.text',
      getValues('corporationIntroduction.text'),
      {
        shouldValidate: true
      }
    );
  }, [isVisible, getValues, setValue]);

  return (
    <ContentAcc
      ref={accordionRef}
      title="会社紹介"
      dispCrown
      isExpanded={isExpanded}
      isError={
        errors.corporationIntroduction &&
        Object.keys(errors.corporationIntroduction).length > 0
      }
      onChange={(value) => onChange?.('corpIntro', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="採用サイトのリード文として、貴社の魅力や想いを伝える文章を設定してください。" />
        <TabContentFormWrap>
          <Controller
            name="corporationIntroduction.isVisible"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <VisibilityToggleButton isVisible={value} onChange={onChange} />
            )}
          />
          <Controller
            name="corporationIntroduction.text"
            control={control}
            rules={{ ...textRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="会社紹介"
                value={value}
                placeholder="人材サービスとDXサービスの提供を通して労働市場における諸課題を解決し、誰もが働く喜びと幸せを感じられる社会の実現を目指した事業を展開しています。"
                maxLength={300}
                rows={8}
                helperText={
                  errors.corporationIntroduction?.text?.type !== 'maxLength'
                    ? errors.corporationIntroduction?.text?.message
                    : ''
                }
                hasLengthHelperText
                isRequired={!!isVisible}
                isError={!!errors.corporationIntroduction?.text}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default CorporationIntroAccordion;
