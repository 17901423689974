import { generateTextRule } from '../../shared';

/**
 * お仕事のポイントの内容のバリデーションルールを返す
 * @returns  バリデーションルール
 */
export const useJobPointContentValidationRules = () => {
  const propertyName = '内容';
  const maxLength = 100;
  return generateTextRule<`jobPoint.points.${number}.content`>(
    propertyName,
    maxLength,
    true
  );
};
