import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import PopoverUserCard from '../popover/PopoverUserCard';

interface CustomListItemButtonProps extends ButtonProps {
  open: boolean;
  transitionduration: string;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(Button)<CustomListItemButtonProps>(
  ({ open, transitionduration }) => ({
    width: '100%',
    transitionDuration: transitionduration,
    minHeight: 48,
    height: open ? 'auto' : '60px',
    justifyContent: open ? 'initial' : 'center',
    display: open ? 'block' : 'flex',
    paddingRight: open ? '20px' : '10px',
    paddingLeft: open ? '20px' : '20px',
    '&:hover': {
      backgroundColor: 'var(--kanri-primary-hover)'
    }
  })
);

interface UserInformationBaseProps {
  children: React.ReactNode;
  open: boolean;
  transitionduration: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openUserMenu: boolean;
  anchorEl: HTMLButtonElement | null;
}
/**
 * メニュー内のユーザー情報項目のボタンベース
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @param onClick ボタンクリック時のイベント
 * @param openUserMenu ポップオーバーの開閉判定
 * @param anchorEl ポップオーバーのアンカー
 * @returns
 */
export const UserInformationBase = React.memo(
  ({
    children,
    open,
    transitionduration,
    onClick,
    openUserMenu,
    anchorEl
  }: UserInformationBaseProps) => {
    return (
      <>
        <CustomListItemButton
          open={open}
          transitionduration={transitionduration}
          onClick={onClick}
        >
          {children}
        </CustomListItemButton>
        <PopoverUserCard
          openUserMenu={openUserMenu}
          open={open}
          anchorEl={anchorEl}
          onClick={onClick}
        />
      </>
    );
  }
);
