import { FC, useCallback, useState } from 'react';
import { FormHelperText } from '@mui/material';
import EditImageOrIconButton from '../../common/EditImageOrIconButton';
import IconSelectDialog from './IconSelectDialog';
import LazyIcon from './LazyIcon';
import './style.scss';

type Props = {
  selectedIconPath?: string;
  errorMessage?: string;
  onSelectIcon?: (iconPath: string) => void;
};

/**
 * アイコンセレクタを返す
 * @param {string | undefined} selectedIconPath 選択されたアイコンのパス
 * @param {string | undefined} errorMessage エラーメッセージ
 * @param {Function | undefined} onSelectIcon アイコンが選択された際に実行される関数
 * @returns アイコンセレクタ
 */
const IconSelector: FC<Props> = ({
  selectedIconPath = '',
  errorMessage = '',
  onSelectIcon
}) => {
  const [isOpenDialog, setOpenDialog] = useState(false);

  const openDialog = useCallback(() => setOpenDialog(true), []);

  const closeDialog = useCallback(() => setOpenDialog(false), []);

  const handleSelectIcon = useCallback(
    (iconPath: string) => {
      closeDialog();
      onSelectIcon?.(iconPath);
    },
    [onSelectIcon, closeDialog]
  );

  return (
    <div className="IconSelector">
      {selectedIconPath && <LazyIcon filePath={selectedIconPath} />}
      <EditImageOrIconButton label="アイコン変更" onClick={openDialog} />
      {errorMessage && (
        <FormHelperText error style={{ fontSize: 14 }}>
          {errorMessage}
        </FormHelperText>
      )}
      <IconSelectDialog
        selectedIconPath={selectedIconPath}
        isOpen={isOpenDialog}
        onSelectIcon={handleSelectIcon}
        onClickClose={closeDialog}
      />
    </div>
  );
};

export default IconSelector;
