import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ToggleIcon } from 'assets/icons';

/**
 * AddButtonPropsはAddButtonコンポーネントのプロパティを定義します。
 *
 * @interface AddButtonProps
 * @property {() => void} onClick - ボタンをクリックしたときに呼び出されるコールバック関数
 */
interface AddButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

/**
 * CustomButtonはスタイルをカスタマイズしたボタンコンポーネントです。
 *
 * @component
 * @returns {JSX.Element} カスタマイズされたボタンコンポーネントのJSX要素
 */
const CustomButton = styled(Button)({
  borderRadius: 8,
  border: '1px solid #CCC',
  background: '#FFF',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.20)',
  display: 'flex',
  height: 30,
  width: 120,
  padding: '8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  flex: '1 0 0',
  color: 'var(--kanri-secondary-text, #595959)',
  textAlign: 'center',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  marginLeft: 'auto',
  '&:hover': {
    borderRadius: 8,
    border: '1px solid #CCC',
    background: '#FFF',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.20)',
    color: 'var(--kanri-secondary-text, #595959)'
  },
  '& svg': {
    fill: 'var(--kanri-secondary-text, #595959)'
  },
  '&.Mui-disabled svg': {
    fill: 'rgba(0, 0, 0, 0.26)'
  },
  '& .MuiButton-startIcon': {
    margin: 0
  }
});

/**
 * AddButtonは追加アクションを実行するためのボタンコンポーネントです。
 *
 * @param {AddButtonProps} props - AddButtonコンポーネントのプロパティ
 * @returns {JSX.Element} AddButtonコンポーネントのJSX要素
 */
const ReorderBlockAddButton: React.FC<AddButtonProps> = ({
  onClick,
  disabled
}) => {
  return (
    <CustomButton
      variant="contained"
      startIcon={<ToggleIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      追加
    </CustomButton>
  );
};

// PropTypesを追加
ReorderBlockAddButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default ReorderBlockAddButton;
