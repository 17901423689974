import {
  FC,
  FocusEventHandler,
  SyntheticEvent,
  useCallback,
  useMemo
} from 'react';
import { Autocomplete, Chip, TextField, styled } from '@mui/material';
import { ArrowBottomIcon, CloseBlack } from 'assets/icons';
import FieldLabel from '../FieldLabel';
import StyledSelectBoxControl from './StyledSelectBoxControl';

type GroupingSelectBoxValue = boolean | number | string | undefined;

export type GroupingSelectBoxOption = {
  label: string;
  value: GroupingSelectBoxValue;
  category?: string;
};

type SyntheticEventHandler = (values: GroupingSelectBoxValue[]) => void;

type Props = {
  label?: string;
  values?: GroupingSelectBoxValue | GroupingSelectBoxValue[];
  placeholder?: string;
  focused?: boolean;
  options?: GroupingSelectBoxOption[];
  helperText?: string;
  width?: number;
  isMultiple?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onChange?: SyntheticEventHandler;
  style?: React.CSSProperties;
};

/**
 * グルーピングされたセレクトボックスを返す
 * @param {string | undefined} label ラベル文言
 * @param {GroupingSelectBoxValue | GroupingSelectBoxValue[] | undefined} values 値(複数可)
 * @param {string | undefined} placeholder プレースホルダ
 * @param {boolean | undefined} focused フォーカス状態であるか(プレースホルダーをデフォルトで表示するか)
 * @param {GroupingSelectBoxOption[]} options 選択オプション
 * @param {string | undefined} helperText ヘルパーテキスト
 * @param {number | undefined} width フィールド幅
 * @param {boolean | undefined} isMultiple 複数選択可能であるか
 * @param {boolean | undefined} isRequired 選択が必須であるか
 * @param {boolean | undefined} isDisabled 選択が無効であるか
 * @param {boolean | undefined} isError エラーがあるか
 * @param {FocusEventHandler<HTMLDivElement> | undefined} onBlur フォーカスが外れた際に実行される関数
 * @param {SyntheticEventHandler | undefined} onChange 値が変更された際に実行される関数
 * @returns グルーピングされたセレクトボックス
 */
const GroupingSelectBox: FC<Props> = ({
  label = '',
  values = undefined,
  placeholder = '',
  focused = true,
  options = [],
  helperText = '',
  width = 0,
  isMultiple = false,
  isRequired = false,
  isDisabled = false,
  isError = false,
  onBlur,
  onChange,
  style
}) => {
  const localValues = useMemo(():
    | GroupingSelectBoxOption
    | GroupingSelectBoxOption[]
    | null => {
    if (values === undefined || values === null) return null;

    if (Array.isArray(values)) {
      const results = options.filter((option) => values.includes(option.value));
      return results.length > 0 ? results : [];
    } else {
      const result = options.find((option) => option.value === values);
      return result ? result : null;
    }
  }, [values, options]);

  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      value: GroupingSelectBoxOption | GroupingSelectBoxOption[] | null
    ) => {
      if (value) {
        if (Array.isArray(value)) onChange?.(value.map((v) => v.value));
        else onChange?.([value.value]);
      } else {
        onChange?.([]);
      }
    },
    [onChange]
  );

  const handleDeleteOption = useCallback(
    (value: GroupingSelectBoxValue) => {
      if (value === undefined || value === null) return;

      if (Array.isArray(values)) onChange?.(values.filter((v) => v !== value));
      else onChange?.([]);
    },
    [values, onChange]
  );

  return (
    <StyledSelectBoxControl
      fullWidth={width <= 0}
      disabled={isDisabled}
      style={{
        ...(width && { width }),
        ...style
      }}
    >
      <Autocomplete
        value={localValues}
        options={options}
        groupBy={(option) => option.category ?? ''}
        getOptionLabel={(option) => option.label}
        multiple={isMultiple}
        disabled={isDisabled}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => (
          <StyledTextField
            focused={focused}
            label={
              label && <FieldLabel label={label} isRequired={isRequired} />
            }
            placeholder={placeholder}
            helperText={helperText}
            error={isError}
            style={{ marginTop: '10px' }}
            {...params}
          />
        )}
        renderTags={(values) =>
          values.map((option, i) => (
            <Chip
              key={`${label}_${i}`}
              label={option.label}
              deleteIcon={<CloseBlack />}
              onDelete={() => handleDeleteOption(option.value)}
            />
          ))
        }
        popupIcon={<ArrowBottomIcon />}
        onBlur={onBlur}
        onChange={handleChange}
      />
    </StyledSelectBoxControl>
  );
};

export default GroupingSelectBox;

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root.MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1
    }
  }
}));
