import { generateTextRule } from '../shared';

/**
 * キャッチフレーズのバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useCatchPhraseValidationRules = () => {
  const propertyName = 'キャッチフレーズ';
  const maxLength = 40;
  return generateTextRule<'topImage.catchPhrase'>(
    propertyName,
    maxLength,
    true
  );
};
