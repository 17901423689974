import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { SelectBox } from '../parts/SelectBox';
import { PersonalInformationProtectorTypeOptions } from '../parts/groupOptions';

/**
 * 個人情報の管理責任を有するものセクションをレンダリングする
 * @returns {React.JSX.Element} 個人情報の管理責任を有するものセクション
 */
export default function PersonalInformationProtectorTypeSection() {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext<EnhancedPolicyFormData>();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          個人情報の管理責任を有するもの
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <>
            <Controller
              name={'personalInformationProtectorType'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectBox
                  value={value ?? ''}
                  width={400}
                  category={PersonalInformationProtectorTypeOptions}
                  isError={!!errors.personalInformationProtectorType}
                  onChange={(selectedValue) => {
                    onChange(selectedValue);
                    setValue('personalInformationProtectorName', '');
                  }}
                />
              )}
            />
          </>
        </div>
      </label>
    </div>
  );
}
