import { generateTextRule } from '../../shared';

/**
 * よくある質問の内容のバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useQuestionContentValidationRules = () => {
  const propertyName = '内容';
  const maxLength = 300;
  return generateTextRule<`faq.questions.${number}.content`>(
    propertyName,
    maxLength,
    true
  );
};
