import { generateTextRule } from '../shared';

/**
 * 事業内容のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useBusinessValidationRules = () => {
  const propertyName = '事業内容';
  const maxLength = 160;
  return generateTextRule<'corporationAbout.business'>(
    propertyName,
    maxLength,
    true
  );
};
