import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import TextField from '../parts/TextField';
import { useMemoValidationRules } from '../validations/memo';

/**
 * 「本人開示要求など」の備考セクションをレンダリングする
 * @returns {React.JSX.Element} 「本人開示要求など」の備考セクション
 */
export default function ContactMemoSection() {
  const {
    control,
    formState: { errors }
  } = useFormContext<EnhancedPolicyFormData>();

  const rules = useMemoValidationRules<'contactMemo'>();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <Controller
            name="contactMemo"
            control={control}
            rules={rules}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                label={'備考'}
                value={value}
                placeholder={'住所・その他連絡先など'}
                width={585}
                maxLength={300}
                hasHelperText={true}
                errorMessage={
                  errors.contactMemo?.type !== 'maxLength'
                    ? errors.contactMemo?.message
                    : ''
                }
                isError={!!errors.contactMemo}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </div>
      </label>
    </div>
  );
}
