import { generateTextRule } from '../../shared';

/**
 * 応募の流れの内容のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useAfterProcessContentValidationRules = () => {
  const propertyName = '内容';
  const maxLength = 100;

  return generateTextRule<`afterProcess.processList.${number}.content`>(
    propertyName,
    maxLength,
    true
  );
};
