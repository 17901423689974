import { FC } from 'react';
import NormalRadioLabel from './NormalRadioLabel';
import SelectedRadioLabel from './SelectedRadioLabel';

type Props = {
  label: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
};

/**
 * ラジオボタンラベルを返す
 * @param {string} label ラベル文言
 * @param {boolean | undefined} isSelected 選択されているか
 * @param {CSSProperties | undefined} style 追加のCSSプロパティ
 * @returns ラジオボタンラベル
 */
const RadioLabel: FC<Props> = ({ label, isSelected = false, style }) => (
  <>
    {isSelected ? (
      <SelectedRadioLabel label={label} style={style} />
    ) : (
      <NormalRadioLabel label={label} style={style} />
    )}
  </>
);

export default RadioLabel;
