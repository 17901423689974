import { FC } from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { ErrorCircleIcon } from 'assets/icons';

/**
 * 未設定エラーツールチップを返す
 * @returns 未設定エラーツールチップ
 */
const ErrorTooltip: FC = () => (
  <StyledTooltip
    title="エラー項目があります"
    placement="right"
    style={{ marginTop: 2, marginLeft: 'auto', marginRight: 0 }}
  >
    <ErrorCircleIcon />
  </StyledTooltip>
);

export default ErrorTooltip;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20] // x, y オフセット
          }
        }
      ]
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));
