/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSettingChangeOpenStatusReqDto
 */
export interface PageSettingChangeOpenStatusReqDto {
    /**
     * 変更後のステータス
     * @type {number}
     * @memberof PageSettingChangeOpenStatusReqDto
     */
    isOpen?: PageSettingChangeOpenStatusReqDtoIsOpenEnum;
}


/**
 * @export
 */
export const PageSettingChangeOpenStatusReqDtoIsOpenEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type PageSettingChangeOpenStatusReqDtoIsOpenEnum = typeof PageSettingChangeOpenStatusReqDtoIsOpenEnum[keyof typeof PageSettingChangeOpenStatusReqDtoIsOpenEnum];


/**
 * Check if a given object implements the PageSettingChangeOpenStatusReqDto interface.
 */
export function instanceOfPageSettingChangeOpenStatusReqDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSettingChangeOpenStatusReqDtoFromJSON(json: any): PageSettingChangeOpenStatusReqDto {
    return PageSettingChangeOpenStatusReqDtoFromJSONTyped(json, false);
}

export function PageSettingChangeOpenStatusReqDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingChangeOpenStatusReqDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
    };
}

export function PageSettingChangeOpenStatusReqDtoToJSON(value?: PageSettingChangeOpenStatusReqDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isOpen': value.isOpen,
    };
}

