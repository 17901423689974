import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, styled } from '@mui/material';

const RadioButtonsGroupForm = styled(FormControl)({
  padding: '0 10px',
  '& .MuiRadio-colorPrimary': {
    color: 'var(--kanri-secondary-text, #595959)',
    '&.Mui-checked': {
      color: 'var(--kanri-secondary-text, #595959)'
    }
  },
  '& .MuiTypography-root': {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    color: 'var(--kanri-secondary-text, #595959)',
    paddingLeft: 2
  },
  '& .MuiRadio-root, &.MuiFormControl-root': {
    padding: 0
  },
  '& .MuiFormControlLabel-root': {
    marginRight: 10,
    marginLeft: 0,
    color: 'var(--kanri-secondary-text, #595959)',
    '&:nth-of-type(3n)': {
      marginRight: 0
    }
  }
});

/**
 * ラジオボタンの各オプションに適用する型
 * @interface Option
 * @property {string} label - ラジオボタンのラベル
 * @property {string} value - ラジオボタンの値
 * @property {boolean} defaultSelected - デフォルト値の設定
 */
type Option = {
  label: string;
  value: string;
  defaultSelected?: boolean;
};

/**
 * RadioButtonsGroup コンポーネントのプロパティに適用する型
 * @interface RadioButtonsGroupProps
 * @property {Option[]} options - ラジオボタンのオプション
 * @property {string} value - 選択されているラジオボタンの値
 * @property {(value: string) => void} onChange - ラジオボタンの値が変更された時のハンドラ
 */
type Props = {
  options?: Option[];
  value?: string;
  onChange?: (value: string) => void;
};

/**
 * ラジオボタンのグループをレンダリングするコンポーネント
 *
 * @param {RadioButtonsGroupProps} props - RadioButtonsGroup コンポーネントのプロパティ
 * @returns {JSX.Element} レンダリングする要素
 */
const RadioButtonsGroup: React.FC<Props> = ({
  options = [],
  value = '',
  onChange
}) => {
  return (
    <RadioButtonsGroupForm>
      <RadioGroup
        row
        aria-labelledby="custom-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={
          value || options?.find((option) => option.defaultSelected)?.value
        }
        onChange={(e) => onChange?.(e.target.value)}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={`radio-button_${index}`}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </RadioButtonsGroupForm>
  );
};

export default RadioButtonsGroup;
