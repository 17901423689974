/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSettingInitialPreReferenceResDto
 */
export interface PageSettingInitialPreReferenceResDto {
    /**
     * 小職種コード。区分値のバリデーションはかけない
     * @type {Array<string>}
     * @memberof PageSettingInitialPreReferenceResDto
     */
    shok3CdList: Array<string>;
    /**
     * ターゲット（雇用形態）
     * @type {string}
     * @memberof PageSettingInitialPreReferenceResDto
     */
    siteTargetKoyoKeitai: PageSettingInitialPreReferenceResDtoSiteTargetKoyoKeitaiEnum;
    /**
     * デザイン
     * @type {string}
     * @memberof PageSettingInitialPreReferenceResDto
     */
    designType: PageSettingInitialPreReferenceResDtoDesignTypeEnum;
    /**
     * カラー
     * @type {string}
     * @memberof PageSettingInitialPreReferenceResDto
     */
    color: PageSettingInitialPreReferenceResDtoColorEnum;
    /**
     * 表示用企業名
     * @type {string}
     * @memberof PageSettingInitialPreReferenceResDto
     */
    dispCorpName: string;
}


/**
 * @export
 */
export const PageSettingInitialPreReferenceResDtoSiteTargetKoyoKeitaiEnum = {
    Normal: 'normal',
    Regular: 'regular',
    Contract: 'contract',
    Haken: 'haken',
    Outsg: 'outsg',
    NlimEhaken: 'nlim_ehaken',
    SyokaiRhaken: 'syokai_rhaken'
} as const;
export type PageSettingInitialPreReferenceResDtoSiteTargetKoyoKeitaiEnum = typeof PageSettingInitialPreReferenceResDtoSiteTargetKoyoKeitaiEnum[keyof typeof PageSettingInitialPreReferenceResDtoSiteTargetKoyoKeitaiEnum];

/**
 * @export
 */
export const PageSettingInitialPreReferenceResDtoDesignTypeEnum = {
    Basic: 'basic',
    Stylish: 'stylish',
    Pop: 'pop',
    Natural: 'natural'
} as const;
export type PageSettingInitialPreReferenceResDtoDesignTypeEnum = typeof PageSettingInitialPreReferenceResDtoDesignTypeEnum[keyof typeof PageSettingInitialPreReferenceResDtoDesignTypeEnum];

/**
 * @export
 */
export const PageSettingInitialPreReferenceResDtoColorEnum = {
    Red: 'red',
    Orange: 'orange',
    Green: 'green',
    Blue: 'blue',
    Gray: 'gray',
    Purple: 'purple'
} as const;
export type PageSettingInitialPreReferenceResDtoColorEnum = typeof PageSettingInitialPreReferenceResDtoColorEnum[keyof typeof PageSettingInitialPreReferenceResDtoColorEnum];


/**
 * Check if a given object implements the PageSettingInitialPreReferenceResDto interface.
 */
export function instanceOfPageSettingInitialPreReferenceResDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shok3CdList" in value;
    isInstance = isInstance && "siteTargetKoyoKeitai" in value;
    isInstance = isInstance && "designType" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "dispCorpName" in value;

    return isInstance;
}

export function PageSettingInitialPreReferenceResDtoFromJSON(json: any): PageSettingInitialPreReferenceResDto {
    return PageSettingInitialPreReferenceResDtoFromJSONTyped(json, false);
}

export function PageSettingInitialPreReferenceResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingInitialPreReferenceResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shok3CdList': json['shok3CdList'],
        'siteTargetKoyoKeitai': json['siteTargetKoyoKeitai'],
        'designType': json['designType'],
        'color': json['color'],
        'dispCorpName': json['dispCorpName'],
    };
}

export function PageSettingInitialPreReferenceResDtoToJSON(value?: PageSettingInitialPreReferenceResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shok3CdList': value.shok3CdList,
        'siteTargetKoyoKeitai': value.siteTargetKoyoKeitai,
        'designType': value.designType,
        'color': value.color,
        'dispCorpName': value.dispCorpName,
    };
}

