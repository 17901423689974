import { CustomButton } from 'components/parts/CustomButton';

export const ActionButton = ({
  label,
  onClick,
  gaTag,
  width = 'auto',
  disabled = false
}: {
  label: string;
  onClick?: () => void;
  width?: string;
  gaTag?: string;
  disabled?: boolean;
}) => {
  return (
    <span
      data-ga-selector={gaTag}
      style={{ width: 'fit-content', display: 'block' }}
    >
      <CustomButton
        width={width}
        height="100%"
        textcolor="white"
        border="1px solid"
        bordercolor="var(--kanri-primary)"
        backgroundcolor="var(--kanri-primary)"
        hoveropacity="0.85"
        hoverborder="1px solid"
        hoverbordercolor="var(--kanri-primary)"
        margin="10px 0 0 0"
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </CustomButton>
    </span>
  );
};
