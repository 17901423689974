import { FC, useCallback, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useReorderBlocks from 'hooks/saiyouPageEditing/useReorderBlocks';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ReorderBlockAddButton from '../../shared/ReorderBlockAddButton';
import StaffInterviewReorderBlock from './StaffInterviewReorderBlock';

type Props = {
  minLength?: number;
};

/**
 * 「スタッフインタビュー」の並び替え可能ブロックグループを返す
 * @param {number | undefined} minLength ブロックの最小要素数
 * @returns 「スタッフインタビュー」の並び替え可能ブロックグループを返す
 */
const StaffInterviewReorderBlocks: FC<Props> = ({ minLength = 1 }) => {
  const { setValue, watch, trigger, clearErrors } = useFormContext<FormData>();

  const interviews = watch('staffInterview.interviews');

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const {
    blocks,
    canAddBlock,
    canRemoveBlock,
    addBlock,
    removeBlock,
    swapUpBlock,
    swapDownBlock,
    forceSetBlockLength
  } = useReorderBlocks(minLength, 2, 0);

  const handleRemoveBlock = useCallback(
    (index: number) => {
      removeBlock();

      clearErrors(`staffInterview.interviews.${index}`);
      setValue(
        'staffInterview.interviews',
        interviews.filter((_, i) => i !== index)
      );

      onChangeValue?.();
    },
    [interviews, setValue, onChangeValue, removeBlock]
  );

  const handleSwapUpBlock = useCallback(
    (index: number) => {
      setValue('staffInterview.interviews', swapUpBlock(interviews, index));
      trigger(`staffInterview.interviews.${index - 1}`);
      trigger(`staffInterview.interviews.${index}`);

      onChangeValue?.();
    },
    [interviews, setValue, onChangeValue, swapUpBlock]
  );

  const handleSwapDownBlock = useCallback(
    (index: number) => {
      setValue('staffInterview.interviews', swapDownBlock(interviews, index));
      trigger(`staffInterview.interviews.${index}`);
      trigger(`staffInterview.interviews.${index + 1}`);

      onChangeValue?.();
    },
    [interviews, setValue, onChangeValue, swapDownBlock]
  );

  useEffect(() => {
    forceSetBlockLength(interviews?.length);
  }, [interviews, forceSetBlockLength]);

  return (
    <>
      {blocks.map((block, i) => (
        <StaffInterviewReorderBlock
          key={block.id}
          index={i}
          canSwapUp={i > 0}
          canSwapDown={i < blocks.length - 1}
          canRemove={canRemoveBlock}
          onSwapUp={() => handleSwapUpBlock(i)}
          onSwapDown={() => handleSwapDownBlock(i)}
          onRemove={() => handleRemoveBlock(i)}
        />
      ))}
      <div style={{ paddingTop: '10px' }}>
        <ReorderBlockAddButton disabled={!canAddBlock} onClick={addBlock} />
      </div>
    </>
  );
};

export default StaffInterviewReorderBlocks;
