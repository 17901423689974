import { Auth0ContextInterface } from '@auth0/auth0-react/dist/auth0-context';
import {
  Configuration,
  MeApi,
  PageSettingManagementApi,
  PageSettingCacheClearReqDtoTargetsEnum,
  PageSettingChangeOpenStatusReqDtoIsOpenEnum,
  ResponseError,
  PolicySettingRegisterReqDto,
  PolicySettingManagementApi,
  ImageApi,
  ImageUploadReqDto,
  PointIconApi,
  PageSettingRegisterReqDto
} from '../../api-client/management';
import { User } from '@auth0/auth0-react';
import { Buffer } from 'buffer';
import { PreFormData } from 'types/saiyouPageEditing/PreFormData';
import { default as ExtensibleCustomError } from 'extensible-custom-error';

class ApiClientConfGenerateError extends ExtensibleCustomError {}

/**
 * 管理APIのクライアントのconfigを返す
 */
export const getMgrApiClientConf = async (
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const basicIpass = process.env.REACT_APP_PUBLIC_BASIC_AUTH_USER_PASS;

    const _basicIpass = basicIpass
      ? `Basic ${Buffer.from(basicIpass).toString('base64')}`
      : '';

    return new Configuration({
      basePath: process.env.REACT_APP_API_HOST,
      headers: {
        'x-access-token': await auth0.getAccessTokenSilently(),
        authorization: _basicIpass
      }
    });
  } catch (e) {
    throw new ApiClientConfGenerateError(e as Error);
  }
};

const handleException = (e: unknown, auth0: Auth0ContextInterface<User>) => {
  console.error(e);
  //トークンの期限切れ＝セッション切れなどAuth0のトークン不正などであればここに引っかかる
  if (e instanceof ApiClientConfGenerateError) {
    auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout?redirectTo=${encodeURI(
          location.href
        )}`
      }
    });
    return;
  }

  if (!(e instanceof ResponseError)) {
    return;
  }
  const status = e.response.status;
  console.error(status);
  if ([403, 401].includes(status)) {
    //権限エラーなら権限なしページに遷移
    location.href = '/unauthorized';
  }

  return status;
};
/**
 * ログインユーザー情報の取得
 */
export const getMe = async (
  _compMgrNo: string | undefined,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new MeApi(await getMgrApiClientConf(auth0));
    return await api.getMe({ compMgrNo });
  } catch (e) {
    const status = handleException(e, auth0);
    if (!status) throw e;

    return null;
  }
};

/**
 * ページ設定の取得
 */
export const getPageSetting = async (
  _compMgrNo: string | undefined,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));
    return await api.getPageSetting({ compMgrNo });
  } catch (e) {
    handleException(e, auth0);
    return null;
  }
};

/**
 * 採用ページ設定の登録
 */
export const registerPageSetting = async (
  compMgrNo: string,
  requestBody: PageSettingRegisterReqDto,
  auth0: Auth0ContextInterface<User>
): Promise<{ success: boolean; statusCode?: number }> => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(_compMgrNo)) return { success: false };

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));
    await api.registerPageSetting({
      compMgrNo: _compMgrNo,
      pageSettingRegisterReqDto: requestBody
    });

    return { success: true };
  } catch (e) {
    const statusCode = handleException(e, auth0);
    return { success: false, statusCode };
  }
};

/**
 * 採用ページプレ初期設定の取得
 */
export const getPreInitialPageSetting = async (
  compMgrNo: string,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(_compMgrNo)) return { status: 'error', data: null };

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));
    const res = await api.getPreInitialPageSetting({ compMgrNo: _compMgrNo });
    return { status: 'success', data: res };
  } catch (e) {
    const status = handleException(e, auth0) === 404 ? '404error' : 'error';
    return { status, data: null };
  }
};

/**
 * 採用ページ初期設定取得API
 */
export const getInitialPageSetting = async (
  compMgrNo: string,
  preData: PreFormData,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(_compMgrNo)) return null;

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));
    return await api.getInitialPageSetting({
      compMgrNo: _compMgrNo,
      shok3CdList: preData.occupationCodes,
      siteTargetKoyoKeitai: preData.employmentStatus,
      designType: preData.pageDesign,
      color: preData.pageColor,
      dispCorpName: preData.corporationName
    });
  } catch (e) {
    handleException(e, auth0);
    return null;
  }
};

/**
 * 公開ステータス変更
 */
export const changePageStatus = async (
  _compMgrNo: string | undefined,
  auth0: Auth0ContextInterface<User>,
  isOpen: PageSettingChangeOpenStatusReqDtoIsOpenEnum
) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));

    await api.changePageStatus({
      compMgrNo,
      pageSettingChangeOpenStatusReqDto: { isOpen }
    });
    return 1; //戻り値がないため
  } catch (e) {
    handleException(e, auth0);
    return null;
  }
};

/**
 * キャッシュクリア
 */
export const clearPageCache = async (
  _compMgrNo: string | undefined,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new PageSettingManagementApi(await getMgrApiClientConf(auth0));

    await api.clearPageCache({
      compMgrNo,
      pageSettingCacheClearReqDto: {
        targets: [
          PageSettingCacheClearReqDtoTargetsEnum.Preview,
          PageSettingCacheClearReqDtoTargetsEnum.Published
        ]
      }
    });
    return 1; //戻り値がないため
  } catch (e) {
    handleException(e, auth0);
    return null;
  }
};

/**
 * 利用規約を登録
 */
export const registerPolicySetting = async (
  _compMgrNo: string | undefined,
  policySetting: PolicySettingRegisterReqDto,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return { isSuccess: false };

    const api = new PolicySettingManagementApi(
      await getMgrApiClientConf(auth0)
    );

    await api.registerPolicySetting({
      compMgrNo,
      policySettingRegisterReqDto: policySetting
    });

    return { isSuccess: true };
  } catch (e) {
    const statusCd = handleException(e, auth0);
    return { isSuccess: false, statusCd };
  }
};

/**
 * 画像リストの取得
 */
export const listImage = async (
  compMgrNo: string,
  auth0: Auth0ContextInterface<User>,
  page = 1,
  searchWord = '',
  imageType: 'baitoru' | 'free' = 'baitoru'
) => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new ImageApi(await getMgrApiClientConf(auth0));

    return await api.listImage({
      compMgrNo: _compMgrNo,
      page,
      searchWord,
      imageType
    });
  } catch (e) {
    return handleException(e, auth0);
  }
};

/**
 * 画像アップロード
 */
export const uploadImage = async (
  compMgrNo: string,
  imageUploadReqDto: ImageUploadReqDto,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new ImageApi(await getMgrApiClientConf(auth0));

    return await api.uploadImage({ compMgrNo: _compMgrNo, imageUploadReqDto });
  } catch (e) {
    return handleException(e, auth0);
  }
};

/**
 * アイコン画像一覧の取得
 */
export const listIcon = async (
  compMgrNo: string,
  auth0: Auth0ContextInterface<User>
) => {
  try {
    const _compMgrNo = Number(compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new PointIconApi(await getMgrApiClientConf(auth0));

    return await api.listIcon({ compMgrNo: _compMgrNo });
  } catch (e) {
    return handleException(e, auth0);
  }
};
