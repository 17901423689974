import { AccordionCode } from 'types/saiyouPageEditing/AccordionCode';
import { LeftMenuTabType } from 'types/saiyouPageEditing/LeftMenuTabType';

/**
 * 左ペイン用アコーディオンの所属タブの一覧
 */
export const accordionParents: { [key in AccordionCode]: LeftMenuTabType } = {
  design: 'design',
  topImage: 'content',
  corpIntro: 'content',
  jobIntro: 'content',
  staffInterview: 'content',
  jobMgrNos: 'content',
  jobPoint: 'content',
  afterProcess: 'content',
  message: 'content',
  faq: 'content',
  corpName: 'site',
  corpLogo: 'site',
  homePage: 'site',
  sns: 'site',
  corpAbout: 'site',
  topPageVisibility: 'site',
  siteTitle: 'seo',
  brand: 'seo',
  target: 'seo',
  siteDescription: 'seo'
};
