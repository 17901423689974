import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getInitialPageSetting } from 'logic/clientWrapper/management';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { PreFormData } from 'types/saiyouPageEditing/PreFormData';
import useFormDataConverter from './useFormDataConverter';
import useImageUploader from './useImageUploader';

/**
 * デフォルトフォームデータ取得Hooks
 * @returns デフォルトフォームデータの取得関数
 */
const useDefaultFormData = (
  compId?: string
): {
  getDefaultFormDataAsync: (
    preFormData: PreFormData
  ) => Promise<FormData | null>;
} => {
  const { toFormData } = useFormDataConverter();
  const { uploadImageAsyncFromUrl } = useImageUploader(compId);

  const auth0 = useAuth0();

  const getDefaultFormDataAsync = useCallback(
    async (preFormData: PreFormData) => {
      if (!compId || !auth0) return null;

      const response = await getInitialPageSetting(compId, preFormData, auth0);
      if (!response) return null;

      //origin系のプロパティは不要なので取り除いておく
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { aboutJobImageOriginUrlList, logoImgOriginUrl, ...formData } =
        structuredClone(response);

      //Originの画像をアップロードし、アップロード後のURLをデフォルト値としてセットする
      if (response.logoImgOriginUrl) {
        const logoImagePath = await uploadImageAsyncFromUrl(
          response.logoImgOriginUrl,
          'icon'
        );
        formData.logoImgPath = logoImagePath;
      }
      if (response.aboutJobImageOriginUrlList) {
        const uploadedOccupationImagePaths = await Promise.all(
          response.aboutJobImageOriginUrlList.map(async (org) => {
            return {
              shok3Cd: org.shok3Cd,
              url: org.url
                ? await uploadImageAsyncFromUrl(org.url, 'default')
                : ''
            };
          })
        );

        formData.aboutJobList = formData.aboutJobList?.map((job) => ({
          ...job,
          imagePath:
            uploadedOccupationImagePaths.find(
              (uploaded) => uploaded.shok3Cd === job.shok3Cd
            )?.url || ''
        }));
      }

      return toFormData(formData);
    },
    [compId, auth0, toFormData]
  );

  return { getDefaultFormDataAsync };
};

export default useDefaultFormData;
