import React from 'react';
import styles from './style.module.scss';

/**
 * @typedef CommonFooterProps
 * @property {'commonPage' | 'logoutPage' | 'notFoundPage'} pt - ページタイプ
 * @property {number} [paddingTop] - フッターの上部余白
 * @property {number} [paddingBottom] - フッターの下部余白
 */
interface CommonFooterProps {
  pt: 'commonPage' | 'logoutPage' | 'notFoundPage';
  paddingTop?: number;
  paddingBottom?: number;
}

/**
 * 共通フッターコンポーネント
 *
 * `pt` プロパティに応じて、異なるフッターコンテンツを表示します。
 *
 * @param {CommonFooterProps} props - コンポーネントのプロパティ
 * @param {'commonPage' | 'logoutPage' | 'notFoundPage'} props.pt - ページタイプ
 * @param {number} [props.paddingTop] - フッターの上部余白
 * @param {number} [props.paddingBottom] - フッターの下部余白
 * @returns {JSX.Element} レンダリングされるJSXエレメント
 */
export const CommonFooter = React.memo(
  ({ pt, paddingTop, paddingBottom }: CommonFooterProps) => {
    return (
      <footer
        className={`${styles['footer']} ${styles[`_${pt}`]}`}
        style={{ paddingTop: paddingTop, paddingBottom: paddingBottom }}
      >
        {pt === 'commonPage' && <p>&copy; dip Corporation.</p>}
        {(pt === 'logoutPage' || pt === 'notFoundPage') && (
          <dl>
            <dt>&copy; dip Corporation.</dt>
            <dd>
              powered by{' '}
              <a
                href="https://www.dip-net.co.jp/service/saiyopagekobot"
                target="_blank"
                rel="noreferrer"
              >
                採用ページコボット
              </a>
            </dd>
          </dl>
        )}
      </footer>
    );
  }
);
