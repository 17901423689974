import { getPolicySetting } from 'logic/clientWrapper/public';
import { useEffect, useState } from 'react';

/**
 * 個人利用規約Hooks
 * @param compId 企業管理番号
 * @returns 個人利用規約に関するデータ群
 */
const usePolicyData = (compId?: string): { hasPolicy: boolean } => {
  const [hasPolicy, setHasPolicy] = useState(true);

  useEffect(() => {
    const getPolicySettingAsync = async () => {
      const response = await getPolicySetting(compId);
      setHasPolicy(!!response && !!response.updatedAt);
    };

    if (compId) void getPolicySettingAsync();
  }, [compId]);

  return { hasPolicy };
};

export default usePolicyData;
