import { FC } from 'react';
import { CircularProgress } from '@mui/material';

/**
 * プログレスサークルを返す
 * @returns プログレスサークル
 */
const LoadingCircularProgress: FC = () => (
  <div style={{ marginTop: 30, textAlign: 'center' }}>
    <CircularProgress />
  </div>
);

export default LoadingCircularProgress;
