import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { getMaxLengthErrorMessage, getRequiredErrorMessage } from './shared';

/**
 * 個人情報保護管理者のバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const usePersonalInformationProtectionManagerNameValidationRules =
  (): RegisterOptions<
    EnhancedPolicyFormData,
    'personalInformationProtectionManagerName'
  > => {
    const PropertyName = '個人情報保護管理者';
    const MaxLength = 100;

    return useMemo(
      () => ({
        required: {
          value: true,
          message: getRequiredErrorMessage(PropertyName, 'input')
        },
        maxLength: {
          value: MaxLength,
          message: getMaxLengthErrorMessage(PropertyName, MaxLength)
        }
      }),
      []
    );
  };
