/**
 * 最大文字数バリデーションのエラーメッセージを返す
 * @param {string} propertyName プロパティ名
 * @param {number} maxLength 最大文字数
 * @returns {string} エラーメッセージ
 */
export const getMaxLengthMessage = (
  propertyName: string,
  maxLength: number
): string => `${propertyName}は${maxLength}文字以内で入力してください。`;
