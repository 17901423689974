import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material';

type Props = {
  onDropImage?: (image: File) => void;
};

/**
 * 画像のドロップゾーンを返す
 * @param {Function | undefined} onDropImage 画像がドロップされた際に実行される関数
 * @returns 画像のドロップゾーン
 */
const ImageDropzone: FC<Props> = ({ onDropImage }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => onDropImage?.(files[0])
  });

  return (
    <ImageDD {...getRootProps()} className="ImageDropzone">
      <ImageDDText>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>ファイルをアップロード</p>
        ) : (
          <p>ファイルをドラッグ＆ドロップ</p>
        )}
      </ImageDDText>
    </ImageDD>
  );
};

export default ImageDropzone;

const ImageDD = styled('div')(() => ({
  display: 'flex',
  height: 320,
  padding: '0 20',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  alignSelf: 'stretch',
  borderRadius: 8,
  background: 'var(--kanri-bg-01, #F2F3F7)',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8
  }
}));

const ImageDDText = styled('div')(() => ({
  color: 'var(--kanri-gray-02, #CCC)',
  textAlign: 'center',
  fontSize: 42,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '130%',
  letterSpacing: -2.1
}));
