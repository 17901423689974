import { FC, RefObject } from 'react';
import TabContentInner from '../shared/TabContentInner';
import TabContentSubHead from '../shared/TabContentSubHead';
import TabContentText from '../shared/TabContentText';
import TabContentWrapper from '../shared/TabContentWrapper';
import CorporationIntroAccordion from './CorporationIntroAccordion';
import JobIntroAccordion from './JobIntroAccordion';
import TopImageAccordion from './TopImageAccordion';
import StaffInterviewAccordion from './StaffInterviewAccordion';
import JobPointAccordion from './JobPointAccordion';
import AfterProcessAccordion from './AfterProcessAccordion';
import MessageAccordion from './MessageAccordion';
import FAQAccordion from './FAQAccordion';
import AvailableJobsAccordion from './AvailableJobsAccordion';

type Props = {
  sectionRef?: RefObject<HTMLDivElement>;
};

/**
 * 左ペインのコンテンツ設定セクションを返す
 * @param {RefObject<HTMLDivElement> | undefined} sectionRef コンテンツ設定セクションのref
 * @returns 左ペインのコンテンツ設定セクション
 */
const ContentsSettingSection: FC<Props> = ({ sectionRef }) => (
  <>
    <TabContentWrapper>
      <div id="content" ref={sectionRef}>
        <TabContentSubHead title="コンテンツ設定" />
      </div>
      <TabContentInner>
        <TabContentText text="各セクションのレイアウトや画像、テキスト内容などを変更できます。" />
      </TabContentInner>
    </TabContentWrapper>
    <TabContentWrapper>
      <TopImageAccordion />
      <CorporationIntroAccordion />
      <JobIntroAccordion />
      <StaffInterviewAccordion />
      <AvailableJobsAccordion />
      <JobPointAccordion />
      <AfterProcessAccordion />
      <MessageAccordion />
      <FAQAccordion />
    </TabContentWrapper>
  </>
);

export default ContentsSettingSection;
