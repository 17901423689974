import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { getPageSetting } from 'logic/clientWrapper/management';
import { FormData } from 'types/saiyouPageEditing/FormData';
import useFormDataConverter from './useFormDataConverter';

/**
 * 初期フォームデータ取得Hooks
 * @param {string | undefined} compId 企業管理番号
 * @returns 初期フォームデータ
 */
const useInitialFormData = (
  compId?: string
): {
  isInitialized: boolean;
  previewData?: FormData;
  publishedData?: FormData;
  previewUuid?: string;
  pageSetting?: PageSettingAdminReferenceResDto;
  isServerError?: boolean;
  fetchCurrentSetting: () => Promise<void>;
} => {
  const [isInitialized, setInitialized] = useState(false);
  const [previewData, setPreviewData] = useState<FormData>();
  const [publishedData, setPublishedData] = useState<FormData>();
  const [previewUuid, setPreviewUuid] = useState<string>('');
  const [pageSetting, setPageSetting] =
    useState<PageSettingAdminReferenceResDto>();
  const [isServerError, setServerError] = useState(false);

  const { toFormData } = useFormDataConverter();

  const auth0 = useAuth0();
  const getPageSettingAsync = async () => {
    const response = await getPageSetting(compId, auth0);

    if (response) {
      setPageSetting(response);

      //更新日の有無でデータの存在有無を判定
      if (response.preview && response.preview.updatedAt) {
        setPreviewData(toFormData(response.preview));
      }

      if (response.published && response.published.updatedAt) {
        setPublishedData(toFormData(response.published));
      }

      if (response.previewPageUuid) setPreviewUuid(response.previewPageUuid);

      setInitialized(true);
    } else {
      setServerError(true);
    }
  };

  useEffect(() => {
    if (compId && auth0) void getPageSettingAsync();
  }, [compId, auth0, toFormData]);
  const fetchCurrentSetting = async () => await getPageSettingAsync();

  return {
    isInitialized,
    previewData,
    publishedData,
    previewUuid,
    pageSetting,
    isServerError,
    fetchCurrentSetting
  };
};

export default useInitialFormData;
