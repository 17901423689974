import styles from './style.module.scss';
import { useCallback, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { getPolicySetting } from 'logic/clientWrapper/public';
import {
  getPageSetting,
  registerPolicySetting
} from 'logic/clientWrapper/management';
import { PrivatePageLayout } from 'components/layouts/PrivatePageLayout';
import {
  CommonDialogBoxWithRef,
  CommonDialogBoxHandler
} from 'components/parts/CommonDialogBox';
import { CustomButton } from 'components/parts/CustomButton';
import { CommonFooter } from 'components/parts/footer';
import {
  CommonSnackbarWithRef,
  SnackbarHandler
} from 'components/parts/SnackBarWrapper';
import {
  CommonAlertDialogHandler,
  CommonAlertDialogWithRef
} from 'components/parts/alert';
import {
  checkPolicyTypeIsTemplate,
  convertToEnhancedPolicyFormData,
  convertToPolicyFormData
} from './logic';
import { defaultValues } from './defaults';
import { EnhancedPolicyFormData } from './type';
import NormalForm from './parts/form/normal';
import FreeForm from './parts/form/free';
import { PolicySettingReferenceResDtoPolicyTypeEnum } from 'api-client/public';

export const PolicyEditPage: React.FC = () => {
  const { compId } = useParams();
  const auth0 = useAuth0();

  const formMethods = useForm<EnhancedPolicyFormData>({
    mode: 'all',
    defaultValues: defaultValues
  });
  const { handleSubmit, reset, watch, setValue } = formMethods;

  /**
   * 表示・非表示に使用する値
   */
  const policyType = watch('policyType');
  const isChangedPolicyType = watch('isChangedPolicyType');

  useEffect(() => {
    fetchPolicySettings();
  }, []);

  /**
   * 該当の利用規約を取得する
   */
  const fetchPolicySettings = async () => {
    const result = await getPolicySetting(compId);
    if (!result) {
      snackRef.current?.open('serverError');
      return;
    }
    if (
      result &&
      !Object.values(result).every((value) => value === undefined)
    ) {
      reset(convertToEnhancedPolicyFormData(result));
    }
  };

  /**
   * 各テンプレート参照
   */
  const publicDialogRef = useRef<CommonDialogBoxHandler | null>(null);
  const snackRef = useRef<SnackbarHandler | null>(null);
  const alertDialog = useRef<CommonAlertDialogHandler | null>(null);

  /**
   * 「保存」ボタン押下時に確認モーダルを表示する
   */
  const showPublicDialog = async () => {
    handleSubmit(async (data) => {
      publicDialogRef.current?.open(
        <div style={{ textAlign: 'center' }}>
          <span style={{ whiteSpace: 'pre-wrap' }}>
            {(isChangedPolicyType
              ? '現在のページの内容が保存されます。\n' +
                'もし' +
                (checkPolicyTypeIsTemplate(policyType)
                  ? '自由'
                  : 'テンプレート') +
                '記述で入力した内容がある場合、そちらの内容は利用規約に反映されません。\n'
              : '') + `現在のページの内容を反映します。よろしいですか？`}
          </span>
        </div>,
        () => submitPolicySettings(data),
        undefined,
        true
      );
    }, displayValidationError)();
  };

  /**
   * 利用規約を更新する
   * @param data EnhancedPolicyFormData 入力用フォームデータ
   */
  const submitPolicySettings = async (data: EnhancedPolicyFormData) => {
    const { isSuccess, statusCd } = await registerPolicySetting(
      compId,
      convertToPolicyFormData(data),
      auth0
    );
    if (isSuccess) {
      //公開設定がない場合は、採用サイトの設定画面にリダイレクトして初期設定ガイドを見せる
      const pageSetting = await getPageSetting(compId, auth0);
      const redirectSec = 5;
      const additionalMessage = !pageSetting?.published?.updatedAt
        ? redirectSec + '秒後に「採用サイトの設定」に戻ります。'
        : '';

      snackRef.current?.open('success', additionalMessage);
      await fetchPolicySettings();
      if (additionalMessage !== '') {
        setTimeout(() => {
          window.location.href = `/${compId}/edit`;
        }, redirectSec * 1000);
      }
      return;
    }
    const snackMessage =
      statusCd === 409 ? 'exclusiveCheckError' : 'serverError';
    snackRef.current?.open(snackMessage);
  };

  const changePolicyType = () => {
    setValue(
      'policyType',
      checkPolicyTypeIsTemplate(policyType)
        ? PolicySettingReferenceResDtoPolicyTypeEnum.NUMBER_1
        : PolicySettingReferenceResDtoPolicyTypeEnum.NUMBER_0
    );
    setValue('isChangedPolicyType', true);
    window.scrollTo({ top: 0 });
  };

  /**
   * バリデーションエラーを表示する
   */
  const displayValidationError = useCallback(() => {
    alertDialog.current?.open();
  }, [alertDialog]);

  /**
   * GAタグの設定
   */
  const gaTags = {
    yes: 'PolicySettingSaveYesBtn',
    no: 'PolicySettingSaveNoBtn'
  };

  return (
    <>
      <PrivatePageLayout>
        <FormProvider {...formMethods}>
          <div className={styles.policyEdit}>
            <div className={styles.policyEdit_title}>
              <p>個人情報利用規約の設定</p>
            </div>

            {/* 個人情報利用規約の設定フォーム */}
            <div className={styles.policyEdit_formWrapper}>
              <p>
                以下の内容は求職者が応募時に確認する利用規約に反映されます。
              </p>
              <form action="" className={styles.policyEdit_form}>
                {checkPolicyTypeIsTemplate(policyType) && <NormalForm />}
                {!checkPolicyTypeIsTemplate(policyType) && <FreeForm />}
                <div
                  className={styles.policyEdit_formButton}
                  data-ga-selector="PolicySettingSaveBtn"
                >
                  <CustomButton
                    width="100%"
                    height="50px"
                    textcolor="white"
                    border="1px solid"
                    bordercolor="var(--kanri-primary)"
                    backgroundcolor="var(--kanri-primary)"
                    hoveropacity="1"
                    hoverborder="1px solid"
                    hoverbordercolor="var(--kanri-primary)"
                    disabled={false}
                    onClick={showPublicDialog}
                  >
                    保存
                  </CustomButton>
                </div>
              </form>
              <div className={styles.policyEdit_typeChangeWrapper}>
                <span>
                  個人情報利用規約の記述形式を変更できます。
                  <br />
                  保存ボタンを押すまでは、このページで記述した内容は保持されています。
                </span>
                <CustomButton
                  width="230px"
                  height="37px"
                  textcolor="var(--kanri-primary)"
                  border="1px solid"
                  bordercolor="var(--kanri-primary)"
                  backgroundcolor="white"
                  hoveropacity="1"
                  hoverborder="2px solid"
                  hoverbordercolor="var(--kanri-primary)"
                  onClick={changePolicyType}
                >
                  {checkPolicyTypeIsTemplate(policyType)
                    ? '自由記述に切替'
                    : 'テンプレート記述に切替'}
                </CustomButton>
              </div>
            </div>
          </div>
        </FormProvider>
      </PrivatePageLayout>

      {/* 共通フッター */}
      <CommonFooter paddingTop={10} paddingBottom={30} pt="commonPage" />

      {/* 確認モーダル */}
      <CommonDialogBoxWithRef ref={publicDialogRef} gaTags={gaTags} />

      {/* バリデーションエラーモーダル */}
      <CommonAlertDialogWithRef
        ref={alertDialog}
        message="不正な入力項目があります。内容をご確認ください。"
      />
      {/* スナックバー */}
      <CommonSnackbarWithRef ref={snackRef} />
    </>
  );
};
