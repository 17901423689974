import { FC, PropsWithChildren } from 'react';
import EditSaiyouPageContext, {
  EditSaiyouPageProps
} from 'context/saiyouPageEditing/EditSaiyouPageContext';

type Props = EditSaiyouPageProps & PropsWithChildren;

/**
 * コンテンツ編集画面のコンテキストプロバイダを返す
 */
const EditSaiyouPageProvider: FC<Props> = ({
  children,
  isLoading,
  hasDisplayedInitModal,
  hasSavedBySaveButton,
  onChangeValue,
  pageSetting,
  hasPolicy,
  onClickSave,
  onClickPublish,
  navigateToPolicyPage,
  compId,
  canPublish
}) => (
  <EditSaiyouPageContext.Provider
    value={{
      isLoading,
      hasDisplayedInitModal,
      hasSavedBySaveButton,
      onChangeValue,
      pageSetting,
      hasPolicy,
      onClickSave,
      onClickPublish,
      navigateToPolicyPage,
      compId,
      canPublish
    }}
  >
    {children}
  </EditSaiyouPageContext.Provider>
);

export default EditSaiyouPageProvider;
