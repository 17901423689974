/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MasterGyoshuDto
 */
export interface MasterGyoshuDto {
    /**
     * 業種コード
     * @type {string}
     * @memberof MasterGyoshuDto
     */
    gyoCode?: string;
    /**
     * 業種名
     * @type {string}
     * @memberof MasterGyoshuDto
     */
    name?: string;
}

/**
 * Check if a given object implements the MasterGyoshuDto interface.
 */
export function instanceOfMasterGyoshuDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterGyoshuDtoFromJSON(json: any): MasterGyoshuDto {
    return MasterGyoshuDtoFromJSONTyped(json, false);
}

export function MasterGyoshuDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterGyoshuDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gyoCode': !exists(json, 'gyoCode') ? undefined : json['gyoCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function MasterGyoshuDtoToJSON(value?: MasterGyoshuDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gyoCode': value.gyoCode,
        'name': value.name,
    };
}

