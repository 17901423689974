import { HashLink } from 'react-router-hash-link';
import './style.scss';

type SettingProps = {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconAlt: string;
  description: string;
  to: string;
};
type SsettingCardProps = {
  setting: SettingProps;
  index: number;
};

export const SettingCard = ({ setting, index }: SsettingCardProps) => {
  return (
    <li key={index} className="top_settingCard_base">
      <div className="top_settingCard_title">{setting.title}</div>
      <div className="top_settingCard_imageBase">
        <setting.icon
          aria-label={setting.iconAlt}
          className="top_settingCard_image"
        />
      </div>
      <div className="top_settingCard_description">{setting.description}</div>
      <HashLink className="top_settingCard_button" to={setting.to}>
        設定
      </HashLink>
    </li>
  );
};
