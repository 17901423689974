import { Button, styled } from '@mui/material';
export const CopyButton = styled(Button)(() => ({
  minWidth: '49px',
  border: '1px solid var(--kanri-gray-04, #AEB6D1)',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
  padding: '6px 16px',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fill: '#333333',
      width: 15.3,
      height: 18
    }
  },
  '&.Mui-disabled': {
    background: 'var(--kanri-disabled-button-color)',
    border: 'none',
    boxShadow: 'none',
    '& svg': {
      fill: 'var(--kanri-disabled-button-text-color)'
    }
  }
}));
