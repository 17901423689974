import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

type Rules = RegisterOptions<FormData, 'corporationAbout.establishedYear'>;

/**
 * 設立年のバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useEstablishedYearValidationRules = (): Rules => {
  const propertyName = '設立年';
  const maxDigit = 4;

  return useMemo(
    () => ({
      validate: {
        maxDigit: (value) => {
          if (!value) return undefined;

          return value.toString().length <= maxDigit
            ? undefined
            : `${propertyName}は数字4桁以内で入力してください。`;
        }
      }
    }),
    []
  );
};
