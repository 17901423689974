import { FC } from 'react';
import CorporationNameTextField from '../../../SharedFields/CorporationNameTextField';

/**
 * 表示用企業名の入力フィールドを返す
 * @returns 表示用企業名の入力フィールド
 */
const CorporationNameField: FC = () => (
  <div className="input-content-wrap">
    <div className="input-content-title-wrap">
      <span className="input-content-title">表示用企業名</span>
      <span className="input-content-title-sub">
        採用サイト上に表示する企業名を入力してください。10文字程度を推奨します。
      </span>
    </div>
    <CorporationNameTextField placeholder="ディップ居酒屋" width={400} />
  </div>
);

export default CorporationNameField;
