import { generateTextRule } from './shared';

/**
 * サイト説明文のバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useSiteDescriptionValidationRules = () => {
  const propertyName = 'サイト説明文';
  const maxLength = 200;
  return generateTextRule<'siteDescription'>(propertyName, maxLength, false);
};
