import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useBrandNameValidationRules } from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「ブランド」アコーディオンを返す
 * @returns 「ブランド」アコーディオン
 */
const BrandAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('brand');

  const rules = useBrandNameValidationRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="ブランド"
      isExpanded={isExpanded}
      isError={errors.brandNames && Object.keys(errors.brandNames).length > 0}
      onChange={(value) => onChange?.('brand', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="貴社ブランド名を設定できます。設定すると、サイト説明文などに使用されます。" />
        <TabContentFormWrap>
          {Array(5)
            .fill(0)
            .map((_, i) => (
              <Controller
                key={`brand_name_${i}`}
                name={`brandNames.${i}`}
                control={control}
                rules={{ ...rules, onChange: onChangeValue }}
                render={({
                  field: { value, onBlur, onChange },
                  formState: { errors }
                }) => (
                  <TextField
                    value={value}
                    placeholder="ブランド名"
                    maxLength={20}
                    helperText={
                      errors.brandNames?.[i]?.type !== 'maxLength'
                        ? errors.brandNames?.[i]?.message
                        : ''
                    }
                    hasLengthHelperText
                    isRequired
                    isError={!!errors.brandNames?.[i]}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            ))}
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default BrandAccordion;
