import { StepLabel, styled } from '@mui/material';
export const StyledLabel = styled(StepLabel)(() => ({
  padding: '5px 0',
  '& .MuiStepLabel-label': {
    color: 'var(--kanri-disabled-button-text-color, #777777)',
    '&.Mui-completed': {
      color: 'var(--token-main-color-secondary-text, #595959)'
    },
    '&.Mui-active': {
      color: 'var(--kanri-primary, #3E91D5)',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '130%'
    }
  }
}));
