import { generateTextRule } from './shared';

/**
 * サイトタイトルのバリデーションルールを返す
 * @returns  バリデーションルール
 */
export const useSiteTitleValidationRules = () => {
  const propertyName = 'サイトタイトル';
  const maxLength = 150;
  return generateTextRule<'siteTitle'>(propertyName, maxLength, false);
};
