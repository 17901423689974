import { useMemo } from 'react';
import { getMaxLengthMessage } from '../../messages';

/**
 * helperの中でerrorsXXXXX.content?.type !== 'maxLength'のような判定を行なっているため、
 * そちらとプロパティ名があっていないといけない。
 * なのでリファクタリングの際にプロパティ名を書き換えられないように定数として外出し
 */
const maxLengthRuleName = 'maxLength';
/**
 * 改行コードを除いた最大文字数のバリデーションルールを返す
 * @param {string} propertyName プロパティ名
 * @param {number} maxLength 最大文字数
 * @returns バリデーションルール
 */
export const useMaxLengthWithoutNewLinesRule = (
  propertyName: string,
  maxLength: number
) =>
  useMemo(
    () => ({
      //HACK 引数の方の指定が難しそうなのでanyにしておく
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [maxLengthRuleName]: (value: any) => {
        if (!value) return undefined;

        return String(value).replace(/\n/g, '').length > maxLength
          ? getMaxLengthMessage(propertyName, maxLength)
          : undefined;
      }
    }),
    [propertyName, maxLength]
  );
