import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { getMaxLengthErrorMessage, getRequiredErrorMessage } from './shared';

/**
 * 共同利用の範囲のバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useRangeOfJointUseValidationRules = (): RegisterOptions<
  EnhancedPolicyFormData,
  'rangeOfJointUse'
> => {
  const PropertyName = '共同利用の範囲';
  const MaxLength = 300;

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredErrorMessage(PropertyName, 'input')
      },
      maxLength: {
        value: MaxLength,
        message: getMaxLengthErrorMessage(PropertyName, MaxLength)
      }
    }),
    []
  );
};
