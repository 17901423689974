import * as React from 'react';
import { styled } from '@mui/material/styles';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';

interface CustomListItemIconProps extends ListItemIconProps {
  open: boolean;
  path: string;
  itempath: string;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(ListItemIcon)<CustomListItemIconProps>(
  ({ open, path, itempath }) => ({
    width: open ? '18px' : '50px',
    height: open ? '18px' : '50px',
    marginRight: open ? '8px' : 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: open ? 0 : '56px',
    transform: itempath.includes(path) && !open ? 'translateX(2px)' : 'none'
  })
);
/**
 * メニュー内のユーザー情報項目のボタンベース
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param path 表示されているURL
 * @param itempath 対象のメニューアイテムが持つURL
 * @returns
 */
interface MenuIconBaseProps {
  children: React.ReactNode;
  open: boolean;
  path: string;
  itempath: string;
}
export const MenuIconBase = React.memo(
  ({ children, open, path, itempath }: MenuIconBaseProps) => {
    return (
      <CustomListItemButton open={open} path={path} itempath={itempath}>
        {children}
      </CustomListItemButton>
    );
  }
);
