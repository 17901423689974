import { FC } from 'react';
import { ConfigProvider } from 'context/ConfigContext';
import PageStyleSelector from '../../PageStyleSelector';
import NextButton from './NextButton';
import PrevButton from './PrevButton';

type Props = {
  canGoToNext?: boolean;
  onClickPrev?: () => void;
  onClickNext?: () => void;
};

/**
 * 初期設定モーダルのSTEP2コンテンツを返す
 * @param {boolean | undefined} canGoToNext 次ステップへ進むことができるか
 * @param {Function | undefined} onClickPrev 戻るボタンをクリックされた際に実行される関数
 * @param {Function | undefined} onClickNext 進むボタンをクリックされた際に実行される関数
 * @returns 初期設定モーダルのSTEP2コンテンツ
 */
const DesignStepContent: FC<Props> = ({
  canGoToNext = false,
  onClickPrev,
  onClickNext
}) => {
  return (
    <>
      <div className="dialog-content-wrap design">
        <div className="design-wrap">
          <ConfigProvider>
            <PageStyleSelector />
          </ConfigProvider>
        </div>
      </div>
      <div className="button-wrap justify-between">
        <span data-ga-selector="InitialSettingDIalogBackBtn">
          <PrevButton onClick={onClickPrev} />
        </span>
        <span data-ga-selector="InitialDIalogFinishBtn">
          <NextButton
            label="入力した内容で作成"
            isDisabled={!canGoToNext}
            isHoverDisabled={!canGoToNext}
            onClick={onClickNext}
          />
        </span>
      </div>
    </>
  );
};

export default DesignStepContent;
