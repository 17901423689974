import { FC } from 'react';
import OptionalLabel from '../OptionalLabel';

type Props = {
  label: string;
  isRequired?: boolean;
};

/**
 * テキストフィールドのラベルを返す
 * @param {string} label ラベル文言
 * @param {boolean | undefined} isRequired 必須項目であるか
 * @returns テキストフィールドのラベル
 */
const TextFieldLabel: FC<Props> = ({ label, isRequired = false }) => {
  return (
    <div style={{ display: 'inline-flex', gap: '10px' }}>
      {label}
      {label && !isRequired && <OptionalLabel isRequired={true} />}
    </div>
  );
};

export default TextFieldLabel;
