/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolicySettingReferenceResDto
 */
export interface PolicySettingReferenceResDto {
    /**
     * テンプレート(0) or 自由(1) 記述
     * @type {number}
     * @memberof PolicySettingReferenceResDto
     */
    policyType?: PolicySettingReferenceResDtoPolicyTypeEnum;
    /**
     * 所属名・役職名
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    affiliationName?: string;
    /**
     * (利用規約用)企業名
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    corporationName?: string;
    /**
     * 個人情報保護管理者名
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    personalInformationProtectionManagerName?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    tel?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    email?: string;
    /**
     * URL
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    url?: string;
    /**
     * 備考
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    memo?: string;
    /**
     * 共同利用するかどうか
     * @type {number}
     * @memberof PolicySettingReferenceResDto
     */
    isJointUse?: PolicySettingReferenceResDtoIsJointUseEnum;
    /**
     * 共同利用の範囲
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    rangeOfJointUse?: string;
    /**
     * 個人情報の管理責任を有するもの
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    personalInformationProtectorType?: PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum;
    /**
     * 個人情報の管理について責任を有するもの(個人名)
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    personalInformationProtectorName?: string;
    /**
     * 「本人開示要求など」の連絡先が同じかどうか
     * @type {number}
     * @memberof PolicySettingReferenceResDto
     */
    isSameContact?: PolicySettingReferenceResDtoIsSameContactEnum;
    /**
     * 「本人開示要求など」の連絡先＞電話番号
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    contactTel?: string;
    /**
     * 「本人開示要求など」の連絡先＞メールアドレス
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    contactEmail?: string;
    /**
     * 「本人開示要求など」の連絡先＞URL
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    contactUrl?: string;
    /**
     * 「本人開示要求など」の連絡先＞備考
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    contactMemo?: string;
    /**
     * 自由記述
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    freeText?: string;
    /**
     * 最終更新時刻
     * @type {string}
     * @memberof PolicySettingReferenceResDto
     */
    updatedAt?: string;
}


/**
 * @export
 */
export const PolicySettingReferenceResDtoPolicyTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;
export type PolicySettingReferenceResDtoPolicyTypeEnum = typeof PolicySettingReferenceResDtoPolicyTypeEnum[keyof typeof PolicySettingReferenceResDtoPolicyTypeEnum];

/**
 * @export
 */
export const PolicySettingReferenceResDtoIsJointUseEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type PolicySettingReferenceResDtoIsJointUseEnum = typeof PolicySettingReferenceResDtoIsJointUseEnum[keyof typeof PolicySettingReferenceResDtoIsJointUseEnum];

/**
 * @export
 */
export const PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum = {
    ContractedCorp: 'contractedCorp',
    JoitUseCorp: 'joitUseCorp',
    Other: 'other'
} as const;
export type PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum = typeof PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum[keyof typeof PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum];

/**
 * @export
 */
export const PolicySettingReferenceResDtoIsSameContactEnum = {
    NUMBER_1: 1,
    NUMBER_0: 0
} as const;
export type PolicySettingReferenceResDtoIsSameContactEnum = typeof PolicySettingReferenceResDtoIsSameContactEnum[keyof typeof PolicySettingReferenceResDtoIsSameContactEnum];


/**
 * Check if a given object implements the PolicySettingReferenceResDto interface.
 */
export function instanceOfPolicySettingReferenceResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicySettingReferenceResDtoFromJSON(json: any): PolicySettingReferenceResDto {
    return PolicySettingReferenceResDtoFromJSONTyped(json, false);
}

export function PolicySettingReferenceResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicySettingReferenceResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policyType': !exists(json, 'policyType') ? undefined : json['policyType'],
        'affiliationName': !exists(json, 'affiliationName') ? undefined : json['affiliationName'],
        'corporationName': !exists(json, 'corporationName') ? undefined : json['corporationName'],
        'personalInformationProtectionManagerName': !exists(json, 'personalInformationProtectionManagerName') ? undefined : json['personalInformationProtectionManagerName'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'isJointUse': !exists(json, 'isJointUse') ? undefined : json['isJointUse'],
        'rangeOfJointUse': !exists(json, 'rangeOfJointUse') ? undefined : json['rangeOfJointUse'],
        'personalInformationProtectorType': !exists(json, 'personalInformationProtectorType') ? undefined : json['personalInformationProtectorType'],
        'personalInformationProtectorName': !exists(json, 'personalInformationProtectorName') ? undefined : json['personalInformationProtectorName'],
        'isSameContact': !exists(json, 'isSameContact') ? undefined : json['isSameContact'],
        'contactTel': !exists(json, 'contactTel') ? undefined : json['contactTel'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactUrl': !exists(json, 'contactUrl') ? undefined : json['contactUrl'],
        'contactMemo': !exists(json, 'contactMemo') ? undefined : json['contactMemo'],
        'freeText': !exists(json, 'freeText') ? undefined : json['freeText'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
    };
}

export function PolicySettingReferenceResDtoToJSON(value?: PolicySettingReferenceResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policyType': value.policyType,
        'affiliationName': value.affiliationName,
        'corporationName': value.corporationName,
        'personalInformationProtectionManagerName': value.personalInformationProtectionManagerName,
        'tel': value.tel,
        'email': value.email,
        'url': value.url,
        'memo': value.memo,
        'isJointUse': value.isJointUse,
        'rangeOfJointUse': value.rangeOfJointUse,
        'personalInformationProtectorType': value.personalInformationProtectorType,
        'personalInformationProtectorName': value.personalInformationProtectorName,
        'isSameContact': value.isSameContact,
        'contactTel': value.contactTel,
        'contactEmail': value.contactEmail,
        'contactUrl': value.contactUrl,
        'contactMemo': value.contactMemo,
        'freeText': value.freeText,
        'updatedAt': value.updatedAt,
    };
}

