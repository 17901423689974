import { useCallback } from 'react';

/**
 * 画像アップロード時のバリデーション関数を返す
 * @returns 画像アップロード時のバリデーション関数
 */
const useImageValidation = (): ((image?: File) => boolean) => {
  const validateFileExtension = useCallback(
    (image?: File) =>
      !image || !!image.name.toLowerCase().match(/^.+\.(jpg|jpeg|png|gif)$/),
    []
  );

  const validateFileSize = useCallback((image?: File) => {
    const maxFileSize = 50 * 1000 * 1000; // 50MB
    return !image || image.size <= maxFileSize;
  }, []);

  const validate = useCallback(
    (image?: File) => validateFileExtension(image) && validateFileSize(image),
    [validateFileExtension, validateFileSize]
  );

  return validate;
};

export default useImageValidation;
