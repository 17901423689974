/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MasterCityDto } from './MasterCityDto';
import {
    MasterCityDtoFromJSON,
    MasterCityDtoFromJSONTyped,
    MasterCityDtoToJSON,
} from './MasterCityDto';

/**
 * 
 * @export
 * @interface MasterTodofukenWithCitiesDto
 */
export interface MasterTodofukenWithCitiesDto {
    /**
     * 都道府県コード
     * @type {string}
     * @memberof MasterTodofukenWithCitiesDto
     */
    tdfkCode?: string;
    /**
     * 都道府県名
     * @type {string}
     * @memberof MasterTodofukenWithCitiesDto
     */
    tdfkName?: string;
    /**
     * 市区町村マスタ
     * @type {Array<MasterCityDto>}
     * @memberof MasterTodofukenWithCitiesDto
     */
    cities?: Array<MasterCityDto>;
}

/**
 * Check if a given object implements the MasterTodofukenWithCitiesDto interface.
 */
export function instanceOfMasterTodofukenWithCitiesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterTodofukenWithCitiesDtoFromJSON(json: any): MasterTodofukenWithCitiesDto {
    return MasterTodofukenWithCitiesDtoFromJSONTyped(json, false);
}

export function MasterTodofukenWithCitiesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterTodofukenWithCitiesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tdfkCode': !exists(json, 'tdfkCode') ? undefined : json['tdfkCode'],
        'tdfkName': !exists(json, 'tdfkName') ? undefined : json['tdfkName'],
        'cities': !exists(json, 'cities') ? undefined : ((json['cities'] as Array<any>).map(MasterCityDtoFromJSON)),
    };
}

export function MasterTodofukenWithCitiesDtoToJSON(value?: MasterTodofukenWithCitiesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tdfkCode': value.tdfkCode,
        'tdfkName': value.tdfkName,
        'cities': value.cities === undefined ? undefined : ((value.cities as Array<any>).map(MasterCityDtoToJSON)),
    };
}

