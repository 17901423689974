import * as React from 'react';
import { styled } from '@mui/material/styles';
import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';

interface MenuTitleProps extends ListItemTextProps {
  open?: boolean;
  transitionduration: string;
}
/**
 * muiのAppBarを上書き
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 */
const MenuTitle = styled(ListItemText)<MenuTitleProps>(
  ({ open, transitionduration }) => ({
    opacity: open ? 1 : 0,
    transitionDuration: transitionduration,
    '& .MuiTypography-root': {
      // text setting
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '130%'
    }
  })
);
/**
 * ヘッダー部分
 * @param open ドロワーの開閉判定
 * @param primary テキストの文言
 * @param drawerwidth ドロワーを開いた時の横幅
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @returns
 */
interface CustomListItemTextProps {
  open: boolean;
  primary: string;
  transitionduration: string;
}
export const CustomListItemText = React.memo(
  ({ open, primary, transitionduration }: CustomListItemTextProps) => {
    return (
      <MenuTitle
        primary={primary}
        open={open}
        transitionduration={transitionduration}
      />
    );
  }
);
