import { RefObject, createContext } from 'react';
import { SnackbarHandler } from 'components/parts/SnackBarWrapper';

type SnackbarProps = {
  snackbar: RefObject<SnackbarHandler> | null;
};

/**
 * 共通スナックバーのコンテキスト
 */
const SnackbarContext = createContext<SnackbarProps>({
  snackbar: null
});

export default SnackbarContext;
