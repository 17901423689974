/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MasterTok2Dto } from './MasterTok2Dto';
import {
    MasterTok2DtoFromJSON,
    MasterTok2DtoFromJSONTyped,
    MasterTok2DtoToJSON,
} from './MasterTok2Dto';

/**
 * 
 * @export
 * @interface MasterTok1Dto
 */
export interface MasterTok1Dto {
    /**
     * 大特徴タイプ
     * @type {string}
     * @memberof MasterTok1Dto
     */
    tok1Type?: string;
    /**
     * 大特徴名
     * @type {string}
     * @memberof MasterTok1Dto
     */
    name?: string;
    /**
     * 中特徴マスタ
     * @type {Array<MasterTok2Dto>}
     * @memberof MasterTok1Dto
     */
    tok2?: Array<MasterTok2Dto>;
}

/**
 * Check if a given object implements the MasterTok1Dto interface.
 */
export function instanceOfMasterTok1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterTok1DtoFromJSON(json: any): MasterTok1Dto {
    return MasterTok1DtoFromJSONTyped(json, false);
}

export function MasterTok1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterTok1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tok1Type': !exists(json, 'tok1Type') ? undefined : json['tok1Type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tok2': !exists(json, 'tok2') ? undefined : ((json['tok2'] as Array<any>).map(MasterTok2DtoFromJSON)),
    };
}

export function MasterTok1DtoToJSON(value?: MasterTok1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tok1Type': value.tok1Type,
        'name': value.name,
        'tok2': value.tok2 === undefined ? undefined : ((value.tok2 as Array<any>).map(MasterTok2DtoToJSON)),
    };
}

