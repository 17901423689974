import { AppBar, Typography } from '@mui/material';
import { FC, memo } from 'react';

export const TopPageHeader: FC = memo(() => {
  // トップページで用いるデータ取得APIを呼び出す実装を追加

  return (
    <AppBar
      position="static"
      sx={{
        py: '15px',
        px: '20px',
        backgroundColor: '#fff',
        boxShadow: 'unset'
      }}
    >
      <Typography
        sx={{
          color: 'var(--kanri-secondary-text, #595959)',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '100%'
        }}
      >
        トップページ
      </Typography>
    </AppBar>
  );
});
