import { FC, MouseEventHandler, useState } from 'react';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  styled
} from '@mui/material';
import { PresetImageData } from 'types/saiyouPageEditing/PresetImageData';

type Props = {
  image: PresetImageData;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * プリセット画像のラジオボタンを返す
 * @param {PresetImageData} image 画像データ
 * @param {boolean | undefined} isSelected 選択されているか
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick クリックされた際に実行される関数
 * @returns プリセット画像のラジオボタン
 */
const ImageRadio: FC<Props> = ({ image, isSelected = false, onClick }) => {
  const [isLoading, setLoading] = useState(true);

  return (
    <div className={'icon-wrap' + (isSelected ? ' checked' : '')}>
      <StyledFormControllLabel
        label={
          <>
            {isLoading && (
              <div style={{ width: 170, height: 100, textAlign: 'center' }}>
                <CircularProgress style={{ color: '#1976d2' }} />
              </div>
            )}
            <img
              src={image.urlWithoutCredential}
              style={{ display: isLoading ? 'none' : 'block' }}
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
            />
            {!!image.filename && <span>{image.filename}</span>}
          </>
        }
        control={
          <Radio className="Radio" checked={isSelected} onClick={onClick} />
        }
      />
    </div>
  );
};

export default ImageRadio;

const StyledFormControllLabel = styled(FormControlLabel)(() => ({
  flexDirection: 'column',
  margin: 0,
  alignItems: 'flex-start',
  padding: '10px',
  '& .MuiRadio-root': {
    marginBottom: '5px',
    paddingBottom: '0',
    color: 'var(--kanri-secondary-text, #595959)',
    '.Mui-checked': {
      color: 'var(--kanri-secondary-text, #595959)'
    }
  },
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)'
}));
