import {
  CustomButton,
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogContentText
} from './parts';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

type BaseProps = {
  message?: string;
  onClickOk?: () => void;
};

type Props = {
  isOpened?: boolean;
} & BaseProps;

/**
 * 警告ダイアログのハンドラ
 */
export type CommonAlertDialogHandler = {
  open: () => void;
};

function CommonAlertDialog({
  message = '',
  isOpened = false,
  onClickOk
}: Props) {
  return (
    <CustomDialog
      open={isOpened}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CustomDialogContent>
        <CustomDialogContentText id="alert-dialog-description">
          {message}
        </CustomDialogContentText>
        <CustomDialogActions>
          <CustomButton autoFocus onClick={onClickOk}>
            閉じる
          </CustomButton>
        </CustomDialogActions>
      </CustomDialogContent>
    </CustomDialog>
  );
}

/**
 * 警告ダイアログをレンダリングする
 * @param {Ref<CommonAlertDialogHandler> | undefined} props.ref
 * @param {string | undefined} props.message 警告メッセージ
 * @param {Function | undefined} props.onClickOk 「はい」がクリックされた際に実行される関数
 * @returns {ForwardRefExoticComponent<BaseProps & RefAttributes<CommonAlertDialogHandler>>} 警告ダイアログ
 */
export const CommonAlertDialogWithRef = forwardRef<
  CommonAlertDialogHandler,
  BaseProps
>(({ message, onClickOk }, ref) => {
  const [isOpened, setOpened] = useState(false);

  const handleClickOk = useCallback(() => {
    setOpened(false);
    onClickOk?.();
  }, [onClickOk]);

  useImperativeHandle(ref, () => ({
    open: () => setOpened(true)
  }));

  return (
    <CommonAlertDialog
      message={message}
      isOpened={isOpened}
      onClickOk={handleClickOk}
    />
  );
});
