import { FormControlLabel, styled } from '@mui/material';

const StyledRadioLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    margin: 0,
    opacity: 1,
    transition: '0.5s',
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s'
    },
    '& .Mui-checked': {
      '& .radioChoiceButton': {
        background: 'var(--kanri-secondary-text, #595959)'
      }
    }
  },

  '& .radioChoiceButton': {
    margin: '5px 5px 0',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    display: 'flex',
    padding: '5px 0',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: 15
  },
  '& .checkCircleIcon': {
    width: 15,
    height: 15,
    marginRight: 4,
    borderRadius: 15
  },
  '& .MuiFormControlLabel-label': {
    lineHeight: 1
  },
  '& .MuiButtonBase-root': {
    color: 'var(--kanri-secondary-text, #595959)'
  }
});

export default StyledRadioLabel;
