import { CSSProperties, FC } from 'react';
import { CheckIcon } from 'assets/icons';
import RadioLabel from './RadioLabel';
import StyledRadio from './StyledRadio';
import StyledRadioLabel from './StyledRadioLabel';

type Props = {
  label: string;
  value: string;
  color: string;
  isSelected?: boolean;
  style?: CSSProperties;
};

/**
 * カラー設定用ラジオボタンを返す
 * @param {string} label ラベル文言
 * @param {string} value 選択値
 * @param {string} color 色の変数名 (./src/assets/styles/style.scssを参照)
 * @param {boolean | undefined} isSelected 選択されているか
 * @param {CSSProperties | undefined} style 追加のCSSプロパティ
 * @returns カラー設定用ラジオボタン
 */
const ColorRadio: FC<Props> = ({
  label,
  value,
  color,
  isSelected = false,
  style
}) => {
  return (
    <StyledRadioLabel
      value={value}
      control={<StyledRadio style={style} />}
      style={{
        width: '100%',
        display: 'block',
        lineHeight: 1
      }}
      label={
        <>
          <div
            className="radio-button-color-area"
            style={{
              width: '100%',
              height: 50,
              marginTop: -15,
              borderRadius: 6,
              background: `var(${color})`,
              boxShadow: '1px 1px 1px 0 rgba(0, 0, 0, 0.15)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CheckIcon
              style={{
                width: 13,
                height: 10,
                display: 'none',
                ...(isSelected && { fill: 'white' })
              }}
            />
          </div>
          <RadioLabel
            label={label}
            isSelected={isSelected}
            style={{ width: '100%', marginRight: 0, marginLeft: 0 }}
          />
        </>
      }
    />
  );
};

export default ColorRadio;
