import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import MasterDataContext from 'context/saiyouPageEditing/MasterDataContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useEmploymentStatusValidationRules } from 'validation/rules/saiyouPageEditing';
import GroupingSelectBox from '../../common/SelectBox/GroupingSelectBox';

type Props = {
  label?: string;
  placeholder?: string;
  width?: number;
  style?: React.CSSProperties;
};

/**
 * ターゲット(雇用形態)のセレクトボックスを返す
 * @param {LabelAndValue<EmploymentStatus>[] | undefined} employmentStatues 雇用形態の一覧
 * @param {string | undefined} label ラベル文言
 * @param {string | undefined} placeholder プレースホルダー
 * @param {number | undefined} width フィールド幅
 * @returns ターゲット(雇用形態)のセレクトボックス
 */
const EmploymentStatusSelectBox: FC<Props> = ({
  label = '',
  placeholder = '',
  width,
  style
}) => {
  const { control } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);
  const { employmentStatuses } = useContext(MasterDataContext);

  const rules = useEmploymentStatusValidationRules();

  return (
    <Controller
      name="employmentStatus"
      control={control}
      rules={{ ...rules, onChange: onChangeValue }}
      render={({
        field: { value, onBlur, onChange },
        formState: { errors }
      }) => (
        <GroupingSelectBox
          label={label}
          values={value}
          placeholder={placeholder}
          options={employmentStatuses}
          helperText={errors.employmentStatus?.message}
          width={width}
          isRequired
          isError={!!errors.employmentStatus}
          onBlur={onBlur}
          onChange={(items) => onChange(items.length > 0 ? items[0] : null)}
          style={style}
        />
      )}
    />
  );
};

export default EmploymentStatusSelectBox;
