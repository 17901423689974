import { FC } from 'react';

/**
 * 「必須」ラベルを返す
 * @returns 「必須」ラベル
 */
const RequiredLabel: FC = () => (
  <span
    style={{
      fontSize: 11,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: -0.33,
      display: 'inline-block',
      padding: 4,
      alignItems: 'center',
      gap: 4,
      borderRadius: 4,
      border: '1px solid #595959',
      background: '#FFF'
    }}
  >
    必須
  </span>
);

export default RequiredLabel;
