import { FC, PropsWithChildren, RefObject } from 'react';
import { SnackbarHandler } from 'components/parts/SnackBarWrapper';
import SnackbarContext from 'context/saiyouPageEditing/SnackbarContext';

type Props = {
  snackbar: RefObject<SnackbarHandler> | null;
} & PropsWithChildren;

/**
 * 共通スナックバーのコンテキストプロバイダを返す
 * @param {ReactNode | ReactNode[] | undefined} children 子コンポーネント
 * @param {RefObject<SnackbarHandler> | null} snackbar スナックバー
 * @returns コンテキストプロバイダ
 */
const SnackbarProvider: FC<Props> = ({ children, snackbar }) => {
  return (
    <SnackbarContext.Provider value={{ snackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
