import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { styled } from '@mui/material';

export const CustomDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '600px',
    minHeight: '190px'
  }
}));
export const CustomButton = styled(Button)(() => ({
  width: '200px',
  height: '44px',
  borderRadius: '8px',
  border: '1px solid var(--kanri-gray-02, #CCC)',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '100%',
  color: 'var(--kanri-secondary-text, #595959)',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgb(0,0,0,0.04)'
  }
}));
export const CustomDialogContent = styled(DialogContent)(() => ({
  width: '100%',
  padding: '40px'
}));
export const CustomDialogContentText = styled(DialogContentText)(() => ({
  marginBottom: '40px',
  fontSize: '16px',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#595959',
  lineHeight: '160%'
}));
export const CustomDialogActions = styled(DialogActions)(() => ({
  justifyContent: 'center',
  padding: 0
}));
