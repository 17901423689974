import { FC } from 'react';
import { styled } from '@mui/material';
import ImageDropzone from './ImageDropzone';
import ImageBrowseField from './ImageBrowseField';

type Props = {
  uploadedImage?: File;
  isError?: boolean;
  onUploadImage?: (image?: File) => void;
};

/**
 * 「画像をアップロードする」タブを返す
 * @param {File | undefined} uploadImage アップロードされた画像
 * @param {boolean | undefined} isError エラーが発生しているか
 * @param {Function | undefined} onUpload 画像がアップロードされた際に実行される関数
 * @returns 「画像をアップロードする」タブ
 */
const UploadImageTab: FC<Props> = ({
  uploadedImage,
  isError = false,
  onUploadImage
}) => (
  <div className="image-upload">
    <ImageDropzone onDropImage={onUploadImage} />
    <ImageSelectWrap className="image-select-wrap">
      <ImageSelectInner className="image-select-inner">
        <ImageSelectText className="sp-br">もしくは、</ImageSelectText>
        <ImageBrowseField
          image={uploadedImage}
          isError={isError}
          onChange={onUploadImage}
        />
      </ImageSelectInner>
      <ImageSelectUl>
        <ImageSelectLi>※画像は50MBまでアップロード可能です。</ImageSelectLi>
        <ImageSelectLi>
          ※.jpg、.jpeg、.png、.gif（ただしアニメーションGIF画像は不可）のファイルが可能です。
        </ImageSelectLi>
      </ImageSelectUl>
    </ImageSelectWrap>
  </div>
);

export default UploadImageTab;

const ImageSelectWrap = styled('div')(() => ({
  display: 'block',
  width: 600,
  margin: '10px auto 0 auto',
  rowGap: 5
}));

const ImageSelectInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  marginBottom: 5,
  alignSelf: 'stretch'
}));

const ImageSelectText = styled('p')(() => ({
  color: 'var(--kanri-primary-text, #333)',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '100%',
  whiteSpace: 'nowrap'
}));

const ImageSelectUl = styled('ul')(() => ({
  paddingLeft: 85
}));

const ImageSelectLi = styled('li')(() => ({
  color: 'var(--kanri-primary-text, #333)',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '130%'
}));
