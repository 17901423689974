import React from 'react';
import {
  CustomTabs,
  CustomTab
} from 'components/parts/topHeaderMenu/parts/EditAppBar/CustomTabs';
import { styled } from '@mui/material/styles';

const CustomTabsStyled = styled(CustomTabs)({
  marginRight: 0,
  height: 32,
  minHeight: 32,
  padding: 0
});

const CustomTabStyled = styled(CustomTab)({
  padding: '8px 0',
  '&.MuiButtonBase-root': {
    height: 32,
    minHeight: 32,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    color: 'var(--kanri-primary-text, #333)',
    ':first-of-type': {
      borderRight: 'none'
    },
    ':last-of-type': {
      borderLeft: 'none'
    }
  },
  '&.Mui-selected': {
    fontWeight: 700
  }
});

/**
 * `DisplaySwitchTabs`コンポーネントのプロップタイプを定義します。
 *
 * @typedef {Object} DisplaySwitchTabsProps
 * @property {boolean | undefined} isVisible 現在選択されているタブのインデックスを示す値です
 * @property {function(event: React.ChangeEvent<unknown>, newValue: number): void} onChange - タブが切り替わった時に呼び出されるコールバック関数です
 * @property {boolean} [isHidden] - タブが非表示の場合はtrue、表示の場合はfalseを指定します
 */
type Props = {
  isVisible?: boolean;
  onChange?: (value: boolean) => void;
};

/**
 * `DisplaySwitchTabs`コンポーネントは、表示と非表示を切り替えるためのタブを提供します。
 * @param {boolean | undefined} isVisible 「表示」が選択されているか
 * @param {Function | undefined} onChange 値が変更された際に実行される関数
 * @returns スタイリングされたタブコンポーネントを表示するJSX要素
 */
const DisplaySwitchTabs: React.FC<Props> = ({
  isVisible = false,
  onChange
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 10
    }}
  >
    <p
      style={{
        color: 'var(--kanri-secondary-text, #595959)',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%'
      }}
    >
      表示・非表示の選択
    </p>
    <CustomTabsStyled
      value={isVisible ? 0 : 1}
      onChange={(_, value) => onChange?.(value === 0)}
    >
      <CustomTabStyled label="表示" />
      <CustomTabStyled label="非表示" />
    </CustomTabsStyled>
  </div>
);

export default DisplaySwitchTabs;
