import { AccordionDetails, styled } from '@mui/material';

/**
 * スタイリングされたアコーディオン詳細コンポーネント。
 * @type {StyledComponent}
 */
const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '10px 0 20px'
});

export default StyledAccordionDetails;
