import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import ImageUploadEntry from '../../../ImageUploader/ImageUploadEntry';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「企業ロゴ」アコーディオンを返す
 * @returns 「企業ロゴ」アコーディオン
 */
const CorporationLogoAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('corpLogo');

  return (
    <ContentAcc
      ref={accordionRef}
      title="企業ロゴ"
      isExpanded={isExpanded}
      isError={!!errors.logoImagePath}
      onChange={(value) => onChange?.('corpLogo', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="貴社のロゴ画像を設定してください。採用サイトや求人の上部に表示されます。" />
        <TabContentFormWrap>
          <Controller
            name="logoImagePath"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onChange } }) => (
              <ImageUploadEntry
                uploadedImagePath={value}
                resizeType="icon"
                onUploadImage={onChange}
                onClearImage={() => onChange()}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default CorporationLogoAccordion;
