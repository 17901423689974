import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useCorporationNameValidationRules } from 'validation/rules/saiyouPageEditing';
import TextField from '../../common/TextField';

type Props = {
  placeholder?: string;
  width?: number;
};

/**
 * 表示用企業名のテキストフィールドを返す
 * @param {string | undefined} placeholder プレースホルダー
 * @param {number | undefined} width フィールド幅
 * @returns 表示用企業名のテキストフィールド
 */
const CorporationNameTextField: FC<Props> = ({ placeholder = '', width }) => {
  const { control } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const rules = useCorporationNameValidationRules();

  return (
    <Controller
      name="corporationName"
      control={control}
      rules={{ ...rules, onChange: onChangeValue }}
      render={({
        field: { value, onBlur, onChange },
        formState: { errors }
      }) => (
        <TextField
          value={value}
          placeholder={placeholder}
          width={width}
          maxLength={100}
          helperText={
            errors.corporationName?.type !== 'maxLength'
              ? errors.corporationName?.message
              : ''
          }
          hasLengthHelperText
          isRequired
          isError={!!errors.corporationName}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    />
  );
};

export default CorporationNameTextField;
