/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MeResDto,
} from '../models';
import {
    MeResDtoFromJSON,
    MeResDtoToJSON,
} from '../models';

export interface GetMeRequest {
    compMgrNo: number;
}

/**
 * 
 */
export class MeApi extends runtime.BaseAPI {

    /**
     *        DBから該当Compの最終契約プラン情報を取得       共通認可ロジックの際に取得したログインユーザー情報と上記をマージしたものをレスポンスとして返す       
     * ログインユーザー・ログインComp取得API
     */
    async getMeRaw(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/me`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeResDtoFromJSON(jsonValue));
    }

    /**
     *        DBから該当Compの最終契約プラン情報を取得       共通認可ロジックの際に取得したログインユーザー情報と上記をマージしたものをレスポンスとして返す       
     * ログインユーザー・ログインComp取得API
     */
    async getMe(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeResDto> {
        const response = await this.getMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
