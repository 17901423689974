import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useStaffInterviewProfileValidationRules,
  useStaffInterviewTextValidationRules,
  useStaffInterviewYouTubeURLValidationRules
} from 'validation/rules/saiyouPageEditing';
import TextField from '../../../../../common/TextField';
import ImageUploadEntry from '../../../../ImageUploader/ImageUploadEntry';
import FormDivider from '../../shared/FormDivider';
import ReorderBlockHeader from '../../shared/ReorderBlockHeader';
import TabContentFormWrap from '../../shared/TabContentFormWrap';

type Props = {
  index: number;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 「スタッフインタビュー」の並び替え可能ブロックを返す
 * @param {number} index 表示順
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 「スタッフインタビュー」の並び替え可能ブロックを返す
 */
const StaffInterviewReorderBlock: FC<Props> = ({
  index,
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  const { control, watch } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const profileRules = useStaffInterviewProfileValidationRules();
  const textRules = useStaffInterviewTextValidationRules();
  const youtubeUrlRules = useStaffInterviewYouTubeURLValidationRules();

  const profile = watch(`staffInterview.interviews.${index}.profile`);
  const text = watch(`staffInterview.interviews.${index}.text`);
  const imagePath = watch(`staffInterview.interviews.${index}.imagePath`);
  const youtubeUrl = watch(`staffInterview.interviews.${index}.youtubeUrl`);

  return (
    <TabContentFormWrap>
      <ReorderBlockHeader
        title={`インタビュー${index + 1}`}
        canSwapUp={canSwapUp}
        canSwapDown={canSwapDown}
        canRemove={canRemove}
        onSwapUp={onSwapUp}
        onSwapDown={onSwapDown}
        onRemove={onRemove}
      />
      <Controller
        name={`staffInterview.interviews.${index}.profile`}
        control={control}
        rules={{ ...profileRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="プロフィール情報"
            value={profile}
            placeholder="採用コンサルタント 田中太郎さん"
            maxLength={20}
            helperText={
              errors.staffInterview?.interviews?.[index]?.profile?.type !==
              'maxLength'
                ? errors.staffInterview?.interviews?.[index]?.profile?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.staffInterview?.interviews?.[index]?.profile}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`staffInterview.interviews.${index}.text`}
        control={control}
        rules={{ ...textRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="インタビュー本文"
            value={text}
            placeholder="入社前の会社説明会で先輩社員が言っていたように、この仕事はがんばったからといっていつも成果に結びつくとは限りません。しかし、働く人やお客様のために貢献できた成功体験を一度経験すれば、その喜びが大きいため、成果が出ないときも前向きに仕事を続けられます。後輩たちに早く大きな成功体験を得てもらえるように、私にできるサポートを行っていきたいと考えています。"
            maxLength={500}
            rows={8}
            helperText={
              errors.staffInterview?.interviews?.[index]?.text?.type !==
              'maxLength'
                ? errors.staffInterview?.interviews?.[index]?.text?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.staffInterview?.interviews?.[index]?.text}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`staffInterview.interviews.${index}.imagePath`}
        control={control}
        rules={{ onChange: onChangeValue }}
        render={({ field: { onChange } }) => (
          <ImageUploadEntry
            uploadedImagePath={imagePath}
            onUploadImage={onChange}
            onClearImage={() => onChange()}
          />
        )}
      />
      <Controller
        name={`staffInterview.interviews.${index}.youtubeUrl`}
        control={control}
        rules={{ ...youtubeUrlRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="YouTube 動画URL"
            value={youtubeUrl}
            placeholder="https://www.youtube.com/xxxxxxxx/"
            helperText={
              errors.staffInterview?.interviews?.[index]?.youtubeUrl?.type !==
              'maxLength'
                ? errors.staffInterview?.interviews?.[index]?.youtubeUrl
                    ?.message
                : ''
            }
            isError={!!errors.staffInterview?.interviews?.[index]?.youtubeUrl}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <FormDivider />
    </TabContentFormWrap>
  );
};

export default StaffInterviewReorderBlock;
