import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { createContext } from 'react';

export type EditSaiyouPageProps = {
  /**
   * 今回初期設定モーダルが開かれたかどうか
   */
  hasDisplayedInitModal: boolean;

  /**
   * ロード中かどうか
   */
  isLoading: boolean;

  /**
   * フォーム値が変更された際に実行される関数
   */
  onChangeValue?: () => void;

  /**
   * 一時保存ボタンからの一時保存がされたか
   */
  hasSavedBySaveButton: boolean;

  /**
   * 規約設定済みかどうか
   */
  hasPolicy?: boolean;

  /**
   * 公開可能かどうか
   */
  canPublish: boolean;

  /**
   * ページ設定
   */
  pageSetting: PageSettingAdminReferenceResDto | undefined;

  /**
   * 一時保存ボタンがクリックされた際に実行される関数
   */
  onClickSave?: () => void;

  /**
   * 公開ボタンがクリックされた際に実行される関数
   */
  onClickPublish?: () => void;

  compId: string | undefined;

  /**
   * 利用規約設定画面に遷移するための関数
   */
  navigateToPolicyPage?: () => void;
};

/**
 * コンテンツ編集画面のコンテキスト
 */
const EditSaiyouPageContext = createContext<EditSaiyouPageProps>({
  hasDisplayedInitModal: false,
  isLoading: false,
  onChangeValue: undefined,
  hasSavedBySaveButton: false,
  hasPolicy: true,
  pageSetting: undefined,
  onClickSave: undefined,
  onClickPublish: undefined,
  navigateToPolicyPage: undefined,
  canPublish: false,
  compId: ''
});

export default EditSaiyouPageContext;
