/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PolicySettingRegisterReqDto,
} from '../models';
import {
    PolicySettingRegisterReqDtoFromJSON,
    PolicySettingRegisterReqDtoToJSON,
} from '../models';

export interface RegisterPolicySettingRequest {
    compMgrNo: number;
    policySettingRegisterReqDto: PolicySettingRegisterReqDto;
}

/**
 * 
 */
export class PolicySettingManagementApi extends runtime.BaseAPI {

    /**
     * 利用規約を登録する      「表示・非表示」系の項目が「非表示」の場合になっている場合、かつリクエストパラメータが入ればバリデーション・DB登録は行う     テキストエリアで入力する文字列に関して特記事項がない限りは改行文字を許容しない     リクエストパラメータlastUpdatedAtをもとに排他ロックチェックを行う。     CloudFrontの本番環境のキャッシュクリアも行う    
     * ユーザー利用規約登録API
     */
    async registerPolicySettingRaw(requestParameters: RegisterPolicySettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling registerPolicySetting.');
        }

        if (requestParameters.policySettingRegisterReqDto === null || requestParameters.policySettingRegisterReqDto === undefined) {
            throw new runtime.RequiredError('policySettingRegisterReqDto','Required parameter requestParameters.policySettingRegisterReqDto was null or undefined when calling registerPolicySetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/policy-setting`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PolicySettingRegisterReqDtoToJSON(requestParameters.policySettingRegisterReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 利用規約を登録する      「表示・非表示」系の項目が「非表示」の場合になっている場合、かつリクエストパラメータが入ればバリデーション・DB登録は行う     テキストエリアで入力する文字列に関して特記事項がない限りは改行文字を許容しない     リクエストパラメータlastUpdatedAtをもとに排他ロックチェックを行う。     CloudFrontの本番環境のキャッシュクリアも行う    
     * ユーザー利用規約登録API
     */
    async registerPolicySetting(requestParameters: RegisterPolicySettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerPolicySettingRaw(requestParameters, initOverrides);
    }

}
