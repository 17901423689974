/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MasterGyoshuDto } from './MasterGyoshuDto';
import {
    MasterGyoshuDtoFromJSON,
    MasterGyoshuDtoFromJSONTyped,
    MasterGyoshuDtoToJSON,
} from './MasterGyoshuDto';
import type { MasterKoyokeitaiDto } from './MasterKoyokeitaiDto';
import {
    MasterKoyokeitaiDtoFromJSON,
    MasterKoyokeitaiDtoFromJSONTyped,
    MasterKoyokeitaiDtoToJSON,
} from './MasterKoyokeitaiDto';
import type { MasterShok1Dto } from './MasterShok1Dto';
import {
    MasterShok1DtoFromJSON,
    MasterShok1DtoFromJSONTyped,
    MasterShok1DtoToJSON,
} from './MasterShok1Dto';
import type { MasterTodofukenWithCitiesDto } from './MasterTodofukenWithCitiesDto';
import {
    MasterTodofukenWithCitiesDtoFromJSON,
    MasterTodofukenWithCitiesDtoFromJSONTyped,
    MasterTodofukenWithCitiesDtoToJSON,
} from './MasterTodofukenWithCitiesDto';
import type { MasterTok1Dto } from './MasterTok1Dto';
import {
    MasterTok1DtoFromJSON,
    MasterTok1DtoFromJSONTyped,
    MasterTok1DtoToJSON,
} from './MasterTok1Dto';

/**
 * 
 * @export
 * @interface MasterDataResDto
 */
export interface MasterDataResDto {
    /**
     * 市区町村マスタ
     * @type {Array<MasterTodofukenWithCitiesDto>}
     * @memberof MasterDataResDto
     */
    cityList?: Array<MasterTodofukenWithCitiesDto>;
    /**
     * 職種マスタ
     * @type {Array<MasterShok1Dto>}
     * @memberof MasterDataResDto
     */
    shokushuList?: Array<MasterShok1Dto>;
    /**
     * 特徴マスタ
     * @type {Array<MasterTok1Dto>}
     * @memberof MasterDataResDto
     */
    tokuchoList?: Array<MasterTok1Dto>;
    /**
     * 業種マスタ
     * @type {Array<MasterGyoshuDto>}
     * @memberof MasterDataResDto
     */
    gyoshuList?: Array<MasterGyoshuDto>;
    /**
     * 雇用形態マスタ
     * @type {Array<MasterKoyokeitaiDto>}
     * @memberof MasterDataResDto
     */
    koyokeitaiList?: Array<MasterKoyokeitaiDto>;
}

/**
 * Check if a given object implements the MasterDataResDto interface.
 */
export function instanceOfMasterDataResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterDataResDtoFromJSON(json: any): MasterDataResDto {
    return MasterDataResDtoFromJSONTyped(json, false);
}

export function MasterDataResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterDataResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityList': !exists(json, 'cityList') ? undefined : ((json['cityList'] as Array<any>).map(MasterTodofukenWithCitiesDtoFromJSON)),
        'shokushuList': !exists(json, 'shokushuList') ? undefined : ((json['shokushuList'] as Array<any>).map(MasterShok1DtoFromJSON)),
        'tokuchoList': !exists(json, 'tokuchoList') ? undefined : ((json['tokuchoList'] as Array<any>).map(MasterTok1DtoFromJSON)),
        'gyoshuList': !exists(json, 'gyoshuList') ? undefined : ((json['gyoshuList'] as Array<any>).map(MasterGyoshuDtoFromJSON)),
        'koyokeitaiList': !exists(json, 'koyokeitaiList') ? undefined : ((json['koyokeitaiList'] as Array<any>).map(MasterKoyokeitaiDtoFromJSON)),
    };
}

export function MasterDataResDtoToJSON(value?: MasterDataResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cityList': value.cityList === undefined ? undefined : ((value.cityList as Array<any>).map(MasterTodofukenWithCitiesDtoToJSON)),
        'shokushuList': value.shokushuList === undefined ? undefined : ((value.shokushuList as Array<any>).map(MasterShok1DtoToJSON)),
        'tokuchoList': value.tokuchoList === undefined ? undefined : ((value.tokuchoList as Array<any>).map(MasterTok1DtoToJSON)),
        'gyoshuList': value.gyoshuList === undefined ? undefined : ((value.gyoshuList as Array<any>).map(MasterGyoshuDtoToJSON)),
        'koyokeitaiList': value.koyokeitaiList === undefined ? undefined : ((value.koyokeitaiList as Array<any>).map(MasterKoyokeitaiDtoToJSON)),
    };
}

