import { generateTextRule } from '../../shared';

/**
 * お仕事のポイントのタイトルのバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useJobPointTitleValidationRules = () => {
  const propertyName = 'タイトル';
  const maxLength = 20;
  return generateTextRule<`jobPoint.points.${number}.title`>(
    propertyName,
    maxLength,
    true
  );
};
