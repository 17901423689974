import { styled } from '@mui/material';
import { QuestionMarkIcon } from 'assets/icons';

const StyledQuestionIcon = styled(QuestionMarkIcon)(() => ({
  background: 'rgba(51, 51, 51, 0.80)',
  width: '42px',
  height: '42px',
  padding: '10px',
  boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.20)',
  borderRadius: '28px'
}));

export default StyledQuestionIcon;
