import { createContext } from 'react';
import {
  CategorizedLabelAndValue,
  LabelAndValue
} from 'types/saiyouPageEditing/LabelAndValue';

type MasterDataProps = {
  employmentStatuses: LabelAndValue[];
  occupations: CategorizedLabelAndValue[];
  features: LabelAndValue[];
};

/**
 * バイトルCoreマスターデータのコンテキスト
 */
const MasterDataContext = createContext<MasterDataProps>({
  employmentStatuses: [],
  occupations: [],
  features: []
});

export default MasterDataContext;
