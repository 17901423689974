import { useMemo } from 'react';
import { useMaxLengthWithoutNewLinesRule } from '../shared';
import { getRequiredMessage } from 'validation/messages';
import { FieldPath, RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

/**
 * 編集画面のテキストボックス・エリア用バリデーションを生成して返す
 * @param dispPropertyName
 * @param maxLength
 * @param required
 * @returns
 */
export const generateTextRule = <P extends FieldPath<FormData>>(
  dispPropertyName: string,
  maxLength: number,
  required = true
): RegisterOptions<FormData, P> => {
  const validate = useMaxLengthWithoutNewLinesRule(dispPropertyName, maxLength);
  return useMemo(() => {
    const rule: RegisterOptions<FormData, P> = { validate };
    if (required) {
      rule.required = {
        value: true,
        message: getRequiredMessage(dispPropertyName, 'input')
      };
    } else {
      rule.required = {
        value: false,
        message: ''
      };
    }

    return rule;
  }, [validate, required]);
};
