import * as React from 'react';
import { DialogContent, DialogTitle, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import './style.scss';
import { CustomButton } from '../CustomButton';
import { CloseBlack } from 'assets/icons';

/**
 * ダイアログ閉じるボタンコンポーネント
 */
const CustomCloseBlack = styled('div')(() => ({
  position: 'absolute',
  right: -10,
  top: -50,
  zIndex: 1,
  width: 50,
  height: 50,
  padding: 10,
  ':hover': {
    opacity: 0.8,
    cursor: 'pointer'
  },
  '& svg': {
    height: 30,
    width: 30,
    fill: 'white'
  }
}));

/**
 * ダイアログコンポーネント
 * @param {number} height - 高さ
 * @param {number} maxheight - 最大高さ
 */
const CustomDialog = styled(Dialog)<{ height?: number; maxheight?: number }>(
  ({ height, maxheight }) => ({
    '& .MuiPaper-root': {
      width: '90% !important',
      minWidth: '300px',
      maxWidth: '1160px',
      overflow: 'visible',
      maxHeight: maxheight ? maxheight : 'calc(90% - 40px)',
      ...(height && { height: height })
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    '& .MuiDialogContent-root': {
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingBottom: '40px'
    },
    '& .MuiDialogTitle-root': {
      margin: '40px 40px 0 40px',
      padding: 0
    }
  })
);

export type customDialogClassType = 'IconSelectDialog' | 'ImageChange';

/**
 * @interface CustomSelectDialogProps
 * @description 共通ダイアログコンポーネントのプロパティ
 * @property {string} title - ダイアログのタイトル
 * @property {React.ReactNode} children - ダイアログの内容
 * @property {React.ReactNode} finishButtonContent - 完了ボタンのテキスト
 * @property {React.ReactNode} [finishButtonIcon] - 完了ボタンのアイコン
 * @property {React.MouseEventHandler<HTMLButtonElement>} [onClickFinish] - 完了ボタンがクリックされたときの処理
 * @property {boolean} isOpen - ダイアログが開いているかどうか
 * @property {Function} onChangeSetOpen - ダイアログの開閉状態を制御する関数
 * @property {boolean} [hasError] - エラーが存在するかどうか
 * @property {React.Dispatch<React.SetStateAction<string | null>>} [setInputFileName] - 選択されたファイル名を設定する関数
 * @property {React.Dispatch<React.SetStateAction<string | null>>} [setSelectedFileName] - 選択されたファイル名を設定する関数
 * @property {string | null} [selectedFileName] - 選択されたファイル名
 * @property {React.Dispatch<React.SetStateAction<string | null>>} [setSelectedImage] - 選択された画像名を設定する関数
 * @property {number} [height] - 高さ
 * @property {number} [maxheight] - 最大高さ
 * @property {customDialogClassType} [customDialogClass] - ダイアログのルートにつけるクラス
 */
interface CustomSelectDialogProps {
  title: string;
  children: React.ReactNode;
  finishButtonContent: React.ReactNode;
  finishButtonIcon?: React.ReactNode;
  isDisableFinishButton?: boolean;
  onClickFinish?: React.MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
  onChangeSetOpen: (value: boolean) => void;
  hasError?: boolean;
  setInputFileName?: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedFileName?: React.Dispatch<React.SetStateAction<string | null>>;
  selectedFileName?: string | null;
  setSelectedImage?: React.Dispatch<React.SetStateAction<string | null>>;
  height?: number;
  maxheight?: number;
  customDialogClass?: customDialogClassType;
}

/**
 * @function CustomSelectDialog
 * @description 共通ダイアログコンポーネント
 * @param {CustomSelectDialogProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} レンダリングされるJSX.Element
 */
const CustomSelectDialog = React.memo(
  ({
    title,
    children,
    finishButtonContent,
    finishButtonIcon,
    isDisableFinishButton = false,
    onClickFinish,
    isOpen,
    onChangeSetOpen,
    hasError,
    height,
    maxheight,
    customDialogClass
  }: CustomSelectDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    const onClickFinishHandler: React.MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      // 親コンポーネントから渡された関数があれば、最初にそれを実行する
      if (onClickFinish) {
        onClickFinish(e);
      }
    };
    /**
     * 閉じるボタンが押されら時に発火する関数
     * ダイアログを閉じ、onChangeSetOpenでセットされた関数に閉じられたことを伝える
     */
    const handleClose = () => {
      setOpen(false);
      onChangeSetOpen(false);
    };

    /**
     * もし親コンポーネントでisOpenの状態が変わった時、ダイアログのオープン状態も変える
     */
    React.useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
      // ウィンドウのサイズが変わったときに実行される関数
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      // イベントリスナーを追加
      window.addEventListener('resize', handleResize);

      // コンポーネントがアンマウントされるときに実行されるクリーンアップ関数
      return () => {
        // イベントリスナーを削除
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <CustomDialog
        open={open}
        className={
          'CustomSelectDialog ' + (customDialogClass && customDialogClass)
        }
        height={height}
        maxheight={maxheight}
      >
        <CustomCloseBlack onClick={handleClose}>
          <CloseBlack />
        </CustomCloseBlack>
        <DialogTitle>
          <div className="dialog-title-wrap">
            <span className="dialog-title">{title}</span>
            <div>
              <CustomButton
                width={windowWidth >= 450 ? '200px' : '100px'}
                height="44px"
                textcolor="white"
                border="1px solid"
                bordercolor="var(--kanri-primary)"
                backgroundcolor="var(--kanri-primary)"
                hoveropacity={hasError || isDisableFinishButton ? '1' : '0.85'}
                hoverborder="1px solid"
                hoverbordercolor="var(--kanri-primary)"
                disabled={hasError || isDisableFinishButton}
                onClick={onClickFinishHandler}
              >
                {finishButtonIcon && (
                  <span
                    className={`dialog-title-icon ${
                      hasError || isDisableFinishButton
                        ? 'dialog-title-icon-error'
                        : ''
                    }`}
                  >
                    {finishButtonIcon}
                  </span>
                )}
                <div>{finishButtonContent}</div>
              </CustomButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </CustomDialog>
    );
  }
);

export default CustomSelectDialog;
