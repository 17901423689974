import { FC } from 'react';
import { Visibility } from 'enums/Visibility';
import DisplaySwitchTabs from './DisplaySwitchTabs';

type Props = {
  isVisible?: Visibility;
  onChange?: (value: Visibility) => void;
};

/**
 * 表示・非表示の切り替えボタンを返す
 * @param {boolean | undefined} isVisible 表示するか
 * @param {Function | undefined} onChange 値が変更された際に実行される関数
 * @returns 表示・非表示切り替えボタン
 */
const VisibilityToggleButton: FC<Props> = ({
  isVisible = Visibility.Visible,
  onChange
}) => (
  <DisplaySwitchTabs
    isVisible={isVisible === Visibility.Visible}
    onChange={(value) =>
      onChange?.(value ? Visibility.Visible : Visibility.Invisible)
    }
  />
);

export default VisibilityToggleButton;
