import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData, InformationType } from '../type';
import TextField from '../parts/TextField';
import { SelectBox } from '../parts/SelectBox';
import { InformationTypeOptions } from '../parts/groupOptions';
import { useTelValidationRules } from '../validations/tel';
import { useEmailValidationRules } from '../validations/email';
import { useUrlValidationRules } from '../validations/url';

/**
 * 電話番号・メールアドレス・URLセクションをレンダリングする
 * @returns {React.JSX.Element} 電話番号・メールアドレス・URLセクション
 */
export default function TelOrMailOrUrlSection() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors
  } = useFormContext<EnhancedPolicyFormData>();

  const informationTypeValue = watch('informationType');

  const telRules = useTelValidationRules<'tel'>();
  const emailRules = useEmailValidationRules<'email'>();
  const urlRules = useUrlValidationRules<'url'>();

  const configMap = {
    [InformationType.NotSelected]: {
      placeholder: '選択してください',
      rules: {},
      isDisabled: true
    },
    [InformationType.Tel]: { placeholder: '電話番号を入力', rules: telRules },
    [InformationType.Email]: {
      placeholder: 'メールアドレスを入力',
      rules: emailRules
    },
    [InformationType.Url]: { placeholder: 'URLを入力', rules: urlRules }
  };

  const currentConfig = configMap[informationTypeValue];

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          電話番号・メールアドレス・URL
          <span
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              fontWeight: 'normal'
            }}
          >
            ※いずれかを選択してください
          </span>
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <>
            <Controller
              name={'informationType'}
              control={control}
              rules={{
                validate: (value) =>
                  value !== InformationType.NotSelected
                    ? undefined
                    : '選択してください。'
              }}
              render={({ field: { value, onChange } }) => (
                <SelectBox
                  value={value}
                  width={180}
                  category={InformationTypeOptions}
                  isError={!!errors.informationType}
                  errorMessage={errors.informationType?.message}
                  onChange={(selectedValue) => {
                    onChange(selectedValue);
                    setValue(InformationType.Tel, '');
                    setValue(InformationType.Email, '');
                    setValue(InformationType.Url, '');
                    clearErrors(InformationType.Tel);
                    clearErrors(InformationType.Email);
                    clearErrors(InformationType.Url);
                  }}
                />
              )}
            />
            {informationTypeValue === InformationType.NotSelected && (
              <TextField
                placeholder={currentConfig.placeholder}
                width={400}
                maxLength={100}
                hasHelperText={false}
                isDisabled={true}
              />
            )}
            {informationTypeValue !== InformationType.NotSelected && (
              <Controller
                name={informationTypeValue}
                control={control}
                rules={currentConfig.rules}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    value={value}
                    placeholder={currentConfig.placeholder}
                    width={400}
                    maxLength={100}
                    errorMessage={errors[informationTypeValue]?.message}
                    isError={!!errors[informationTypeValue]}
                    onBlur={onBlur}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </>
        </div>
      </label>
    </div>
  );
}
