import React from 'react';

/**
 * OptionalLabelStyledコンポーネントは、「任意」というテキストを表示するためのスタイル付きコンポーネントです。
 *
 * @component
 * @returns {JSX.Element} 「任意」というテキストを含むStyledコンポーネントのJSX要素
 */
const OptionalLabelStyled: React.FC = () => {
  const style = {
    fontSize: 11,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: -0.33,
    display: 'inline-block',
    padding: 4,
    alignItems: 'center',
    gap: 4,
    borderRadius: 4,
    border: '1px solid #595959',
    background: '#FFF'
  };

  return <span style={style}>任意</span>;
};

export default OptionalLabelStyled;
