import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * 並び替え可能ブロックのHooks
 * @param {number} minBlockLength ブロックの要素数の最小値
 * @param {number} maxBlockLength ブロックの要素数の最大値
 * @param {number | undefined} defaultBlockLength ブロックの初期状態の要素数
 * @returns 並び替え可能ブロック関連の処理
 */
const useReorderBlocks = (
  minBlockLength: number,
  maxBlockLength: number,
  defaultBlockLength = 1
): {
  blocks: { id: string }[];
  canAddBlock: boolean;
  canRemoveBlock: boolean;
  addBlock: () => void;
  removeBlock: () => void;
  swapUpBlock: <T>(blockItems: T[], index: number) => T[];
  swapDownBlock: <T>(blockItems: T[], index: number) => T[];
  forceSetBlockLength: (length: number) => void;
} => {
  const [blockLength, setBlockLength] = useState(defaultBlockLength);

  const blocks = useMemo(() => {
    if (!blockLength || blockLength <= 0) return [];

    return new Array(blockLength).fill(0).map((_, i) => ({
      id: `reorder_block_${i}`
    }));
  }, [blockLength]);

  const canAddBlock = useMemo(
    () => blockLength < maxBlockLength,
    [maxBlockLength, blockLength]
  );

  const canRemoveBlock = useMemo(
    () => blockLength > minBlockLength,
    [minBlockLength, blockLength]
  );

  const addBlock = useCallback(() => {
    setBlockLength((prev) => prev + 1);
  }, []);

  const removeBlock = useCallback(() => {
    setBlockLength((prev) => prev - 1);
  }, []);

  const swapBlock = useCallback(
    <T>(blockItems: T[], index: number, targetIndex: number) => {
      const tmp = blockItems[index];
      blockItems[index] = blockItems[targetIndex];
      blockItems[targetIndex] = tmp;

      return blockItems;
    },
    []
  );

  const swapUpBlock = useCallback(
    <T>(blockItems: T[], index: number) =>
      swapBlock(blockItems, index, index - 1),
    [swapBlock]
  );

  const swapDownBlock = useCallback(
    <T>(blockItems: T[], index: number) =>
      swapBlock(blockItems, index, index + 1),
    [swapBlock]
  );

  const forceSetBlockLength = useCallback((length: number) => {
    setBlockLength(length);
  }, []);

  useEffect(() => {
    setBlockLength((current) =>
      current >= minBlockLength ? current : minBlockLength
    );
  }, [minBlockLength]);

  return {
    blocks,
    canAddBlock,
    canRemoveBlock,
    addBlock,
    removeBlock,
    swapUpBlock,
    swapDownBlock,
    forceSetBlockLength
  };
};

export default useReorderBlocks;
