import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { useHomepageUrlValidationRules } from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「会社ホームページ」アコーディオンを返す
 * @returns 「会社ホームページ」アコーディオン
 */
const HomePageAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('homePage');

  const rules = useHomepageUrlValidationRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="会社ホームページ"
      dispCrown
      isExpanded={isExpanded}
      isError={!!errors.homepageUrl}
      onChange={(value) => onChange?.('homePage', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="採用サイトに貴社ホームページのリンクを表示する場合は、URLを設定してください。" />
        <TabContentFormWrap>
          <Controller
            name="homepageUrl"
            control={control}
            rules={{ ...rules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                value={value}
                placeholder="https://www.dip-net.co.jp/"
                helperText={errors.homepageUrl?.message}
                isError={!!errors.homepageUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default HomePageAccordion;
