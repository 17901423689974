import React from 'react';
import {
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  Link
} from '@mui/material';
import { QuestionMarkIcon } from 'assets/icons';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-50, 20] // x, y オフセット
          }
        }
      ]
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    [`&:before`]: {
      background: theme.palette.common.black
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

const CustomQuestionIcon = styled(QuestionMarkIcon)(() => ({
  background: 'rgba(51, 51, 51, 0.80)',
  width: '42px',
  height: '42px',
  padding: '10px',
  boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.20)',
  borderRadius: '28px'
}));

const HelpButton: React.FC = () => {
  return (
    <BootstrapTooltip
      title={
        <div style={{ textAlign: 'center' }}>
          ヘルプページが
          <br />
          別ウィンドウで開きます。
        </div>
      }
    >
      <Link href="/help" target="_blank">
        <CustomQuestionIcon />
      </Link>
    </BootstrapTooltip>
  );
};

export default HelpButton;
