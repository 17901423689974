import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { getMaxLengthErrorMessage, getRequiredErrorMessage } from './shared';
import { EnhancedPolicyFormData } from '../type';

type EmailProperty = 'email' | 'contactEmail';
/**
 * メールアドレスのバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useEmailValidationRules = <
  T extends EmailProperty
>(): RegisterOptions<EnhancedPolicyFormData, T> => {
  const PropertyName = 'メールアドレス';
  const MaxLength = 100;

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredErrorMessage(PropertyName, 'input')
      },
      maxLength: {
        value: MaxLength,
        message: getMaxLengthErrorMessage(PropertyName, MaxLength)
      },
      pattern: {
        value: /^[A-Za-z0-9¥.¥+_-]+@[A-Za-z0-9¥._-]+\.+[A-Za-z]{2,4}$/u,
        message: `${PropertyName}に間違いがあります。`
      }
    }),
    []
  );
};
