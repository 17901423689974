import { FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ProgressBar from '../ProgressBar';
import BasicStepContent from './BasicStepContent';
import DesignStepContent from './DesignStepContent';

type Props = {
  onComplete?: () => void;
  onValidationError?: () => void;
};

/**
 * 初期設定モーダルの通常コンテンツを返す
 * @param {Function | undefined} onComplete 入力完了ボタンがクリックされた際に実行される関数
 * @param {Function | undefined} onValidationError バリデーションエラー発生時に実行される関数
 * @returns 初期設定モーダルの通常コンテンツ
 */
const NormalContent: FC<Props> = ({ onComplete, onValidationError }) => {
  const {
    formState: { errors }
  } = useFormContext<FormData>();

  const [step, setStep] = useState(1);

  const goToPrevStep = useCallback(() => setStep((prev) => prev - 1), []);

  const goToNextStep = useCallback(() => setStep((prev) => prev + 1), []);

  const handleClickComplete = useCallback(() => {
    if (
      !!errors.corporationName ||
      !!errors.employmentStatus ||
      !!errors.jobIntroduction?.occupations ||
      !!errors.style?.pageDesign ||
      !!errors.style?.pageColor
    ) {
      onValidationError?.();
    } else {
      onComplete?.();
    }
  }, [errors, goToNextStep, onComplete, onValidationError]);

  return (
    <>
      <ProgressBar step={step} />
      {step === 1 ? (
        <BasicStepContent
          canGoToNext={
            !errors.corporationName &&
            !errors.employmentStatus &&
            !errors.jobIntroduction?.occupations
          }
          onClickNext={goToNextStep}
        />
      ) : (
        <DesignStepContent
          canGoToNext={!errors.style?.pageDesign && !errors.style?.pageColor}
          onClickPrev={goToPrevStep}
          onClickNext={handleClickComplete}
        />
      )}
    </>
  );
};

export default NormalContent;
