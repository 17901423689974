import './style.scss';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { useState } from 'react';
import { CopyAllIcon, OpenInNewIcon } from 'assets/icons';
import { TooltipQuestion } from 'components/parts/tooltipQuestion';
import { CopyButton } from 'components/parts/CopyButton';
import { NewTabButton } from 'components/parts/NewTabButton';

type UrlBarProps = {
  title: string;
  helpText?: string;
  url: string;
  isPublished: boolean;
};
export const UrlBar = ({ isPublished, url, title, helpText }: UrlBarProps) => {
  const [open, setOpen] = useState(false);
  const clickCopyUrl = () => {
    if (!open) {
      navigator.clipboard.writeText(url);
    }
    setOpen(!open);
  };
  const closeCopyUrl = () => {
    setOpen(false);
  };
  const openNewTab = () => {
    window.open(url);
  };

  //空白をbrに変換
  const helpTextDom = (
    <>
      {helpText?.split(' ').map((t, i) => {
        return (
          <span key={i}>
            {t}
            <br />
          </span>
        );
      })}
    </>
  );

  return (
    <div className="top_status_openUrl_base">
      <div className="top_status_openUrl_titleBase">
        <div>{title}</div>
        {helpText && <TooltipQuestion text={helpTextDom} />}
      </div>

      <div className="top_status_openUrl_urlBase">
        <div
          className={`top_status_openUrl_url ${!isPublished ? 'disabled' : ''}`}
        >
          {url}
        </div>
        <ClickAwayListener onClickAway={closeCopyUrl}>
          <Tooltip
            title="URLをコピーしました"
            placement="top"
            arrow
            open={open}
            onClose={closeCopyUrl}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            PopperProps={{
              disablePortal: true
            }}
          >
            <CopyButton
              disabled={!isPublished}
              startIcon={<CopyAllIcon />}
              variant="outlined"
              className="top_status_openUrl_copyButton"
              onClick={clickCopyUrl}
            ></CopyButton>
          </Tooltip>
        </ClickAwayListener>
      </div>

      <NewTabButton onClick={openNewTab} disabled={!isPublished}>
        <p className="top_status_openUrl_newWindow_title">URLを開く</p>
        <OpenInNewIcon className="top_status_openUrl_newWindow_icon" />
      </NewTabButton>
    </div>
  );
};
