import { FC } from 'react';
import {
  DesignServiceIcon,
  EditDocumentIcon,
  ManageSearchIcon,
  PermDataSettingIcon
} from 'assets/icons';
import { LeftMenuTabType } from 'types/saiyouPageEditing/LeftMenuTabType';
import DrawerTab from './DrawerTab';
import DrawerTabGroup from './DrawerTabGroup';
import useLeftMenuTab from 'hooks/saiyouPageEditing/useLeftMenuTab';

type Props = {
  currentTab?: LeftMenuTabType;
  onChangeTab?: (newTab: LeftMenuTabType) => void;
};

/**
 * ドロワー内タブメニューを返す
 * @param {LeftMenuTabType | undefined} currentTab 現在のタブ
 * @param {Function | undefined} onChangeTab タブが切り替わった際に実行される関数
 * @returns ドロワー内タブメニュー
 */
const DrawerTabMenu: FC<Props> = ({ currentTab = 'design', onChangeTab }) => {
  const { getLeftMenuTabType, getLeftMenuTabIndex } = useLeftMenuTab();

  return (
    <DrawerTabGroup
      value={getLeftMenuTabIndex(currentTab)}
      aria-label="icon label tabs"
      onChange={(_, value) => onChangeTab?.(getLeftMenuTabType(value))}
    >
      <DrawerTab label="デザイン" icon={<DesignServiceIcon />} />
      <DrawerTab label="コンテンツ" icon={<EditDocumentIcon />} />
      <DrawerTab
        label="サイト"
        style={{ minWidth: '78px' }}
        icon={<PermDataSettingIcon />}
      />
      <DrawerTab
        label="SEO"
        style={{ minWidth: '78px' }}
        icon={<ManageSearchIcon />}
      />
    </DrawerTabGroup>
  );
};

export default DrawerTabMenu;
