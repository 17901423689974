import { ChangeEventHandler, FC } from 'react';
import { RadioGroup, styled } from '@mui/material';
import { useConfig } from 'context/ConfigContext';
import ColorRadio from './ColorRadio';

const StyledRadioGroup = styled(RadioGroup)({
  '&.MuiFormGroup-root': {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '20px 10px'
  }
});

type Props = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

/**
 * カラー設定用ラジオグループを返す
 * @param {string | undefined} value 色
 * @param {ChangeEventHander<HTMLInputElement> | undefined} onChange 色が変更された際に実行される関数
 * @returns カラー設定用ラジオグループを返す
 */
const ColorRadioGroup: FC<Props> = ({ value = '', onChange }) => {
  const config = useConfig();

  return (
    <StyledRadioGroup
      value={value}
      className="DesignSelectColor"
      onChange={onChange}
    >
      <ColorRadio
        label={config.colors.gray.label}
        value={config.colors.gray.value}
        color="--kanri-gray-key-color"
        isSelected={value === config.colors.gray.value}
      />
      <ColorRadio
        label={config.colors.orange.label}
        value={config.colors.orange.value}
        color="--kanri-orange-key-color"
        isSelected={value === config.colors.orange.value}
      />
      <ColorRadio
        label={config.colors.red.label}
        value={config.colors.red.value}
        color="--kanri-red-key-color"
        isSelected={value === config.colors.red.value}
      />
      <ColorRadio
        label={config.colors.green.label}
        value={config.colors.green.value}
        color="--kanri-green-key-color"
        isSelected={value === config.colors.green.value}
      />
      <ColorRadio
        label={config.colors.blue.label}
        value={config.colors.blue.value}
        color="--kanri-blue-key-color"
        isSelected={value === config.colors.blue.value}
      />
      <ColorRadio
        label={config.colors.purple.label}
        value={config.colors.purple.value}
        color="--kanri-purple-key-color"
        isSelected={value === config.colors.purple.value}
      />
    </StyledRadioGroup>
  );
};

export default ColorRadioGroup;
