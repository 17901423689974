import { FC, PropsWithChildren } from 'react';
import MasterDataContext from 'context/saiyouPageEditing/MasterDataContext';
import {
  CategorizedLabelAndValue,
  LabelAndValue
} from 'types/saiyouPageEditing/LabelAndValue';

type Props = {
  employmentStatuses?: LabelAndValue[];
  occupations?: CategorizedLabelAndValue[];
  features?: LabelAndValue[];
} & PropsWithChildren;

/**
 * バイトルCoreマスターデータのコンテキストプロバイダを返す
 * @param {LabelAndValue[]} employmentStatuses 雇用形態リスト
 * @param {CategorizedLabelAndValue[]} occupations 職種リスト
 * @param {LabelAndValue[]} features 特徴リスト
 * @param {ReactNode | ReactNode[] | undefined} children 子コンポーネント
 * @returns コンテキストプロバイダ
 */
const MasterDataProvider: FC<Props> = ({
  employmentStatuses = [],
  occupations = [],
  features = [],
  children
}) => {
  return (
    <MasterDataContext.Provider
      value={{
        employmentStatuses,
        occupations,
        features
      }}
    >
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataProvider;
