import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { getRequiredErrorMessage } from './shared';
import { EnhancedPolicyFormData } from '../type';

type UrlProperty = 'url' | 'contactUrl';
/**
 * URLのバリデーションHooks
 * @returns {RegisterOptions} RHFの登録オプション
 */
export const useUrlValidationRules = <T extends UrlProperty>(): RegisterOptions<
  EnhancedPolicyFormData,
  T
> => {
  const PropertyName = 'URL';

  return useMemo(
    () => ({
      required: {
        value: true,
        message: getRequiredErrorMessage(PropertyName, 'input')
      },
      pattern: {
        value: /^(ftp|http|https):\/\/[^ "]+$/,
        message: `${PropertyName}に間違いがあります。`
      }
    }),
    []
  );
};
