import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  Card,
  CardContent,
  Typography,
  Popover
} from '@mui/material';
import {} from '../listitemButton';
import { ListItem, ListItemText, List } from '@mui/material';
import { LogoutIcon, MenuBookIcon } from 'assets/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useLoginContext } from 'components/LoginWrapper';

interface UserCardProps {
  openUserMenu: boolean;
  anchorEl: HTMLButtonElement | null;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}
/**
 * メニューのポップオーバーで出現するユーザー情報カード
 * @params userData ユーザー情報オブジェクト
 * @params openUserMenu ユーザー情報ポップアップ開閉
 * @params anchorEl ポップオーバーのアンカー
 * @params onClick ポップオーバー開閉イベント
 * @params open ドロワーメニュー開閉
 * @returns
 */
export default function PopoverUserCard({
  openUserMenu,
  anchorEl,
  onClick,
  open
}: UserCardProps) {
  type Item = {
    text: string;
    icon: React.ReactElement;
    onClick?: () => void;
  };
  const { logout } = useAuth0();
  const { meInfo } = useLoginContext();

  type ItemObject = Item[];
  const menuItem: ItemObject = [
    {
      text: 'サービス利用規約',
      icon: <MenuBookIcon style={{ fill: '#595959' }} />,
      onClick: () => {
        window.open('/policy');
      }
    },
    {
      text: 'ログアウト',
      icon: <LogoutIcon style={{ fill: '#595959' }} />,
      onClick: () =>
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/logout`
          }
        })
    }
  ];
  const id = openUserMenu ? 'user-pop-over' : undefined;
  return (
    <Popover
      id={id}
      open={openUserMenu}
      anchorEl={anchorEl}
      onClose={onClick}
      anchorOrigin={{
        vertical: open ? -10 : 'top',
        horizontal: open ? 220 : 60
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Card
        sx={{
          width: 260,
          borderRadius: '6px',
          boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.15)'
        }}
      >
        <CardContent
          sx={{
            px: '20px',
            pt: '20px',
            pb: '10px',
            '&:last-child': { pb: '10px' }
          }}
        >
          <Typography
            sx={{
              color: 'var(--kanri-primary-text, #333)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '130%',
              pb: '20px'
            }}
          >
            {meInfo?.name}
          </Typography>
          <Divider sx={{ margin: '5px 0' }} />
          <List sx={{ pt: 0, pb: 0 }}>
            {menuItem.map((item, index) => (
              <ListItem
                key={`${index}-${item}`}
                disablePadding
                sx={{ display: 'block' }}
              >
                <Link to="" onClick={item.onClick}>
                  <ListItemButton
                    sx={{
                      height: 48,
                      padding: '15px 0'
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        width: '16px',
                        minWidth: '16px',
                        height: '16px',
                        marginRight: '10px',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        '& .MuiTypography-root': {
                          // text setting
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 700,
                          lineHeight: '100%',
                          color: '#595959'
                        }
                      }}
                      primary={item.text}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Popover>
  );
}
