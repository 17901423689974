import {
  Configuration,
  GetBaitoruMasterDataKindsEnum,
  MasterApi,
  MasterDataResDto,
  PolicySettingPublicApi
} from 'api-client/public';
import { Buffer } from 'buffer';

/**
 * 公開APIのクライアントのconfigを返す
 */
export const getPubApiClientConf = async () => {
  const basicIpass = process.env.REACT_APP_PUBLIC_BASIC_AUTH_USER_PASS;
  const _basicIpass = basicIpass
    ? `Basic ${Buffer.from(basicIpass).toString('base64')}`
    : '';

  return new Configuration({
    basePath: process.env.REACT_APP_API_HOST,
    headers: {
      authorization: _basicIpass
    }
  });
};

/**
 * 利用規約情報の取得
 */
export const getPolicySetting = async (_compMgrNo: string | undefined) => {
  try {
    const compMgrNo = Number(_compMgrNo);
    if (Number.isNaN(compMgrNo)) return null;

    const api = new PolicySettingPublicApi(await getPubApiClientConf());
    return await api.getPolicySetting({ compMgrNo });
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * バイトルCoreマスタデータの取得
 * @param {GetBaitoruMasterDataKindsEnum[]} kinds マスタデータの種類(複数選択可)
 * @returns バイトルCoreマスタデータ
 */
export const getBaitoruMasterData = async (
  kinds: GetBaitoruMasterDataKindsEnum[]
): Promise<MasterDataResDto | null> => {
  try {
    const api = new MasterApi(await getPubApiClientConf());
    return await api.getBaitoruMasterData({ kinds });
  } catch (e) {
    console.error(e);
    return null;
  }
};
