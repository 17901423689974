import { Button, styled } from '@mui/material';
export const NewTabButton = styled(Button)(() => ({
  display: 'flex',
  gap: '4px',
  height: '30px',
  padding: '5px 8px 4px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid var(--kanri-primary, #3E91D5)',
  background: '#FFF',
  fontWeight: '700',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
  cursor: 'pointer',
  color: 'var(--kanri-primary, #3E91D5)',
  '&_title': {
    color: 'var(--kanri-primary, #3E91D5)',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%'
  },
  '&.Mui-disabled': {
    background: 'var(--kanri-disabled-button-color)',
    border: 'none',
    boxShadow: 'none',
    color: 'var(--kanri-sub-text)',
    '& svg > .fill': {
      fill: 'var(--kanri-disabled-button-text-color)'
    }
  }
}));
