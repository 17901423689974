/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MasterDataResDto,
  ZipCodeResDto,
} from '../models';
import {
    MasterDataResDtoFromJSON,
    MasterDataResDtoToJSON,
    ZipCodeResDtoFromJSON,
    ZipCodeResDtoToJSON,
} from '../models';

export interface GetBaitoruMasterDataRequest {
    kinds?: Array<GetBaitoruMasterDataKindsEnum>;
}

export interface SearchZipcodeRequest {
    zipCode: string;
}

/**
 * 
 */
export class MasterApi extends runtime.BaseAPI {

    /**
     *        バイトルCoreAPIへリクエストを行い都道府県・市区町村・大職種・特徴・業種マスタを返す。       取得するマスタデータの種類に関してはリクエストパラメータのkindsによって変わる       
     * バイトルCoreマスタデータ取得API
     */
    async getBaitoruMasterDataRaw(requestParameters: GetBaitoruMasterDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MasterDataResDto>> {
        const queryParameters: any = {};

        if (requestParameters.kinds) {
            queryParameters['kinds'] = requestParameters.kinds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/master`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MasterDataResDtoFromJSON(jsonValue));
    }

    /**
     *        バイトルCoreAPIへリクエストを行い都道府県・市区町村・大職種・特徴・業種マスタを返す。       取得するマスタデータの種類に関してはリクエストパラメータのkindsによって変わる       
     * バイトルCoreマスタデータ取得API
     */
    async getBaitoruMasterData(requestParameters: GetBaitoruMasterDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MasterDataResDto> {
        const response = await this.getBaitoruMasterDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *      リクエストパラメータの郵便番号に該当する住所を返す。     取得元はバイトルの郵便番号マスタ。存在しない郵便番号の場合は空レスポンスを返す
     * 郵便番号検索API
     */
    async searchZipcodeRaw(requestParameters: SearchZipcodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZipCodeResDto>> {
        if (requestParameters.zipCode === null || requestParameters.zipCode === undefined) {
            throw new runtime.RequiredError('zipCode','Required parameter requestParameters.zipCode was null or undefined when calling searchZipcode.');
        }

        const queryParameters: any = {};

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zipCode'] = requestParameters.zipCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/public/zip-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZipCodeResDtoFromJSON(jsonValue));
    }

    /**
     *      リクエストパラメータの郵便番号に該当する住所を返す。     取得元はバイトルの郵便番号マスタ。存在しない郵便番号の場合は空レスポンスを返す
     * 郵便番号検索API
     */
    async searchZipcode(requestParameters: SearchZipcodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZipCodeResDto> {
        const response = await this.searchZipcodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetBaitoruMasterDataKindsEnum = {
    City: 'City',
    Shokushu: 'Shokushu',
    Tokucho: 'Tokucho',
    Gyoshu: 'Gyoshu',
    Koyokeitai: 'Koyokeitai'
} as const;
export type GetBaitoruMasterDataKindsEnum = typeof GetBaitoruMasterDataKindsEnum[keyof typeof GetBaitoruMasterDataKindsEnum];
