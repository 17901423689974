import React from 'react';

/**
 * @typedef {object} TabContentInnerProps
 * @property {React.ReactNode} children - 子要素として描画するReactノード
 */
type TabContentInnerProps = {
  children: React.ReactNode;
};

/**
 * タブコンテンツの内部レイアウトを管理するコンポーネント。
 * @param {TabContentInnerProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} 内部コンテンツのレンダリング
 */
const TabContentInner: React.FC<TabContentInnerProps> = ({ children }) => {
  return (
    <div
      style={{
        padding: '0 10px'
      }}
    >
      {children}
    </div>
  );
};

export default TabContentInner;
