import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import { SelectBox } from '../parts/SelectBox';
import { IsJointUseOptions } from '../parts/groupOptions';
import { PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum } from 'api-client/public';

/**
 * 共同利用しない/するセクションをレンダリングする
 * @returns {React.JSX.Element} 共同利用しない/するセクション
 */
export default function IsJointUseSection() {
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext<EnhancedPolicyFormData>();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          共同利用
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <>
            <Controller
              name={'isJointUse'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <SelectBox
                  value={value !== undefined ? value.toString() : ''}
                  width={400}
                  category={IsJointUseOptions}
                  isError={!!errors.isJointUse}
                  onChange={(selectedValue) => {
                    onChange(selectedValue);
                    setValue('rangeOfJointUse', '');
                    setValue(
                      'personalInformationProtectorType',
                      PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.ContractedCorp
                    );
                    setValue('personalInformationProtectorName', '');
                    clearErrors('rangeOfJointUse');
                    clearErrors('personalInformationProtectorType');
                    clearErrors('personalInformationProtectorName');
                  }}
                />
              )}
            />
          </>
        </div>
      </label>
    </div>
  );
}
