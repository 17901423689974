import { FC } from 'react';
import { Button, ButtonGroup, styled } from '@mui/material';
import { MdiCloseBoxIcon2 } from 'assets/icons';
import ContentAccHead from '../../../../common/ContentAcc/ContentAccHead';

type Props = {
  title?: string;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 並び替え可能ブロックのヘッダを返す
 * @param {string | undefined} title タイトル
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 並び替え可能ブロックのヘッダ
 */
const ReorderBlockHeader: FC<Props> = ({
  title = '',
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  return (
    <BlockButtonGroupWrap>
      <ContentAccHead title={title} style={{ padding: 0 }} />
      <BlockButtonGroupInner>
        <ButtonGroup
          variant="outlined"
          aria-label="outlined primary button group"
        >
          {canSwapUp && (
            <UpOrDownButton aria-label="上に移動" onClick={onSwapUp}>
              ↑
            </UpOrDownButton>
          )}
          {canSwapDown && (
            <UpOrDownButton aria-label="下に移動" onClick={onSwapDown}>
              ↓
            </UpOrDownButton>
          )}
        </ButtonGroup>
        {canRemove && (
          <CloseButton aria-label="削除" onClick={onRemove}>
            <MdiCloseBoxIcon2 />
          </CloseButton>
        )}
      </BlockButtonGroupInner>
    </BlockButtonGroupWrap>
  );
};

export default ReorderBlockHeader;

const BlockButtonGroupWrap = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
});

const BlockButtonGroupInner = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 10
});

const UpOrDownButton = styled(Button)({
  display: 'flex',
  height: 26,
  padding: 10,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  color: 'var(--kanri-secondary-text, #595959)',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '130%',
  borderRadius: 6,
  borderColor: 'var(--primary-primary-300, #AEB6D1)',
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.20)',
  '&:hover': {
    borderColor: 'var(--primary-primary-300, #AEB6D1)',
    background: '#fff'
  }
});

const CloseButton = styled(Button)({
  display: 'flex',
  height: 26,
  padding: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  color: 'var(--kanri-secondary-text, #595959)',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '130%',
  borderRadius: 6,
  borderColor: 'var(--primary-primary-300, #AEB6D1)',
  boxShadow: 'none',
  minWidth: 34,
  '& svg': {
    fill: 'var(--kanri-secondary-text, #595959)'
  },
  '&:hover': {
    borderColor: 'var(--primary-primary-300, #AEB6D1)',
    background: '#fff'
  }
});
