import React from 'react';

/**
 * @typedef {object} TabContentHeadProps
 * @property {string} title - タブコンテンツのタイトル
 */
type TabContentHeadProps = {
  title: string;
};

/**
 * タブコンテンツの見出しを表示するコンポーネント。
 * @param {TabContentHeadProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} 見出し部分のレンダリング
 */
const TabContentHead: React.FC<TabContentHeadProps> = ({ title }) => {
  return (
    <div>
      <h2
        style={{
          color: 'var(--kanri-secondary-text, #595959)',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '130%',
          marginBottom: '10px'
        }}
      >
        {title}
      </h2>
    </div>
  );
};

export default TabContentHead;
