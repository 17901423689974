import { generateTextRule } from '../shared';

/**
 * 派遣許認可番号のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useLicenseNoValidationRules = () => {
  const propertyName = '派遣許認可番号';
  const maxLength = 100;
  return generateTextRule<'corporationAbout.licenseNo'>(
    propertyName,
    maxLength,
    false
  );
};
