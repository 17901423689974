import { Tabs, styled } from '@mui/material';
import { DrawerTabSharedProps } from 'constants/saiyouPageEditing/DrawerTabSharedProps';

/**
 * ドロワー内タブグループ
 */
const DrawerTabGroup = styled(Tabs)({
  position: 'fixed',
  background: '#ffffff',
  width: 350,
  top: 60,
  left: 0,
  minHeight: DrawerTabSharedProps.minHeight,
  zIndex: 10,
  '& .MuiTabs-root': {
    minHeight: DrawerTabSharedProps.minHeight
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  }
});

export default DrawerTabGroup;
