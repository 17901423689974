import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useJobPointContentValidationRules,
  useJobPointIconPathValidationRules,
  useJobPointTitleValidationRules
} from 'validation/rules/saiyouPageEditing';
import TextField from '../../../../../common/TextField';
import IconSelector from '../../../../IconSelector';
import FormDivider from '../../shared/FormDivider';
import ReorderBlockHeader from '../../shared/ReorderBlockHeader';
import TabContentFormWrap from '../../shared/TabContentFormWrap';

type Props = {
  index: number;
  canSwapUp?: boolean;
  canSwapDown?: boolean;
  canRemove?: boolean;
  onSwapUp?: () => void;
  onSwapDown?: () => void;
  onRemove?: () => void;
};

/**
 * 「お仕事のポイント」の並び替え可能ブロックを返す
 * @param {number} index 表示順
 * @param {boolean | undefined} canSwapUp 上要素と交換可能であるか
 * @param {boolean | undefined} canSwapDown 下要素と交換可能であるか
 * @param {boolean | undefined} canRemove 削除可能であるか
 * @param {Function | undefined} onSwapUp 上要素との交換時に実行される関数
 * @param {Function | undefined} onSwapDown 下要素との交換時に実行される関数
 * @param {Function | undefined} onRemove 削除時に実行される関数
 * @returns 「お仕事のポイント」の並び替え可能ブロックを返す
 */
const JobPointReorderBlock: FC<Props> = ({
  index,
  canSwapUp = false,
  canSwapDown = false,
  canRemove = false,
  onSwapUp,
  onSwapDown,
  onRemove
}) => {
  const { control, watch } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const titleRules = useJobPointTitleValidationRules();
  const contentRules = useJobPointContentValidationRules();
  const iconPathRules = useJobPointIconPathValidationRules();

  const title = watch(`jobPoint.points.${index}.title`);
  const content = watch(`jobPoint.points.${index}.content`);
  const iconPath = watch(`jobPoint.points.${index}.iconPath`);

  return (
    <TabContentFormWrap>
      <ReorderBlockHeader
        title={`ポイント${index + 1}`}
        canSwapUp={canSwapUp}
        canSwapDown={canSwapDown}
        canRemove={canRemove}
        onSwapUp={onSwapUp}
        onSwapDown={onSwapDown}
        onRemove={onRemove}
      />
      <Controller
        name={`jobPoint.points.${index}.title`}
        control={control}
        rules={{ ...titleRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="タイトル"
            value={title}
            placeholder="育児休業"
            maxLength={20}
            helperText={
              errors.jobPoint?.points?.[index]?.title?.type !== 'maxLength'
                ? errors.jobPoint?.points?.[index]?.title?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.jobPoint?.points?.[index]?.title}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`jobPoint.points.${index}.content`}
        control={control}
        rules={{ ...contentRules, onChange: onChangeValue }}
        render={({ field: { onBlur, onChange }, formState: { errors } }) => (
          <TextField
            label="内容"
            value={content}
            placeholder="原則として、子どもが1歳になるまで取得することができます。さらに当社では、1歳の時点で保育所へ入所できない等の事情がある場合、2歳になるまで延長も可能です。もちろん男性でも育児休業の取得が可能です。"
            maxLength={100}
            rows={8}
            helperText={
              errors.jobPoint?.points?.[index]?.content?.type !== 'maxLength'
                ? errors.jobPoint?.points?.[index]?.content?.message
                : ''
            }
            hasLengthHelperText
            isRequired
            isError={!!errors.jobPoint?.points?.[index]?.content}
            onBlur={onBlur}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`jobPoint.points.${index}.iconPath`}
        control={control}
        rules={{ ...iconPathRules, onChange: onChangeValue }}
        render={({ field: { onChange }, formState: { errors } }) => (
          <IconSelector
            selectedIconPath={iconPath}
            errorMessage={errors.jobPoint?.points?.[index]?.iconPath?.message}
            onSelectIcon={onChange}
          />
        )}
      />
      <FormDivider />
    </TabContentFormWrap>
  );
};

export default JobPointReorderBlock;
