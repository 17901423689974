import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { listImage } from 'logic/clientWrapper/management';
import { PresetImageData } from 'types/saiyouPageEditing/PresetImageData';

/**
 * プリセット画像のダウンロードHooks
 * @param {'baitoru' | 'free'} type 画像一覧のタイプ
 * @returns プリセット画像のダウンロード関数
 */
const useImageDownloader = (
  type: 'baitoru' | 'free'
): {
  downloadImagesAsync: (
    compId: string,
    page?: number,
    searchWord?: string
  ) => Promise<{ isSuccess: boolean; images: PresetImageData[] }>;
} => {
  const auth0 = useAuth0();

  const downloadImagesAsync = useCallback(
    async (compId: string, page = 1, searchWord = '') => {
      const response = await listImage(compId, auth0, page, searchWord, type);
      if (!response || !Array.isArray(response))
        return { isSuccess: false, images: [] };

      const removeCredentialsFromURL = (url: string | undefined) => {
        if (!url) return '';
        const parsedURL = new URL(url);
        parsedURL.username = '';
        parsedURL.password = '';
        return parsedURL.href;
      };

      const images = response
        .map((src) => ({
          url: src.url || '',
          urlWithoutCredential: removeCredentialsFromURL(src.url),
          filename: type === 'free' ? '' : src.name
        }))
        .filter((image) => image.url);

      return { isSuccess: true, images };
    },
    [type]
  );

  return { downloadImagesAsync };
};

export default useImageDownloader;
