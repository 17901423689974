import { StatusText } from 'components/parts/statusText';
import './style.scss';
import { Button } from '@mui/material';
import { PageSettingAdminReferenceResDto } from 'api-client/management';

/**
 * ステータスコンポーネント
 * 公開ステータスに関する情報を表示するコンポーネントです。
 * @param {StatusProps} props - プロパティ
 * @param {string} props.pageSetting - ページ設定
 * @param {Function | undefined} props.onClickStatus - ステータス部分がクリックされたときの処理
 * @returns {JSX.Element} レンダリングされる要素
 */

type StatusProps = {
  pageSetting: PageSettingAdminReferenceResDto | undefined;
  onClickStatus: (() => Promise<void>) | undefined;
};

export const Status = ({ pageSetting, onClickStatus }: StatusProps) => {
  return (
    <div className="top_status_openStatus_base">
      {pageSetting !== undefined && (
        <>
          <div className="top_status_openStatus_title">公開ステータス</div>
          <div className="top_status_openStatus_sign">
            <StatusText pageSetting={pageSetting} />
            {onClickStatus && (
              <Button
                onClick={onClickStatus}
                style={{
                  padding: 0,
                  fontSize: 12,
                  lineHeight: 1.4,
                  color: 'var(--kanri-link)',
                  minWidth: 'unset'
                }}
              >
                変更
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
