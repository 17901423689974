import { FC, RefObject } from 'react';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';
import TabContentSubHead from '../shared/TabContentSubHead';
import TabContentWrapper from '../shared/TabContentWrapper';
import CorporationAboutAccordion from './CorporationAboutAccordion';
import CorporationLogoAccordion from './CorporationLogoAccordion';
import CorporationNameAccordion from './CorporationNameAccordion';
import HomePageAccordion from './HomePageAccordion';
import SNSAccordion from './SNSAccordion';
import TopPageVisibilityAccordion from './TopPageVisibilityAccordion';

type Props = {
  sectionRef?: RefObject<HTMLDivElement>;
};

/**
 * 左ペインのサイト設定セクションを返す
 * @param {RefObject<HTMLDivElement> | undefined} sectionRef サイト設定セクションのref
 * @returns 左ペインのサイト設定セクション
 */
const SiteSettingSection: FC<Props> = ({ sectionRef }) => (
  <>
    <TabContentWrapper>
      <div id="site" ref={sectionRef}>
        <TabContentSubHead title="サイト設定" />
      </div>
      <TabContentInner>
        <TabContentText text="採用サイトの基本情報を変更できます。" />
      </TabContentInner>
    </TabContentWrapper>
    <TabContentWrapper>
      <TopPageVisibilityAccordion />
      <CorporationNameAccordion />
      <CorporationLogoAccordion />
      <HomePageAccordion />
      <SNSAccordion />
      <CorporationAboutAccordion />
    </TabContentWrapper>
  </>
);

export default SiteSettingSection;
