import React from 'react';
import OptionalLabelStyled from './OptionalLabelStyled';

/**
 * OptionalLabelコンポーネントのプロパティの型定義です。
 *
 * @typedef {Object} OptionalLabelProps
 * @property {boolean} isRequired - このプロパティがfalseの場合、オプショナルラベルが表示されます。
 */
interface OptionalLabelProps {
  isRequired: boolean;
}

/**
 * オプショナルラベルコンポーネントです。
 * `isRequired`プロパティがfalseの場合に、オプショナルラベルを表示します。
 *
 * @param {OptionalLabelProps} props - コンポーネントのプロパティです。
 * @returns {React.ReactElement} Reactのエレメントを返します。
 */
const OptionalLabel: React.FC<OptionalLabelProps> = ({ isRequired }) => {
  return <>{!isRequired && <OptionalLabelStyled />}</>;
};

export default OptionalLabel;
