import * as React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Logo } from 'assets/icons';

/**
 * muiのAppBarを上書き
 */
const AppBar = styled(MuiAppBar)<MuiAppBarProps>(({ theme }) => ({
  maxWidth: '100%',
  boxShadow: 'none',
  backgroundColor: 'var(--white)',
  borderBottom: '1px solid var(--kanri-gray-01, #DDD)',
  zIndex: theme.zIndex.drawer + 1,
  padding: '10px 20px',
  alignItems: 'center',
  '& .MuiToolbar-root': {
    minHeight: '40px',
    padding: 0,
    width: '100%',
    maxWidth: '960px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '10px 20px'
  }
}));
/**
 * ヘッダー部分
 * @param open ドロワーの開閉判定
 * @param drawerwidth ドロワーを開いた時の横幅
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @returns
 */
export const CommonHeader = React.memo(() => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Link to="./">
          <Logo style={{ width: '204px' }} />
        </Link>
      </Toolbar>
    </AppBar>
  );
});
