import { styled } from '@mui/material/styles';
import { QuestionMarkIcon } from 'assets/icons';
import { BasicTooltip } from '../Tooltip';

const StyledQuestionMarkIcon = styled(QuestionMarkIcon)`
  background-color: #595959;
  border-radius: 100%;
  padding: 2px;
`;

/**
 * @interface TooltipQuestionProps
 * @description ？のツールチッププロパティを定義するインターフェイス
 * @property {object} text - 吹き出し内のテキスト
 */
interface TooltipQuestionProps {
  text?: React.ReactNode;
}

/**
 * ？と吹き出し
 * @param {TooltipQuestionProps} props - コンポーネントのプロパティ
 * @param param.text 吹き出し内のテキスト
 */

export const TooltipQuestion = ({ text }: TooltipQuestionProps) => {
  return (
    <BasicTooltip title={text} arrow>
      <StyledQuestionMarkIcon width={12} height={12} />
    </BasicTooltip>
  );
};
