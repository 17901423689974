import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  ListItemButton,
  Card,
  CardContent,
  Typography,
  Popover
} from '@mui/material';
import {} from '../listitemButton';
import { ListItem, List } from '@mui/material';
import { MenuItem } from '..';

interface LinkCardProps {
  openLinkMenu: boolean;
  anchorEl: HTMLButtonElement | null;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  menuItem: MenuItem[];
}
/**
 * メニューのポップオーバーで出現するユーザー情報カード
 * @params openLinkMenu ユーザー情報ポップアップ開閉
 * @params anchorEl ポップオーバーのアンカー
 * @params onClick ポップオーバー開閉イベント
 * @params open ドロワーメニュー開閉
 * @returns
 */
export default function PopoverLinkCard({
  openLinkMenu,
  anchorEl,
  onClick,
  open,
  menuItem
}: LinkCardProps) {
  const id = openLinkMenu ? 'user-pop-over' : undefined;
  return (
    <Popover
      id={id}
      open={openLinkMenu}
      anchorEl={anchorEl}
      onClose={onClick}
      anchorOrigin={{
        vertical: open ? -10 : 'top',
        horizontal: open ? 220 : 60
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Card
        sx={{
          width: 234,
          borderRadius: '6px',
          boxShadow: '1px 1px 2px 0px rgba(0, 0, 0, 0.15)'
        }}
      >
        <CardContent
          sx={{
            p: '20px'
          }}
        >
          <List sx={{ p: 0 }}>
            {menuItem.map((item, index) => (
              <div key={`${index}-${item}`}>
                <ListItem
                  disablePadding
                  sx={{
                    display: 'block',
                    pb: index === 0 ? '10px' : 0,
                    pt: index === 1 ? '20px' : 0
                  }}
                >
                  <Link to={item.path ?? ''} onClick={item.onClick}>
                    <ListItemButton
                      sx={{
                        display: 'block',
                        p: 0
                      }}
                    >
                      <img
                        src={item.cardLogo}
                        alt=""
                        style={{
                          margin: 'auto',
                          paddingBottom: '10px',
                          height: `${item.cardImgHeight}`
                        }}
                      />
                      <Typography
                        sx={{
                          textAlign: 'center',
                          // font settings
                          color: 'var(--kanri-secondary-text, #595959)',
                          fontSize: '11px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '100%' /* 11px */,
                          letterSpacing: '-0.55px'
                        }}
                      >
                        {item.cardTitle}
                      </Typography>
                    </ListItemButton>
                  </Link>
                </ListItem>
                {index === 0 ? <Divider sx={{ py: '5px' }} /> : null}
              </div>
            ))}
          </List>
        </CardContent>
      </Card>
    </Popover>
  );
}
