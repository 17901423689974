import { MouseEventHandler } from 'react';
import './style.scss';
import { CachedIcon } from 'assets/icons';
type Prop = {
  onClick: MouseEventHandler | undefined;
};
export const CacheClearButton = ({ onClick }: Prop) => {
  return (
    <div className="top_cacheClear_base">
      <button className="top_cacheClear_button" onClick={onClick}>
        <CachedIcon className="top_cacheClear_icon" />
        <p className="top_cacheClear_title">キャッシュクリア</p>
      </button>
    </div>
  );
};
