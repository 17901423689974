import React from 'react';
import { CommonHeader } from 'components/parts/commonHeader';
import { CommonFooter } from 'components/parts/footer';
import HelpButton from 'components/parts/HelpButton';
import { styled } from '@mui/material';
import SORRY from 'assets/img/SORRY.svg';
import { CloseAndBackButtons } from 'components/parts/CloseAndBackButtons';
interface ErrorPageProp {
  mainMsg: string;
  subMsg: string;
  showCloseAndBackButtons?: boolean;
}

const ErrorPage: React.FC<ErrorPageProp> = (prm) => {
  const NotFoundWrap = styled('div')(() => ({
    display: 'flex',
    padding: '180px 20px 11px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    gap: 50,
    flexShrink: 0
  }));
  const H2Styled = styled('h2')(() => ({
    color: 'var(--kanri-gray-02, #CCC)',
    textAlign: 'center',
    fontSize: 64,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%'
  }));
  const DlStyled = styled('dl')(() => ({
    '& dt': {
      color: 'var(--kanri-primary-text, #333)',
      textAlign: 'center',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: -1.2,
      marginBottom: 20
    },
    '& dd': {
      color: 'var(--kanri-secondary-text, #595959)',
      textAlign: 'center',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '160%'
    }
  }));

  return (
    <>
      <CommonHeader />
      <div className="flex flex-col justify-center items-center w-full h-screen bg-gray-300">
        <NotFoundWrap>
          <H2Styled>
            <img src={SORRY} alt="SORRY" />
          </H2Styled>
          <DlStyled>
            <dt>{prm.mainMsg}</dt>
            <dd>{prm.subMsg}</dd>
          </DlStyled>
          {prm.showCloseAndBackButtons && <CloseAndBackButtons />}
        </NotFoundWrap>
      </div>
      <CommonFooter pt="notFoundPage" />
      <div
        style={{
          position: 'fixed',
          right: 20,
          bottom: 20,
          zIndex: 1
        }}
      >
        <HelpButton />
      </div>
    </>
  );
};
/**
 * 404ページ（ページが見つからない場合）を表示するコンポーネント
 * @returns {React.FC} 404ページのReactコンポーネント
 */
export const NotFoundPage: React.FC = () => (
  <ErrorPage
    mainMsg="お探しのページは見つかりませんでした。"
    subMsg="別のURLを試すか、トップページに戻ってください。"
  />
);
export const UnauthorizedPage: React.FC = () => (
  <ErrorPage
    mainMsg="この企業へのアクセス権がありません。"
    subMsg="別のURLを試すか、バイトル管理画面からアクセスし直してください。"
    showCloseAndBackButtons
  />
);
