import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MeResDtoIsAvailablePublishEnum } from 'api-client/management';
import { useLoginContext } from 'components/LoginWrapper';
import {
  CommonAlertDialogHandler,
  CommonAlertDialogWithRef
} from 'components/parts/alert';
import { useConfig } from 'context/ConfigContext';
import { Visibility } from 'enums/Visibility';
import useDefaultFormData from 'hooks/saiyouPageEditing/useDefaultFormData';
import useEditPreview from 'hooks/saiyouPageEditing/useEditPreview';
import useFormDataConverter from 'hooks/saiyouPageEditing/useFormDataConverter';
import useInitialFormData from 'hooks/saiyouPageEditing/useInitialFormData';
import useMasterData from 'hooks/saiyouPageEditing/useMasterData';
import usePageStyleEffect from 'hooks/saiyouPageEditing/usePageStyleEffect';
import usePolicyData from 'hooks/saiyouPageEditing/usePolicyData';
import usePreFormData from 'hooks/saiyouPageEditing/usePreFormData';
import {
  changePageStatus,
  registerPageSetting
} from 'logic/clientWrapper/management';
import EditSaiyouPageProvider from 'providers/saiyouPageEditing/EditingStatusProvider';
import MasterDataProvider from 'providers/saiyouPageEditing/MasterDataProvider';
import SnackbarProvider from 'providers/saiyouPageEditing/SnackbarProvider';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { PreFormData } from 'types/saiyouPageEditing/PreFormData';
import { EditPageLayout } from '../../layouts/EditPageLayout';
import {
  CommonDialogBoxWithRef,
  CommonDialogBoxHandler
} from '../../parts/CommonDialogBox';
import HelpTooltip from '../../parts/saiyouPageEditing/features/HelpTooltip';
import InitialSettingDialog from '../../parts/saiyouPageEditing/features/InitialSettingDialog';
import LeftMenu from '../../parts/saiyouPageEditing/features/LeftMenu';
import LoadingMask from '../../parts/saiyouPageEditing/features/LoadingMask';
import PreviewContent from '../../parts/saiyouPageEditing/features/PreviewContent';
import {
  CommonSnackbarWithRef,
  SnackbarHandler
} from '../../parts/SnackBarWrapper';
import './style.scss';
import { strToDate } from 'logic/util';

/**
 * 編集画面のルートコンポーネント
 */
export const EditSaiyouPage: FC = () => {
  const config = useConfig();

  const [sliderValue, setSliderValue] = React.useState<number>(75);
  const [deviceWidth, setDeviceWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    // イベントリスナーを追加
    window.addEventListener('resize', handleResize);

    // コンポーネントがアンマウントされたときにイベントリスナーを削除
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeSlider = (newValue: number) => {
    if (newValue !== 0) {
      setSliderValue(newValue);
    }
  };

  /**
   * ドロワーが開いているかどうかの状態
   * @type {boolean}
   */
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);

  const handleOpenDrawer = useCallback(() => setIsDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);

  const [deviceValue, setDeviceValue] = React.useState(0);

  /**
   * PC・スマホの見た目が切り替えられた時の動作
   * @param event クリックイベント
   * @returns
   */
  const handleDeviceChange = (newValue: number) => {
    setDeviceValue(newValue);
  };

  /**
   * 現在の左ウィンドウの画面幅
   */
  const getNowDrawerWidth = () => {
    return isDrawerOpen
      ? config.leftWindowOpenWidth
      : config.leftWindowCloseWidth;
  };

  /**
   * iframe領域のサイズ
   */
  const getContntDefaultWidth = () => {
    return deviceValue === 0 ? config.pcWidth : config.spWidth;
  };
  /**
   * iframeを含むmainタグの幅制御
   */
  const getMainContntWidth = () => {
    const needWidth = 980 - getNowDrawerWidth();
    return Math.max(window.innerWidth - getNowDrawerWidth(), needWidth);
  };

  /**
   * mainタグ内の要素の左位置制御
   * @return iframeを中央配置するためのポジションを返す。もしポジションが左ウィンドウと被る位置なら、被らないギリギリの位置を返す。
   */
  const getMainContentLeft = () => {
    const iframeWidth = Math.min(getContntDefaultWidth(), getMainContntWidth()); //iframeの幅はPCの幅とmainタグの幅の小さい方
    return (getMainContntWidth() - iframeWidth) / 2 + getNowDrawerWidth();
  };

  /**
   * @returns iframeの高さが画面いっぱいになるような比率を返す
   */
  const getsMainContentHeight = () => {
    return 10000 / sliderValue;
  };

  const [mainContentLeft, setMainContentLeft] = React.useState<number>(
    getMainContentLeft()
  );
  const [mainContentHeight, setMainContentHeight] = React.useState<number>(
    getsMainContentHeight()
  );

  React.useEffect(() => {
    setMainContentLeft(getMainContentLeft());
    setMainContentHeight(getsMainContentHeight());
  }, [deviceWidth, deviceValue, sliderValue, isDrawerOpen]);

  // ここから機能実装分
  // ---------------------------------------------------------------------------
  const [defaultFormData, setDefaultFormData] = useState<FormData>();
  const [canPreview, setCanPreview] = useState(false);
  //今回初期設定モーダルを開いたか
  const [hasDisplayedInitModal, setHasDisplayedInitModal] = useState(false);

  //ローディング表示をするかどうかを保持するステート群
  //// 初期設定モーダル表示の準備ができるまでのローディング表示
  const [isInitDialogLoadingMask, setIsInitDialogLoadingMask] = useState(false);
  //// 初期設定モーダル完了後のバックエンド処理中のローディング表示
  const [isDefaultDataCreatingMask, setIsDefaultDataCreatingMask] =
    useState(false);
  //// プレビュー用のiframeが描画可能になるまでのローディング表示
  const [isPreviewDataLoadingMask, setIsPreviewDataLoadingMask] =
    useState(true);

  const isLoading =
    isInitDialogLoadingMask ||
    isDefaultDataCreatingMask ||
    isPreviewDataLoadingMask;

  const [isEditing, setEditing] = useState(false);
  const [isServerError, setServerError] = useState(false);

  const navigate = useNavigate();

  const auth0 = useAuth0();

  const formProps = useForm<FormData>({
    mode: 'all'
  });
  const {
    formState: { errors },
    watch
  } = formProps;
  const pageDesign = watch('style.pageDesign');
  const pageColor = watch('style.pageColor');

  const { compId } = useParams();

  const { meInfo } = useLoginContext();

  const initialData = useInitialFormData(compId);
  const { previewData, previewUuid, pageSetting, fetchCurrentSetting } =
    initialData;

  const isOpenInitDialog =
    initialData.isInitialized && !previewData && !defaultFormData;

  const preFormData = usePreFormData(compId);

  const { getDefaultFormDataAsync } = useDefaultFormData(compId);

  const { hasPolicy } = usePolicyData(compId);

  const masterData = useMasterData();

  const { previewIFrame, updatePreview } = useEditPreview();

  const { toRequestBody } = useFormDataConverter();

  const convFinalUpdateAt = (updateAt: string | undefined) =>
    updateAt ? strToDate(updateAt).toISOString() : undefined;

  const confirmDialog = useRef<CommonDialogBoxHandler>(null);
  const validationAlertDialog = useRef<CommonAlertDialogHandler>(null);
  const snackbar = useRef<SnackbarHandler>(null);

  const canPublish = useMemo(() => {
    return (
      meInfo?.isAvailablePublish === MeResDtoIsAvailablePublishEnum._1 &&
      hasPolicy
    );
  }, [meInfo, hasPolicy]);

  const [isAutoSaving, setIsAutoSaving] = useState(false);

  /** 初期設定モーダルの完了時のハンドラー */
  const handleCompleteInitialSetting = useCallback(
    async (data: PreFormData) => {
      if (!compId) return;

      setIsDefaultDataCreatingMask(true);
      const result = await getDefaultFormDataAsync(data);
      setIsDefaultDataCreatingMask(false);
      if (result) {
        setDefaultFormData(result);
        setIsAutoSaving(true);
        setCanPreview(true);
      } else {
        snackbar.current?.open('serverError');
      }
    },
    [compId, snackbar, getDefaultFormDataAsync]
  );

  /**
   * 初期設定モーダルの完了後の自動保存
   * ReactHookFormへのセット完了後に発火する必要があるため、ハンドラと別にuseEffectで発火
   * */
  useEffect(() => {
    const exec = async () => {
      if (!isAutoSaving) return;
      //バリデーションエラーになる可能性は低いが、生成AIで文字数をオーバーする可能性があるので一応ハンドリング
      await formProps.handleSubmit(saveData, handleInvalid)();
      setIsAutoSaving(false);
    };
    exec();
  }, [isAutoSaving, formProps]);

  const handleClickPreview = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_PUBLIC_FRONT_HOST}/preview/${compId}/${previewUuid}`,
      '_blank'
    );
  }, [compId, previewUuid]);

  const saveData = useCallback(
    async (data: FormData) => {
      if (!compId) return;

      const { success, statusCode } = await registerPageSetting(
        compId,
        {
          ...toRequestBody(data),
          targets: [
            {
              pageEnv: 'preview',
              finalUpdatedAt: convFinalUpdateAt(pageSetting?.preview?.updatedAt)
            }
          ]
        },
        auth0
      );

      if (!success) {
        snackbar.current?.open(
          statusCode === 409 ? 'exclusiveCheckError' : 'serverError'
        );
        return;
      }

      await fetchCurrentSetting();

      setEditing(false);

      snackbar.current?.open('saveSuccess');
    },
    [compId, auth0, snackbar, toRequestBody, pageSetting]
  );

  /**一時保存ボタンによって一時保存したか（初期設定時のオートセーブを除く） */
  const [hasSavedBySaveButton, setHasSavedBySaveButton] = useState(false);
  const handleValidSave = useCallback(
    (data: FormData) => {
      confirmDialog.current?.open('この内容を一時保存しますか？', async () => {
        await saveData(data);
        setHasSavedBySaveButton(true);
        return new Promise((resolve) => resolve());
      });
    },
    [confirmDialog, saveData]
  );

  const publishData = useCallback(
    async (data: FormData) => {
      if (!compId) return;

      const { success, statusCode } = await registerPageSetting(
        compId,
        {
          ...toRequestBody(data),
          targets: [
            {
              pageEnv: 'published',
              finalUpdatedAt: convFinalUpdateAt(
                pageSetting?.published?.updatedAt
              )
            },
            {
              pageEnv: 'preview',
              finalUpdatedAt: convFinalUpdateAt(pageSetting?.preview?.updatedAt)
            }
          ]
        },
        auth0
      );

      if (!success) {
        snackbar.current?.open(
          statusCode === 409 ? 'exclusiveCheckError' : 'serverError'
        );
        return;
      }

      const changeResponse = await changePageStatus(
        compId,
        auth0,
        Visibility.Visible
      );

      if (!changeResponse) return;

      await fetchCurrentSetting();

      setEditing(false);

      snackbar.current?.open('success');
    },
    [compId, auth0, snackbar, toRequestBody, pageSetting]
  );

  const handleValidPublish = useCallback(
    (data: FormData) => {
      confirmDialog.current?.open(
        'この内容を採用サイトへ反映します。よろしいですか？',
        () => publishData(data),
        () => new Promise((resolve) => resolve()),
        true
      );
    },
    [confirmDialog, publishData]
  );

  const handleInvalid = useCallback(() => {
    validationAlertDialog.current?.open();
  }, [validationAlertDialog]);

  const openEditDiscardConfirmDialog = useCallback(
    (onAgree?: () => void, onDisagree?: () => void) => {
      confirmDialog.current?.open(
        '「はい」を押すと\n編集途中の内容は保存されずに進みます\nがよろしいですか？',
        async () => {
          onAgree?.();
        },
        async () => {
          onDisagree?.();
        }
      );
    },
    [confirmDialog]
  );

  const navigateToPolicyPage = useCallback(() => {
    const navigateToPolicyPageCore = () => {
      window.location.href = `/${compId}/policy-edit`;
    };
    if (isEditing) {
      openEditDiscardConfirmDialog(navigateToPolicyPageCore);
    } else {
      navigateToPolicyPageCore();
    }
  }, [isEditing, openEditDiscardConfirmDialog, compId]);

  const pushDummyHistory = useCallback(() => {
    navigate('');
  }, [navigate]);

  const backPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handlePopstate = useCallback(() => {
    if (isEditing) {
      openEditDiscardConfirmDialog(backPage, pushDummyHistory);
    } else {
      backPage();
    }
  }, [isEditing, openEditDiscardConfirmDialog, backPage, pushDummyHistory]);

  const handleChangeValue = useCallback(() => {
    if (isEditing) return;

    setEditing(true);
    window.history.pushState(null, '', null);
  }, [isEditing]);

  useEffect(() => {
    if (!initialData.isInitialized) return;

    // 描画可能になるまでに時間がかかるため、0.5秒ごとに送信
    // TODO: 描画可能かどうかを判定できるなら、それを使った方法へ修正する
    // 10回送信が終わったらローディングマスクをクローズする
    const intervalMs = 500;
    const maxUpdateCount = 10;
    let updateCount = 0;
    const intervalHandler = setInterval(() => {
      if (updateCount >= maxUpdateCount) {
        clearInterval(intervalHandler);
        setIsPreviewDataLoadingMask(false);

        return;
      }

      if (previewData) {
        updatePreview(previewData);
        setCanPreview(true);
      }

      updateCount++;
    }, intervalMs);
  }, [initialData.isInitialized]);

  useEffect(() => {
    if (!initialData.isInitialized) return;

    const subscription = watch((formData) => {
      // errorsの状態が変更されるよりも先にwatchによる変更検出が走ってしまうので、
      // 0.1秒遅らせてからerrorsの状態を確認して、送信するように修正
      setTimeout(() => {
        if (!errors || Object.keys(errors).length <= 0) {
          updatePreview(formData);
        }
      }, 100);
    });
    return () => subscription.unsubscribe();
  }, [errors, initialData.isInitialized, watch, updatePreview]);

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate, false);

    return () => {
      window.removeEventListener('popstate', handlePopstate, false);
    };
  }, [handlePopstate]);

  usePageStyleEffect(pageDesign, pageColor);

  useEffect(() => {
    if (initialData.isServerError) setServerError(true);
  }, [initialData.isServerError]);

  useEffect(() => {
    if (preFormData.isServerError) setServerError(true);
  }, [preFormData.isServerError]);

  useEffect(() => {
    if (isServerError) snackbar.current?.open('serverError');
  }, [isServerError, snackbar]);

  useEffect(() => {
    //初期設定モーダルを開くパターンである かつ 初期設定データがまだ取得できていない場合にローディングマスクを表示する
    const isInitializing = isOpenInitDialog && !preFormData.isInitialized;
    setIsInitDialogLoadingMask(isInitializing);
    if (isOpenInitDialog) setHasDisplayedInitModal(true);
  }, [isOpenInitDialog, preFormData.isInitialized]);

  // ---------------------------------------------------------------------------

  //compIdの存在確認ができたものだけ表示
  return (
    <div id="EditSaiyouPageContent">
      <FormProvider {...formProps}>
        <MasterDataProvider {...masterData}>
          {/* この画面に関わる かつ ネストしたコンポーネントで使う(or複数コンポーネントで使う)データは
          このコンテキストに詰め込むこと */}
          <EditSaiyouPageProvider
            isLoading={isLoading}
            hasDisplayedInitModal={hasDisplayedInitModal}
            onChangeValue={handleChangeValue}
            hasSavedBySaveButton={hasSavedBySaveButton}
            hasPolicy={hasPolicy}
            pageSetting={pageSetting}
            compId={compId}
            onClickSave={formProps.handleSubmit(handleValidSave, handleInvalid)}
            onClickPublish={formProps.handleSubmit(
              handleValidPublish,
              handleInvalid
            )}
            navigateToPolicyPage={navigateToPolicyPage}
            canPublish={canPublish}
          >
            <SnackbarProvider snackbar={snackbar}>
              {isLoading && <LoadingMask />}
              <LeftMenu
                defaultFormData={previewData || defaultFormData}
                isOpen={isDrawerOpen}
                onOpen={handleOpenDrawer}
                onClose={handleCloseDrawer}
              />
              {!isServerError && (
                <InitialSettingDialog
                  defaultPreFormData={
                    initialData.isInitialized && previewData
                      ? undefined
                      : preFormData.preFormData
                  }
                  isNoManuscriptError={preFormData.isNoManuscriptError}
                  isOpen={isOpenInitDialog}
                  onComplete={handleCompleteInitialSetting}
                  onValidationError={() =>
                    validationAlertDialog.current?.open()
                  }
                />
              )}
              <EditPageLayout
                mainContentWidth={getMainContntWidth()}
                changeSlider={changeSlider}
                sliderValue={sliderValue}
                changeDevice={handleDeviceChange}
                deviceValue={deviceValue}
                pageSetting={pageSetting}
                onClickPreview={handleClickPreview}
              >
                {/* TODO: 利用規約未設定メッセージのスタイル調整が必要かも */}
                <div
                  style={{
                    position: 'relative',
                    left: isDrawerOpen ? 350 : 5
                  }}
                ></div>
                <PreviewContent
                  iframeRef={previewIFrame}
                  mainContentWidth={getMainContntWidth()}
                  offsetX={mainContentLeft}
                  heightPercentage={mainContentHeight}
                  scalePercentage={sliderValue}
                  targetDevice={deviceValue === 0 ? 'pc' : 'sp'}
                  isVisible={canPreview}
                />
                <HelpTooltip />
              </EditPageLayout>
            </SnackbarProvider>
          </EditSaiyouPageProvider>
        </MasterDataProvider>
      </FormProvider>
      <CommonDialogBoxWithRef ref={confirmDialog} />
      <CommonAlertDialogWithRef
        ref={validationAlertDialog}
        message="不正な入力項目があります。内容をご確認ください。"
      />
      <CommonSnackbarWithRef ref={snackbar} />
    </div>
  );
};

export default EditSaiyouPage;
