import { FC, useCallback, useMemo, useState } from 'react';
import { FormHelperText } from '@mui/material';
import { ImageResizeType } from 'types/saiyouPageEditing/ImageResizeType';
import EditImageOrIconButton from '../../common/EditImageOrIconButton';
import DeselectButton from './DeselectButton';
import ImageUploadDialog from './ImageUploadDialog';
import PreviewImage from './PreviewImage';
import './ImageUploadEntry.scss';

type Props = {
  uploadedImagePath?: string;
  resizeType?: ImageResizeType;
  errorMessage?: string;
  onUploadImage?: (uploadedImagePath?: string) => void;
  onClearImage?: () => void;
};

/**
 * 画像アップロードダイアログのエントリを返す
 * @param {string | undefined} uploadedImagePath アップロードされた画像のパス
 * @param {ImageResizeType | undefined} resizeType 画像アップロード時のリサイズタイプ
 * @param {string | undefined} errorMessage エラーメッセージ
 * @param {Function | undefined} onUploadImage 画像がアップロードされた際に実行される関数
 * @param {Function | undefined} onClearImage 画像が削除された際に実行される関数
 * @returns 画像アップロードダイアログのエントリ
 */
const ImageUploadEntry: FC<Props> = ({
  uploadedImagePath = '',
  resizeType = 'default',
  errorMessage = '',
  onUploadImage,
  onClearImage
}) => {
  const [isOpen, setOpen] = useState(false);

  const uploadedImageUrl = useMemo(
    () => `${process.env.REACT_APP_PUBLIC_IMAGE_BASEPATH}/${uploadedImagePath}`,
    [uploadedImagePath]
  );

  const openDialog = useCallback(() => setOpen(true), []);

  const closeDialog = useCallback(() => setOpen(false), []);

  const handleUploadImage = useCallback(
    (uploadedImagePath?: string) => {
      closeDialog();
      onUploadImage?.(uploadedImagePath);
    },
    [onUploadImage, closeDialog]
  );

  return (
    <div className="ImageUploader">
      {uploadedImagePath && (
        <PreviewImage selectedImageUrl={uploadedImageUrl} />
      )}
      {uploadedImagePath && <DeselectButton onClick={onClearImage} />}
      <EditImageOrIconButton label="画像選択" onClick={openDialog} />
      {errorMessage && (
        <FormHelperText error style={{ fontSize: 14 }}>
          {errorMessage}
        </FormHelperText>
      )}
      <ImageUploadDialog
        isOpen={isOpen}
        resizeType={resizeType}
        onUploadImage={handleUploadImage}
        onClickClose={closeDialog}
      />
    </div>
  );
};

export default ImageUploadEntry;
