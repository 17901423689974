import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import './assets/styles/style.scss';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWrapper } from './components/Auth0ProviderWrapper';
import { HelpPage } from './components/pages/HelpPage';
import { LogoutPage } from './components/pages/LogoutPage';
import { LoginWrapper } from './components/LoginWrapper';
import { RedirectPage } from './components/pages/RedirectPage';
import { NotFoundPage, UnauthorizedPage } from 'components/pages/ErrorPage';
import { TopPage } from 'components/pages/TopPage';
import { ConfigProvider } from 'context/ConfigContext';
import { PolicyPage } from 'components/pages/PolicyPage';
import { PolicyEditPage } from 'components/pages/PolicyEditPage';
import EditSaiyouPage from 'components/pages/EditSaiyouPage';
import TagManager from 'react-gtm-module';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: ['Meiryo', 'メイリオ', 'Hiragino Sans', 'sans-serif'].join(',')
  }
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWrapper>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0ProviderWrapper>
    </BrowserRouter>
  </React.StrictMode>
);

function App() {
  useEffect(() => {
    //GTM初期設定（環境ごとにIDが異ならないので固定値で持たせておく）
    TagManager.initialize({
      gtmId: 'GTM-P3W7JBLQ'
    });

    // NewRelic Browser Agent初期設定
    const licenseKey = process.env.REACT_APP_NEWRELIC_LICENSE_KEY;
    const applicationID = process.env.REACT_APP_NEWRELIC_APPLICATION_ID;
    const accountID = process.env.REACT_APP_NEWRELIC_ACCOUNT_ID;
    const trustKey = process.env.REACT_APP_NEWRELIC_TRUST_KEY;
    const agentID = process.env.REACT_APP_NEWRELIC_AGENT_ID;

    if (licenseKey && applicationID && accountID && trustKey && agentID) {
      new BrowserAgent({
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ['bam.nr-data.net'] }
        },
        info: {
          beacon: 'bam.nr-data.net',
          errorBeacon: 'bam.nr-data.net',
          licenseKey,
          applicationID,
          sa: 1
        },
        loader_config: {
          accountID,
          trustKey,
          agentID,
          licenseKey,
          applicationID
        }
      });
    }
  }, []);

  return (
    <Routes>
      <Route path="/help" element={<HelpPage />} />
      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/redirect" element={<RedirectPage />} />
      {/* 明示的にNotFoundPageに飛ばしたい時用 */}
      <Route path="/notfound" element={<NotFoundPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route
        path="/:compId/*"
        element={
          <LoginWrapper>
            <Routes>
              <Route path="top" element={<TopPage />} />
              <Route
                path="edit"
                element={
                  <ConfigProvider>
                    <EditSaiyouPage />
                  </ConfigProvider>
                }
              />
              <Route path="policy-edit" element={<PolicyEditPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </LoginWrapper>
        }
      />
    </Routes>
  );
}

reportWebVitals();
