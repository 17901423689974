import * as React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Logo } from 'assets/icons';
import { useLoginContext } from 'components/LoginWrapper';
import {
  ButtonProps,
  Stack,
  Tooltip,
  TooltipProps,
  tooltipClasses
} from '@mui/material';
import { ComputerIcon, IphoneIcon, OpenInNewIcon } from 'assets/icons';
import './EditAppBar/style.scss';
import { CustomTab, CustomTabs } from './EditAppBar/CustomTabs';
import { CustomButton } from 'components/parts/CustomButton';
import { BasicTooltip } from '../../Tooltip';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -10] // x, y オフセット
          }
        }
      ]
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

interface CustomOpenInNewIconProps extends ButtonProps {
  fillcolor: string;
}
const CustomOpenInNewIcon = styled(OpenInNewIcon)<CustomOpenInNewIconProps>(
  ({ fillcolor }) => ({
    marginLeft: '4px',
    '& .fill': {
      fill: fillcolor
    }
  })
);

const CustomStack = styled(Stack)(({ theme }) => ({
  gap: '10px;'
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerwidth: number;
  transitionduration: string;
  isEditPage?: boolean;
}
/**
 * muiのAppBarを上書き
 */
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: PropertyKey) =>
    !['open', 'drawerWidth', 'transitionDuration', 'isEditPage'].includes(
      String(prop)
    )
})<AppBarProps>(
  ({ theme, open, drawerwidth, transitionduration, isEditPage }) => ({
    maxWidth: isEditPage ? 'unset' : 'calc(100% - 60px)',
    borderBottom: '1px solid var(--kanri-gray-01, #DDD)',
    boxShadow: 'none',
    backgroundColor: 'var(--white)',
    zIndex: theme.zIndex.drawer + 1,
    paddingLeft: isEditPage ? '80px !important' : '20px',
    paddingRight: '20px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: transitionduration
    }),
    width: '100%',
    ...(open && {
      marginLeft: drawerwidth,
      ...(!isEditPage && { width: `calc(100% - ${drawerwidth}px)` }),
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: transitionduration
      })
    }),
    '& .MuiToolbar-root': {
      minHeight: '60px',
      padding: 0,
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  })
);

interface CommonPartsProps {
  compId?: string;
  meInfoString?: string;
}
const CommonParts = React.memo(({ compId, meInfoString }: CommonPartsProps) => {
  return (
    <div
      style={{
        display: 'flex',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        alignItems: 'center'
      }}
    >
      <Link
        to={`/${compId}/top`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Logo style={{ width: '204px' }} />
      </Link>
      <BasicTooltip title={meInfoString} arrow>
        <div
          style={{
            color: '#333333',
            padding: '2px 10px 0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%'
          }}
        >
          {meInfoString}
        </div>
      </BasicTooltip>
    </div>
  );
});

/**
 * 編集ページヘッダー部分
 * @param compId 企業ID
 * @returns
 */
interface CustomAppBarProps {
  open: boolean;
  drawerwidth: number;
  transitionduration: string;
  compId: string | undefined;
  deviceValue?: number;
  changeDevice?: (value: number) => void;
  isEditPage: boolean;
  onClickPreview?: () => void;
}

/**
 * ヘッダーに並べるコンテンツ
 * @param {boolean} open ヘッダーメニュー開閉状態
 * @param {number} drawerwidth ヘッダーメニューの大きさ
 * @param {number} transitionduration ヘッダーメニュー開閉アニメーション時間
 * @param {React.ReactNode} compId 企業ID
 * @param {number} deviceValue PC・iPhoneの表示切り替えの現在値
 * @param {Function} changeDevice PC・iPhoneの表示切り替えがあった時に動く関数
 * @param {number} isEditPage 編集ページかどうか
 * @param {boolean | undefined} 公開できるか
 * @param {Function | undefined} onClickPreview プレビューボタンがクリックされた際に実行される関数
 * @param {Function | undefined} onClickSave 一時保存ボタンがクリックされた際に実行される関数
 * @param {Function | undefined} onClickPublish 公開ボタンがクリックされた際に実行される関数
 * @returns {JSX.Element} ヘッダーに並べるコンテンツコンポーネント
 */
export const CustomAppBar = React.memo(
  ({
    open,
    drawerwidth,
    transitionduration,
    compId,
    deviceValue,
    changeDevice,
    isEditPage,
    onClickPreview
  }: CustomAppBarProps) => {
    const { meInfo } = useLoginContext();
    const { onClickPublish, onClickSave, canPublish } = React.useContext(
      EditSaiyouPageContext
    );

    const handleChange = (
      event: React.ChangeEvent<unknown>,
      newValue: number
    ) => {
      if (changeDevice) {
        changeDevice(newValue);
      }
    };

    return (
      <AppBar
        {...{
          drawerwidth: drawerwidth,
          transitionduration: transitionduration,
          open: open
        }}
        {...(isEditPage
          ? {
              position: 'sticky',
              isEditPage: true,
              id: 'editHeaderMenu_AppBar'
            }
          : {
              position: 'fixed'
            })}
      >
        {isEditPage ? (
          <div className="edit-header-wrap">
            <Toolbar>
              <CommonParts
                compId={compId}
                meInfoString={`${
                  meInfo?.currentCorporation?.name ?? ''
                }  ${compId}`}
              />
              <div className="edit-header-contents-wrap">
                <div className="edit-header-contents">
                  <CustomTabs value={deviceValue ?? 0} onChange={handleChange}>
                    <CustomTab icon={<ComputerIcon />} />
                    <CustomTab icon={<IphoneIcon />} />
                  </CustomTabs>
                  <CustomStack direction="row">
                    <BootstrapTooltip title="外部確認用URLを開きます">
                      <CustomButton
                        width="127px"
                        textcolor="var(--kanri-primary)"
                        border="1px solid"
                        bordercolor="var(--kanri-primary)"
                        backgroundcolor="white"
                        hoveropacity="1"
                        hoverborder="2px solid"
                        hoverbordercolor="var(--kanri-primary)"
                        disabled={false}
                        onClick={onClickPreview}
                      >
                        プレビュー
                        <CustomOpenInNewIcon fillcolor="var(--kanri-primary)" />
                      </CustomButton>
                    </BootstrapTooltip>
                    <CustomButton
                      width="127px"
                      textcolor="var(--kanri-primary)"
                      border="1px solid"
                      bordercolor="var(--kanri-primary)"
                      backgroundcolor="white"
                      hoveropacity="1"
                      hoverborder="2px solid"
                      hoverbordercolor="var(--kanri-primary)"
                      disabled={false}
                      onClick={onClickSave}
                    >
                      一時保存
                    </CustomButton>
                    <span data-ga-selector="PublishBtn">
                      <CustomButton
                        width="127px"
                        height="100%"
                        textcolor="white"
                        border="1px solid"
                        bordercolor="var(--kanri-primary)"
                        backgroundcolor="var(--kanri-primary)"
                        hoveropacity={canPublish ? '0.85' : '1'}
                        hoverborder="1px solid"
                        hoverbordercolor="var(--kanri-primary)"
                        disabled={!canPublish}
                        onClick={onClickPublish}
                      >
                        公開
                      </CustomButton>
                    </span>
                  </CustomStack>
                </div>
              </div>
            </Toolbar>
          </div>
        ) : (
          <Toolbar>
            <CommonParts
              compId={compId}
              meInfoString={`${
                meInfo?.currentCorporation?.name ?? ''
              }  ${compId}`}
            />
          </Toolbar>
        )}
      </AppBar>
    );
  }
);
