import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { FileUploadIcon } from 'assets/icons';
import SnackbarContext from 'context/saiyouPageEditing/SnackbarContext';
import useImageUploader from 'hooks/saiyouPageEditing/useImageUploader';
import useImageValidation from 'hooks/saiyouPageEditing/useImageValidation';
import { ImageResizeType } from 'types/saiyouPageEditing/ImageResizeType';
import CustomSelectDialog from '../../../../customSelectDialog';
import BaitoruImageTab from './BaitoruImageTab';
import DialogTabHeader from './DialogTabHeader';
import FreeImageTab from './FreeImageTab';
import { TabType } from './TabType';
import UploadImageTab from './UploadImageTab';

type Props = {
  isOpen?: boolean;
  resizeType?: ImageResizeType;
  onUploadImage?: (uploadedImagePath?: string) => void;
  onClickClose?: () => void;
};

/**
 * 画像アップロードダイアログを返す
 * @param {boolean | undefined} isOpen ダイアログが開いているか
 * @param {ImageResizeType | undefined} resizeType 画像アップロード時のリサイズタイプ
 * @param {Function | undefined} onUploadImage 画像がアップロードされた際に実行される関数
 * @param {Function | undefined} onClickClose 閉じるボタンがクリックされた際に実行される関数
 * @returns 画像アップロードダイアログ
 */
const ImageUploadDialog: FC<Props> = ({
  isOpen = false,
  resizeType = 'default',
  onUploadImage,
  onClickClose
}) => {
  const [currentTab, setCurrentTab] = useState<TabType>('upload');
  const [localUploadedImage, setLocalUploadedImage] = useState<
    File | undefined
  >(undefined);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [isValidImage, setValidImage] = useState(true);

  const { compId } = useParams();
  const { uploadImageAsyncFromFile, uploadImageAsyncFromUrl } =
    useImageUploader(compId);

  const { snackbar } = useContext(SnackbarContext);

  const canClickFinish = useMemo(
    () => (currentTab === 'upload' ? !!localUploadedImage : !!selectedImageUrl),
    [currentTab, localUploadedImage, selectedImageUrl]
  );

  const handleClickFinish = useCallback(async () => {
    let path = undefined;
    if (currentTab === 'upload') {
      if (localUploadedImage) {
        path = await uploadImageAsyncFromFile(localUploadedImage, resizeType);
      }
    } else {
      path = await uploadImageAsyncFromUrl(selectedImageUrl, resizeType);
    }

    if (path) onUploadImage?.(path);
    else snackbar?.current?.open('serverError');
  }, [
    resizeType,
    currentTab,
    localUploadedImage,
    selectedImageUrl,
    snackbar,
    uploadImageAsyncFromFile,
    uploadImageAsyncFromUrl,
    onUploadImage
  ]);

  const handleChangeSetOpen = useCallback(
    (value: boolean) => {
      if (!value) onClickClose?.();
    },
    [onClickClose]
  );

  const handleChangeTab = useCallback(
    (value: TabType) => setCurrentTab(value),
    []
  );

  const validateImage = useImageValidation();

  const handleUploadImage = useCallback(
    (image?: File) => {
      setSelectedImageUrl('');
      setLocalUploadedImage(image);
      setValidImage(validateImage(image));
    },
    [validateImage]
  );

  const handleSelectImage = useCallback((url?: string) => {
    setLocalUploadedImage(undefined);
    setValidImage(true);
    setSelectedImageUrl(url || '');
  }, []);

  const openServerErrorSnackbar = useCallback(() => {
    snackbar?.current?.open('serverError');
  }, [snackbar]);

  useEffect(() => {
    if (isOpen) {
      setLocalUploadedImage(undefined);
      setValidImage(true);
      setSelectedImageUrl('');
      setCurrentTab('upload');
    }
  }, [isOpen]);

  return (
    <CustomSelectDialog
      title="画像選択"
      finishButtonContent={currentTab === 'upload' ? 'アップロード' : '設定'}
      height={609}
      customDialogClass="ImageChange"
      isOpen={isOpen}
      isDisableFinishButton={!canClickFinish}
      hasError={!isValidImage}
      onClickFinish={handleClickFinish}
      onChangeSetOpen={handleChangeSetOpen}
      {...(currentTab === 'upload' && {
        finishButtonIcon: <StyledFileUploadIcon />
      })}
    >
      <DialogTabContainer>
        <DialogTabHeader
          currentTab={currentTab}
          onChangeTab={handleChangeTab}
        />
        {currentTab === 'upload' && (
          <UploadImageTab
            uploadedImage={localUploadedImage}
            isError={!isValidImage}
            onUploadImage={handleUploadImage}
          />
        )}
        {currentTab === 'baitoru' && (
          <BaitoruImageTab
            selectedImageUrl={selectedImageUrl}
            onSelectImage={handleSelectImage}
            onServerError={openServerErrorSnackbar}
          />
        )}
        {currentTab === 'free' && (
          <FreeImageTab
            selectedImageUrl={selectedImageUrl}
            onSelectImage={handleSelectImage}
            onServerError={openServerErrorSnackbar}
          />
        )}
      </DialogTabContainer>
    </CustomSelectDialog>
  );
};

export default ImageUploadDialog;

const StyledFileUploadIcon = styled(FileUploadIcon)(() => ({
  width: 16,
  height: 16,
  marginRight: 4,
  fill: '#ffffff'
}));

const DialogTabContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 10
}));
