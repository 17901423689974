import { FC } from 'react';
import { RadioGroup } from '@mui/material';
import DialogTabButton from './DialogTabButton';
import { TabType } from './TabType';

type Props = {
  currentTab?: TabType;
  onChangeTab?: (tab: TabType) => void;
};

/**
 * 画像アップロードモーダルのタブのヘッダー部分を返す
 * @param {TabType | undefined} currentTab 現在開いているタブ
 * @param {Function | undefined} onChangeTab 開いているタブが変更された際に実行される関数
 * @returns タブのヘッダー部分
 */
const DialogTabHeader: FC<Props> = ({
  currentTab = 'baitoru',
  onChangeTab
}) => {
  return (
    <RadioGroup
      value={currentTab}
      className="mode-change-buttons"
      onChange={(_, value) => onChangeTab?.(value as TabType)}
    >
      <div className="mode-change-buttons-wrap">
        <DialogTabButton label="画像をアップロードする" value="upload" />
        <div className="divider" />
        <DialogTabButton
          label="バイトル管理画面の画像から選ぶ"
          value="baitoru"
        />
        <div className="divider" />
        <DialogTabButton label="フリー素材から選ぶ" value="free" />
      </div>
    </RadioGroup>
  );
};

export default DialogTabHeader;
