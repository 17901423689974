import { FC } from 'react';
import { CircularProgress } from '@mui/material';

export const Loading: FC = () => {
  return (
    <div style={{ paddingTop: 30, textAlign: 'center' }}>
      <CircularProgress />
    </div>
  );
};
