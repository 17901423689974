/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ZipCodeResDto
 */
export interface ZipCodeResDto {
    /**
     * 都道府県名
     * @type {string}
     * @memberof ZipCodeResDto
     */
    tdfkName?: string;
    /**
     * 都道府県コード
     * @type {string}
     * @memberof ZipCodeResDto
     */
    tdfkCode?: string;
    /**
     * 市区町村名
     * @type {string}
     * @memberof ZipCodeResDto
     */
    cityName?: string;
    /**
     * 市区町村コード
     * @type {string}
     * @memberof ZipCodeResDto
     */
    cityCode?: string;
}

/**
 * Check if a given object implements the ZipCodeResDto interface.
 */
export function instanceOfZipCodeResDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ZipCodeResDtoFromJSON(json: any): ZipCodeResDto {
    return ZipCodeResDtoFromJSONTyped(json, false);
}

export function ZipCodeResDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZipCodeResDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tdfkName': !exists(json, 'tdfkName') ? undefined : json['tdfkName'],
        'tdfkCode': !exists(json, 'tdfkCode') ? undefined : json['tdfkCode'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
        'cityCode': !exists(json, 'cityCode') ? undefined : json['cityCode'],
    };
}

export function ZipCodeResDtoToJSON(value?: ZipCodeResDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tdfkName': value.tdfkName,
        'tdfkCode': value.tdfkCode,
        'cityName': value.cityName,
        'cityCode': value.cityCode,
    };
}

