import './style.scss';
import { FC, useState } from 'react';
import { OpenInNewIcon } from 'assets/icons';
import { CommonHeader } from 'components/parts/commonHeader';
import { CommonFooter } from 'components/parts/footer';
import { CommonFrame } from 'components/parts/commonFrame';
import { CloseButton } from 'components/parts/closeButton';
import { TooltipQuestion } from 'components/parts/tooltipQuestion';
import { CustomizedAccordions } from './parts/CustomizedAccordions';
import { faqData } from './faqData';
import { PdfButton } from './parts/PdfButton';

// スムーズに該当のアコーディオンにスクロールする処理
const handleAccordion = (id: string) => {
  const targetElement = document.getElementById(id);
  if (targetElement) {
    const offset = targetElement.getBoundingClientRect().top;
    const targetPosition = window.scrollY + offset;
    const duration = 100;

    let start: number | undefined = undefined;

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentage = Math.min(progress / duration, 1);

      window.scrollTo({
        top: targetPosition * percentage - 70,
        behavior: 'smooth'
      });

      if (percentage < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }
};

export const HelpPage: FC = () => {
  /**
   * TODO:faqData.tsにJSONで値が格納されています
   */
  const { manual, frequentFaq, faq } = faqData;

  // 親でアコーディオンの状態管理をする
  const [expanded, setExpanded] = useState('');
  const handleLinkClick = async (id: string) => {
    setExpanded(id);
    setTimeout(() => {
      handleAccordion(id);
    }, 300);
  };

  return (
    <>
      <CommonHeader />
      <div className="help_content">
        <CommonFrame
          title="管理画面マニュアル"
          subtitle="下記のマニュアルがダウンロードできます。"
          parentStyle="helpManual"
        >
          {manual.map((item, index) => (
            <div className="help_content_helpManual" key={index}>
              <p className="help_content_helpManual_title">{item.title}</p>
              <p className="help_content_helpManual_body">
                {item.body}
                {item.notes && (
                  <span className="help_content_helpManual_body_notes">
                    {item.notes}
                  </span>
                )}
              </p>
              <PdfButton link={item.src} />
            </div>
          ))}
        </CommonFrame>
        <span className="help_content_pdf">
          pdf閲覧について
          <TooltipQuestion
            text={
              <>
                Adobe
                Readerが必要です。(無料)ボタンを押してpdfを開いたら、Windowsは、右クリックし、表示されたメニューから[対象をファイルに保存]を選択してください。
                <br />
                Macは、右クリックした後、そのままブラウザの枠外までドラッグしてください。
                {/* TODO: 移動先URLの設定 */}
                <a
                  href="https://acrobat.adobe.com/jp/ja/products/pdf-reader.html"
                  className="help_content_pdf_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Adobe Readerダウンロードはこちら
                  <OpenInNewIcon width="12" height="12" />
                </a>
              </>
            }
          />
        </span>
        <CommonFrame
          title="よくあるご質問"
          subtitle="よくあるご質問をご紹介しております。"
          parentStyle="helpFaq"
        >
          <div className="help_content_helpFaq">
            下記以外のお問い合わせは、担当営業までお問合せください。
            <h3 className="help_content_helpFaq_title">多いご質問</h3>
            <ul>
              {frequentFaq.map((item) => (
                <li key={item.id} className="help_content_helpFaq_list">
                  <a
                    href={`#${item.id}`}
                    className="help_content_helpFaq_list_link"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(item.id);
                    }}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <CustomizedAccordions
              contentArray={faq}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </div>
        </CommonFrame>
        <CloseButton />
        <CommonFooter paddingTop={70} pt="commonPage" />
      </div>
    </>
  );
};
