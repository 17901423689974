import { Tab, styled } from '@mui/material';
import { DrawerTabSharedProps } from 'constants/saiyouPageEditing/DrawerTabSharedProps';

const commonColor = 'var(--kanri-secondary-text, #595959)';
const selectedColor = 'var(--kanri-blue, #1A86D1)';
const borderBottom = `solid 1px #DDDDDD`;

const selectedAndHoverStyles = {
  color: selectedColor,
  border: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderBottom: `solid 2px ${selectedColor}`
  },
  '& .MuiTab-iconWrapper': {
    fill: selectedColor
  }
};

/**
 * ドロワー内タブ
 */
const DrawerTab = styled(Tab)({
  height: DrawerTabSharedProps.minHeight,
  fontSize: '11px',
  fontWeight: '700',
  letterSpacing: '-0.55px',
  color: commonColor,
  borderBottom,
  display: 'flex',
  flexDirection: 'inherit',
  padding: '0',
  minWidth: '97px',
  minHeight: DrawerTabSharedProps.minHeight,
  background:
    'linear-gradient(180deg, rgba(204, 204, 204, 0.00) 0%, #CCC 30.21%, #CCC 80.21%, rgba(204, 204, 204, 0.00) 100%)',
  backgroundPosition: 'right center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '1px 20px',
  '&.Mui-selected': {
    ...selectedAndHoverStyles
  },
  '&:hover': {
    ...selectedAndHoverStyles
  },
  '&:last-of-type': {
    background: 'none'
  },
  '& .MuiTab-iconWrapper': {
    width: '14px',
    height: '14px',
    margin: '0 4px 0 0',
    fill: commonColor
  }
});

export default DrawerTab;
