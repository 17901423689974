/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MasterKoyokeitaiDto
 */
export interface MasterKoyokeitaiDto {
    /**
     * 雇用形態コード
     * @type {string}
     * @memberof MasterKoyokeitaiDto
     */
    code?: string;
    /**
     * 雇用形態名
     * @type {string}
     * @memberof MasterKoyokeitaiDto
     */
    name?: string;
}

/**
 * Check if a given object implements the MasterKoyokeitaiDto interface.
 */
export function instanceOfMasterKoyokeitaiDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterKoyokeitaiDtoFromJSON(json: any): MasterKoyokeitaiDto {
    return MasterKoyokeitaiDtoFromJSONTyped(json, false);
}

export function MasterKoyokeitaiDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterKoyokeitaiDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function MasterKoyokeitaiDtoToJSON(value?: MasterKoyokeitaiDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
    };
}

