import { CardContent, styled } from '@mui/material';
export const StyledCardContent = styled(CardContent)(() => ({
  padding: '10px 15px 20px',
  ':last-child': {
    paddingBottom: '15px'
  },
  '.MuiStepConnector-line.MuiStepConnector-lineVertical': {
    minHeight: '15px'
  }
}));
