import { FC, RefObject } from 'react';
import TabContentInner from '../shared/TabContentInner';
import TabContentSubHead from '../shared/TabContentSubHead';
import TabContentText from '../shared/TabContentText';
import TabContentWrapper from '../shared/TabContentWrapper';
import BrandAccordion from './BrandAccordion';
import SiteDescriptionAccordion from './SiteDescriptionAccordion';
import SiteTitleAccordion from './SiteTitleAccordion';
import TargetAccordion from './TargetAccordion';

type Props = {
  sectionRef?: RefObject<HTMLDivElement>;
};

/**
 * 左ペインのSEO設定セクションを返す
 * @param {RefObject<HTMLDivElement> | undefined} sectionRef SEO設定セクションのref
 * @returns 左ペインのSEO設定セクション
 */
const SEOSettingSection: FC<Props> = ({ sectionRef }) => (
  <>
    <TabContentWrapper>
      <div id="seo" ref={sectionRef}>
        <TabContentSubHead title="SEO設定" />
      </div>
      <TabContentInner>
        <TabContentText text="採用サイトのSEO対策としてサイト説明文などを変更できます。" />
      </TabContentInner>
    </TabContentWrapper>
    <TabContentWrapper>
      <SiteTitleAccordion />
      <BrandAccordion />
      <TargetAccordion />
      <SiteDescriptionAccordion />
    </TabContentWrapper>
  </>
);

export default SEOSettingSection;
