import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

/**
 * サイドメニューオープン時のスタイル
 * @param theme
 * @param drawerwidth ドロワーを開いた時の横幅
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @returns
 */
const openedmixin = (
  theme: Theme,
  drawerwidth: number,
  customtransitionduration: string
): CSSObject => ({
  width: drawerwidth,
  transition: [
    theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: customtransitionduration
    }),
    theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: customtransitionduration
    })
  ].join(','),
  overflowX: 'hidden'
});

/**
 * サイドメニュークローズ時のスタイル
 * @param theme
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @returns
 */
const closedmixin = (
  theme: Theme,
  customtransitionduration: string
): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: customtransitionduration
  }),
  overflowX: 'hidden',
  width: '60px',
  [theme.breakpoints.up('sm')]: {
    width: '60px'
  }
});
interface CustomOverrideDrawerProps extends DrawerProps {
  drawerwidth: number;
  customtransitionduration: string;
  isEditPage: boolean;
}
/**
 * muiのDrawerを上書き
 * @param theme
 * @param open ドロワーの開閉判定
 * @param drawerwidth ドロワーを開いた時の横幅
 * @param customtransitionduration ドロワーを開閉した時のアニメーションのduration
 * @param isEditPage 編集ページかどうか
 * @returns
 */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    !['open', 'drawerwidth', 'customtransitionduration', 'isEditPage'].includes(
      String(prop)
    )
})<CustomOverrideDrawerProps>(
  ({ theme, open, drawerwidth, customtransitionduration, isEditPage }) => ({
    width: drawerwidth,
    height: 'auto',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    paddingBottom: '20px',
    transitionDuration: customtransitionduration,
    '& .MuiPaper-root': {
      transitionDuration: customtransitionduration,
      height: 'auto',
      paddingBottom: '20px',
      border: 0,
      boxShadow:
        isEditPage && !open ? 'unset' : '0px 2px 4px 1px rgba(0, 0, 0, 0.20)',
      backgroundColor: isEditPage && !open ? '#fff0' : '#fff'
    },
    ...(open && {
      ...openedmixin(theme, drawerwidth, customtransitionduration),
      '& .MuiDrawer-paper': openedmixin(
        theme,
        drawerwidth,
        customtransitionduration
      )
    }),
    ...(!open && {
      ...closedmixin(theme, customtransitionduration),
      '& .MuiDrawer-paper': closedmixin(theme, customtransitionduration)
    }),
    ...(isEditPage && { zIndex: 1250 }),
    position: isEditPage ? 'fixed' : 'unset'
  })
);
/**
 * サイドメニューのラッパー
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param drawerwidth ドロワーを開いた時の横幅
 * @param customtransitionduration ドロワーを開閉した時のアニメーションのduration
 * @param isEditPage 編集ページかどうか
 * @returns
 */
interface CustomDrawerProps {
  children: React.ReactNode;
  open: boolean;
  drawerwidth: number;
  customtransitionduration: string;
  isEditPage: boolean;
}
export const CustomDrawer = React.memo(
  ({
    children,
    open,
    drawerwidth,
    customtransitionduration,
    isEditPage
  }: CustomDrawerProps) => {
    return (
      <Drawer
        variant="permanent"
        open={open}
        drawerwidth={drawerwidth}
        customtransitionduration={customtransitionduration}
        isEditPage={isEditPage}
      >
        {children}
      </Drawer>
    );
  }
);
