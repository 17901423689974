import { Step, styled } from '@mui/material';
export const StyledStep = styled(Step)(() => ({
  '& .MuiStepIcon-root': {
    color: 'var(--kanri-disabled-button-color, #ddd)',
    '& .MuiStepIcon-text': {
      fill: 'var(--kanri-disabled-button-text-color, #777)'
    },
    '&.Mui-completed,&.Mui-active': {
      color: 'var(--kanri-primary, #3E91D5)',
      '& .MuiStepIcon-text': {
        fill: 'var(--white, #ffffff)'
      }
    }
  },
  '& + .MuiStepConnector-root': {
    '&.MuiStepConnector-vertical': {
      '&.Mui-active,&.Mui-completed': {
        '& .MuiStepConnector-line': {
          borderColor: 'var(--kanri-primary, #3E91D5)'
        }
      },
      '& .MuiStepConnector-line': {
        borderLeft: '1px solid var(--kanri-border-border-3, #cccccc)'
      }
    }
  }
}));
