import { FC, MouseEventHandler } from 'react';
import { IconButton, styled } from '@mui/material';
import { MdiCloseBoxIcon } from 'assets/icons';

type Props = {
  onClick?: MouseEventHandler<HTMLDivElement>;
};

/**
 * 選択画像の解除ボタンを返す
 * @param {MouseEventHandler<HTMLDivElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 選択画像の解除ボタン
 */
const DeselectButton: FC<Props> = ({ onClick }) => (
  <IconButtonWrap onClick={onClick}>
    画像削除
    <IconButtonStyled>
      <MdiCloseBoxIcon />
    </IconButtonStyled>
  </IconButtonWrap>
);

export default DeselectButton;

const IconButtonWrap = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  marginBottom: 10,
  color: 'var(--kanri-secondary-text, #595959)',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '100%',
  letterSpacing: '0.24px',
  textDecorationLine: 'underline',
  border: 'none',
  background: 'none',
  cursor: 'pointer'
}));

const IconButtonStyled = styled('div')(() => ({
  padding: 0,
  '& svg': {
    width: 10,
    height: 10,
    fill: 'var(--kanri-secondary-text, #595959)'
  }
}));
