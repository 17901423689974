import { Radio, styled } from '@mui/material';

const StyledRadio = styled(Radio)(() => ({
  '&.MuiRadio-root': {
    padding: 0
  },
  '& .MuiSvgIcon-root': {
    display: 'none'
  }
}));

export default StyledRadio;
