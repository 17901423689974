import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { PresetImageData } from 'types/saiyouPageEditing/PresetImageData';
import LoadingCircularProgress from '../../../common/LoadingCircularProgress';
import ImageRadio from './ImageRadio';
import './ImageRadioGroup.scss';

type Props = {
  selectedImage?: PresetImageData;
  imageOptions?: PresetImageData[];
  hasLoadingProgress?: boolean;
  onSelect?: (imageUrl?: string) => void;
  onScrollBottom?: () => void;
};

/**
 * プリセット画像のラジオグループを返す
 * @param {PresetImageData | undefined} SelectedImage 選択された画像
 * @param {PresetImageData[] | undefined} imageOptions 画像オプション
 * @param {boolean | undefined} hasLoadingProgress ロードプログレスを表示するか
 * @param {Function | undefined} onSelect 画像が選択された際に実行される関数
 * @param {Function | undefined} onScrollBottom 下までスクロールした際に実行される関数
 * @returns プリセット画像のラジオグループ
 */
const ImageRadioGroup: FC<Props> = ({
  selectedImage,
  imageOptions = [],
  hasLoadingProgress = false,
  onSelect,
  onScrollBottom
}) => {
  const [, setImageOptionsLength] = useState(0);
  const [maxScrollY, setMaxScrollY] = useState(0);

  const scrollArea = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!scrollArea.current) return;

    const scrollHeight = scrollArea.current.scrollHeight;
    const clientHeight = scrollArea.current.clientHeight;
    const scrollTop = scrollArea.current.scrollTop;
    const scrollPlay = 5; // スクロールの遊びの幅

    if (
      scrollTop >= scrollHeight - clientHeight - scrollPlay &&
      !hasLoadingProgress
    ) {
      setMaxScrollY((current) =>
        scrollTop > current + scrollPlay * 2 ? scrollTop : current
      );
    }
  }, [hasLoadingProgress, scrollArea]);

  useEffect(() => {
    if (maxScrollY > 0) onScrollBottom?.();
  }, [maxScrollY, onScrollBottom]);

  useEffect(() => {
    setImageOptionsLength((currentLength) => {
      if (imageOptions.length < currentLength) {
        scrollArea.current?.scrollTo(1, 1);
        setMaxScrollY(0);
      }

      return imageOptions.length;
    });
  }, [imageOptions, scrollArea]);

  return (
    <div ref={scrollArea} className="ImageRadioButtons" onScroll={handleScroll}>
      <div className="icons-wrap">
        {imageOptions.map((image, i) => (
          <ImageRadio
            key={`image_radio_${i}`}
            image={image}
            isSelected={image.url === selectedImage?.url}
            onClick={() => onSelect?.(image.url)}
          />
        ))}
      </div>
      {hasLoadingProgress && <LoadingCircularProgress />}
    </div>
  );
};

export default ImageRadioGroup;
