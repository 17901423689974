import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dialog, DialogContent, styled } from '@mui/material';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { PreFormData } from 'types/saiyouPageEditing/PreFormData';
import ErrorContent from './ErrorContent';
import NormalContent from './NormalContent';
import './style.scss';

type Props = {
  defaultPreFormData?: PreFormData | null;
  isNoManuscriptError?: boolean;
  isOpen?: boolean;
  onComplete?: (preFormData: PreFormData) => void;
  onValidationError?: () => void;
};

/**
 * 初期設定モーダルを返す
 * @param {PreFormData | null | undefined} defaultPreFormData デフォルトのプレフォームデータ
 * @param {boolean | undefined} isNoManuscriptError プレフォームデータなしエラーか
 * @param {boolean | undefined} isOpen 開いているか
 * @param {Function | undefined} onComplete 全ステップが完了した際に実行される関数
 * @param {Function | undefined} onValidationError バリデーションエラー発生時に実行される関数
 * @returns 初期設定モーダル
 */
const InitialSettingDialog: FC<Props> = ({
  defaultPreFormData,
  isNoManuscriptError,
  isOpen = false,
  onComplete,
  onValidationError
}) => {
  const { getValues, setValue } = useFormContext<FormData>();

  const closeTab = useCallback(() => window.close(), []);

  const handleComplete = useCallback(() => {
    onComplete?.({
      corporationName: getValues('corporationName'),
      employmentStatus: getValues('employmentStatus'),
      occupationCodes: getValues('jobIntroduction.occupations').map(
        (occupation) => occupation.code
      ),
      pageDesign: getValues('style.pageDesign'),
      pageColor: getValues('style.pageColor')
    });
  }, [defaultPreFormData, onComplete, getValues]);

  useEffect(() => {
    if (!defaultPreFormData) return;

    setValue('corporationName', defaultPreFormData.corporationName);
    setValue('employmentStatus', defaultPreFormData.employmentStatus);
    setValue(
      'jobIntroduction.occupations',
      defaultPreFormData.occupationCodes.map((occupationCode) => ({
        code: occupationCode,
        name: '',
        description: ''
      }))
    );
    setValue('style.pageDesign', defaultPreFormData.pageDesign);
    setValue('style.pageColor', defaultPreFormData.pageColor);
  }, [defaultPreFormData, setValue]);

  if (!defaultPreFormData && !isNoManuscriptError) return null;

  return (
    <StyledDialog open={isOpen} className="InitialSettingDialog">
      <DialogContent>
        <div className="dialog-wrap">
          <div className="dialog-header-wrap">
            <div className="dialog-title-wrap">
              <span className="dialog-title">
                ようこそ、採用ページコボットへ
              </span>
            </div>
          </div>
          {!isNoManuscriptError ? (
            <NormalContent
              onComplete={handleComplete}
              onValidationError={onValidationError}
            />
          ) : (
            <ErrorContent onClickClose={closeTab} />
          )}
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default InitialSettingDialog;

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: 800,
    maxWidth: 800,
    '& .MuiDialogContent-root': {
      padding: 0
    }
  }
}));
