import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';
import { getRequiredMessage } from 'validation/messages';

type Rules = RegisterOptions<FormData, 'topImage.layoutImagePath'>;

/**
 * レイアウト画像のバリデーションルールを返す
 * @param isRequired 必須であるか
 * @returns {Rules} バリデーションルール
 */
export const useLayoutImagePathValidationRules = (
  isRequired: boolean
): Rules => {
  const propertyName = 'レイアウト画像';

  return useMemo(
    () => ({
      required: {
        value: isRequired,
        message: getRequiredMessage(propertyName, 'upload')
      }
    }),
    [isRequired]
  );
};
