import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ConfigProvider } from 'context/ConfigContext';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAccHead from '../../common/ContentAcc/ContentAccHead';
import ColorRadioGroup from './ColorRadioGroup';
import DesignRadioGroup from './DesignRadioGroup';

/**
 * デザイン・カラー設定セクションを返す
 * @returns デザイン・カラー設定セクション
 */
const PageStyleSelector: FC = () => {
  const { control } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  return (
    <div>
      <ConfigProvider>
        <Controller
          name="style.pageDesign"
          control={control}
          rules={{ onChange: onChangeValue }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <>
              <ContentAccHead
                title="デザイン選択"
                dispCrown
                className={
                  'design-select-text' +
                  (errors.style?.pageDesign ? ' invalid' : ' valid')
                }
                style={{ paddingTop: '15px' }}
              />
              <DesignRadioGroup value={value} onChange={onChange} />
            </>
          )}
        />
        <Controller
          name="style.pageColor"
          control={control}
          rules={{ onChange: onChangeValue }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <>
              <ContentAccHead
                title="カラー選択"
                className={
                  'color-select-text' +
                  (errors.style?.pageColor ? ' invalid' : ' valid')
                }
                style={{ paddingTop: '20px' }}
              />
              <ColorRadioGroup value={value} onChange={onChange} />
            </>
          )}
        />
      </ConfigProvider>
    </div>
  );
};

export default PageStyleSelector;
