/* eslint-disable react/prop-types */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import List, { ListProps as MuiListProps } from '@mui/material/List';

interface ListProps extends MuiListProps {
  open: boolean;
  transitionduration: string;
  isEditPage: boolean;
}
/**
 * muiのListを上書き
 */
const CustomListBase = styled(List, {
  shouldForwardProp: (prop) =>
    !['open', 'transitionduration', 'isEditPage'].includes(String(prop))
})<ListProps>(({ open, transitionduration, isEditPage }) => ({
  paddingTop: open ? '10px' : 0,
  paddingBottom: open ? '20px' : 0,
  transitionDuration: transitionduration,
  transition: 'opacity ' + transitionduration,
  opacity: !open && isEditPage ? 0 : 1
}));

/**
 * メニューリストのラッパー
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @param isEditPage 編集ページかどうか
 * @returns
 */
interface CustomDividerProps {
  children: React.ReactNode;
  open: boolean;
  transitionduration: string;
  isEditPage?: boolean;
}
export const CustomList = React.memo(
  React.forwardRef(
    (
      { children, open, transitionduration, isEditPage }: CustomDividerProps,
      ref: React.Ref<HTMLUListElement>
    ) => {
      return (
        <CustomListBase
          open={open}
          transitionduration={transitionduration}
          isEditPage={isEditPage ? true : false}
          ref={ref}
        >
          {children}
        </CustomListBase>
      );
    }
  )
);
