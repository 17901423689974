import React from 'react';
import { CheckCircleIcon } from 'assets/icons';

type Props = {
  label: string;
  style?: React.CSSProperties;
};

/**
 * 選択時のラジオボタンラベルを返す
 * @param {string} label ラベル文言
 * @param {CSSProperties | undefined} style 追加のCSSプロパティ
 * @returns 選択時のラジオボタンラベル
 */
const SelectedRadioLabel: React.FC<Props> = ({ label, style }) => (
  <p
    className={'radioChoiceButton checked'}
    style={{
      color: '#ffffff',
      background: 'var(--kanri-secondary-text, #595959)',
      boxShadow: 'none',
      ...style
    }}
  >
    <CheckCircleIcon className="checkCircleIcon" style={{ fill: '#ffffff' }} />
    {label}
  </p>
);

export default SelectedRadioLabel;
