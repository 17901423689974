import { LeftMenuTabType } from 'types/saiyouPageEditing/LeftMenuTabType';

/**
 * 左ペインのタブに関するHooks
 * @returns 左ペインのタブの種類とインデックスを取得する関数
 */
const useLeftMenuTab = (): {
  getLeftMenuTabType: (index: number) => LeftMenuTabType;
  getLeftMenuTabIndex: (type: LeftMenuTabType) => number;
} => {
  const defaultTabType = 'design';
  const defaultTabIndex = 0;

  const leftMenuTabPairs: { tabType: LeftMenuTabType; tabIndex: number }[] = [
    { tabType: 'design', tabIndex: 0 },
    { tabType: 'content', tabIndex: 1 },
    { tabType: 'site', tabIndex: 2 },
    { tabType: 'seo', tabIndex: 3 }
  ];

  const getLeftMenuTabType = (index: number): LeftMenuTabType => {
    return (
      leftMenuTabPairs.find((pair) => pair.tabIndex === index)?.tabType ||
      defaultTabType
    );
  };

  const getLeftMenuTabIndex = (type: LeftMenuTabType): number => {
    return (
      leftMenuTabPairs.find((pair) => pair.tabType === type)?.tabIndex ||
      defaultTabIndex
    );
  };

  return { getLeftMenuTabType, getLeftMenuTabIndex };
};

export default useLeftMenuTab;
