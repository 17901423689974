import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { listIcon } from 'logic/clientWrapper/management';

/**
 * アイコン画像のダウンロードHooks
 * @returns アイコン画像のダウンロード関数
 */
const useIconDownloader = (): {
  downloadIconsAsync: (
    compId: string
  ) => Promise<{ isSuccess: boolean; iconPaths: string[] }>;
} => {
  const auth0 = useAuth0();

  const downloadIconsAsync = useCallback(
    async (compMgrNo: string) => {
      const response = await listIcon(compMgrNo, auth0);

      if (!response || !Array.isArray(response))
        return { isSuccess: false, iconPaths: [] };

      const iconPaths = response
        .map(({ imagePath }) => imagePath || '')
        .filter((imagePath) => !!imagePath);

      return { isSuccess: true, iconPaths };
    },
    [auth0]
  );

  return { downloadIconsAsync };
};

export default useIconDownloader;
