export const faqData = Object.freeze({
  manual: [
    {
      title: '3分でわかる！初期設定マニュアル',
      body: 'ご契約開始時の基本的な初期設定の流れをまとめたマニュアルです。',
      notes: '※詳細な操作については「管理画面マニュアル」をご参照ください。',
      src: '/manual/kobot_3min_manual.pdf'
    },
    {
      title: '管理画面マニュアル',
      body: '採用ページコボット管理画面の操作マニュアルです。\n採用サイトの編集方法について詳しくご説明しています。',
      src: '/manual/kobot_management_manual.pdf'
    }
  ],
  //idはq{カテゴリのインデックス}-{カテゴリ内での質問のインデックス}
  frequentFaq: [
    {
      title: '採用サイトの「公開」ボタンがグレーになっていて、公開できません',
      id: 'q0-1'
    },
    {
      title: '採用サイトに企業ロゴを追加・変更したい',
      id: 'q0-3'
    },
    {
      title:
        '会社名とブランド名が異なります。SEOキーワードとして指定できますか？',
      id: 'q1-1'
    },
    {
      title: '採用サイトのURLはどこですか？',
      id: 'q0-6'
    },
    {
      title: '採用サイトの設定画面にある王冠マークはなんですか？',
      id: 'q0-7'
    }
  ],
  faq: [
    {
      category: '採用サイトについて',
      title: 'TOPページを更新したい',
      body: [
        'TOPページの更新は、メニュー内の「採用サイトの設定」をクリックすると開く画面にて作業いただけます。 詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトの「公開」ボタンがグレーになっていて、公開できません',
      body: [
        '採用サイトの公開には以下の条件が揃っている必要があります。ご確認ください。',
        '・「採用ページコボット」をご契約時のCompNo',
        '・「採用ページコボット」のご契約期間中',
        '・個人情報利用規約が設定済みであること'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトを非公開にしたい',
      body: [
        '採用サイトを非公開にするには、管理画面トップページの「公開ステータス」内にある「変更」ボタンをクリックして下さい。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトに企業ロゴを追加・変更したい',
      body: [
        '企業ロゴの追加・変更は、管理画面トップページで『サイト設定』の「設定」ボタンをクリックすると開く画面にて作業いただけます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトはSNSと連携できますか？',
      body: [
        'TOPページ内にX(旧:Twitter)、FaceBook、Instagram、LINEのプロフィールページへのリンクを追加可能です。管理画面トップページで『サイト設定』の「設定」ボタンをクリックすると開く画面にて作業いただけます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '採用サイトについて',
      title: 'TOPページの会社概要を変更したい',
      body: [
        '会社概要の変更は、管理画面トップページで『サイト設定』の「設定」ボタンをクリックすると開く画面にて作業いただけます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトのURLはどこですか？',
      body: [
        '採用ページコボット管理画面のトップページに採用サイトURLが表示されています。'
      ]
    },
    {
      category: '採用サイトについて',
      title: '採用サイトの設定画面にある王冠マークはなんですか？',
      body: [
        '設定画面にある王冠マークはレギュラープラン以上で反映できる項目です。'
      ]
    },
    {
      category: 'SEOについて',
      title: '採用サイトのサイトタイトルや説明文は変更できますか？',
      body: [
        '任意のタイトルに変更可能です。管理画面トップページで『SEO設定』の「設定」ボタンをクリックすると開く画面にて作業いただけます。未入力の場合はおすすめのタイトルが表示されます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: 'SEOについて',
      title:
        '会社名とブランド名が異なります。SEOキーワードとして指定できますか？',
      body: [
        '任意でブランド名を追加可能です。管理画面トップページで『SEO設定』の「設定」ボタンをクリックすると開く画面にて作業いただけます。入力したブランド名は、サイト説明文などに使用されます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: '求人原稿・応募者管理について',
      title: '採用サイトに掲載する求人原稿を作成したい',
      body: [
        '採用サイトの求人原稿はバイトル管理画面で作成できます。',
        <>
          バイトル管理画面は
          <a
            href={process.env.REACT_APP_BAITORU_MANAGEMENT_URL ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            className="help_content_helpFaq_list_link"
          >
            こちら
          </a>
        </>
      ]
    },
    {
      category: '求人原稿・応募者管理について',
      title: '採用サイトへの応募を確認したい',
      body: [
        '採用サイトの応募状況はバイトル管理画面で確認できます。',
        <>
          バイトル管理画面は
          <a
            href={process.env.REACT_APP_BAITORU_MANAGEMENT_URL ?? ''}
            target="_blank"
            rel="noopener noreferrer"
            className="help_content_helpFaq_list_link"
          >
            こちら
          </a>
        </>
      ]
    },
    {
      category: '求人原稿・応募者管理について',
      title: 'TOPページの「募集中のお仕事一覧」の順番を変えたい',
      body: [
        '固定で順番通りに表示したい案件がある場合は「募集中のお仕事一覧」を使用してください。\nこちらでは案件の仕事管理番号を入力します。最大3件設定でき、任意項目です。\n案件URL(https://saiyo.page/111111/job/1234567)の末尾「1234567」が仕事管理番号です。\nこちらの案件設定が未入力の場合や無効な場合はおすすめ順で案件を自動補充します。'
      ]
    },
    {
      category: '求人原稿・応募者管理について',
      title: '個人情報利用規約を修正したい',
      body: [
        '個人情報利用規約の更新は、メニュー内の「個人情報利用規約の設定」をクリックすると開く画面にて作業いただけます。詳しい設定方法はマニュアルをご覧ください。'
      ]
    },
    {
      category: 'その他',
      title: '動作確認済環境',
      body: [
        <div key="requirements">
          ご利用の際の推奨環境は以下になります。
          （JavaScript、およびCookieが使用できる状態にしてご利用ください）
          <div style={{ margin: '12px 0' }}>
            <div style={{ fontWeight: 'bold' }}>PC版サイト</div>
            <div style={{ margin: '8px 0' }}>
              <div>【Windows OS】 </div>
              <p>Microsoft Edge</p>
              <p>Google Chrome 最新版</p>
              <p>Mozilla Firefox 最新版</p>
            </div>
            <div style={{ margin: '8px 0' }}>
              <div>【Mac OS】</div>
              <p>Safari 最新版</p>
            </div>
          </div>
          <div style={{ margin: '12px 0' }}>
            <div style={{ fontWeight: 'bold' }}>
              スマートフォン版サイト（タブレット）
            </div>
            <div style={{ margin: '8px 0' }}>
              <div>【iOS】</div>
              <p>iOS：iPhone 14 plus（iOS 17.2）</p>
              <p>Safari最新版</p>
            </div>
            <div style={{ margin: '8px 0' }}>
              <div>【iOS】</div>
              <p>iOS：iPad 10（iOS 17.0.3）</p>
              <p>Safari最新版</p>
            </div>
            <div style={{ margin: '8px 0' }}>
              <div>【Android OS】</div>
              <p>Android：Pixel 8 pro（Android 14）</p>
              <p>Chrome最新版</p>
            </div>
            <div style={{ margin: '8px 0' }}>
              <p>2024年3月8日時点</p>
            </div>

            <p>
              ※上記内容については動作確認済みの環境であり、動作を保証するものではありません。
              また、動作確認済みのOS・ブラウザーをご利用の場合でも、お客さまのご利用環境（設定、通信速度など）によっては正しく表示されない場合があります。
            </p>
            <p>※スマートフォンによる管理画面操作は非推奨します。</p>
            <p>
              ※管理画面において、下記の操作は動作保障外のため、行わないでください
            </p>
            <ul style={{ margin: '8px 0' }}>
              <li>
                ・複数ブラウザ、タブブラウザを使用して同時に管理画面を操作すること
              </li>
              <li>
                ・ブラウザの「戻る」や「進む」を使用してページ遷移すること
              </li>
              <li>
                ・ブラウザの「更新」やF5キーを使用してページの更新をすること
              </li>
            </ul>
          </div>
        </div>
      ]
    },
    {
      category: 'その他',
      title: 'その他ご不明な点はこちら',
      body: ['弊社営業担当 までお問い合わせ下さい。']
    }
  ]
});
