import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';

interface Auth0ProviderWrapperProps {
  children: ReactNode;
}

interface AppState {
  returnTo?: string;
  compId?: string;
}

export const Auth0ProviderWrapper = ({
  children
}: Auth0ProviderWrapperProps) => {
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const audience =
    process.env.REACT_APP_AUTH0_BAITORU_MANAGEMENT_API_IDENTIFER ?? '';
  if (!domain || !clientId) {
    throw new Error(
      'Auth0のドメインorクライアントIDが環境変数に設定されていません'
    );
  }

  const onRedirectCallback = (appState?: AppState) => {
    const redirectpath = appState?.returnTo;
    const compId = appState?.compId;

    if (compId && redirectpath) {
      navigate(redirectpath);
    } else {
      navigate(window.location.origin);
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/redirect`,
        scope: 'openid profile email offline_access',
        audience
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
