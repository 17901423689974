import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { CustomButton } from './CustomButton';

let _agreeCallback: () => Promise<void>;
let _disagreeCallback: () => Promise<void>;

export interface CommonDialogBoxHandler {
  open(
    msg: React.ReactNode,
    agreeCallback: () => Promise<void>,
    disagreeCallback?: () => Promise<void>,
    isTargetOfGa?: boolean
  ): void;
}

const CustomDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    overflowY: 'unset'
  }
}));

const CustomDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  gap: 10,
  padding: '20px 0 0 0',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  '.MuiButtonBase-root': {
    margin: 0
  }
}));

const CustomDialogContent = styled(DialogContent)(() => ({
  padding: 0,
  overflowY: 'unset'
}));

type Props = {
  isTargetOfGa?: boolean;
  gaTags?: { yes: string; no: string };
  isOpened?: boolean;
  msg: React.ReactNode;
  onNo?: () => void;
  onYes?: () => void;
  msgStyle?: React.CSSProperties;
};
/**
 * 共通ダイアログコンポーネント
 * @param {boolean} isOpened 開かれているかどうか
 * @param {React.ReactNode} msg ダイアログ内に表示するメッセージ
 * @param {Function} onNo ダイアログにて、「いいえ」が押された時に動く関数
 * @param {Function} onYes ダイアログにて、「はい」が押された時に動く関数
 * @param {React.CSSProperties} msgStyle メッセージにかけるスタイルリスト
 * @param {boolean} isTargetOfGa GAのイベント送信対象かどうか（これをtrueにするとダイアログ内のDOMにGA計測用の属性をつける）
 * @param {Object} gaTags GAのイベント送信用のタグ
 * @returns
 */
function CommonDialogBox({
  isOpened = false,
  msg,
  onNo,
  onYes,
  msgStyle,
  isTargetOfGa,
  gaTags
}: Props) {
  return (
    <div>
      <CustomDialog
        open={isOpened}
        onClose={onNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CustomDialogContent>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            style={{ color: '#595959' }}
          >
            <span style={{ ...msgStyle }}>{msg}</span>
          </DialogContentText>
        </CustomDialogContent>
        <CustomDialogActions>
          <span
            data-ga-selector={
              isTargetOfGa ? gaTags?.no ?? 'CommonDIalogNoBtn' : ''
            }
          >
            <CustomButton
              width="250px"
              height="44px"
              textcolor="var(--kanri-primary)"
              border="1px solid"
              bordercolor="var(--kanri-primary)"
              backgroundcolor="white"
              hoveropacity="1"
              hoverborder="2px solid"
              hoverbordercolor="var(--kanri-primary)"
              disabled={false}
              onClick={onNo}
            >
              いいえ
            </CustomButton>
          </span>
          <span
            data-ga-selector={
              isTargetOfGa ? gaTags?.yes ?? 'CommonDIalogYesBtn' : ''
            }
          >
            <CustomButton
              width="250px"
              height="44px"
              textcolor="#ffffff"
              border="1px solid"
              bordercolor="#3E91D5"
              backgroundcolor="#3E91D5"
              hoveropacity="0.85"
              hoverborder="2px solid"
              hoverbordercolor="#3E91D5"
              disabled={false}
              onClick={onYes}
            >
              はい
            </CustomButton>
          </span>
        </CustomDialogActions>
      </CustomDialog>
    </div>
  );
}

/**
 * 共通ダイアログコンポーネント
 * @property {React.CSSProperties} msgStyle メッセージにかけるスタイルリスト
 * @property {Object} gaTags GAのイベント送信用のタグ
 */
interface CommonDialogBoxWithRefProps {
  msgStyle?: React.CSSProperties;
  gaTags?: { yes: string; no: string };
}

export const CommonDialogBoxWithRef = forwardRef<
  CommonDialogBoxHandler,
  CommonDialogBoxWithRefProps
>((props, ref) => {
  const [isOpened, setOpened] = useState(false);
  const [msg, setMsg] = useState<React.ReactNode>('');
  const [isTargetOfGa, setIsTargetOfGa] = useState(false);

  useImperativeHandle(ref, () => ({
    open(msg, onYes, onNo, isTargetOfGa = false) {
      setMsg(msg);
      setIsTargetOfGa(isTargetOfGa);
      _agreeCallback = onYes;
      if (onNo) {
        _disagreeCallback = onNo;
      }
      setOpened(true);
    }
  }));

  const onNo = useCallback(async () => {
    setOpened(false);
    if (_disagreeCallback) await _disagreeCallback();
  }, [_disagreeCallback]);

  const onYes = useCallback(async () => {
    setOpened(false);
    if (_agreeCallback) await _agreeCallback();
  }, [_agreeCallback]);

  return (
    <CommonDialogBox
      isTargetOfGa={isTargetOfGa}
      gaTags={props.gaTags}
      msg={msg}
      isOpened={isOpened}
      onNo={onNo}
      onYes={onYes}
      msgStyle={props.msgStyle}
    />
  );
});
