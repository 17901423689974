import {
  MAX_URL_LENGTH,
  urlFormatErrorMsg
} from 'validation/rules/shared/consts';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

type Rules = RegisterOptions<
  FormData,
  `staffInterview.interviews.${number}.youtubeUrl`
>;

/**
 * スタッフインタビューのYouTube動画URLのバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useStaffInterviewYouTubeURLValidationRules = (): Rules => {
  const propertyName = 'YouTubeの動画URL';
  return useMemo(
    () => ({
      validate: {
        validUrl: (value) => {
          if (!value) return undefined;

          return (value.startsWith('https://youtu.be/') ||
            value.startsWith('https://www.youtube.com/')) &&
            value.length <= MAX_URL_LENGTH
            ? undefined
            : urlFormatErrorMsg(propertyName);
        }
      }
    }),
    []
  );
};
