import { generateTextRule } from '../shared';

/**
 * 採用担当からのメッセージの本文のバリデーションルールを返す
 * @param {boolean} isRequired 必須であるか
 * @returns バリデーションルール
 */
export const useMessageTextValidationRules = (isRequired: boolean) => {
  const propertyName = 'メッセージ本文';
  const maxLength = 300;
  return generateTextRule<'message.text'>(propertyName, maxLength, isRequired);
};
