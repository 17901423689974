import { FC } from 'react';
import NextButton from '../NextButton';
import CorporationNameField from './CorporationNameField';
import EmploymentStatusField from './EmploymentStatusField';
import OccupationsField from './OccupationsField';

type Props = {
  canGoToNext?: boolean;
  onClickNext?: () => void;
};

/**
 * 初期設定モーダルのSTEP1コンテンツを返す
 * @param {boolean | undefined} canGoToNext 次ステップへ進むことができるか
 * @param {Function | undefined} onClickNext 次へボタンがクリックされた際に実行される関数
 * @returns 初期設定モーダルのSTEP1コンテンツ
 */
const BasicStepContent: FC<Props> = ({ canGoToNext = false, onClickNext }) => {
  return (
    <>
      <div className="dialog-content-wrap basic">
        <CorporationNameField />
        <EmploymentStatusField />
        <OccupationsField />
      </div>
      <div className="button-wrap justify-end">
        <span data-ga-selector="InitialSettingDIalogNextBtn">
          <NextButton
            label="次へ"
            isDisabled={!canGoToNext}
            isHoverDisabled={!canGoToNext}
            onClick={onClickNext}
          />
        </span>
      </div>
    </>
  );
};

export default BasicStepContent;
