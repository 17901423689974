import { FC } from 'react';

type Props = {
  maxLength: number;
  remainingLength: number;
  overLength: number;
  isError?: boolean;
};

/**
 * テキストフィールドの文字数ヘルパーテキストを返す
 * @param {number} maxLength 最大文字数
 * @param {number} remainingLength 残りの文字数
 * @param {number} overLength オーバーしている文字数
 * @param {boolean | undefined} isError エラーであるか
 * @returns テキストフィールドの文字数ヘルパーテキスト
 */
const TextFieldCountHelperText: FC<Props> = ({
  maxLength,
  remainingLength,
  overLength,
  isError = false
}: Props) => {
  return (
    <>
      <span className="MuiFormHelperText-left">
        <span className="MuiFormHelperText-bold">{maxLength}</span>文字まで
      </span>
      <span className="MuiFormHelperText-right">
        {isError ? (
          <>
            <span className="MuiFormHelperText-bold">{overLength}</span>
            文字オーバー
          </>
        ) : (
          <span style={{ color: 'black' }}>
            残り
            <span className="MuiFormHelperText-bold">{remainingLength}</span>
            文字
          </span>
        )}
      </span>
    </>
  );
};

export default TextFieldCountHelperText;
