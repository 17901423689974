import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-50, 20] // x, y オフセット
          }
        }
      ]
    }}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}));

export default StyledTooltip;
