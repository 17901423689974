import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OutlinedInput, styled } from '@mui/material';
import useImageDownloader from 'hooks/saiyouPageEditing/useImageDownloader';
import { PresetImageData } from 'types/saiyouPageEditing/PresetImageData';
import { CustomButton } from '../../../../../parts/CustomButton';
import ImageRadioGroup from './ImageRadioGroup';

type Props = {
  selectedImageUrl?: string;
  onSelectImage?: (imageUrl?: string) => void;
  onServerError?: () => void;
};

/**
 * 「バイトル管理画面の画像から選ぶ」タブを返す
 * @param {string | undefined} selectedImageUrl 選択されている画像のURL
 * @param {Function | undefined} onSelectImage 画像が選択された際に実行される関数
 * @param {Function | undefined} onServerError サーバーエラーが発生した際に実行される関数
 * @returns 「バイトル管理画面の画像から選ぶ」タブ
 */
const BaitoruImageTab: FC<Props> = ({
  selectedImageUrl = '',
  onSelectImage,
  onServerError
}) => {
  const { compId } = useParams();

  const { downloadImagesAsync } = useImageDownloader('baitoru');

  const [imageOptions, setImageOptions] = useState<PresetImageData[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchWord, setSearchWord] = useState('');
  const [searchWordCandidate, setSearchWordCandidate] = useState('');
  const [hasLoadingProgress, setLoadingProgress] = useState(false);
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  const selectedImage = useMemo(
    () => imageOptions.find(({ url }) => url === selectedImageUrl),
    [selectedImageUrl, imageOptions]
  );

  const handleChangeSearch = useCallback(
    (value: string) => setSearchWordCandidate(value),
    []
  );

  const handleClickSearch = useCallback(() => {
    setPageIndex(1);
    setLoadingComplete(false);

    setSearchWord(searchWordCandidate);
  }, [searchWordCandidate]);

  const handleScrollBottom = useCallback(
    () => setPageIndex((current) => current + 1),
    []
  );

  const finishLoading = useCallback(() => {
    setLoadingProgress(false);
    setLoadingComplete(true);
  }, []);

  useEffect(() => {
    const downloadAsync = async (localCompId: string) => {
      setLoadingProgress(true);

      const result = await downloadImagesAsync(
        localCompId,
        pageIndex,
        searchWord
      );

      if (!result.isSuccess) {
        finishLoading();
        onServerError?.();

        return;
      }

      if (pageIndex <= 1) setImageOptions(result.images);
      else
        setImageOptions((currentImageOptions) =>
          currentImageOptions.concat(result.images)
        );

      setLoadingProgress(false);
    };

    if (compId && !isLoadingComplete) void downloadAsync(compId);
  }, [
    compId,
    pageIndex,
    searchWord,
    isLoadingComplete,
    onServerError,
    downloadImagesAsync,
    finishLoading
  ]);

  return (
    <div className="baitoru-management-image">
      <div className="baitoru-management-image-search">
        <CustomInput
          placeholder="画像ファイル名で検索できます"
          onChange={(e) => handleChangeSearch(e.target.value)}
        />
        <CustomButton
          width="120px"
          height="46px"
          textcolor="var(--kanri-primary)"
          border="1px solid"
          bordercolor="var(--kanri-primary)"
          backgroundcolor="white"
          hoveropacity="1"
          hoverborder="2px solid"
          hoverbordercolor="var(--kanri-primary)"
          disabled={false}
          onClick={handleClickSearch}
        >
          検索
        </CustomButton>
      </div>
      <div>
        <ImageRadioGroup
          selectedImage={selectedImage}
          imageOptions={imageOptions}
          hasLoadingProgress={hasLoadingProgress}
          onSelect={onSelectImage}
          onScrollBottom={handleScrollBottom}
        />
      </div>
    </div>
  );
};

export default BaitoruImageTab;

const CustomInput = styled(OutlinedInput)(() => ({
  width: 955,
  height: 46,
  marginRight: 5,
  '@media screen and (max-width:800px)': {
    fontSize: '10.5px'
  },
  input: {
    padding: 5
  }
}));
