/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MasterTok3Dto } from './MasterTok3Dto';
import {
    MasterTok3DtoFromJSON,
    MasterTok3DtoFromJSONTyped,
    MasterTok3DtoToJSON,
} from './MasterTok3Dto';

/**
 * 
 * @export
 * @interface MasterTok2Dto
 */
export interface MasterTok2Dto {
    /**
     * 中特徴タイプ
     * @type {string}
     * @memberof MasterTok2Dto
     */
    tok2Type?: string;
    /**
     * 中特徴名
     * @type {string}
     * @memberof MasterTok2Dto
     */
    name?: string;
    /**
     * 小特徴マスタ
     * @type {Array<MasterTok3Dto>}
     * @memberof MasterTok2Dto
     */
    tok3?: Array<MasterTok3Dto>;
}

/**
 * Check if a given object implements the MasterTok2Dto interface.
 */
export function instanceOfMasterTok2Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterTok2DtoFromJSON(json: any): MasterTok2Dto {
    return MasterTok2DtoFromJSONTyped(json, false);
}

export function MasterTok2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterTok2Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tok2Type': !exists(json, 'tok2Type') ? undefined : json['tok2Type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tok3': !exists(json, 'tok3') ? undefined : ((json['tok3'] as Array<any>).map(MasterTok3DtoFromJSON)),
    };
}

export function MasterTok2DtoToJSON(value?: MasterTok2Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tok2Type': value.tok2Type,
        'name': value.name,
        'tok3': value.tok3 === undefined ? undefined : ((value.tok3 as Array<any>).map(MasterTok3DtoToJSON)),
    };
}

