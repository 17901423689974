import React from 'react';

/**
 * @typedef {object} TabContentSubHeadProps
 * @property {string} title - サブヘッダーのタイトル文字列
 */
type TabContentSubHeadProps = {
  title: string;
};

/**
 * タブコンテンツのサブヘッダーを管理するコンポーネント。
 * @param {TabContentSubHeadProps} props - コンポーネントのプロパティ
 * @returns {JSX.Element} サブヘッダーのレンダリング
 */
const TabContentSubHead: React.FC<TabContentSubHeadProps> = ({ title }) => {
  return (
    <div
      style={{
        marginBottom: 5
      }}
    >
      <h3
        style={{
          display: 'flex',
          height: 46,
          padding: '15px 5px 15px 15px',
          alignItems: 'center',
          gap: 4,
          alignSelf: 'stretch',
          borderRadius: 8,
          marginBottom: 5,
          background: 'var(--kanri-bg-02, #E1E4ED)',
          color: 'var(--kanri-primary-text, #333)',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '100%'
        }}
      >
        {title}
      </h3>
    </div>
  );
};

export default TabContentSubHead;
