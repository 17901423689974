import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from '@mui/material';
import PopoverLinkCard from '../popover/PopoverLinkCard';
import { MenuItem } from '..';

interface CustomListItemButtonProps extends ButtonProps {
  open: boolean;
  transitionduration: string;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(Button)<CustomListItemButtonProps>(
  ({ open, transitionduration }) => ({
    transitionDuration: transitionduration,
    minHeight: open ? 46 : 48,
    justifyContent: open ? 'initial' : 'center',
    paddingLeft: 2.5,
    paddingRight: 2.5,
    '&:hover': {
      backgroundColor: 'var(--kanri-primary-hover)'
    }
  })
);
/**
 * メニューが閉じている時のリンク一覧のアイコンベース
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @param onClick ボタンクリック時のイベント
 * @param openLinkMenu ポップオーバーの開閉判定
 * @param anchorEl ポップオーバーのアンカー
 * @returns
 */
interface MoreVertIconBaseProps {
  children: React.ReactNode;
  open: boolean;
  transitionduration: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openLinkMenu: boolean;
  anchorEl: HTMLButtonElement | null;
  menuItem: MenuItem[];
}
export const MoreVertIconBase = React.memo(
  ({
    children,
    open,
    transitionduration,
    onClick,
    openLinkMenu,
    anchorEl,
    menuItem
  }: MoreVertIconBaseProps) => {
    return (
      <>
        <CustomListItemButton
          open={open}
          transitionduration={transitionduration}
          onClick={onClick}
        >
          {children}
        </CustomListItemButton>
        <PopoverLinkCard
          openLinkMenu={openLinkMenu}
          open={open}
          anchorEl={anchorEl}
          onClick={onClick}
          menuItem={menuItem}
        />
      </>
    );
  }
);
