import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { PrivatePageLayout } from 'components/layouts/PrivatePageLayout';
import { TopPageHeader } from './TopPageHeader';
import { CommonFooter } from 'components/parts/footer';
import {
  DesignColorServiceIcon,
  ContentsSettingsIcon,
  SeoSettingsIcon,
  SiteSettingsIcon
} from 'assets/icons';
import { SettingCard } from './parts/SettingCard';
import { Status } from './parts/Status';
import './style.scss';
import { Link, useParams } from 'react-router-dom';
import {
  changePageStatus,
  clearPageCache,
  getPageSetting
} from 'logic/clientWrapper/management';
import { useAuth0 } from '@auth0/auth0-react';
import {
  MeResDtoIsAvailablePublishEnum,
  PageSettingAdminReferenceResDto
} from 'api-client/management';
import {
  CommonSnackbarWithRef,
  SnackbarHandler
} from 'components/parts/SnackBarWrapper';
import { useNavigate } from 'react-router-dom';
import { useLoginContext } from 'components/LoginWrapper';
import { CacheClearButton } from './parts/CacheClear';
import {
  CommonDialogBoxWithRef,
  CommonDialogBoxHandler
} from 'components/parts/CommonDialogBox';
import { UrlBar } from './parts/UrlBar';
import { getPolicySetting } from 'logic/clientWrapper/public';
import { PolicySettingReferenceResDto } from 'api-client/public';
import { WarningMessageArea } from 'components/parts/WarningMessageArea';

/**
 * トップページコンポーネント
 *
 * 各種設定や公開ステータスを管理するダッシュボードです。
 *
 * @component
 */
export const TopPage: FC = memo(() => {
  const { compId } = useParams();
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [pageSetting, setPageSetting] = useState<
    PageSettingAdminReferenceResDto | undefined
  >();
  const [policySetting, setPolicySetting] =
    useState<PolicySettingReferenceResDto | null>(null);
  const snackRef = useRef<SnackbarHandler | null>(null);
  const alertRef = useRef<CommonDialogBoxHandler | null>(null);

  /**
   * ページ設定の更新
   *
   * サーバからページ設定を取得してstateを更新します。
   * エラーが発生した場合、Snackbarで通知します。
   */
  const updatePageSetting = async () => {
    const pg = await getPageSetting(compId, auth0);
    // レスポンスがNullならエラー表示
    if (!pg) snackRef.current?.open('serverError');
    // プレビューデータがなければ編集画面で初期設定
    else if (!pg?.preview?.updatedAt) navigate(`/${compId}/edit`);
    else setPageSetting(pg);
  };

  const getIsPublished = useCallback(() => {
    return pageSetting?.published?.isOpen === 1;
  }, [pageSetting?.published?.isOpen]);

  useEffect(() => {
    (async () => {
      await updatePageSetting();
      const policy = await getPolicySetting(compId);
      setPolicySetting(policy);
    })();
  }, []);

  const { meInfo } = useLoginContext();
  const myRoleCodes = meInfo?.roleCodes ?? [];
  const canCacheclear = myRoleCodes.includes(1);
  const canChangeStatus =
    policySetting &&
    policySetting.updatedAt &&
    pageSetting?.published?.updatedAt &&
    meInfo?.isAvailablePublish === MeResDtoIsAvailablePublishEnum._1;

  const onClickStatusHandler = async () => {
    const res = await changePageStatus(compId, auth0, getIsPublished() ? 0 : 1);
    snackRef.current?.open(res ? 'success' : 'serverError');
    await updatePageSetting();
  };
  /**
   * ステータス変更ボタンクリック時
   */
  const onClickStatus = async () => {
    alertRef.current?.open(
      <div
        style={{
          display: 'flex',
          gap: 20,
          flexDirection: 'column'
        }}
      >
        <span
          style={{
            display: 'inline-block',
            margin: 'auto',
            color: 'var(--kanri-primary-text)',
            fontSize: 16,
            lineHeight: '160%'
          }}
        >
          {`採用サイトを${!getIsPublished() ? '公開' : '非公開'}にしますか？`}
        </span>
        <ul
          style={{
            color: 'var(--kanri-secondary-text)',
            fontSize: 12,
            lineHeight: '130%',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
          }}
        >
          {getIsPublished() ? (
            <>
              <li>
                ※採用サイトのほか、採用ページコボットの求人一覧や詳細ページも非公開になります。
              </li>
              <li>
                ※各検索エンジン（Google,Yahoo!など）のSEO評価がリセットされます。
              </li>
              <li>
                ※各検索エンジンの検索結果に反映されるまでしばらく時間がかかる場合があります。
              </li>
            </>
          ) : (
            <>
              <li>
                ※各検索エンジンの検索結果に反映されるまでしばらく時間がかかる場合があります。
              </li>
              <li>
                ※入力したサイト情報、個人情報利用規約に誤りがないことを確認してから公開してください。
              </li>
            </>
          )}
        </ul>
      </div>,
      onClickStatusHandler
    );
  };

  /**
   * キャッシュクリアボタンのクリックイベント
   *
   * キャッシュをクリアする前にダイアログを表示します。
   * ユーザが確認した後、キャッシュがクリアされます。
   */
  const onClickCacheClear = async () => {
    alertRef.current?.open(
      'ページのキャッシュをクリアします。よろしいですか？',
      async () => {
        const res = await clearPageCache(compId, auth0);
        snackRef.current?.open(res ? 'success' : 'serverError');
      }
    );
  };

  //TODO: 編集画面実装後にtoを微調整する
  const settingPg = `/${compId}/edit`;
  const settings = [
    {
      title: 'デザイン・カラー設定',
      icon: DesignColorServiceIcon,
      iconAlt: '',
      to: settingPg + '#design',
      description:
        'TOPページのデザインや、求人一覧・詳細ページなどのカラーを設定できます。'
    },
    {
      title: 'コンテンツ設定',
      icon: ContentsSettingsIcon,
      iconAlt: '',
      to: settingPg + '#content',
      description:
        'TOPページ内の各コンテンツの内容やレイアウト変更、画像の設定ができます。'
    },
    {
      title: 'サイト設定',
      icon: SiteSettingsIcon,
      iconAlt: '',
      to: settingPg + '#site',
      description:
        '会社名や会社ロゴ、会社ホームページのURL、SNS公式アカウントなどの設定ができます。'
    },
    {
      title: 'SEO設定',
      icon: SeoSettingsIcon,
      iconAlt: '',
      to: settingPg + '#seo',
      description:
        'サイトタイトルや説明文のほか、ブランド名の設定など、SEOに関する設定ができます。'
    }
  ];

  return (
    <>
      <CommonSnackbarWithRef ref={snackRef} />
      <CommonDialogBoxWithRef ref={alertRef} />
      <PrivatePageLayout>
        <div className="top_wrap">
          <TopPageHeader />
          <div className="top_content">
            {pageSetting && !pageSetting?.published?.updatedAt && (
              <WarningMessageArea>
                採用サイトが未公開です。
                <Link to={`/${compId}/edit`} className="top_content_link">
                  こちら
                </Link>
                の画面で初期設定を再開してください
              </WarningMessageArea>
            )}
            <div className="top_status_base">
              <div className="top_status_card">
                <Status
                  pageSetting={pageSetting}
                  onClickStatus={canChangeStatus ? onClickStatus : undefined}
                />
                <UrlBar
                  title={'採用サイトURL'}
                  url={`${process.env.REACT_APP_PUBLIC_FRONT_HOST}/${compId}`}
                  isPublished={getIsPublished()}
                />
                <UrlBar
                  title={'利用規約'}
                  url={`${process.env.REACT_APP_PUBLIC_FRONT_HOST}/${compId}/policy`}
                  isPublished={getIsPublished()}
                  helpText="求職者が応募時に確認する利用規約です。 貴社独自の内容になっていますのでご確認ください。"
                />
              </div>
            </div>
            <div className="top_settings_base">
              <div className="top_settings_title_base">
                <h3 className="top_settings_title_title">採用サイトの設定</h3>
                <h4 className="top_settings_title_description">
                  求職者に貴社の魅力を伝えましょう
                </h4>
              </div>
              <div className="top_settings_panel">
                <ul className="top_settings_panel_base">
                  {settings.map((setting, index) => (
                    <SettingCard setting={setting} index={index} key={index} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {canCacheclear && <CacheClearButton onClick={onClickCacheClear} />}
          <CommonFooter pt="commonPage" />
        </div>
      </PrivatePageLayout>
    </>
  );
});
