import { generateTextRule } from './shared';

/**
 * 表示用企業名のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useCorporationNameValidationRules = () => {
  const propertyName = '表示用企業名';
  const maxLength = 100;
  return generateTextRule<'corporationName'>(propertyName, maxLength, true);
};
