import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styles from './style.module.scss';
import {
  ListItem,
  ListItemText,
  Box,
  List,
  Typography,
  IconButton
} from '@mui/material';
import {
  AccountIcon,
  MenuCloseIcon,
  ArrowRightIcon,
  DescriptionIcon,
  HomeIcon,
  OpenInNewIcon,
  MenuOpenIcon,
  MoreVertIcon,
  NoteIcon
} from 'assets/icons';
import {
  CustomDrawer,
  CustomDrawerSpacer,
  CustomListItemText,
  CustomDivider,
  CustomList,
  MenuItemObject
} from './parts';
import {
  MenuListItemButton,
  MoreVertIconBase,
  UserInformationBase
} from './parts/listitemButton';
import {
  UserIconBase,
  ListItemMoreVertIcon,
  MenuIconBase
} from './parts/listItemIcon';
import MencoboLogo from 'assets/img/mencoboLogo.png';
import BaitoruLogo from 'assets/img/baitoru.png';
import { PageSettingAdminReferenceResDto } from 'api-client/management';
import { useLoginContext } from 'components/LoginWrapper';
import { StatusBar } from './parts/StatusBar';
import { CustomAppBar } from './parts/AppBar';
import { TooltipQuestion } from '../tooltipQuestion';

const drawerwidth = 220;
const transitionDuration = '250ms';

interface HeaderMenuProps {
  children: React.ReactNode;
  mainContentWidth?: number;
  headerMode?: 'default' | 'edit';
  changeSlider?: (value: number) => void;
  sliderValue?: number;
  changeDevice?: (value: number) => void;
  deviceValue?: number;
  onClickPreview?: () => void;
  pageSetting?: PageSettingAdminReferenceResDto;
}
export function TopHeaderMenu({
  children,
  mainContentWidth,
  headerMode = 'default',
  changeSlider,
  sliderValue,
  changeDevice,
  deviceValue,
  onClickPreview,
  pageSetting
}: HeaderMenuProps) {
  const { compId } = useParams();
  const blackListPath = ['edit'];
  const path = useLocation().pathname ?? '';
  const pathArray = path.split('/');
  const isMenuClose = pathArray.some((path) => blackListPath.includes(path));
  const [open, setOpen] = React.useState(!isMenuClose);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [openLinkMenu, setOpenLinkMenu] = React.useState(false);

  const [userMenuAnchorEl, setUserMenuAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [linkAnchorEl, setLinkAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const customListRef = React.useRef<HTMLUListElement>(null);
  const boxRef = React.useRef<HTMLUListElement>(null);

  const { meInfo } = useLoginContext();

  /**
   * メニューの開閉ボタンが押された時の動作
   * @returns
   */
  const handleDrawer = () => {
    setOpen(!open);
  };

  /**
   * メニューにおける、「トップページ」の上のユーザ情報が押されたときの動作
   * @param event クリックイベント
   * @returns
   */
  const handleUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenUserMenu(!openUserMenu);
    setUserMenuAnchorEl(openUserMenu ? null : event.currentTarget);
  };

  /**
   * メニューにおける、一番下の3点マークが押されたときの動作
   * @param event クリックイベント
   * @returns
   */
  const handleOpenLinkMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenLinkMenu(!openLinkMenu);
    setLinkAnchorEl(openLinkMenu ? null : event.currentTarget);
  };

  React.useEffect(() => {
    //TODO パワープレイになっているので修正の必要あり。これらは子コンポーネント内へ移せない？
    if (headerMode != 'edit') return;
    const listElement = customListRef.current;
    const boxElement = boxRef.current;

    const handleAnimationEnd = (event: TransitionEvent) => {
      if (event.target === listElement && event.propertyName === 'opacity') {
        if (!open && listElement && listElement.style) {
          listElement.style.display = 'none';
        }
        if (!open && boxElement && boxElement.style) {
          boxElement.style.display = 'none';
        }
      }
    };

    if (open && listElement && listElement.style) {
      listElement.style.display = 'block';
    } else if (listElement && listElement.style) {
      listElement.style.display = 'none';
    }
    if (open && boxElement && boxElement.style) {
      boxElement.style.display = 'block';
    } else if (boxElement && boxElement.style) {
      boxElement.style.display = 'none';
    }

    if (listElement) {
      listElement.addEventListener('transitionend', handleAnimationEnd);
      return () => {
        listElement.removeEventListener('transitionend', handleAnimationEnd);
      };
    }
  }, [open]);

  const menuItem: MenuItemObject = {
    section1: [
      { text: 'トップページ', icon: <HomeIcon />, path: `/${compId}/top` },
      {
        text: '採用サイトの設定',
        icon: <DescriptionIcon />,
        path: `/${compId}/edit`
      },
      {
        text: '個人情報利用規約の設定',
        icon: <NoteIcon />,
        path: `/${compId}/policy-edit`
      }
    ],
    section2: [
      {
        text: 'ヘルプ/マニュアル',
        icon: <TooltipQuestion />,
        onClick: () => {
          window.open('/help');
        }
      }
    ],
    section3: [
      {
        text: 'バイトル管理画面',
        cardLogo: BaitoruLogo,
        cardTitle: '求人原稿管理、応募者管理はこちら',
        cardImgHeight: '34px',
        icon: <OpenInNewIcon />,
        onClick: () => {
          window.open(process.env.REACT_APP_BAITORU_MANAGEMENT_URL);
        }
      },
      {
        text: '面接コボット',
        cardLogo: MencoboLogo,
        cardTitle: '応募者管理､面接コボットの設定はこちら',
        cardImgHeight: '32px',
        icon: <OpenInNewIcon />,
        onClick: () => {
          window.open(process.env.REACT_APP_MENKOBO_URL);
        }
      }
    ]
  };

  return (
    <Box
      className={
        styles.wrapperBox +
        (headerMode === 'edit' ? ' ' + styles.absoluteScreen : '')
      }
    >
      {headerMode == 'edit' ? (
        <>
          <CustomAppBar
            open={false}
            drawerwidth={0}
            transitionduration=""
            compId={compId}
            isEditPage={true}
            deviceValue={deviceValue}
            changeDevice={changeDevice}
            onClickPreview={onClickPreview}
          />
          <StatusBar
            changeSlider={changeSlider}
            sliderValue={sliderValue}
            pageSetting={pageSetting}
          />
        </>
      ) : (
        <CustomAppBar
          open={open}
          drawerwidth={drawerwidth}
          transitionduration={transitionDuration}
          compId={compId}
          isEditPage={false}
        />
      )}

      <CustomDrawer
        open={open}
        drawerwidth={drawerwidth}
        customtransitionduration={transitionDuration}
        isEditPage={headerMode == 'edit'}
      >
        <CustomDrawerSpacer
          open={open}
          onClick={handleDrawer}
          transitionduration={transitionDuration}
        >
          <IconButton color="inherit" aria-label="handle drawer">
            {open ? (
              <MenuCloseIcon
                style={{ width: 22, height: 22, fill: '#595959' }}
              />
            ) : (
              <MenuOpenIcon style={{ fill: '#595959' }} />
            )}
          </IconButton>
        </CustomDrawerSpacer>
        <CustomDivider open={open} isEditPage={headerMode == 'edit'} />
        <CustomList
          open={open}
          transitionduration={transitionDuration}
          isEditPage={headerMode == 'edit'}
          ref={customListRef}
        >
          <ListItem key="Inbox" disablePadding sx={{ display: 'block' }}>
            <UserInformationBase
              open={open}
              transitionduration={transitionDuration}
              onClick={handleUserMenu}
              openUserMenu={openUserMenu}
              anchorEl={userMenuAnchorEl}
            >
              <UserIconBase open={open} transitionduration={transitionDuration}>
                <AccountIcon />
                <ArrowRightIcon
                  style={{
                    width: open ? '10px' : '8px',
                    height: open ? '10px' : '8px',
                    marginLeft: '4px'
                  }}
                />
              </UserIconBase>
              <div
                style={{
                  transitionDuration: transitionDuration,
                  opacity: open ? 1 : 0,
                  flex: '1 1 auto',
                  minWidth: 0,
                  marginTop: '4px',
                  marginBottom: '4px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    opacity: open ? 1 : 0
                  }}
                >
                  <div
                    style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
                  >
                    <AccountIcon
                      style={{
                        margin: 'auto',
                        height: '40px',
                        width: '40px'
                      }}
                    />
                    <div style={{ width: '100%', marginTop: '10px' }}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          marginTop: '0',
                          // font setting
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '130%',
                          color: '#333333'
                        }}
                      >
                        {meInfo?.name}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      right: 5,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 1
                    }}
                  >
                    <ArrowRightIcon style={{ width: '10px', height: '10px' }} />
                  </div>
                </div>
              </div>
            </UserInformationBase>
          </ListItem>
        </CustomList>
        <Box
          ref={boxRef}
          sx={
            headerMode == 'edit'
              ? {
                  transition: 'opacity ' + transitionDuration,
                  opacity: open ? 1 : 0,
                  pointerEvents: open ? 'auto' : 'none'
                }
              : {}
          }
        >
          {Object.keys(menuItem).map((key, index) => {
            return (
              <div key={`${key}-${index}`}>
                <CustomDivider open={open} />
                <List sx={{ padding: open ? '20px 0' : 0 }}>
                  {key === 'section3' && !open ? (
                    <ListItem
                      key={`${key}-close`}
                      disablePadding
                      sx={{
                        display: 'block'
                      }}
                    >
                      <MoreVertIconBase
                        open={open}
                        transitionduration={transitionDuration}
                        onClick={handleOpenLinkMenu}
                        openLinkMenu={openLinkMenu}
                        anchorEl={linkAnchorEl}
                        menuItem={menuItem[key]}
                      >
                        <ListItemMoreVertIcon open={open}>
                          <MoreVertIcon />
                        </ListItemMoreVertIcon>
                        <ListItemText />
                      </MoreVertIconBase>
                    </ListItem>
                  ) : (
                    <>
                      {menuItem[key].map((item, index) => (
                        <ListItem
                          key={`${index}-${item}`}
                          disablePadding
                          sx={{
                            display: 'block'
                          }}
                        >
                          <Link
                            to={item.path ?? ''}
                            onClick={item.onClick}
                            className={
                              key === 'section2'
                                ? styles.top_menu_help_icon
                                : ''
                            }
                          >
                            <MenuListItemButton
                              open={open}
                              transitionduration={transitionDuration}
                              path={path}
                              itempath={item.path ?? ''}
                            >
                              <MenuIconBase
                                open={open}
                                path={path}
                                itempath={item.path ?? ''}
                              >
                                {item.icon}
                              </MenuIconBase>
                              <CustomListItemText
                                primary={item.text}
                                open={open}
                                transitionduration={transitionDuration}
                              />
                            </MenuListItemButton>
                          </Link>
                        </ListItem>
                      ))}
                    </>
                  )}
                </List>
              </div>
            );
          })}
        </Box>
      </CustomDrawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          padding: 0,
          position: 'relative',
          ...(mainContentWidth && { width: mainContentWidth })
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
