import { ClickAwayListener, Tooltip } from '@mui/material';
import { styled } from '@mui/material';
import { useState } from 'react';
import { CopyAllIcon, OpenInNewIcon } from 'assets/icons';
import { CopyButton } from '../../../../../../CopyButton';
import { NewTabButton } from '../../../../../../NewTabButton';
export const LastStepContent = ({
  hasPublished,
  compId
}: {
  hasPublished: boolean;
  compId: string;
}) => {
  const url = `${process.env.REACT_APP_PUBLIC_FRONT_HOST}/${compId}`;
  const [openToolChip, setOpenToolChip] = useState(false);
  const clickCopyUrl = () => {
    if (!openToolChip) {
      navigator.clipboard.writeText(url);
    }
    setOpenToolChip(!openToolChip);
  };
  const closeCopyUrl = () => {
    setOpenToolChip(false);
  };
  const openNewTab = () => {
    window.open(url);
  };
  return (
    <div>
      <URLContainer>
        <URLLabel>URL</URLLabel>
        <URL>{url}</URL>
      </URLContainer>
      <ActionButtons>
        <ClickAwayListener onClickAway={closeCopyUrl}>
          <Tooltip
            title="URLをコピーしました"
            placement="top"
            arrow
            open={openToolChip}
            onClose={closeCopyUrl}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            PopperProps={{
              disablePortal: true
            }}
          >
            <CopyButton
              disabled={!hasPublished}
              startIcon={<CopyAllIcon />}
              variant="outlined"
              className="top_status_openUrl_copyButton"
              onClick={clickCopyUrl}
            ></CopyButton>
          </Tooltip>
        </ClickAwayListener>
        <NewTabButton onClick={openNewTab} disabled={!hasPublished}>
          <p className="top_status_openUrl_newWindow_title">URLを開く</p>
          <OpenInNewIcon className="top_status_openUrl_newWindow_icon" />
        </NewTabButton>
      </ActionButtons>
    </div>
  );
};
const URLContainer = styled('div')(() => ({
  display: 'flex',
  gap: '5px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0',
  wordBreak: 'break-word'
}));
const ActionButtons = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-end'
}));
const URLLabel = styled('div')(() => ({
  padding: '8px',
  textAlign: 'center',
  flexShrink: 0,
  borderRadius: '4px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '130%',
  background: 'var(--color-palette-achromatic-color-color-3, #EEE)'
}));
const URL = styled('div')(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '130%'
}));
