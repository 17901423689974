import { generateTextRule } from '../../shared';

/**
 * スタッフインタビューのインタビュー本文のバリデーションルールを返す
 * @returns バリデーションルール
 */
export const useStaffInterviewTextValidationRules = () => {
  const propertyName = 'インタビュー本文';
  const maxLength = 500;
  return generateTextRule<`staffInterview.interviews.${number}.text`>(
    propertyName,
    maxLength,
    true
  );
};
