import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import { StockStatus } from 'enums/saiyouPageEditing/StockStatus';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useAddressValidationRules,
  useBusinessValidationRules,
  useCapitalValidationRules,
  useEarningsValidationRules,
  useEmployeesLengthValidationRules,
  useEstablishedYearValidationRules,
  useLicenseNo2ValidationRules,
  useLicenseNoValidationRules,
  usePresidentNameValidationRules,
  useWelfareValidationRules
} from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import GroupingSelectBox from '../../../../common/SelectBox/GroupingSelectBox';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

const stockOptions = [
  { label: '選択しない', value: StockStatus.Unselected },
  { label: '公開', value: StockStatus.Public },
  { label: '非公開', value: StockStatus.Private }
];

/**
 * 「会社概要」アコーディオンを返す
 * @returns 「会社概要」アコーディオン
 */
const CorporationAboutAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('corpAbout');

  const addressRules = useAddressValidationRules();
  const establishedYearRules = useEstablishedYearValidationRules();
  const capitalRules = useCapitalValidationRules();
  const earningsRules = useEarningsValidationRules();
  const presidentNameRules = usePresidentNameValidationRules();
  const employeesLengthRules = useEmployeesLengthValidationRules();
  const licenseNoRules = useLicenseNoValidationRules();
  const licenseNo2Rules = useLicenseNo2ValidationRules();
  const welfareRules = useWelfareValidationRules();
  const businessRules = useBusinessValidationRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="会社概要"
      dispCrown
      isExpanded={isExpanded}
      isRequired
      isError={
        errors.corporationAbout &&
        Object.keys(errors.corporationAbout).length > 0
      }
      onChange={(value) => onChange?.('corpAbout', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="会社概要の設定ができます。" />
        <TabContentFormWrap>
          <Controller
            name="corporationAbout.address"
            control={control}
            rules={{ ...addressRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="所在地"
                value={value}
                placeholder="〒000-0000&#13;東京都○○区○○1-1-1&#13;○○○○ビル 1F"
                maxLength={300}
                rows={8}
                helperText={
                  errors.corporationAbout?.address?.type !== 'maxLength'
                    ? errors.corporationAbout?.address?.message
                    : ''
                }
                hasLengthHelperText
                isRequired
                isError={!!errors.corporationAbout?.address}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.establishedYear"
            control={control}
            rules={{ ...establishedYearRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="設立年（西暦）"
                value={value?.toString()}
                placeholder="0000"
                maxLength={4}
                helperText={
                  errors.corporationAbout?.establishedYear?.type !== 'maxLength'
                    ? errors.corporationAbout?.establishedYear?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.establishedYear}
                onBlur={onBlur}
                onChange={(e) => {
                  if (!e.target.value || isNaN(+e.target.value))
                    onChange(undefined);
                  else onChange(+e.target.value);
                }}
              />
            )}
          />
          <Controller
            name="corporationAbout.capital"
            control={control}
            rules={{ ...capitalRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="資本金"
                value={value}
                placeholder="0,000万円"
                maxLength={20}
                helperText={
                  errors.corporationAbout?.capital?.type !== 'maxLength'
                    ? errors.corporationAbout?.capital?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.capital}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.earnings"
            control={control}
            rules={{ ...earningsRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="売上高"
                value={value}
                placeholder="0,000万円"
                maxLength={20}
                helperText={
                  errors.corporationAbout?.earnings?.type !== 'maxLength'
                    ? errors.corporationAbout?.earnings?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.earnings}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.stockStatus"
            control={control}
            rules={{ onChange: onChangeValue }}
            render={({ field: { value, onBlur, onChange } }) => (
              <GroupingSelectBox
                label="株式公開区分"
                values={value}
                options={stockOptions}
                onBlur={onBlur}
                onChange={(items) => {
                  onChange(
                    items.length > 0 ? items[0] : StockStatus.Unselected
                  );
                }}
              />
            )}
          />
          <Controller
            name="corporationAbout.presidentName"
            control={control}
            rules={{ ...presidentNameRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="代表者名"
                value={value}
                placeholder="代表取締役社長 田中太郎"
                maxLength={50}
                helperText={
                  errors.corporationAbout?.presidentName?.type !== 'maxLength'
                    ? errors.corporationAbout?.presidentName?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.presidentName}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.employeesLength"
            control={control}
            rules={{ ...employeesLengthRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="従業員数"
                value={value}
                placeholder="1,000"
                maxLength={10}
                helperText={
                  errors.corporationAbout?.employeesLength?.type !== 'maxLength'
                    ? errors.corporationAbout?.employeesLength?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.employeesLength}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.licenseNo"
            control={control}
            rules={{ ...licenseNoRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="派遣許認可番号"
                value={value}
                placeholder="派00-000000"
                maxLength={100}
                helperText={
                  errors.corporationAbout?.licenseNo?.type !== 'maxLength'
                    ? errors.corporationAbout?.licenseNo?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.licenseNo}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.licenseNo2"
            control={control}
            rules={{ ...licenseNo2Rules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="有料職業紹介事業許可番号"
                value={value}
                placeholder="00-ユ-000000"
                maxLength={100}
                helperText={
                  errors.corporationAbout?.licenseNo2?.type !== 'maxLength'
                    ? errors.corporationAbout?.licenseNo2?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.licenseNo2}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.welfare"
            control={control}
            rules={{ ...welfareRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="福利厚生・待遇"
                value={value}
                placeholder="・社会保険完備（健康・労災・雇用・厚生年金）"
                maxLength={300}
                rows={8}
                helperText={
                  errors.corporationAbout?.welfare?.type !== 'maxLength'
                    ? errors.corporationAbout?.welfare?.message
                    : ''
                }
                hasLengthHelperText
                isError={!!errors.corporationAbout?.welfare}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="corporationAbout.business"
            control={control}
            rules={{ ...businessRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="事業内容"
                value={value}
                placeholder="インターネットによる求人情報提供サービス、DXサービスの提供"
                maxLength={160}
                //改行を許容する項目につき無制限にするとDBのデータ量が無尽蔵に増えてしまうため、160行に制限
                //という仕様が元々あったため、改行カウントの対象外にするためそのまま引き継ぐ
                maxNewLines={160}
                rows={8}
                helperText={
                  errors.corporationAbout?.business?.type !== 'maxLength'
                    ? errors.corporationAbout?.business?.message
                    : ''
                }
                hasLengthHelperText
                isRequired
                isError={!!errors.corporationAbout?.business}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default CorporationAboutAccordion;
