/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：公開画面API
 * GET系のAPIは原則として手前でキャッシュすることを前提とし、CompMgrNo単位でのキャッシュクリアを行うことを想定した作りにする
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MasterCityDto
 */
export interface MasterCityDto {
    /**
     * 市区町村コード
     * @type {string}
     * @memberof MasterCityDto
     */
    cityCode?: string;
    /**
     * 市区町村名
     * @type {string}
     * @memberof MasterCityDto
     */
    cityName?: string;
}

/**
 * Check if a given object implements the MasterCityDto interface.
 */
export function instanceOfMasterCityDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MasterCityDtoFromJSON(json: any): MasterCityDto {
    return MasterCityDtoFromJSONTyped(json, false);
}

export function MasterCityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MasterCityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cityCode': !exists(json, 'cityCode') ? undefined : json['cityCode'],
        'cityName': !exists(json, 'cityName') ? undefined : json['cityName'],
    };
}

export function MasterCityDtoToJSON(value?: MasterCityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cityCode': value.cityCode,
        'cityName': value.cityName,
    };
}

