import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  PolicySettingReferenceResDtoIsJointUseEnum,
  PolicySettingReferenceResDtoIsSameContactEnum,
  PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum
} from 'api-client/public';
import {
  CompanyNameSection,
  AffiliationNameSection,
  PersonalInformationProtectionManagerNameSection,
  TelOrMailOrUrlSection,
  MemoSection,
  IsJointUseSection,
  RangeOfJointUseSection,
  PersonalInformationProtectorTypeSection,
  PersonalInformationProtectorNameSection,
  IsSameContactSection,
  ContactTelOrMailOrUrlSection,
  ContactMemoSection
} from '../../summary';
import { EnhancedPolicyFormData } from '../../type';

const NormalForm: FC = () => {
  const { watch } = useFormContext<EnhancedPolicyFormData>();

  /**
   * 表示・非表示に使用する値
   */
  const isJointUseValue = watch('isJointUse');
  const personalInformationProtectorTypeValue = watch(
    'personalInformationProtectorType'
  );
  const isSameContactValue = watch('isSameContact');

  return (
    <>
      <CompanyNameSection />
      <AffiliationNameSection />
      <PersonalInformationProtectionManagerNameSection />
      <TelOrMailOrUrlSection />
      <MemoSection />
      <IsJointUseSection />
      {isJointUseValue ==
        PolicySettingReferenceResDtoIsJointUseEnum.NUMBER_1 && (
        <>
          <RangeOfJointUseSection />
          <PersonalInformationProtectorTypeSection />
          {personalInformationProtectorTypeValue ==
            PolicySettingReferenceResDtoPersonalInformationProtectorTypeEnum.Other && (
            <PersonalInformationProtectorNameSection />
          )}
        </>
      )}
      <IsSameContactSection />
      {isSameContactValue ==
        PolicySettingReferenceResDtoIsSameContactEnum.NUMBER_0 && (
        <>
          <ContactTelOrMailOrUrlSection />
          <ContactMemoSection />
        </>
      )}
    </>
  );
};

export default NormalForm;
