import { FC, useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  styled,
  useRadioGroup
} from '@mui/material';
import { TabType } from './TabType';

type Props = {
  label?: string;
  value: TabType;
};

/**
 * 画像アップロードモーダルのタブボタンを返す
 * @param {string | undefined} label ラベル
 * @param {TabType} value 値
 * @returns タブボタン
 */
const DialogTabButton: FC<Props> = ({ label = '', value }) => {
  const radioGroup = useRadioGroup();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (radioGroup) {
      setChecked(radioGroup.value === value);
    }
  }, [value, radioGroup]);

  return (
    <StyledFormControlLabel
      label={label}
      value={value}
      checked={checked}
      control={<Radio />}
    />
  );
};

export default DialogTabButton;

const StyledFormControlLabel = styled(
  (props: FormControlLabelProps & { checked: boolean }) => (
    <FormControlLabel {...props} />
  )
)(({ checked }) => ({
  display: 'flex',
  height: 40,
  padding: '5px 20px 5px 20px',
  alignItems: 'center',
  margin: 0,
  position: 'relative',
  '.PrivateSwitchBase-root': {
    display: 'none'
  },
  '.MuiFormControlLabel-label': {
    color: 'var(--kanri-secondary-text, #595959)',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%',
    ...(checked && {
      color: 'var(--kanri-primary, #3E91D5)'
    })
  },
  ...(checked && {
    '&:after': {
      content: '""',
      backgroundColor: 'var(--kanri-primary, #3E91D5)',
      width: '100%',
      position: 'absolute',
      height: '2px',
      bottom: '-2px',
      left: 0
    }
  }),
  '@media screen and (max-width:800px)': {
    padding: '5px 5px 5px 5px',
    '.MuiTypography-root': {
      fontSize: '10px'
    }
  }
}));
