/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageSettingRegisterEnvironmentInfoDto
 */
export interface PageSettingRegisterEnvironmentInfoDto {
    /**
     * 処理対象
     * @type {string}
     * @memberof PageSettingRegisterEnvironmentInfoDto
     */
    pageEnv?: PageSettingRegisterEnvironmentInfoDtoPageEnvEnum;
    /**
     * 最終更新日。画面が表示された時点でのデータの最終更新日をリクエストする想定。DBの最新データの更新日がこれより後になっていたら排他エラーを返す。      新規登録の場合はこのパラメータは不要だが、登録されたデータがあれば排他エラーに引っかかる。
     * @type {string}
     * @memberof PageSettingRegisterEnvironmentInfoDto
     */
    finalUpdatedAt?: string;
}


/**
 * @export
 */
export const PageSettingRegisterEnvironmentInfoDtoPageEnvEnum = {
    Preview: 'preview',
    Published: 'published'
} as const;
export type PageSettingRegisterEnvironmentInfoDtoPageEnvEnum = typeof PageSettingRegisterEnvironmentInfoDtoPageEnvEnum[keyof typeof PageSettingRegisterEnvironmentInfoDtoPageEnvEnum];


/**
 * Check if a given object implements the PageSettingRegisterEnvironmentInfoDto interface.
 */
export function instanceOfPageSettingRegisterEnvironmentInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSettingRegisterEnvironmentInfoDtoFromJSON(json: any): PageSettingRegisterEnvironmentInfoDto {
    return PageSettingRegisterEnvironmentInfoDtoFromJSONTyped(json, false);
}

export function PageSettingRegisterEnvironmentInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettingRegisterEnvironmentInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageEnv': !exists(json, 'pageEnv') ? undefined : json['pageEnv'],
        'finalUpdatedAt': !exists(json, 'finalUpdatedAt') ? undefined : json['finalUpdatedAt'],
    };
}

export function PageSettingRegisterEnvironmentInfoDtoToJSON(value?: PageSettingRegisterEnvironmentInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageEnv': value.pageEnv,
        'finalUpdatedAt': value.finalUpdatedAt,
    };
}

