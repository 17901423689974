import styles from '../style.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EnhancedPolicyFormData } from '../type';
import TextField from '../parts/TextField';
import { useRangeOfJointUseValidationRules } from '../validations/rangeOfJointUse';

/**
 * 共同利用の範囲セクションをレンダリングする
 * @returns {React.JSX.Element} 共同利用の範囲セクション
 */
export default function RangeOfJointUseSection() {
  const {
    control,
    formState: { errors }
  } = useFormContext<EnhancedPolicyFormData>();

  const rules = useRangeOfJointUseValidationRules();

  return (
    <div>
      <label className={`${styles[`policyEdit_form_default`]}`}>
        <span className={`${styles[`policyEdit_form_default_label`]}`}>
          共同利用の範囲（本契約企業名と一致しない会社名）
        </span>
        <div className={`${styles[`policyEdit_form_default_content`]}`}>
          <Controller
            name="rangeOfJointUse"
            control={control}
            rules={rules}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                value={value}
                placeholder={''}
                width={400}
                maxLength={300}
                hasHelperText={true}
                errorMessage={
                  errors.rangeOfJointUse?.type !== 'maxLength'
                    ? errors.rangeOfJointUse?.message
                    : ''
                }
                isError={!!errors.rangeOfJointUse}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </div>
      </label>
    </div>
  );
}
