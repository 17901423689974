import { CrownIcon } from 'assets/icons';
import { CSSProperties, FC } from 'react';

type Props = {
  title: string;
  className?: string;
  style?: CSSProperties;
  dispCrown?: boolean;
};

/**
 * アコーディオンヘッダーを返す
 * @param {string} title タイトル
 * @param {string | undefined} className クラス名
 * @param {CSSProperties} style 追加のCSSプロパティ
 * @param {boolean | undefined} dispCrown 王冠アイコンを表示するかどうか
 * @returns アコーディオンヘッダー
 */
const ContentAccHead: FC<Props> = ({
  title,
  className = '',
  style,
  dispCrown = false
}) => (
  <div
    className={className}
    style={{
      color: 'var(--kanri-primary-text, #333)',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '130%',
      display: 'flex',
      padding: '10px 0 5px',
      alignItems: 'center',
      gap: 10,
      alignSelf: 'stretch',
      ...style
    }}
  >
    {title}
    {dispCrown && (
      <CrownIcon
        style={{
          width: '14px',
          height: '12.16px',
          fill: 'var(--kanri-secondary-text, #595959)))'
        }}
      />
    )}
  </div>
);

export default ContentAccHead;
