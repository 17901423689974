import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import CustomSelectDialog from 'components/parts/customSelectDialog';
import SnackbarContext from 'context/saiyouPageEditing/SnackbarContext';
import useIconDownloader from 'hooks/saiyouPageEditing/useIconDownloader';
import LoadingCircularProgress from '../../common/LoadingCircularProgress';
import LazyIcon from './LazyIcon';
import './IconSelectDialog.scss';

type Props = {
  selectedIconPath?: string;
  isOpen?: boolean;
  onSelectIcon?: (icon: string) => void;
  onClickClose?: () => void;
};

/**
 * アイコン選択ダイアログを返す
 * @param {string | undefined} selectedIconPath 選択されたアイコンのファイルパス
 * @param {boolean | undefined} isOpen ダイアログが開いているか
 * @param {Function | undefined} onSelectIcon アイコンが選択された際に実行される関数
 * @param {Function | undefined} onClickClose 閉じるボタンがクリックされた際に実行される関数
 * @returns アイコン選択ダイアログ
 */
const IconSelectDialog: FC<Props> = ({
  selectedIconPath = '',
  isOpen = false,
  onSelectIcon,
  onClickClose
}) => {
  const [iconPaths, setIconPaths] = useState<string[]>([]);
  const [isLoadingIconPaths, setLoadingIconPaths] = useState(false);
  const [localSelectedIconPath, setLocalSelectIconPath] =
    useState(selectedIconPath);

  const { compId } = useParams();

  const { snackbar } = useContext(SnackbarContext);

  const { downloadIconsAsync } = useIconDownloader();

  const handleSelectIcon = useCallback((iconPath: string) => {
    setLocalSelectIconPath((current) => (iconPath !== current ? iconPath : ''));
  }, []);

  const handleClickFinish = useCallback(
    () => onSelectIcon?.(localSelectedIconPath),
    [onSelectIcon, localSelectedIconPath]
  );

  useEffect(() => {
    const downloadAsync = async (localCompId: string) => {
      setIconPaths([]);
      setLoadingIconPaths(true);

      const result = await downloadIconsAsync(localCompId);

      if (!result.isSuccess) {
        snackbar?.current?.open('serverError');
        return;
      }

      setIconPaths(result.iconPaths);
      setLoadingIconPaths(false);
    };

    if (compId && isOpen) {
      void downloadAsync(compId);
    }
  }, [isOpen, compId, snackbar, downloadIconsAsync]);

  useEffect(() => setLocalSelectIconPath(selectedIconPath), [selectedIconPath]);

  useEffect(() => {
    if (isOpen) {
      setLocalSelectIconPath('');
    }
  }, [isOpen]);

  return (
    <CustomSelectDialog
      title="アイコン選択"
      height={770}
      isOpen={isOpen}
      finishButtonContent={<>設定</>}
      onChangeSetOpen={(value) => {
        if (!value) onClickClose?.();
      }}
      onClickFinish={handleClickFinish}
    >
      <div className="IconSelectDialog">
        <RadioGroup value={localSelectedIconPath}>
          {isLoadingIconPaths ? (
            <LoadingCircularProgress />
          ) : (
            <div className="icons-wrap">
              {iconPaths.map((path) => (
                <div
                  key={path}
                  className={
                    'icon-wrap' +
                    (path === localSelectedIconPath ? ' checked' : '')
                  }
                >
                  <StyledFormControlLabel
                    value={path}
                    label={<LazyIcon filePath={path} />}
                    control={
                      <Radio
                        className="Radio"
                        checked={path === localSelectedIconPath}
                        onClick={() => handleSelectIcon(path)}
                      />
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </RadioGroup>
      </div>
    </CustomSelectDialog>
  );
};

export default IconSelectDialog;

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  flexDirection: 'column',
  margin: 0,
  alignItems: 'flex-start',
  padding: '10px',
  '& .MuiRadio-root': {
    marginBottom: '5px',
    paddingBottom: '0',
    color: 'var(--kanri-secondary-text, #595959)',
    '.Mui-checked': {
      color: 'var(--kanri-secondary-text, #595959)'
    }
  }
}));
