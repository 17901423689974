import { FC, RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../common/ContentAcc/ContentAcc';
import PageStyleSelector from '../../PageStyleSelector';
import TabContentInner from './shared/TabContentInner';
import TabContentSubHead from './shared/TabContentSubHead';
import TabContentText from './shared/TabContentText';
import TabContentWrapper from './shared/TabContentWrapper';

type Props = {
  sectionRef?: RefObject<HTMLDivElement>;
};

/**
 * 左ペインのデザイン設定セクションを返す
 * @param {RefObject<HTMLDivElement> | undefined} sectionRef デザイン設定セクションのref
 * @returns 左ペインのデザイン設定セクション
 */
const DesignSettingSection: FC<Props> = ({ sectionRef }) => {
  const {
    formState: { errors }
  } = useFormContext<FormData>();

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('design');

  return (
    <>
      <TabContentWrapper>
        <div id="design" ref={sectionRef}>
          <TabContentSubHead title="デザイン設定" />
        </div>
        <TabContentInner>
          <TabContentText text="TOPページのデザインや、求人一覧・詳細ページなどのカラーを設定できます。" />
        </TabContentInner>
      </TabContentWrapper>
      <TabContentWrapper>
        <ContentAcc
          ref={accordionRef}
          title="デザイン"
          dispCrown
          isExpanded={isExpanded}
          isRequired
          isError={errors.style && Object.keys(errors.style).length > 0}
          onChange={(value) => onChange?.('design', value)}
          onEntered={onEntered}
          onExited={onExited}
        >
          <TabContentInner>
            <TabContentText text="お好みのデザインを選択してください" />
            <PageStyleSelector />
          </TabContentInner>
        </ContentAcc>
      </TabContentWrapper>
    </>
  );
};

export default DesignSettingSection;
