/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う     
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageSettingAdminReferenceResDto,
  PageSettingCacheClearReqDto,
  PageSettingChangeOpenStatusReqDto,
  PageSettingInitialPreReferenceResDto,
  PageSettingInitialReferenceResDto,
  PageSettingRegisterReqDto,
} from '../models';
import {
    PageSettingAdminReferenceResDtoFromJSON,
    PageSettingAdminReferenceResDtoToJSON,
    PageSettingCacheClearReqDtoFromJSON,
    PageSettingCacheClearReqDtoToJSON,
    PageSettingChangeOpenStatusReqDtoFromJSON,
    PageSettingChangeOpenStatusReqDtoToJSON,
    PageSettingInitialPreReferenceResDtoFromJSON,
    PageSettingInitialPreReferenceResDtoToJSON,
    PageSettingInitialReferenceResDtoFromJSON,
    PageSettingInitialReferenceResDtoToJSON,
    PageSettingRegisterReqDtoFromJSON,
    PageSettingRegisterReqDtoToJSON,
} from '../models';

export interface ChangePageStatusRequest {
    compMgrNo: number;
    pageSettingChangeOpenStatusReqDto: PageSettingChangeOpenStatusReqDto;
}

export interface ClearPageCacheRequest {
    compMgrNo: number;
    pageSettingCacheClearReqDto: PageSettingCacheClearReqDto;
}

export interface GetInitialPageSettingRequest {
    compMgrNo: number;
    shok3CdList: Array<string>;
    siteTargetKoyoKeitai: GetInitialPageSettingSiteTargetKoyoKeitaiEnum;
    designType: GetInitialPageSettingDesignTypeEnum;
    color: GetInitialPageSettingColorEnum;
    dispCorpName: string;
}

export interface GetPageSettingRequest {
    compMgrNo: number;
}

export interface GetPreInitialPageSettingRequest {
    compMgrNo: number;
}

export interface RegisterPageSettingRequest {
    compMgrNo: number;
    pageSettingRegisterReqDto: PageSettingRegisterReqDto;
}

/**
 * 
 */
export class PageSettingManagementApi extends runtime.BaseAPI {

    /**
     *        本番の公開設定を変更する（ただし元のデータから変更がない場合はDB更新を行わない）       CloudFrontのキャッシュクリアも行う。       本番の採用ページ設定がない場合は404エラーを返す。        [個別認可ロジック]       下記のいずれかを満たしていなければ403エラーとする       ・ログインユーザーのロールコードが1・2・3のいずれか       ・該当のCompがフェニックス（新採コボ）契約されていない       
     * 採用ページ公開状態更新API
     */
    async changePageStatusRaw(requestParameters: ChangePageStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling changePageStatus.');
        }

        if (requestParameters.pageSettingChangeOpenStatusReqDto === null || requestParameters.pageSettingChangeOpenStatusReqDto === undefined) {
            throw new runtime.RequiredError('pageSettingChangeOpenStatusReqDto','Required parameter requestParameters.pageSettingChangeOpenStatusReqDto was null or undefined when calling changePageStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting/status`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PageSettingChangeOpenStatusReqDtoToJSON(requestParameters.pageSettingChangeOpenStatusReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *        本番の公開設定を変更する（ただし元のデータから変更がない場合はDB更新を行わない）       CloudFrontのキャッシュクリアも行う。       本番の採用ページ設定がない場合は404エラーを返す。        [個別認可ロジック]       下記のいずれかを満たしていなければ403エラーとする       ・ログインユーザーのロールコードが1・2・3のいずれか       ・該当のCompがフェニックス（新採コボ）契約されていない       
     * 採用ページ公開状態更新API
     */
    async changePageStatus(requestParameters: ChangePageStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePageStatusRaw(requestParameters, initOverrides);
    }

    /**
     *      CompMgrNo単位で採用ページ（外部公開プレビューか本番か）のCloudFrontのキャッシュ削除リクエストをSQSに投げる。     (リクエストパラメータのtargets次第でどちらのデータを更新するかが決まる)     クリア対象はpublicのバックエンドAPI・フロントエンド・CompMgrNo単位にアップロードされたS3の画像ファイル     （クリティカルになるものではないので、認可は共通認可ロジックのみで緩めにしておく）     
     * 採用ページキャッシュクリアAPI
     */
    async clearPageCacheRaw(requestParameters: ClearPageCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling clearPageCache.');
        }

        if (requestParameters.pageSettingCacheClearReqDto === null || requestParameters.pageSettingCacheClearReqDto === undefined) {
            throw new runtime.RequiredError('pageSettingCacheClearReqDto','Required parameter requestParameters.pageSettingCacheClearReqDto was null or undefined when calling clearPageCache.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting/cache`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PageSettingCacheClearReqDtoToJSON(requestParameters.pageSettingCacheClearReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *      CompMgrNo単位で採用ページ（外部公開プレビューか本番か）のCloudFrontのキャッシュ削除リクエストをSQSに投げる。     (リクエストパラメータのtargets次第でどちらのデータを更新するかが決まる)     クリア対象はpublicのバックエンドAPI・フロントエンド・CompMgrNo単位にアップロードされたS3の画像ファイル     （クリティカルになるものではないので、認可は共通認可ロジックのみで緩めにしておく）     
     * 採用ページキャッシュクリアAPI
     */
    async clearPageCache(requestParameters: ClearPageCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearPageCacheRaw(requestParameters, initOverrides);
    }

    /**
     *        リクエストをもとにバイトル管理APIの原稿情報をもとに初期設定値を返す。         リクエストされた職種をもとにお仕事紹介文言を返却         バイトル管理 原稿取得APIをリクエストし最新50件のデータを取得し、このデータをもとに下記の処理を行う           リクエストされた職種それぞれに紐づく画像URLを原稿情報から1つずつ取得し、お仕事のポイントのデフォルト値としてレスポンスとして返す             それぞれに関して、取得順の若い原稿から画像を取得する。（ただし元ファイル名が　_cw _pt _pit _it GAZO　の画像は優先度を下げる）           リクエストされた職種に紐づく案件の特徴コードを集計。特定の特徴コードを除き多い順に6つ特徴コード取得し、その上位の特徴コードに基づいて下記のデフォルト設定を生成             お仕事のポイント・よくある質問           バイトル管理 企業情報取得APIをリクエストし、このデータをもとに下記の処理を行う               取得した企業情報をデフォルト値として返却             キャッチフレーズが存在しない場合は、OpenAI APIからキャッチフレーズを生成                 生成に失敗した場合は「（表示用企業名）の採用サイト」という文字列をキャッチフレーズとして返す        その他のデフォルト値に関しては画面設計書参照       （GETなので認可は共通認可ロジックのみで緩めにしておく）             
     * 採用ページ初期設定取得API
     */
    async getInitialPageSettingRaw(requestParameters: GetInitialPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSettingInitialReferenceResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getInitialPageSetting.');
        }

        if (requestParameters.shok3CdList === null || requestParameters.shok3CdList === undefined) {
            throw new runtime.RequiredError('shok3CdList','Required parameter requestParameters.shok3CdList was null or undefined when calling getInitialPageSetting.');
        }

        if (requestParameters.siteTargetKoyoKeitai === null || requestParameters.siteTargetKoyoKeitai === undefined) {
            throw new runtime.RequiredError('siteTargetKoyoKeitai','Required parameter requestParameters.siteTargetKoyoKeitai was null or undefined when calling getInitialPageSetting.');
        }

        if (requestParameters.designType === null || requestParameters.designType === undefined) {
            throw new runtime.RequiredError('designType','Required parameter requestParameters.designType was null or undefined when calling getInitialPageSetting.');
        }

        if (requestParameters.color === null || requestParameters.color === undefined) {
            throw new runtime.RequiredError('color','Required parameter requestParameters.color was null or undefined when calling getInitialPageSetting.');
        }

        if (requestParameters.dispCorpName === null || requestParameters.dispCorpName === undefined) {
            throw new runtime.RequiredError('dispCorpName','Required parameter requestParameters.dispCorpName was null or undefined when calling getInitialPageSetting.');
        }

        const queryParameters: any = {};

        if (requestParameters.shok3CdList) {
            queryParameters['shok3CdList'] = requestParameters.shok3CdList;
        }

        if (requestParameters.siteTargetKoyoKeitai !== undefined) {
            queryParameters['siteTargetKoyoKeitai'] = requestParameters.siteTargetKoyoKeitai;
        }

        if (requestParameters.designType !== undefined) {
            queryParameters['designType'] = requestParameters.designType;
        }

        if (requestParameters.color !== undefined) {
            queryParameters['color'] = requestParameters.color;
        }

        if (requestParameters.dispCorpName !== undefined) {
            queryParameters['dispCorpName'] = requestParameters.dispCorpName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting/initial`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSettingInitialReferenceResDtoFromJSON(jsonValue));
    }

    /**
     *        リクエストをもとにバイトル管理APIの原稿情報をもとに初期設定値を返す。         リクエストされた職種をもとにお仕事紹介文言を返却         バイトル管理 原稿取得APIをリクエストし最新50件のデータを取得し、このデータをもとに下記の処理を行う           リクエストされた職種それぞれに紐づく画像URLを原稿情報から1つずつ取得し、お仕事のポイントのデフォルト値としてレスポンスとして返す             それぞれに関して、取得順の若い原稿から画像を取得する。（ただし元ファイル名が　_cw _pt _pit _it GAZO　の画像は優先度を下げる）           リクエストされた職種に紐づく案件の特徴コードを集計。特定の特徴コードを除き多い順に6つ特徴コード取得し、その上位の特徴コードに基づいて下記のデフォルト設定を生成             お仕事のポイント・よくある質問           バイトル管理 企業情報取得APIをリクエストし、このデータをもとに下記の処理を行う               取得した企業情報をデフォルト値として返却             キャッチフレーズが存在しない場合は、OpenAI APIからキャッチフレーズを生成                 生成に失敗した場合は「（表示用企業名）の採用サイト」という文字列をキャッチフレーズとして返す        その他のデフォルト値に関しては画面設計書参照       （GETなので認可は共通認可ロジックのみで緩めにしておく）             
     * 採用ページ初期設定取得API
     */
    async getInitialPageSetting(requestParameters: GetInitialPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSettingInitialReferenceResDto> {
        const response = await this.getInitialPageSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *        採用ページ設定(外部公開プレビュー用)と本番設定とをDBから取得する。       解約・非公開CompMgrNoに関してもデータが存在していればデータを返す。       データがなければ空データを返す。       
     * 採用ページ設定取得API(管理者用)
     */
    async getPageSettingRaw(requestParameters: GetPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSettingAdminReferenceResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getPageSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSettingAdminReferenceResDtoFromJSON(jsonValue));
    }

    /**
     *        採用ページ設定(外部公開プレビュー用)と本番設定とをDBから取得する。       解約・非公開CompMgrNoに関してもデータが存在していればデータを返す。       データがなければ空データを返す。       
     * 採用ページ設定取得API(管理者用)
     */
    async getPageSetting(requestParameters: GetPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSettingAdminReferenceResDto> {
        const response = await this.getPageSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *        バイトル管理 原稿取得APIをリクエストし最新50件のデータを取得し、このデータをもとに下記の処理を行う         雇用形態ごとの集計を行い、最も多い雇用形態コードを返す         小職種（1−3全て）ごとの集計を行い、多いものから最大2つ小職種コードを返す。         （同順位の場合はコードの若い順に返す）         1つ目の小職種をもとにデザインテーマを決定し返す       テーマカラーはグレーで固定         バイトル管理 企業情報取得APIをリクエストし、このデータをもとに下記の処理を行う         企業名を返す         （GETなので認可は共通認可ロジックのみで緩めにしておく）       
     * 採用ページプレ初期設定（初期設定を始めるのに必要なデータ）取得API
     */
    async getPreInitialPageSettingRaw(requestParameters: GetPreInitialPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSettingInitialPreReferenceResDto>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling getPreInitialPageSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting/pre-initial`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSettingInitialPreReferenceResDtoFromJSON(jsonValue));
    }

    /**
     *        バイトル管理 原稿取得APIをリクエストし最新50件のデータを取得し、このデータをもとに下記の処理を行う         雇用形態ごとの集計を行い、最も多い雇用形態コードを返す         小職種（1−3全て）ごとの集計を行い、多いものから最大2つ小職種コードを返す。         （同順位の場合はコードの若い順に返す）         1つ目の小職種をもとにデザインテーマを決定し返す       テーマカラーはグレーで固定         バイトル管理 企業情報取得APIをリクエストし、このデータをもとに下記の処理を行う         企業名を返す         （GETなので認可は共通認可ロジックのみで緩めにしておく）       
     * 採用ページプレ初期設定（初期設定を始めるのに必要なデータ）取得API
     */
    async getPreInitialPageSetting(requestParameters: GetPreInitialPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSettingInitialPreReferenceResDto> {
        const response = await this.getPreInitialPageSettingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     *       採用ページ（外部公開プレビューか本番か）設定をDBに反映する。           「表示・非表示」系の項目が「非表示」の場合になっている場合、かつリクエストパラメータが入ればバリデーション・DB登録は行う           テキストエリアで入力する文字列に関して特記事項がない限りは改行文字を許容しない           リクエストパラメータのtargets（複数可）次第でどちらのデータを更新するかが決まる           プレビューの場合はプレビュー請求用UUIDをランダムで生成しDBに保存する           リクエストパラメータlastUpdatedAtをもとに排他ロックチェックを行う。           外部公開プレビュー設定の場合は常に公開状態でレコードを登録、本番設定の場合は最新の状態を引き継いでレコードを登録        プレビューでも本番でも使われていない画像データを検索し削除する。       CloudFrontのキャッシュクリアも行う        [個別認可ロジック]       targetsに本番が含まれる場合　かつ　下記のいずれかを満たしていなければ403エラーとする       ・ログインユーザーのロールコードが1・2・3のいずれか       ・該当のCompがフェニックス（新採コボ）契約されていない       
     * 採用ページ設定登録API
     */
    async registerPageSettingRaw(requestParameters: RegisterPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.compMgrNo === null || requestParameters.compMgrNo === undefined) {
            throw new runtime.RequiredError('compMgrNo','Required parameter requestParameters.compMgrNo was null or undefined when calling registerPageSetting.');
        }

        if (requestParameters.pageSettingRegisterReqDto === null || requestParameters.pageSettingRegisterReqDto === undefined) {
            throw new runtime.RequiredError('pageSettingRegisterReqDto','Required parameter requestParameters.pageSettingRegisterReqDto was null or undefined when calling registerPageSetting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // AccessToken authentication
        }

        const response = await this.request({
            path: `/api/management/{compMgrNo}/page-setting`.replace(`{${"compMgrNo"}}`, encodeURIComponent(String(requestParameters.compMgrNo))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PageSettingRegisterReqDtoToJSON(requestParameters.pageSettingRegisterReqDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *       採用ページ（外部公開プレビューか本番か）設定をDBに反映する。           「表示・非表示」系の項目が「非表示」の場合になっている場合、かつリクエストパラメータが入ればバリデーション・DB登録は行う           テキストエリアで入力する文字列に関して特記事項がない限りは改行文字を許容しない           リクエストパラメータのtargets（複数可）次第でどちらのデータを更新するかが決まる           プレビューの場合はプレビュー請求用UUIDをランダムで生成しDBに保存する           リクエストパラメータlastUpdatedAtをもとに排他ロックチェックを行う。           外部公開プレビュー設定の場合は常に公開状態でレコードを登録、本番設定の場合は最新の状態を引き継いでレコードを登録        プレビューでも本番でも使われていない画像データを検索し削除する。       CloudFrontのキャッシュクリアも行う        [個別認可ロジック]       targetsに本番が含まれる場合　かつ　下記のいずれかを満たしていなければ403エラーとする       ・ログインユーザーのロールコードが1・2・3のいずれか       ・該当のCompがフェニックス（新採コボ）契約されていない       
     * 採用ページ設定登録API
     */
    async registerPageSetting(requestParameters: RegisterPageSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerPageSettingRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetInitialPageSettingSiteTargetKoyoKeitaiEnum = {
    Normal: 'normal',
    Regular: 'regular',
    Contract: 'contract',
    Haken: 'haken',
    Outsg: 'outsg',
    NlimEhaken: 'nlim_ehaken',
    SyokaiRhaken: 'syokai_rhaken'
} as const;
export type GetInitialPageSettingSiteTargetKoyoKeitaiEnum = typeof GetInitialPageSettingSiteTargetKoyoKeitaiEnum[keyof typeof GetInitialPageSettingSiteTargetKoyoKeitaiEnum];
/**
 * @export
 */
export const GetInitialPageSettingDesignTypeEnum = {
    Basic: 'basic',
    Stylish: 'stylish',
    Pop: 'pop',
    Natural: 'natural'
} as const;
export type GetInitialPageSettingDesignTypeEnum = typeof GetInitialPageSettingDesignTypeEnum[keyof typeof GetInitialPageSettingDesignTypeEnum];
/**
 * @export
 */
export const GetInitialPageSettingColorEnum = {
    Red: 'red',
    Orange: 'orange',
    Green: 'green',
    Blue: 'blue',
    Gray: 'gray',
    Purple: 'purple'
} as const;
export type GetInitialPageSettingColorEnum = typeof GetInitialPageSettingColorEnum[keyof typeof GetInitialPageSettingColorEnum];
