import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import EmploymentStatusSelectBox from '../../../SharedFields/EmploymentStatusSelectBox';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「ターゲット」アコーディオンを返す
 * @returns 「ターゲット」アコーディオン
 */
const TargetAccordion: FC = () => {
  const {
    formState: { errors }
  } = useFormContext<FormData>();

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('target');

  return (
    <ContentAcc
      ref={accordionRef}
      title="ターゲット"
      isExpanded={isExpanded}
      isRequired
      isError={!!errors.employmentStatus}
      onChange={(value) => onChange?.('target', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="採用サイトのメインターゲットを設定できます。サイトタイトルやサイト説明文などにも使用されます。" />
        <TabContentFormWrap>
          <EmploymentStatusSelectBox label="雇用形態" />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default TargetAccordion;
