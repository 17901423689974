import * as React from 'react';
import { styled } from '@mui/material/styles';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';

interface CustomListItemIconProps extends ListItemIconProps {
  open: boolean;
  transitionduration: string;
}
/**
 * muiのListItemButtonを上書き
 */
const CustomListItemButton = styled(ListItemIcon)<CustomListItemIconProps>(
  ({ open, transitionduration }) => ({
    minWidth: 0,
    mr: open ? 3 : 'auto',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    opacity: open ? 0 : 1,
    transitionDuration: transitionduration
  })
);
/**
 * メニュー内のユーザー情報項目のボタンベース
 * @param children 子要素
 * @param open ドロワーの開閉判定
 * @param transitionduration ドロワーを開閉した時のアニメーションのduration
 * @returns
 */
interface UserIconBaseProps {
  children: React.ReactNode;
  open: boolean;
  transitionduration: string;
}
export const UserIconBase = React.memo(
  ({ children, open, transitionduration }: UserIconBaseProps) => {
    return (
      <CustomListItemButton open={open} transitionduration={transitionduration}>
        {children}
      </CustomListItemButton>
    );
  }
);
