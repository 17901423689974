import { FC, MouseEventHandler } from 'react';
import { CustomButton } from 'components/parts/CustomButton';

type Props = {
  isHoverDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * 閉じるボタンを返す
 * @param {boolean | undefined} isHoverDisabled ホバーが無効であるか
 * @param {MouseEventHandler<HTMLButtonElement> | undefined} onClick クリックされた際に実行される関数
 * @returns 閉じるボタン
 */
const CloseButton: FC<Props> = ({ isHoverDisabled = false, onClick }) => (
  <CustomButton
    disabled={false}
    width={'200px'}
    height={'44px'}
    textcolor={'var(--kanri-secondary-text)'}
    border="1px solid"
    bordercolor="#CCC"
    backgroundcolor="white"
    hoveropacity={isHoverDisabled ? '1' : '0.85'}
    hoverborder="1px solid"
    hoverbordercolor="#CCC"
    onClick={onClick}
  >
    <span style={{ fontSize: '14px' }}>閉じる</span>
  </CustomButton>
);

export default CloseButton;
