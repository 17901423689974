import { FC } from 'react';
import noSetImage from 'assets/img/noSetImage.png';

type Props = {
  selectedImageUrl?: string;
};

/**
 * 選択された画像のプレビューを返す
 * @param {string | undefined} selectedImageUrl 選択された画像のURL
 * @returns 選択された画像のプレビュー
 */
const PreviewImage: FC<Props> = ({ selectedImageUrl = '' }) => {
  return (
    <div style={{ width: '100%', height: '100px', margin: '0 auto 5px' }}>
      <img
        src={selectedImageUrl || noSetImage}
        alt="プレビュー"
        style={{
          width: 'auto',
          height: '100%',
          objectFit: 'contain',
          margin: '0 auto'
        }}
      />
    </div>
  );
};

export default PreviewImage;
